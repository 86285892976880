import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

interface BtnProps {
  classes: any;
  handleClick?: any;
  btnText?: string;
  backgroundColor?: string;
  color?: string;
  classNames?: any;
  height?: string;
  width?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

class CommonButton extends Component<BtnProps> {
  constructor(props: BtnProps) {
    super(props);
  }

  render() {
    const {
      btnText,
      classes,
      handleClick,
      backgroundColor,
      color,
      classNames,
      height,
      width,
      disabled,
      type
    } = this.props;

    const buttonStyle = {
        backgroundColor: disabled ? "#979797" : backgroundColor || '#E5B632',
        color: color || '#FFFFFF',
        width: width || '210px',
        height: height || '42px',
      };

    return (
      <Button
        className={`${classes?.btn} ${classNames}`}
        onClick={handleClick} 
        style={buttonStyle}
        disabled={!!disabled}
        type={type || "button"}
      >
        {btnText || "Click"}
      </Button>
    );
  }
}

const styles: any = {
   btn : {
    backgroundColor: "white",
    borderRadius: "4px",
    fontFamily: "'Plus Jakarta Sans'",
    "& .MuiButton-label": {
      fontStyle: "normal",
      lineHeight: "20px",
      textAlign: "center",
      letterSpacing: "-0.24px",
      fontFamily: "'Plus Jakarta Sans'",
      fontWeight: 600,
      fontSize: "16px",
      textTransform: "capitalize",
      marginTop: "-2px",
    },
  },
};

export default withStyles(styles)(CommonButton);
