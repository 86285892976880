export const styles: any = {
  DashboardContainerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "840px",
    width:"calc(100vw - 286px)"
  },
  AnalyticsBar: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "5px",
    padding: "15px 20px",
    backgroundColor: "rgb(143, 146, 161, 5%)"
  },
  gapProvider: {
    minHeight: "30px",
    backgroundColor: "white"
  },
  ChartAndNewsOverview: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px 10px",
    backgroundColor: "rgb(143, 146, 161, 5%)"
  },
  cardHolder: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  MainCardDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    backgroundColor: "#fff",
    height: "112px",
    width: "100%",
    borderRadius: "10px"
  },
  divMainTitle: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "-0.4px",
    textAlign: "left",
    marginLeft: "20px"
  },
  cardTitle: {
    fontWeight: 800,
    fontSize: "12px",
    textAlign: "center",
    color: "#000000"
  },
  cardDetails: {
    fontWeight: 800,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: "24px",
    textAlign: "center",
    color: "#E5B632",
    "& .bold": {
      color: "#E5B632",
      textAlign: "center",
      fontFamily: 'Plus Jakarta Sans',
      fontSize: "24px",
      fontWeight: 800,
    }
  },
  creditPaymentClass:{
    color: "rgba(32, 187, 110, 1) !important",
    "& .bold": {
      color: "rgba(32, 187, 110, 1) !important",
    }
  },
  cardDue: {
    fontWeight: 500,
    fontSize: "8px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#000000"
  },
  chartHolder: {
    backgroundColor: "#ffffff",
    borderRadius: "16px"
  },

  factorHolder: {
    borderBottom: "1px solid #8080803d",
    padding: "30px 28px",
    fontSize: "18px",
    fontWeight: 800
  },
  newsHolder: {
    backgroundColor: "#ffffff",
    borderRadius: "16px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    maxHeight:'520px',
    position:'relative',
    "&::after":{
      position: "absolute",
      content:"''",
      width:'100%',
      height: '70px',
      background: 'linear-gradient(transparent,rgba(255, 255, 255, 1))',
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
      bottom:'40px',
      left: 0
    } 
  },
  tooltip: {
    backgroundColor: "white",
    color: "#000000",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    borderRadius: "8px",
    textAlign: "center",
    boxShadow: "1px 3px 6px 0px rgb(0 0 0 / 43%)",
    border: "0.5px solid rgb(250,250,250)",
    maxWidth: 500,
    padding: "12px",
    "& span": {
        color:'#fff',
        "&::before": {
            boxShadow: '12px 20px 20px 2px rgba(0, 0, 0, 0.3)',
            backgroundColor: 'white'
        }
    }
},
  boxLable: {
    padding: "30px 28px 20px 28px",
    fontSize: "18px",
    fontWeight: 700
  },
  newsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    height:'100%',
    width: "100%",
    overflow: "auto",
    scrollbarWidth: "thin",
    position:'relative',
    scrollbarColor: "rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1)",
    "&::-webkit-scrollbar": {
      width: "6px",
      height: "6px" // Adjust the height value as desired
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      borderRadius: "6px"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.4)"
    },
  },
  singleNewsContainer: {
    padding: "0px 20px",
    minHeight: "106px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap:'10px',
  },
  borderBottomProvider: {
    borderBottom: "1px solid #E4E6E8",
    marginTop:'15px'
  },
  singleNewsTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .singleNewsDate": {
      fontSize: "10px",
      fontWeight: 600,
      color: "#8F92A1",
      textTransform: "capitalize",
      maxWidth: "100%",
    }
  },
  titleHolderDiv:{
    display: "flex",
    gap:'10px',
    alignItems: "center",
    width:'90%',
    "& .singleNewsTitle": {
      fontSize: "16px",
      fontWeight: 600,
      color: "#222222",
      textTransform: "capitalize",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer'
    },
  },
  singleNewsDescriptionDiv: {
    minHeight: "60px",
    "& .singleNewsDescription": {
      fontSize: "12px",
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 4,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  },
  singleNewsAutherDiv: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& .singleNewsAutherName": {
      fontSize: "12px",
      fontWeight: 600,
      "& .MuiButton-text ":{
        color:'#E5B632',
        padding:0,
        fontSize: "12px",
        fontWeight: 600,
        fontFamily: "Plus Jakarta Sans",
        textTransform: "capitalize",
      }
    }
  },
  viewAllButton: {
    height: "54px",
    width: "100%",
    textAlign: "center",
    fontWeight: 600,
    fontFamily: "Plus Jakarta Sans",
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    "&:hover": {
      borderBottomLeftRadius: "16px",
      borderBottomRightRadius: "16px"
    }
  },
  buttonEffect:{
    width: '100%',
    zIndex: 100,
    background: 'white',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  chartLabelData:{     
    flexDirection:"row",
    alignItems:"center",
    display:"flex",
    gridGap:"10px",
    fontFamily: 'Plus Jakarta Sans',
    width:"80%",
    marginBottom: "10px",
    "& .box":{
      width: "18px",
      borderRadius: "4px",
      height: "18px",
    },
    "& .value":{
      fontSize: "14px",
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: 800,
    },
    "& .labelValue":{
      marginLeft: "auto",
      "& .value":{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: "14px",
        fontWeight: 800,
      }
    }                
  },
  maxHeightProvider:{
    maxHeight:'100%'
  },
  '@media (max-width: 600px)': {
    DashboardContainerDiv:{
        width:"100vw"
    },
},
};
