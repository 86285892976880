import { BlockComponent } from '../../../framework/src/BlockComponent';
import * as Yup from "yup";
export const configJSON = require("./config");
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from '../../../framework/src/IBlock';

export interface Props {
  navigation: any;
  classes: any;
}

export type token = string | null

interface S {
  bankName:string | null,
  bankBranch:string | null,
  bankAddress:string | null,
  bankAccountName:string | null,
  bankAccountNumber:string | null,
  bankAccountCurrency:string | null,
  bankAccountType:string | null,
  businessBackground:string | null,
  taxLiabilities:string ,
  licensesCurrent:string ,
  isAdmin: boolean;
  accountId:string;
  currentLiabilities:string | null,
  currentLicense:string | null,
  navigation:any,
  apiError : string,
  token: token,
}

interface SS {
  id: any;
}

export default class EKYC2Controller extends BlockComponent<Props, S, SS> {

  ekycApi2: string = ''
  getEkycData:string = ''

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    
    this.state = {
      bankName:'',
      bankBranch:'',
      bankAddress:'',
      bankAccountName:'',
      bankAccountNumber:'',
      bankAccountCurrency:'JMD',
      isAdmin: false,
      accountId:'',
      bankAccountType:'',
      businessBackground:'',
      taxLiabilities:'false',
      licensesCurrent:'false',
      currentLiabilities:'',
      currentLicense:'',
      navigation:'',
      apiError : '',
      token: '',
    }
    
}

async componentDidMount() {
  window.scrollTo(0, 0)

  if (!localStorage.getItem("auth_token")) {
    this.props.navigation.navigate("EmailLogin");
  } else {
    const token = localStorage.getItem("auth_token");
    const role = localStorage.getItem("account_role");
    const pathCheck = window.location.pathname
    this.setState({ token: token });
    if ( role === "admin" || role === "analyst" || pathCheck.includes('admin') ) {
      const path = pathCheck.split('/')
      const id = path[path.length - 1];
      this.setState({
        isAdmin: true,
        accountId: id,
      })
      this.getEkycData = this.apiCall(token, `get_ekyc_detail?account_id=${id}` , "GET");
    }else{
    this.getEkycData = this.apiCall(token,"get_ekyc_detail","GET")
    }
  }
}

handleErrors = (error:any, touch:any) =>{
  return touch && Boolean(error);
}

handleHelperText = (text:any, touch:any) => {
  return touch && text
}

async receive(from: string, message: Message) {
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      this.receiveApi(apiRequestCallId, responseJson);
    }
  }
}

receiveApi = (apiRequestCallId: any, responseJson: any) => {
  if (apiRequestCallId === this.ekycApi2) {
    if (responseJson.data || responseJson.meta) {
      const adminRedirection = { id: this.state.accountId}
      this.state.isAdmin ? this.props.navigation.navigate("AdminKyc3Review" , adminRedirection) : this.props.navigation.navigate("EKYC3");
      this.setState({ apiError: "" });
    } else {
      const errorMessages = responseJson?.error || '';
      this.setState({ apiError: errorMessages });
    }
  }
  if( apiRequestCallId === this.getEkycData ){
    this.handleGetKycData(responseJson)
  }
  
}

handleFormSubmit = (values : any ) => {
  if (!!this.state.token && !!values ) {
    this.setSubmit(values)
  }
}

handleGetKycData = (responseJson:any) =>{
  const data = responseJson.data.attributes
  this.setState({
    bankName:data.bank_name,
    bankBranch:data.bank_branch,
    bankAddress:data.bank_address,
    bankAccountName:data.business_account_name,
    bankAccountNumber:data.business_account_number,
    bankAccountCurrency:data.business_account_currency || "USD",
    bankAccountType:data.business_account_type,
    businessBackground:data.business_background,
    taxLiabilities:data.tax_liabilities || "false",
    licensesCurrent:data.relevant_licenses || "false",
    currentLiabilities:data.list_current_liabilities,
    currentLicense:data.current_relevant_licenses,
  })
}


setSubmit = (values : any) => {
  const { token , isAdmin , accountId } = this.state
  const endPoint = isAdmin ? `/kyc_update?account_id=${accountId}` : "kyc_update"
  const header = {
    "Content-Type": "application/json",
    token
  }

  const httpBody = {
    ...values
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  )

  this.ekycApi2 = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  )

  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

 runEngine.sendMessage(requestMessage.id, requestMessage)
};

  apiCall = (token: token, endPoint: string, method: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
  }

}
