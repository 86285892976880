import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";


export interface Props {
  classes: any;
  navigation: any;
}

type token = string | null

interface S {
    isRevenueSelected: boolean;
    isVelocitySelected: boolean;
    isPastDueSelected: boolean;
    isAuditTrailSelected: boolean;
}

interface SS {
  id: any;
}

export default class CompanyReportsTabsController extends BlockComponent<Props, S, SS> {
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
        isRevenueSelected: false,
        isVelocitySelected: false,
        isPastDueSelected: false,
        isAuditTrailSelected: false,
    };
  }

  async componentDidMount() {
    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else{
      const path = window.location.pathname;
      if(path.includes('/admin/company-reports/past-due')){
        this.setState({ isPastDueSelected: true });
      } else if (path.includes('/admin/company-reports/velocity')){
        this.setState({ isVelocitySelected: true });
      } else {
        this.setState({ isRevenueSelected: true });
      }
    }
  }

  handleRevenueSelection = () => {
    this.props.navigation.navigate("AdminCompanyReports") 
  }

  handleVelocitySelection = () => {
    this.props.navigation.navigate("AdminCompanyReportsVelocity") 
  }
  
  handlePastDueSelection = () => {
    this.props.navigation.navigate("AdminCompanyReportsPastDue")  
  }
  
  handleAuditTrailSelection = () => {
    this.props.navigation.navigate("AdminCompanyReportsAuditTrial")  
  }

}
