import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { FileUploadValidationSchema } from "../../../components/src/Utils/validations"
import { transformData, mergeFiles } from "../../../components/src/Utils/utils"
import { IBlock } from "../../../framework/src/IBlock";


export interface Props {
  classes: any;
  navigation: any;
}

type token = string | null

type snackBar = {
  open: boolean,
  message: string,
  severity: string
}

type arrayOfFileToShow = {
  id: number;
  file: File;
};

interface S {
  token: token;
  isLoading: boolean;
  snackbar: snackBar;
  documents: any;
  agreementDate: string;
  accountId: number;
  isDataAvialable: boolean;
  existingDocuments: any;
  companyName: string | null;
  fieldError: string;
  isError: boolean;
  isModalOpen:boolean;
}

interface SS {
  id: any;
}

export default class FactorAgreemenetReviewController extends BlockComponent<Props, S, SS> {
  getInitialData: string = ''
  postFactorAgreementData: string = ''
  deleteDocumentbyID: string = ''

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      token: '',
      isLoading: false,
      documents: [],
      snackbar: {
        open: false,
        message: "",
        severity: "",
      },
      fieldError: '',
      isError: false,
      agreementDate: '',
      accountId: 0,
      isDataAvialable: false,
      existingDocuments: [],
      companyName: '',
      isModalOpen:false
    };
  }

  async componentDidMount() {
    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else {
      const path: any[] = window.location.pathname.split("/");
      const token = localStorage.getItem("auth_token");
      const id = path[path.length - 1];
      this.setState({
        token: token,
        accountId: Number(id),
        isLoading: true
      });
      this.getInitialData = this.apiCall(token, `/get_factoring_agreement_documentation?account_id=${id}`, "GET")
    }
  }

  handleDocUpload = (Files: File[]) => {
    const existingFileIds = this.state.existingDocuments.map((file: any) => file.id);
    const existingFilesCount = existingFileIds.length;
    let startingId = existingFilesCount > 0 ? existingFileIds[existingFilesCount - 1] + 1 : 1;

    if (this.state.documents.length > 0) {
      const documentIds = this.state.documents.map((file: any) => file.id);
      const maxDocumentId = Math.max(...documentIds);

      startingId = Math.max(startingId, maxDocumentId + 1);
    }

    Files.forEach((fileToUpload: any, index: number) => {
      FileUploadValidationSchema
        .validate({ file: fileToUpload })
        .then(() => {
          const newId = startingId + index;

          const setDocuments: arrayOfFileToShow[] = [
            ...this.state.documents,
            ...Files.map((file, i) => ({
              id: newId + i,
              file: file,
              url: URL.createObjectURL(file), // Create URL from the uploaded file
            }))
          ];
          this.setState({
            documents: setDocuments,
          });
        })
        .catch((error: any) => {
          this.setState({
            snackbar: {
              open: true,
              message: error.message,
              severity: "error",
            }
          });
        })
    });
  }

  deleteDoc = (id: number) => {
    const updatedDocuments = this.state.documents.filter((file: any) => file.id !== id);
    this.setState({
      documents: updatedDocuments,
    });
  }

  handleDeleteDocFromAPI = (id: number) => {
    this.setState({
      isLoading: true
    })
    this.deleteDocumentbyID = this.apiCall(this.state.token, `kyc_document_delete?attachment_id=${id}`, "PUT")
  }

  handleDateChange = (date: string) => {
    this.setState({ agreementDate: date, isError: false, fieldError: '' })
  }

  handleInitialRendering = (response?: any) => {
    if (response.errors) {
      this.setState({
        isLoading: false
      })
    } else {

      const companyName = response.data?.attributes.company_name || ""
      const agreementDate = new Date(response.data?.attributes.agreement_date);
      const year = agreementDate.getFullYear();
      const month = String(agreementDate.getMonth() + 1).padStart(2, '0');
      const day = String(agreementDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      if (response.data?.attributes?.documents?.length) {
        const updatedDocuments = transformData(response.data.attributes.documents)
        this.setState({
          existingDocuments: updatedDocuments,
          agreementDate: formattedDate,
          isLoading: false,
          isDataAvialable: true,
          companyName: companyName
        })
      } else if (response.data?.attributes?.agreement_date) {
        this.setState({
          agreementDate: formattedDate,
          isLoading: false,
          companyName: companyName,
          isDataAvialable: false,
          existingDocuments: []
        })
      } else {
        this.setState({
          isLoading: false,
          isDataAvialable: false,
          companyName: companyName,
          existingDocuments: [],
          agreementDate: '',
        })
      }
    }
  }

  handleDeleteAPIResponse = () => {
    const { token, accountId } = this.state;
    this.getInitialData = this.apiCall(token, `get_factoring_agreement_documentation?account_id=${accountId}`, "GET")
  }

  handleFormSubmission = () => {
    const { token, accountId, existingDocuments, documents, agreementDate } = this.state;
    const mergedData = mergeFiles(existingDocuments, documents);

    if (!agreementDate || mergedData.length === 0) {
      if (!agreementDate) {
        this.setState({
          isError: true,
          fieldError: "Please select a date before continuing",
        });
      }

      if (mergedData.length === 0) {
        this.setState({
          snackbar: {
            open: true,
            message: "Please upload at least one document before continuing",
            severity: "error",
          },
        });
      }

      return;
    }

    this.setState({ isLoading: true, isError: false, fieldError: "" });

    const body = {
      id: accountId,
      date: agreementDate,
      documents: mergedData,
    };

    this.postFactorAgreementData = this.apiCall(
      token,
      "factoring_agreement_documentation",
      "POST",
      body
    );

    this.setState({
      documents:[],
      existingDocuments:[],
      agreementDate:'',
    })
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: "",
        severity: "",
      },
    });
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false})
  }
  
  handleRedirection = () => {
    this.props.navigation.navigate("ActiveTabs")
  }

  handlePostApiResponse = (response:any) => {
    if(response.data){
      this.setState({ isModalOpen: true})
      this.handleDeleteAPIResponse();
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getInitialData) {
      this.handleInitialRendering(responseJson);
    }
    if (apiRequestCallId === this.deleteDocumentbyID) {
      this.handleDeleteAPIResponse();
    }
    if (apiRequestCallId === this.postFactorAgreementData) {
      this.handlePostApiResponse(responseJson)
    }

  }

  apiCall = (token: token, endPoint: string, method: string, body?: any) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const bodyHeader = {
      token: token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    if (body) {

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(bodyHeader)
      );

      const fd = new FormData()

      fd.append('account_id', body.id);
      fd.append('agreement_date', body.date);
      body?.documents?.forEach((element: File) => {
        fd.append('documents[]', element)
      });

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        fd
      )

    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
  }

}
