export const styles: any = {
    FactorAgreementDiv: {
        marginTop: '20px'
    },
    tableHolder:{
        border: '1px solid gray',
        borderRadius: '12px',
    },
    tableDataHolder: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    leftDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
        alignItems: 'center'
    },
    collapse: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 10px',
        alignItems: 'center'
    },
    fileNameWithIcon: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    },
    isReviewed: {
        width: "94px",
        height: "28px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#20BB6E",
        border: "1.0757px solid rgba(0, 0, 0, 0.08)",
        borderRadius: "5.37848px",
        fontFamily: "Arial, sans-serif",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "13px",
        leadingTrim: "both",
        textEdge: "cap",
        color: "#fff",
        opacity: '0.8',
        pointerEvents: 'none',
    },
    notReviewed: {
        width: "94px",
        height: "28px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#FFFFFF",
        border: "1.0757px solid rgba(0, 0, 0, 0.08)",
        borderRadius: "5.37848px",
        fontFamily: "Arial, sans-serif",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "13px",
        leadingTrim: "both",
        textEdge: "cap",
        color: "#979797",
    },
    widthProviderCell1: {
        width: '45%'
    },
    widthProviderCell2: {
        width: '20%'
    },
    widthProviderCell3: {
        width: '20%'
    },
}