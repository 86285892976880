import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { Cell, Label, Pie, PieChart } from "recharts";
import { returnHoverCurrencyFormat } from "../Utils/parseData";

const COLORS = ["#E5B632", "#FEF8E6"];

interface ChartProps {
  classes:any,
  name:string,
  percentage: number | null ,
  monetary_value: string | null ,
  width?: number,
  height?: number,
  colors?: string[],
}

const chartStyles: any = {
  chartContainer:{
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "23px",
    "& .legendBox":{
      alignItems:"center",
      display:"flex",
      gap:"5px",
      "& .colorBox":{
        width: "20px",
        height: "20px",
        borderRadius: "4px",
        backgroundColor: "#E5B632",
      },
      "& .textContainer":{
        lineHeight: "17px",
        color:"#333",
        alignItems:"center",
        display:"flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: "14px",
        "& span":{
          marginLeft: "8px",
          color:"#000",
          fontFamily: 'Plus Jakarta Sans',
          lineHeight: "22px",
          fontSize: "18px",
          fontWeight: 800,
        }
      }
    }
  },
  middleLabel:{
    fill: 'black',
    fontSize: "28px",
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 800,
    wordWrap: 'break-word'
  }
};

class AdminDashbordInvoicePieChart extends React.Component<ChartProps> {
  render() {

    const { width, height, name, percentage, monetary_value, classes} = this.props;
    const chartData = [{name:name, value:percentage ?? 0}, {name:"", value: Math.abs(100-Number(percentage))}]
    return (
      <div className={classes?.chartContainer}>
      <PieChart width={width ?? 240} height={height ?? 240}>
        <Pie
          data={chartData}
          cx={width ? width/2 : 120}
          cy={height ? height/2 : 120}
          innerRadius={65}
          outerRadius={114}
          dataKey="value"
          startAngle={90}
          endAngle={-270}
          fill="#FEF8E6" 
        >
          <Cell fill={"#E5B632"} />
          <Label value={`${percentage ?? 0}%`} position="center" className={classes?.middleLabel} />
        </Pie>
      </PieChart>
        <div className="legendBox">
          <div className="colorBox"/>
          <div className="textContainer">
            {" "}{name}:
            {returnHoverCurrencyFormat(monetary_value ?? "$ 0", "")}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(chartStyles)(AdminDashbordInvoicePieChart);
