//  @ts-nocheck
import { withStyles } from "@material-ui/core/styles";
import { Box,Typography ,Button  } from "@material-ui/core";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { styles } from "./styles";

const TwoDateSelector = ({
  applyFunction,
  startDate,
  endDate,
  classes,
  handleClose,
  isCurrentrequired=true,
  isCustomRequired=true,
  isPreviousRequired=false,
  extraDates={startDate, endDate}
}: any) => {
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate:new Date(startDate) || new Date(),
    endDate:new Date(endDate) || new Date(),
    key: "selection"
  });

  const [activeDateOption, setActiveDateOption] = useState("this_month");
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());
  const [isCurrentOptionsrequired , setIsCurrentOptionsRequired] = useState(isCurrentrequired)
  const [isCustomOptionsRequired , setIsCustomOptionsRequired] = useState(isCustomRequired)
  const [isPreviousOptionsRequiredrequired , setIsPreviousOptionsRequiredRequired] = useState(isPreviousRequired)
  const [useExtraDates, setUseExtraDates] = useState(extraDates);
  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
    setActiveDateOption("custom");
    setCustomStartDate(ranges.selection.startDate); 
    setCustomEndDate(ranges.selection.endDate); 
  };

  const handleApply = () => {
    applyFunction(selectedDateRange.startDate, selectedDateRange.endDate);
  };

  const handleCancel = () => {
    handleClose()
  };

  const handleDateOptions = (dateOption: string) => {
    let startDate = new Date();
    let endDate = new Date();

    if (dateOption === "this_month") {
      startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      endDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      );
    } else if (dateOption === "this_quarter") {
      const quarter = Math.floor(new Date().getMonth() / 3);
      startDate = new Date(new Date().getFullYear(), quarter * 3, 1);
      endDate = new Date(new Date().getFullYear(), (quarter + 1) * 3, 0);
    } else if (dateOption === "this_half_year") {
      const halfYear = new Date().getMonth() < 6 ? 0 : 1;
      startDate = new Date(new Date().getFullYear(), halfYear * 6, 1);
      endDate = new Date(new Date().getFullYear(), (halfYear + 1) * 6, 0);
    } else if (dateOption === "this_year") {
      startDate = new Date(new Date().getFullYear(), 0, 1);
      endDate = new Date(new Date().getFullYear(), 11, 31);
    } else if (dateOption === "last_month") {
      startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      );
      endDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    }else if (dateOption === "last_quarter") {
      const quarter = Math.floor(new Date().getMonth() / 3) - 1; // Go back one quarter
      startDate = new Date(new Date().getFullYear(), quarter * 3, 1);
      endDate = new Date(new Date().getFullYear(), (quarter + 1) * 3, 0);
    } else if (dateOption === "last_half_year") {
      const halfYear = new Date().getMonth() < 6 ? 0 : 1;
      const lastHalfYear = halfYear === 0 ? 1 : 0; // Go back to the previous half-year
      startDate = new Date(new Date().getFullYear(), lastHalfYear * 6, 1);
      endDate = new Date(new Date().getFullYear(), (lastHalfYear + 1) * 6, 0);
    } else if (dateOption === "last_year") {
      startDate = new Date(new Date().getFullYear() - 1, 0, 1);
      endDate = new Date(new Date().getFullYear() - 1, 11, 31);
    } else if (dateOption === "previous") {
      startDate = new Date((new Date(useExtraDates.startDate).setFullYear(new Date(useExtraDates.startDate).getFullYear() - 1)))
      endDate = new Date((new Date(useExtraDates.endDate).setFullYear(new Date(useExtraDates.endDate).getFullYear() - 1)))
    }else if (dateOption === "custom") {
      setActiveDateOption("custom");
    }
    setSelectedDateRange({ startDate, endDate, key: "selection" });
    setActiveDateOption(dateOption);
  };

  const formatDate = (inputDate: string): string => {
    const months: string[] = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const dateObj = new Date(inputDate);
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
  
    return `${month} ${day}, ${year}`;
  };

  return (
    <>
        <Box className={classes.rootContainer}>
          <Box className={classes.containerDiv}>
            <Box className={classes.buttonsHolder}>
            {
              isCurrentOptionsrequired ?
                <>
                  <button
                    className={
                      activeDateOption === "this_month"
                        ? classes.activeButton
                        : classes.inActiveButton
                    }
                    onClick={() => handleDateOptions("this_month")}
                  >
                    This Month
                  </button>
                  <button
                    className={
                      activeDateOption === "this_quarter"
                        ? classes.activeButton
                        : classes.inActiveButton
                    }
                    onClick={() => handleDateOptions("this_quarter")}
                  >
                    This Quarter
                  </button>
                  <button
                    className={
                      activeDateOption === "this_half_year"
                        ? classes.activeButton
                        : classes.inActiveButton
                    }
                    onClick={() => handleDateOptions("this_half_year")}
                  >
                    This Half-Year
                  </button>
                  <button
                    className={
                      activeDateOption === "this_year"
                        ? classes.activeButton
                        : classes.inActiveButton
                    }
                    onClick={() => handleDateOptions("this_year")}
                  >
                    This Year
                  </button>
                </> :
                <></>
            }
            {
              isCustomOptionsRequired ?
                <>
                  <button
                    className={
                      activeDateOption === "last_month"
                        ? classes.activeButton
                        : classes.inActiveButton
                    }
                    onClick={() => handleDateOptions("last_month")}
                  >
                    Last Month
                  </button>
                  <button
                    className={
                      activeDateOption === "last_quarter"
                        ? classes.activeButton
                        : classes.inActiveButton
                    }
                    onClick={() => handleDateOptions("last_quarter")}
                  >
                    Last Quarter
                  </button>
                  <button
                    className={
                      activeDateOption === "last_half_year"
                        ? classes.activeButton
                        : classes.inActiveButton
                    }
                    onClick={() => handleDateOptions("last_half_year")}
                  >
                    Last Half-Year
                  </button>
                  <button
                    className={
                      activeDateOption === "last_year"
                        ? classes.activeButton
                        : classes.inActiveButton
                    }
                    onClick={() => handleDateOptions("last_year")}
                  >
                    Last Year
                  </button>
                </> : ""}
            {
              isPreviousOptionsRequiredrequired ?
                <>
                  <button
                    className={
                      activeDateOption === "previous"
                        ? classes.activeButton
                        : classes.inActiveButton
                    }
                    onClick={() => handleDateOptions("previous")}
                  >
                    Previous Period
                  </button>
                </>
                : ""
            }
            <button
              className={
                activeDateOption === "custom"
                  ? classes.activeButton
                  : classes.inActiveButton
              }
              onClick={() => handleDateOptions("custom")}
            >
              Custom
            </button>
            </Box>
            <Box className={classes.mainCalendarDiv}>
              <Box className={classes.calenderContainer}>
                <Box className={classes.calenderBodyDiv}>
                  <Box className={classes.testProvider}>
                    <DateRangePicker
                      ranges={[selectedDateRange]}
                      onChange={handleSelect}
                      showSelectionPreview={false}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      direction="horizontal"
                      showMonthAndYearPickers={true}
                      showMonthArrow={true}
                      showDateDisplay={false}
                      previewColor="#E5B632"
                      rangeColors={["#E5B632", "red"]}
                      editableDateInputs={true}
                      rangeDividerColor="#FFF6DE"
                      className={classes.selectedDateRange}
                    />
                    <hr className={classes.monthDivider} />
                  </Box>
                  <Box className={classes.dateBox}>
                    <Box className={classes.dateContainer}>
                      <Typography className={classes.dateField}>
                        {formatDate(selectedDateRange.startDate)}
                      </Typography>
                    </Box>
                    <Box className={classes.dateContainer}>
                      <Typography className={classes.dateField}>
                        {formatDate(selectedDateRange.endDate)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "100%", maxHeight: "65px" }}>
                  <hr />
                  <Box className={classes.btnContainer}>
                    <Button
                      className={classes.cancelButton}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.applyButton}
                      onClick={handleApply}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
    </>
  );
};

export default withStyles(styles)(TwoDateSelector);