export const styles: any = {
  loginRightSectionForgot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 50px",
  },
  titleForgot: {
    fontSize: "29px",
    fontWeight: "500",
  },
  subTitleForgot: {
    fontSize: "15px",
    color: "#757575",
    margin: 0,
    marginTop: "20px",
  },
  inputLabelForgot: {
    fontSize: "14px",
    color: "#000000",
    marginTop: "30px",
  },
  inputFieldForgot: {
    margin: 0,
    marginTop: "15px",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "18px",
        fontSize: "14px",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
    "& .MuiFormHelperText-root": {
      color: "#ea2f2f",
      margin: 0,
      marginTop: "5px",
    },
  },
  forgotPassForgot: {
    color: "#e5b632",
    fontSize: "14px",
    marginTop: "23px",
    marginLeft: "15px",
    float: "right",
    cursor: "pointer",
  },
  errorsForgot:{
    marginTop: '15px',
    marginBottom: '-10px',
  },
  errorMsgForgot: {
    color: "#ea2f2f",
    marginTop: "5px",
    marginBottom: "-15px",
    paddingBottom: "10px",
    fontSize: "0.75rem",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
  loginBtnForgot: {
    color: "#fff",
    backgroundColor: "#e5b632",
    margin: "20px 0 25px 0",
    boxShadow: "none",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  endTextForgot: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: "14px",
    "& span": {
      color: "#e5b632",
      cursor: "pointer",
    },
  },
  MuiModalRootForgot : {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    
  },
  
  modalBoxForgot : {
    backgroundColor: 'white',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems:'center',
    gap: '20px',
    width:'45%',
    margin:'auto',
    borderRadius:'10px',
    marginTop: '35vh',
  },
  
  modalOverlayForgot : {
    backgroundColor: '#D8D8D8',
    opacity: '0.33',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '-1',
  },
  
  doneButtonForgot : {
    backgroundColor: '#E5B632',
    color: 'white',
    padding: '8px 16px',
    borderRadius: '4px',
    cursor: 'pointer',
    width:'210px'
  },
  '@media (max-width: 599px)': {
    leftLoginSectionForgot: {
      display: 'none',
    },
    titleForgot:{
      textAlign:'center',
    },
    mobileLogoHolder:{
      display:'revert',
    },
    loginRightSectionForgot:{
      flexDirection: 'column',
      gap:'20px',
      "& img":{
        height:'100px'
      }
    },
  },
};
