import TruncatedTextWithTooltipWeb from "../Tooltip/TruncatedTextWithTooltip.web";
import { convertIntoCurrency, formatCurrencySufWithPrefix, notificationStatusToColor, timeAgoFormatter } from "./utils";
import React from "react";
import moment from "moment";

export const parseTableData = (data: any) => {
  if (!data && !Array.isArray(data)) return [];
  const res = data?.map((item: any) => {
    const { id, attributes } = item;
    return { id, ...attributes }
  })
  return res;
}

export const parseDataIntoOptions = (data: any) => {
  if (!data && !Array.isArray(data)) return [];
  const res = data?.map((item: any) => {
    const { id, attributes } = item;
    return attributes.company_name ? { id: id || attributes.id, name: attributes.company_name } : null
  })
  return res;
}

export const parseSectorIntoOptions = (data: any) => {
  if (!data && !Array.isArray(data)) return [];
  const res = data?.map((item: any) => {
    const { id, attributes } = item;
    return attributes.name ? { id: id || attributes.id, name: attributes.name } : null
  })
  return res;
}

export const parseValueChainData = (arr: any, arr2: any[]) => {
  arr = (!arr && !Array.isArray(arr)) || arr.message ? [] : arr;
  if (!arr2 && !Array.isArray(arr2)) return [];
  const res = arr2.map((item) => {
    const { id } = item;
    const matchingData = arr?.find((data: any) => data?.chain_evaluation_criteria_id === parseInt(id));
    return {
      ...item,
      updateId: matchingData?.id ?? null,
      ranking: matchingData?.ranking ?? 0,
      score: matchingData?.score ?? 0,
      total_score: matchingData?.total_score ?? 0,
      commission: matchingData?.commission ?? 0,
      factor_limit: matchingData?.factor_limit ?? 0,
    };
  });
  return res;
}

export const parseInvoiceBalanceData1 = (data: any) => {
  if (!data) return [];
  return [
    {
      label: { value: "Remaining Amount Disbursed Due" },
      render: { value: `J$ ${convertIntoCurrency(data?.amount_disbursed)}`},
      bgColor: "#FFDA71",
    },
    {
      label: { value: "- Amount Repaid", color: "#757575", fontWeight: 600 },
      render: { value: `J$ ${convertIntoCurrency(data?.amount_repaid)}`, color: "#757575", fontSize: "10px", fontWeight: 600 },
      height: "42px",
    },
    {
      label: { value: "Remaining Total Factor Commission" },
      render: { value: `J$ ${convertIntoCurrency(data?.remaining_total_factor_commission)}` },
      bgColor: "#FFDA71",
    },
    {
      label: { value: "Factor Commission Due", fontWeight: 600 },
      render: { value: `J$ ${convertIntoCurrency(data?.original_factor_commission_due)}`, fontSize: "10px", fontWeight: 700 },
      bgColor: "#F5F5F5",
    },
    {
      label: { value: "- Amount Repaid", color: "#757575", fontWeight: 600 },
      render: { value: `J$ ${convertIntoCurrency(data?.amount_repaid_2)}`, color: "#757575", fontSize: "10px", fontWeight: 600 },
      height: "42px",
    },
    {
      label: { value: "Factor Commission Past Due", fontWeight: 600 },
      render: { value: `J$ ${convertIntoCurrency(data?.original_factor_commission_past_due)}`, fontSize: "10px", fontWeight: 700 },
      bgColor: "#F5F5F5",
    },
    {
      label: { value: "- Amount Repaid", color: "#757575", fontWeight: 600 },
      render: { value: `J$ ${convertIntoCurrency(data?.amount_repaid_3)}`, color: "#757575", fontSize: "10px", fontWeight: 600 },
      height: "42px",
    },
    ...(data?.is_delinquent_enable ? [
      {
        label: { value: "Remaining Delinquent Fee" },
        render: { value: `J$ ${convertIntoCurrency(data?.remaining_delinquent_fee)}` },
        bgColor: "#FFDA71",
      },
      {
        label: { value: "- Amount Repaid", color: "#757575", fontWeight: 600 },
        render: { value: `J$ ${convertIntoCurrency(data?.amount_repaid_4)}`, color: "#757575", fontSize: "10px", fontWeight: 600 },
        height: "42px",
      },
    ]: []),
  ]
}

export const parseInvoiceBalanceData2 = (data: any) => {
  if (!data) return [];
  return [
    {
      label: { value: "Factor Due Date", fontWeight: 700  },
      render: { value: data.factor_due_date ?? "", fontSize: "10px", fontWeight: 600},
      height: "43px",
      borderBtm: "1px solid #E7E7E7"
    },
    {
      label: { value: "# of Days Past Due", fontWeight: 700  },
      render: { value:  data.of_days_past_due ?? "", fontSize: "10px", fontWeight: 600 },
      height: "43px",
      borderBtm: "1px solid #E7E7E7"
    },
    {
      label: { value: "Coverage (%)", fontWeight: 700 },
      render: { value: data.coverage ?? 0, fontSize: "10px", fontWeight: 600},
      height: "43px",
    },
  ];
}

export const shouldUpdateInvoicePOtable = (prevState:any, state:any) =>{
  return prevState?.client?.id !== state?.client?.id ||
    prevState?.client?.name !== state?.client?.name ||
    prevState?.page !== state?.page ||
    prevState?.per_page !== state?.per_page ||
    prevState?.sector.name !== state?.sector.name ||
    prevState?.country !== state?.country ||
    prevState?.of_days_past_due !== state?.of_days_past_due ||
    prevState?.disbursed_date_from !== state?.disbursed_date_from ||
    prevState?.disbursed_date_to !== state?.disbursed_date_to ||
    prevState?.factor_due_date_from !== state?.factor_due_date_from ||
    prevState?.factor_due_date_to !== state?.factor_due_date_to
}

export const shouldUpdateGlobalRevenueTable = (prevState:any, state:any) => {
  return prevState.page !== state.page ||
  prevState.per_page !== state.per_page ||
  prevState.sector.name !== state.sector.name ||
  prevState.country !== state.country ||
  prevState.date_from !== state.date_from ||
  prevState.date_to !== state.date_to
}

export const parseGlobalTableData = (res:any) => {
  if (!res && !Array.isArray(res)) return [];
  const data = res.map((item:any, index:number) => {
    const subData = parseTableData(item.data)
    return {...item.meta, id: index, data: subData}
  })
  return data
}

export const parseGlobalTotalData = (res:any) => {
  const totalData = {
    amount_disbursed: res.amount_disbursed,
    commission_accrued: res.commission_accrued,
    commission_collected: res.commission_collected,
    factor_amount_available: res.factor_amount_available,
    factor_limit: res.factor_limit,
  }
  return totalData
}

export const shouldUpdateclientStatementTable = (prevState:any, state:any) => {
  return prevState.page !== state.page || 
  prevState.per_page !== state.per_page || 
  prevState.dueStartDate !== state.dueStartDate || 
  prevState.dueEndDate !== state.dueEndDate || 
  prevState.disbursmentStartDate !== state.disbursmentStartDate || 
  prevState.columnTitle !== state.columnTitle || 
  prevState.searchSelect !== state.searchSelect || 
  prevState.disbursmentEndDate !== state.disbursmentEndDate
}

export const shouldClientReportTableUpdate = (prevState:any, state:any) => {
  return prevState.page !== state.page || 
  prevState.per_page !== state.per_page ||  
  prevState.country !== state.country || 
  prevState.sectorSelection !== state.sectorSelection || 
  prevState.startDate !== state.startDate || 
  prevState.endDate !== state.endDate || 
  prevState.clientSearch !== state.clientSearch
}

export const shouldVelocityReportTableUpdate = (prevState:any, state:any) => {
  return  prevState.page !== state.page ||
  prevState.per_page !== state.per_page ||
  prevState.sectorSelection !== state.sectorSelection ||
  prevState.mainPeriodStartDate !== state.mainPeriodStartDate ||
  prevState.mainPeriodEndDate !== state.mainPeriodEndDate ||
  prevState.comparisonPeriodStartDate !== state.comparisonPeriodStartDate ||
  prevState.comparisonPeriodEndDate !== state.comparisonPeriodEndDate ||
  prevState.sort_direction !== state.sort_direction ||
  prevState.sort_column !== state.sort_column
}

export const covertDashboardNotifications = (data: any) => {
  if (!data && !Array.isArray(data)) return [];
  const notifications = data.map((item:any)=> {
    const {
      document_title,
      client,
      review_status,
      created_at_2,
      account_id,
      factoring_agreement_id,
      invoice_detail_id,
      purchase_order_id,
    } = item?.attributes;    
    const interval = timeAgoFormatter(created_at_2);
    const color = notificationStatusToColor(review_status)
    return {
      id: item.id,
      title: `${document_title} from ${client}`,
      color: color,
      status: review_status,
      time: interval,
      document_title: document_title,
      account_id: account_id,
      factoring_agreement_id: factoring_agreement_id,
      invoice_detail_id: invoice_detail_id,
      purchase_order_id: purchase_order_id
    }
  })
  return notifications
}

export const parseTimelineChartData = (data:any, key:string)=> {
  if (!data && !Array.isArray(data) && key) return [];
  const result = data?.map((item:any)=>{
    const { formatted_date } = item;
    const date = moment(formatted_date).format("MMM").toUpperCase();
    const number = typeof item[key] === "string" ? parseFloat(item[key].replace(/[^0-9.]/g, "")): item[key];
    return item.hasOwnProperty(key) ? { 
      formatted_date: moment(formatted_date).format("MMM YYYY"),
      key: key,
      [key]: item[key],
      xName: date,
      yName: number
    } : null
  })
  return result
}

export const returnHoverCurrencyFormat = (value: string | number, classNames: string , isPaid?:boolean) => {
  const currencySymbols = ['K', 'M', 'B', 'T'];
  if ( typeof value === "string" && value.includes("$")) {
    const prefix = isPaid ? value.substring(0, 3) :value.substring(0, 2);
    const res = formatCurrencySufWithPrefix(value, prefix);
    const formattedValue = isPaid ? res.slice(1) : res;
    if (currencySymbols.some(symbol => res.includes(symbol))) {
      return <TruncatedTextWithTooltipWeb title={value} text={<span className={classNames}>{isPaid ? `(${formattedValue})` : formattedValue}</span>} isOverflowProps={true} maxWidth="min-content" />;
    } else {
      return <span className={classNames}>{isPaid ? `(${formattedValue})` : formattedValue}</span>;
    }
  } else {
    return <span className={classNames}>{isPaid ? `(${value})` : value}</span>;
  }
}

export const handleAPIErrorMessage = (errors: any[] | string): string => {
  if (Array.isArray(errors)) {
    if (errors.length === 0) {
      return 'Something went wrong';
    }
    if (typeof errors[0] === 'object') {
      return errors.map((obj) => Object.values(obj).join(',')).join(',');
    } else if (typeof errors[0] === 'string') {
      return errors.join(',');
    }
  } else if (typeof errors === 'string') {
    return errors;
  }
  return 'Something went wrong';
};