// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  Grid,
  InputLabel,
  TextField,
} from "@material-ui/core";

import ChangeRequestReviewController, {
  Props,
} from "./ChangeRequestReviewController.web";
import { styles } from "./ChangeRequestReviewStyles.web";
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";
import { downloadFiles } from "../../../components/src/Utils/utils";
import CommonButtonWeb from "../../../components/src/Button/CommonButton.web";
import ReviewRejectModalWeb from "../../../components/src/Modal/ReviewRejectModal.web";
import ReviewApproveModalWeb from "../../../components/src/Modal/ReviewApproveModal.web";
const fileIcon = require("../../../components/src/Assets/file.png");
const downloadIcon = require("./assets");

class ChangeRequestReview extends ChangeRequestReviewController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {
      changeRequestDocuments,
      companyName4,
      createdAtDate4,
      fields,
      isLoading4,
      isRejectOpen,
      isApproveOpen,
      comment5
    } = this.state;
    const { reason_for_request, description } = fields;
    const disabled = changeRequestDocuments.every((items : any) => items?.status === 'reviewed');

    return (
      <div className={classes.changeRequestDiv}>
        {isLoading4 && <CustomLoader open={isLoading4} />}
        <Typography className="maintitle4">Change Request form</Typography>
        <Box className="subTitleContainer4">
          <Typography variant="h3" className="title4">
            {companyName4}
          </Typography>
          <Typography variant="subtitle1" className="date4">
            {createdAtDate4}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={12} md={7} xs={12}>
            <InputLabel className={classes.inputLabel4}>
              Reason for request:
            </InputLabel>
            <TextField
              type="text"
              variant="outlined"
              name="reason_for_request"
              value={reason_for_request}
              fullWidth
              className={classes.inputField4}
              disabled
            />
          </Grid>
          <Grid item sm={12} md={10} xs={12}>
            <InputLabel className={classes.inputLabel4}>
              Description:
            </InputLabel>
            <TextField
              type="text"
              variant="outlined"
              fullWidth
              name="description"
              value={description}
              className={classes.inputField4}
              multiline
              disabled
            />
          </Grid>
        </Grid>
        <div className="collapseContainer4">
          <Typography variant="h3" className="collapseTitle4">
            {changeRequestDocuments.length > 0 &&
              "Find the attached file below:"}{" "}
          </Typography>
          {changeRequestDocuments?.map((item, index) => {
            const isReviewed = item.status === "reviewed";
            return (
              <div className="collapse4" key={index}>
                <Typography variant="body1" gutterBottom>
                  <img src={fileIcon} /> {item.name}
                </Typography>
                <div className="dwnContainer4">
                  <img
                    src={downloadIcon.Download}
                    style={{ cursor: "pointer" }}
                    data-test-id={`downloadFiles-${index}-2`}
                    onClick={() => downloadFiles(item.url)}
                    alt="Download"
                  />
                  <Button
                    data-test-id={`reviewedBtns4-${index}-2`}
                    className={`btn4 ${isReviewed ? "reviewedBtn4" : ""}`}
                    onClick={() =>
                      this.handleChangeRequestChangeRequest(item.id)
                    }
                  >
                    {item.status}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
        <Grid container spacing={4}>
          <Grid item md={4} lg={4} xs={12} sm={12} className={classes.btnContainerLeft4}>
            <CommonButtonWeb
              btnText="In Review"
              handleClick={this.inReviewChangeRequest}
              disabled={disabled}
              backgroundColor="#E5B632"
              width="100%"
            />
          </Grid>
          <Grid item md={4} lg={4} xs={12} sm={12} className={classes.btnContainerLeft4}>
            <CommonButtonWeb
              btnText="Reject"
              handleClick={this.handleRejectModalOpen}
              disabled={!disabled}
              backgroundColor="#F27151"
              width="100%"
            />
          </Grid>
          <Grid item md={4} lg={4} xs={12} sm={12}  className={classes.btnContainerLeft4}>
            <CommonButtonWeb
              btnText="Approve"
              handleClick={this.handleApproveModalOpen}
              disabled={!disabled}
              backgroundColor="#20BB6E"
              width="100%"
            />
          </Grid>
        </Grid>
        <ReviewApproveModalWeb
          open={isApproveOpen}
          handleSubmitModal={this.handleModalSubmit}
          handleModelClose={this.handleModalClose}
          widthToAdd="85%"
        />
        <ReviewRejectModalWeb
          open={isRejectOpen}
          comment={comment5}
          handleChange={this.handleRejectInputChangeClient}
          handleSubmitModal={this.handleModalSubmit}
          handleModelClose={this.handleModalClose}
          widthToAdd="85%"
        />
      </div>
    );
  }
}

export default withStyles(styles)(ChangeRequestReview);
// Customizable Area End
