import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button , Grid } from "@material-ui/core";
import CompanyReportsTabsController, {
    Props,
} from "./CompanyReportsTabsController.web";
import { styles } from "./ActiveTabsStyles.web";
import VelocityReports from "./VelocityReports.web"
import GlobalRevenueReportWeb from "./GlobalRevenueReport.web";
import PastDueWeb from "./PastDue.web";

export class CompanyReportsTabs extends CompanyReportsTabsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const { isRevenueSelected, isVelocitySelected, isPastDueSelected } = this.state;
        return (
            <Box>
                <Box sx={{ padding: "0 32px 32px", width: "calc(100vw - 285px)" }} className={classes.responsiveProvider1}>
                    <Box sx={{ margin: '28px 0px', fontWeight: 700, fontSize: '18px' }}>Company Reports</Box>
                    <Grid container spacing={2} className={classes.navigationTabsBox}>
                        <Grid item md={4} lg={4} xs={12} sm={12}>
                            <Button
                                data-test-id='invoiceNavigationButton'
                                className={
                                    isRevenueSelected
                                        ? classes.activeButton
                                        : classes.inActiveButton
                                }
                                onClick={() => this.handleRevenueSelection()}
                            >
                                Global Revenue
                            </Button>
                        </Grid>
                        <Grid item md={4} lg={4} xs={12} sm={12}>
                            <Button
                                data-test-id='velocityReportNav'
                                className={isVelocitySelected ? classes.activeButton : classes.inActiveButton}
                                onClick={() => this.handleVelocitySelection()}
                            >
                                Velocity
                            </Button>
                        </Grid>
                        <Grid item md={4} lg={4} xs={12} sm={12}>
                            <Button
                                data-test-id='pastDueReportNav'
                                className={
                                    isPastDueSelected
                                        ? classes.activeButton
                                        : classes.inActiveButton
                                }
                                onClick={() => this.handlePastDueSelection()}
                            >
                                Past Due
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {isRevenueSelected && <GlobalRevenueReportWeb navigation={this.props.navigation} id="globalRevenueReport" />}
                {isPastDueSelected && <PastDueWeb navigation={this.props.navigation} id="pastDueReport" />}
                {isVelocitySelected && <VelocityReports navigation={this.props.navigation} />}
            </Box>
        );
    }
}

export default withStyles(styles)(CompanyReportsTabs);
