import { MAIN_DIV_WIDTH } from "../../../components/src/Utils/constant";

export const styles: any = {
  inputField3: {
    marginTop: "12px",
    margin: 0,
    maxWidth: "533px",
    "& .MuiFormHelperText-root": {
      color: "#ea2f2f",
      margin: 0,
      marginTop: "5px",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
    "& .MuiOutlinedInput-adornedStart": {
      fontSize: "12px",
      lineHeight: "15px",
      "& .MuiInputBase-input": {
        paddingLeft: "5px !important",
       },
      fontWeight: 700,
    },
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontWeight: 700,
        padding: "18px 12px 17px",
        lineHeight: "15px",
        letterSpacing: "-0.24px",
        fontSize: "12px",
      },
    },
    
  },
  helperInvoiceText3: {
    marginTop: "5px",
    color: "#ea2f2f",
    margin: 0,
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
  headingTitle3:{
    fontFamily: "Plus Jakarta Sans",
    color: "#2E2A40",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "23px",
    marginBottom: "20px"
  },
  purchaseDiv: {
    alignItems: "flex-start",
    justifyContent: "center",
    display: "flex",
    gap: "28px",
    overflowY: "auto",
    flexDirection: "column",
    width: MAIN_DIV_WIDTH,
    padding: "0 50px 50px",
    flexGrow: 1,
    "& .maintitle3": {
      fontStyle: "normal",
      fontWeight: 800,
      color: "#2E2A40",
      fontFamily: "'Plus Jakarta Sans'",
      fontSize: "24px",
      marginTop: "3px",
      lineHeight: "30px",
      letterSpacing: "-0.48px",
    },
    "& .subTitleContainer3": {
      "& .title3": {
        fontStyle: "normal",
        letterSpacing: "-0.48px",
        fontWeight: 700,
        fontSize: "18px",
        fontFamily: "'Plus Jakarta Sans'",
        lineHeight: "23px",
        color: "#2E2A40",
      },
      marginTop: "20px",
      "& .date3": {
        textAlign: "center",
        fontSize: "10px",
        lineHeight: "13px",
        color: "#757575",
        marginTop: "4px",
        fontFamily: "'Plus Jakarta Sans'",
        float: "left",
        fontStyle: "normal",
        fontWeight: 500,
        letterSpacing: "-0.24px",
      },
    },
    
    "& .btnContainerLeft3": {
      marginTop: "35px",
      display: "flex",
      width: "100%",
      "& .approveBtn3": {
        marginLeft: "20px",
        background: "#20BB6E !important",
      },
      "& .leftbtn3": {
        background: "#F27151",
        borderRadius: "4px",
        width: "210px",
        height: "42px",
        fontFamily: "'Plus Jakarta Sans'",
        "& .MuiButton-label": {
          fontStyle: "normal",
          textAlign: "center",
          letterSpacing: "-0.32px",
          fontSize: "16px",
          lineHeight: "20px",
          color: "#FFFFFF",
          fontFamily: "'Plus Jakarta Sans'",
          fontWeight: 600,
          textTransform: "capitalize",
          marginTop: "-2px",
        },
      },
      "& .disabled3": {
        background: "#B6B6B6",
        marginRight: "20px",
      },
    },
  },
  collapseContainer3: {
    flexDirection: "column",
    display: "flex",
    margin:"27px 0 40px",
    gap: "20px",
    justifyContent: "space-between",
    "& .collapseTitle3": {
      fontSize: "14px",
      fontFamily: "'Plus Jakarta Sans'",
      lineHeight: "18px",
      color: "#202A45",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "-0.24px",
    },
    "& .collapse3": {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      columnGap: "50px",
      display: "flex",
      "& p": {
        "& img": {
          paddingRight: "8px",
        },
        lineHeight: "16px",
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        alignItems: "center",
        fontSize: "12px",
        color: "#2E2A40",
        display: "flex",
        width: "250px",
        opacity: 0.8,
        margin: 0,
        fontStyle: "normal",
        fontWeight: "500",
      },
      "& .dwnContainer3": {
        alignItems: "center",
        columnGap: "24px",
        flexDirection: "row",
        display: "flex",
        "& .btn": {
          border: "1px solid rgba(0, 0, 0, 0.08)",
          width: "94px",
          cursor: "default",
          fontFamily: "'Plus Jakarta Sans'",
          background: "white",
          height: "27px",
          borderRadius: "4px",
          "& .MuiButton-label": {
            textTransform: "capitalize",
            padding: "10px 28px",
            fontWeight: 700,
            textAlign: "center",
            color: "#979797",
            fontSize: "10px",
            lineHeight: "13px",
            fontStyle: "normal",
            fontFamily: "'Plus Jakarta Sans'",
          },
        },
        "& img": {
          width: "20px",
          height: "20px",
        },
        "& .reviewedBtn1": {
          cursor: "default !important",
          pointerEvents:'none',
          "& .MuiButton-label": {
            color: "white !important",
          },
          background: "#20BB6E !important",
        },
      },
    },
  },
  inputLabel3: {
    marginTop: "4px",
    fontSize: "12px",
    fontWeight: 500,
    color: "#202A45",
    lineHeight: "15.12px",
    letterSpacing: "-0.24px",
  },
  bottomDiv3:{
    flexDirection:"row",
    display: "flex",
    flexWrap:"wrap",
  },
  rightBtmDiv3: {
    display: "flex",
    rowGap: "18px",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-end",
  },
  rightBtmHeader3:{
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    width: "100%",
    columnGap: "15px",
    flexDirection: "row",
    display: "flex",
    paddingTop: "28px",
    "& .rightBtmTitle":{
      fontFamily: 'Plus Jakarta Sans',
      color: '#2E2A40',
      fontSize: "14px",
      fontWeight: '800',
      margin:0,
    },
    "& .rightBtmBtn":{
      alignContent: "center",
      display: "grid",
      "& .MuiButton-label":{
        padding:"0px 20px !important",
        whiteSpace: "nowrap",
        fontSize: "12px !important",
      }
    },
  },
  disbursementDateProvider1:{
    flexDirection: "column",
    padding:'12px',
    '@media (max-width: 900px)': {
        width: "100%",
    },
  },
  formWidthProvider1:{
    '@media (max-width: 1440px)': {
      width: "100%",
    },
  },
  '@media (max-width: 600px)': {
    purchaseDiv: {
      width: "100%",
      marginTop:'15px'
    },
  },
};
