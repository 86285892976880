import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Popper,
  IconButton,
  Collapse,
  Menu,
  MenuItem
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from "@material-ui/core/styles";
import tStyles from "./CommonTableStyles.web";
import { sortingOptions } from "../Utils/dummyData";
import { removeUnderscoreAndCapitalize, convertIntoCurrency, formatDate,reFormatDate } from "../Utils/utils";
import CustomTooltip from "../Tooltip/CustomTooltip.web";
import CommonPopper from "../Popper/CommonPopper.web"
import TwoDateSelector from "../CustomDateSelector/TwoDateSelector.web";
import { menuItems } from "../Utils/constant";
const SortIcon = require("../Assets/sort.png");
const delinquentIcon = require("../Assets/delinquentVector.svg");
const downArrowIcon = require("../Assets/downArrow.png");
const upArrowIcon = require("../Assets/upArrow.png");


interface TableProps {
  columns: any[];
  data: any[];
  grand_total_data?: any;
  headerClasses: any;
  classes: any;
  handleSort?: any;
  handleDelinquentValue?: any;
  handleFilter?: any;
  sort_direction?: any;
  isFirstVertical?: boolean;
  handleDateRangeFilter?: any;
  isMoreVert?: boolean;
  width?: any;
  filter?: any;
  loadingTableData: boolean;
  navigate?: any;
  handleRowClick?: (row: any) => void;
  isCollapsibleTable?: boolean;
  isThreeDotsMenuNotRequired?: any;
  navigation?:any
  extraClasses?:any
}

interface SS {
  open: boolean;
  isMorvertOpen: boolean;
  isDateRangeOpen: boolean;
  anchorEl: EventTarget & HTMLElement | null;
  morvertAnchorEl: EventTarget & HTMLElement | null;
  options: any;
  column: any;
  openMoreVert: boolean;
  dateRange:any
  collapseId: any[];
  client:any,
  showOriginalAmount: boolean,
 
}

class CommonTable extends Component<TableProps, SS> {

  constructor(props: TableProps) {
    super(props);
    this.state = {
      open: false,
      isMorvertOpen: false,
      isDateRangeOpen: false,
      morvertAnchorEl: null,
      anchorEl: null,
      options: [],
      column: {},
      openMoreVert: false,
      dateRange:{
        startDate: "",
        endDate: ""
      },
      collapseId: [],
      client:{}, 
      showOriginalAmount: true, // Add this state to toggle between columns
      
    };
  }
 
  handleClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
      isMorvertOpen: false,
      morvertAnchorEl: null,
      options: [],
    });
  }

  handleOptions = (e: any, col: any, text: string) => {
    const {filter} = this.props
    if (text === "sort") {
      this.setState({
        options: sortingOptions,
        column: { text: "sort", name: col.render },
        open: true,
        anchorEl: e.currentTarget,
      })
    } else if (col.filter && col?.filterOptions) {
      this.setState({
        options: col.filterOptions,
        column: { text: "filter", name: col.render },
        open: true,
        anchorEl: e.currentTarget,
      })
    } else if (col.dateFilter) {
      if(col.label === "Disbursement Date") {
        this.setState({ dateRange:{startDate: filter.disbursed_date_from, endDate: filter.disbursed_date_to}})
      } else if (col.label === "Factor Due Date") {
        this.setState({ dateRange:{startDate: filter.factor_due_date_from, endDate: filter.factor_due_date_to}})
      }
      this.setState({
        isDateRangeOpen: true,
        anchorEl: e.currentTarget,
        column: { label: col.label }
      })
    }
  }

  handleSortFilter = (opt: any) => {
    const { column } = this.state;
    if (column.text === "sort" && opt.value) {
      this.props.handleSort(column.name, opt.value)
    } else if (column.text === "filter" && opt.value) {
      this.props.handleFilter(column.name, opt.value)
    }
  }

  handleOpenMorevert = (e: any , client:any ,row?:any) => {
    this.setState({
      isMorvertOpen: true,
      morvertAnchorEl: e.currentTarget,
      client:client,
      showOriginalAmount:true,
     
    })
  }
 
  handleMenuItemClick = (action: string , row?:any) => {
    const { client } = this.state;
    if (client) {
      switch (action) {
        case 'kycForm':
          const kycRedirection = { id: client.account_id}
          this.props.navigation.navigate("AdminKyc1Review" , kycRedirection) ;
          break;
        case 'valueChain':
          const valueChainRedirection = { id: client.account_id}
          this.props.navigation.navigate("AdminEKYCevaluation" , valueChainRedirection) ;
          break;
        case 'factorAgreement':
          const factorRedirection = { id: client.account_id}
          this.props.navigation.navigate("FactorAgreemenetReview" , factorRedirection) ;
          break;
        case 'payments':
          const paymentRedirection = { id: client.account_id}
          this.props.navigation.navigate("AdminProcessPayment" , paymentRedirection) ;
          break;
        // case 'invoices':
        //   const invoiceRedirection = { id: client.id}
        //   this.props.navigation.navigate("AdminClientInvoicesTable" , invoiceRedirection) ;
        //   break;
        case 'markDelinquent':
          let value = false
          if(row.is_delinquent_enable){
            value = true
          }
          this.props.handleDelinquentValue(value, client.account_id)
          break;
        default:
          break;
      }
    }
    this.handleClose();
  };

  renderRowCell = (row: any, col: any) => {
    const isAdmin = col.heighlight && (row.role === "admin" || row.role === "analyst");
    if (isAdmin) {
      return <div className="adminHighlight">{row[col.render]}</div>;
    } else if (col.isStatusBtn) {
      const bgColor = col?.filterOptions?.find((obj: any) => obj.value === row[col.render])?.bgColor || "#f7f7f7";
      return (
        <div
          className="statusBtn"
          style={{ background: bgColor }}
        >
          {row[col.render] ? removeUnderscoreAndCapitalize(row[col.render]) : ""}
        </div>
      );
    } else if (col.isCurrency) {
      return `J$ ${convertIntoCurrency(row[col.render])}`;
    } else if (col.isRate) {
      if (typeof row[col.render] === "number") {
        return `${row[col.render]}%`;
      } else if (typeof row[col.render] === "string") {
        return row[col.render].includes("%") ? row[col.render] : `${row[col.render]}%`;
      } else {
        return "";
      }
    } else if (col.postFix) {
      return <span className="delinquentContainer">
        {row[col.render]}
        {row.is_delinquent_enable ? <img src={delinquentIcon} /> : ''}
      </span>;
    } else if (col.isCollapse) {
      const { collapseId } = this.state;
      const handleCollapse = () => {
        const newCollapse = collapseId.includes(row.id)
          ? collapseId.filter(c => c !== row.id)
          : [...collapseId, row.id];
        this.setState({ collapseId: newCollapse });
      }
      return <div className="collapseIconContainer">
        <span>{`Total ${row[col.render]}`}</span>
        <IconButton aria-label="expand row" size="small" onClick={() => handleCollapse()}>
          {collapseId.includes(row.id) ? <img src={upArrowIcon} /> : <img src={downArrowIcon} /> }
        </IconButton>
      </div>;
    } 
    else {
      let floatValue;
       if (row.factor_commission ||
          row.disbursed_factor_amount ||
          row.principal_current ||
          row.commission_current ||
          row.principal_1_30 ||
          row.commission_1_30 ||
          row.commission_31_60 ||
          row.principal_31_60 ||
          row.principal_61_90 ||
          row.commission_61_90 ||
          row.principal_more_90 ||
          row.commission_more_90) {
          if (typeof row[col?.render] === 'number') {
            floatValue = parseFloat(row[col?.render]);
            if (!isNaN(floatValue)) {
              return `J$${floatValue.toFixed(2)}`
            } else {
              return `J$${floatValue}`;
            }
          }else {
            return row[col?.render];
          }
        } else {
          return row[col?.render];
        }
    }
  }

  renderColumnLabel = (col: any) => {
    return <div style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      columnGap: "4px",
      justifyContent: col?.headerCellStyle?.justifyContent ?? "flex-start",
    }}>
      {col.label}{" "}
      {col.tooltip ? (
        <CustomTooltip
          tooltipText={col.tooltip}
          height="11px"
          width="11px"
          toolTipClasses={this.props.classes?.tooltip}
          direction="top"
        />
      ) : (
        ""
      )}
    </div>
  }

  renderFirstVerticalLine = () => {
    return !!this.props.isFirstVertical && <div
      style={{
        width: "0.6px",
        background: "rgba(0,0,0,0.5)",
        position: "absolute",
        right: "0",
        top: "0",
        bottom: "-1px"
      }}
    />
  }

  handleDateRangeClose = () => {
    this.setState({
      isDateRangeOpen: false,
      anchorEl: null,
      column: {}
    });
  }

  handleDateApply1 = (date1: any, date2: any) => {
    const { column } = this.state;
    this.props.handleDateRangeFilter(column.label, formatDate(date1), formatDate(date2));
    this.handleDateRangeClose()
  }

  render() {
    const { columns, data, headerClasses, classes, loadingTableData, handleRowClick, width, grand_total_data, isCollapsibleTable , isThreeDotsMenuNotRequired , navigation , extraClasses} = this.props;

    return (
      <TableContainer
        component={Paper}
        className={classes?.container}
        style={{
          width: width ?? "100%",
          overflowY: "hidden",
          minHeight: data.length > 0 ? "170px" : "auto"
        }}
      >
        <Table style={{ width: width ? "max-content" : "100%" }}>
          <TableHead className={`header ${headerClasses}`}>
            <TableRow>
              {columns.map((col, ind) => {
                const text = col.sort ? "sort" : col.filter ? "filter" : "dateFilter"
                return (
                  <TableCell
                    key={`${col.id} + ${ind*4}`}
                    style={{
                      minWidth: col?.width ? col.width : "auto",
                      ...(col.headerCellStyle ? col.headerCellStyle : {})
                    }}
                  >
                    {col?.sort || col?.filter || col?.dateFilter ? (
                      <div className="sortFilContainer">
                        <p>{this.renderColumnLabel(col)}</p>
                        <img src={SortIcon} onClick={(e: any) => this.handleOptions(e, col, text)} />
                        <CommonPopper
                          open={this.state.open}
                          anchorEl={this.state.anchorEl}
                          options={this.state.options}
                          handleClose={this.handleClose}
                          handleMenuClick={this.handleSortFilter}
                        />
                        <Popper
                          open={this.state.isDateRangeOpen}
                          anchorEl={this.state.anchorEl}
                          placement="bottom"
                          transition
                          disablePortal
                          style={{ zIndex: 100 }}
                        >
                          <Paper>
                            <TwoDateSelector
                              handleClose={this.handleDateRangeClose}
                              applyFunction={this.handleDateApply1}
                              startDate={reFormatDate(this.state.dateRange.startDate)}
                              endDate={reFormatDate(this.state.dateRange.endDate)}
                            />  
                          </Paper>
                        </Popper>
                      </div>
                    ) : (
                      this.renderColumnLabel(col)
                    )}
                    {ind === 0 && this.renderFirstVerticalLine()}
                  </TableCell>)
              })}
              {isThreeDotsMenuNotRequired ?
                  <TableCell style={{
                    position: "sticky",
                    right: 0,
                    zIndex: 1,
                    padding: "0 15px 0 5px",
                    backgroundColor: "#F6EACC",
                  }}>{""}</TableCell>
                  :
                  ""
                }
            </TableRow>
          </TableHead>
          <TableBody className={`${extraClasses}`}>
            {loadingTableData ?
              <TableRow>
                <TableCell colSpan={columns.length ?? 5}>
                  <div className="loaderContainer">
                    <CircularProgress color="inherit" />
                  </div>
                </TableCell>
              </TableRow>
              : Array.isArray(data) && data.length > 0 && (
                data.map((row, index) => (
                  <>
                  <TableRow key={`${row.id}-${index*3}`}>
                    {columns.map((col, i) => (
                      <TableCell
                        key={`${row.id}-${col.id}-${i*2}`}
                        className={col.isGray ? "grayTd" : ""}
                        onClick={() => { handleRowClick && !col.moreVertOptions && !col.isCollapse ? handleRowClick(row) : "" }}
                        style={{
                          width: col.width ?? "auto",
                          borderBottomColor: !!isCollapsibleTable ? "#807D7D": "#E7E7E7",
                          cursor: handleRowClick ? "pointer" : "default",
                          ...(col.bodyCellStyle ? col.bodyCellStyle : {})
                        }}
                      >
                        {this.renderRowCell(row, col)}
                        {i === 0 && this.renderFirstVerticalLine()}
                      </TableCell>
                    ))}
                    {isThreeDotsMenuNotRequired ?
                        <TableCell 
                        style={{
                          position: "sticky",
                          right: 0,
                          padding: "0 15px 0 5px",
                          zIndex: 1,
                          backgroundColor: "white",
                        }}
                        >
                          <IconButton data-test-id="Menu-open-event" onClick={(event:any)=>this.handleOpenMorevert(event,row)}>
                            <MoreVertIcon />
                          </IconButton>
                          
                          <Menu
                anchorEl={this.state.morvertAnchorEl}
                open={Boolean(this.state.morvertAnchorEl && this.state.client.id === row.id)}
                onClose={() => this.handleClose()}
                data-test-id="Menu-Close-event"
                className={classes.menuItemClass}
              >
                {menuItems.map((item) => (
                  <MenuItem key={item.value} onClick={() => this.handleMenuItemClick(item.value)}>
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem onClick={() => this.handleMenuItemClick("markDelinquent", row)}>
                  {row.is_delinquent_enable ? "Unmark as Delinquent" : "Mark Delinquent"}
                </MenuItem>
              </Menu>
                        </TableCell>
                        :
                        ""
                      }
                  </TableRow>
                  {this.state.collapseId.includes(row.id) &&  !!isCollapsibleTable && 
                    <TableRow key="collapse-row">
                      <TableCell style={{ padding: 0 }} colSpan={columns.length} key="collapse-cell">
                        <Collapse in={this.state.collapseId.includes(row.id)} timeout="auto" unmountOnExit>
                          <Table style={{ width: width ? "max-content" : "100%" }}>
                            <TableBody>

                              {row.data.map((sub:any, j:number) => (
                                
                                <TableRow key={`collapse-${sub.id}-${j*2}`}>
                                  {columns.map((co, inde) => (
                                    // <h1>scscssd</h1>
                                    <TableCell
                                    key={`${co.id}-${sub.id * 10}-${inde * 2}`}
                                    style={{
                                      width: co.width ?? "auto",
                                      ...(co.subBodyCellStyle ? co.subBodyCellStyle : {})
                                    }}
                                  > 
                                    {co.id === 7 && this.state.showOriginalAmount
                                      ? sub["original_amount_disbursed"]
                                      : sub[co.render]}
                                    {inde === 0 && this.renderFirstVerticalLine()}
                                  </TableCell>
                                  ))}
                                  </TableRow>
                              ))}
                              {row.data.length === 0 && <TableRow>
                                <TableCell
                                  style={{
                                    width: "auto",
                                    borderBottom: "none",
                                  }}
                                >No clients</TableCell>
                              </TableRow>}
                            </TableBody>
                             
                          </Table>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  } 
                  </>
                ))
              )
            }
            {
              grand_total_data &&
              <TableRow key="grand-total" className="grand-total-row">
                {columns.map((c, k) => (
                  <TableCell 
                    key={`gt${k * 6}`}
                    style={{
                      width: c.width ?? "auto",
                      ...(c.bodyCellStyle ? c.bodyCellStyle : {})
                    }}
                  >
                    {k===0 ? <div aria-colspan={2} className="grand-first-cell">Grand Total</div> 
                    : !!grand_total_data[c.render] && <div className="grand-btn">{grand_total_data[c.render]}</div>}
                  </TableCell>
                ))}
              </TableRow>
            }
          </TableBody>
          
        </Table>
      </TableContainer>
    );
  }
}

export default withStyles(tStyles)(CommonTable);