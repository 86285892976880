export const styles: any = {
  mainContainer: {
    display: "flex",
    marginBottom: "70px",
    flexDirection: "column",
    gap: "30px",
    "& .topCards": {
      padding: "30px",
      marginTop: "23px",
      background: "rgba(143,146,161,0.05)",
      gap: "20px",
      mixBlendMode: "normal",
      display: "flex",
      flexDirection: "column",
      "& .cardContainer": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
      },
    },
    "& .bottomDiv": {
      padding: "30px",
      display: "flex",
      background: "rgba(143,146,161,0.05)",
      gap: "30px",
      flexDirection: "column",
      "& .bottomChild": {
        "& .firstContent": {
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          width:'100%',
          "@media (max-width: 1320px)": {
            flexWrap: "wrap",
          },
          "& .card": {
            background: "#FFFFFF",
            border: "1px solid #EFF0F6",
            borderRadius: "10px",
            padding: "25px",
            "& .title": {
              fontFamily: "Plus Jakarta Sans",
              fontStyle: "normal",
              display: "flex",
              alignItems: "center",
              color: "#1C1C1C",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "20px",
              fontFeatureSettings: `'ss01' on, 'cv01' on, 'cv11' on`,
            },
          },
          "& .areaChartCard": {
            padding: "30px 27px 28px !important",
            height: "330px",
            "& .monthTitle": {
              fontWeight: '500',
              color: "#222222",
              fontSize: "14px",
              fontFamily: 'Plus Jakarta Sans',
              wordWrap: 'break-word'
            }
          },
          "& .notificationSection": {
            background: "#FFFFFF",
            border: "1px solid #EFF0F6",
            borderRadius: "10px",
            height: "330px",
            padding: "30px 20px 0px 30px",
            "& .title": {
              fontFamily: "Plus Jakarta Sans",
              fontStyle: "normal",
              display: "flex",
              alignItems: "center",
              margin: 0,
              marginBottom: "20px",
              color: "#1C1C1C",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "20px",
              fontFeatureSettings: `'ss01' on, 'cv01' on, 'cv11' on`,
            },
            "& .container": {
              display: "flex",
              overflowY: "scroll",
              flexDirection: "column",
              height: "260px",
              gap: "1.37rem",
              "& .notification": {
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                gap: "8px",
                "& .circle": {
                  marginTop: "3px",
                  width: "0.875rem",
                  height: "0.875rem",
                  borderRadius: "50%",
                },
                "& .textContent": {
                  display: "flex",
                  flexDirection: "column",
                  width:'90%',
                  "& .title": {
                    color: "#1C1C1C",
                    fontSize: "0.875rem",
                    display: "inline-block",
                    fontFamily: "Plus Jakarta Sans",
                    lineHeight: "1.25rem",
                    margin: "0",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  },
                  "& .duration": {
                    fontFamily: "Plus Jakarta Sans",
                    color: "rgba(0, 0, 0, 0.40)",
                    lineHeight: "1.25rem",
                    fontSize: "0.875rem",
                  }
                },
              },
              "&::-webkit-scrollbar-track": {
                background: "white",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "8px",
                background: "#BABABA",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
              "&::-webkit-scrollbar": {
                width: "3px",
              },
            },
          },
        },
      }
    },
  },
  menuTimelineOptionPaper: {
    borderRadius: "5px",
    marginTop: "5px",
    maxHeight: "120px",
    border: "1px solid #E7E7E7",
    "&::-webkit-scrollbar": {
      width: "3px",
    },
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "& .MuiMenu-list": {
      padding: "0 10px 0 0",
      "& li": {
        color: "#8F92A1",
        lineHeight: "15px",
        backgroundColor: "transparent !important",
        "& :hover":{
          backgroundColor: "white",
        },
        padding: "4px 0 !important",
        fontSize: "12px",
        fontWeight: 500,
        "& :active":{
          color: "#2E2A40",
          backgroundColor: "white",
        }
      },
      "& .Mui-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        color: "#2E2A40",
      },
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#BABABA",
      borderRadius: "8px",
    },
    padding: "5px 12px",
    boxShadow: "0px 2px 3px rgba(41, 41, 41, 0.13)",
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  selectTimelineField: {
    "& .MuiSelect-root": {
      padding: "0 42px 5px 0",
      color: '#1C1C1C',
      fontSize: "18px",
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: '600',
      lineHeight: "20px",
    },
    "& .MuiSelect-select:focus":{
      backgroundColor: "transparent",
    },
    "& .MuiSelect-iconOutlined":{
      marginTop: "-0.5px",
      width: "20px",
    },
    "& .MuiOutlinedInput-notchedOutline":{
      border: "none",
    },
    margin: 0,
  },
  selectField: {
    "& .MuiSelect-root": {
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: 800,
    },
    "& .Mui-focused": {
      color: 'red',
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632 !important",
      },
    },
    "&::before": {
      border: 'none !important',
    },
    "&::after": {
      border: 'none'
    }
  },
  menuPaper: {
    marginTop: "-10px",
    border: "1px solid #E7E7E7",
    boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.2)",
    borderRadius: "4px",
    "& .MuiMenu-list": {
      padding: "5px 12px",
      "& li": {
        backgroundColor: "transparent !important",
        fonteight: 500,
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "-0.24px",
        color: "#757575",
        borderBottom: "1px solid #E7E7E7",
        "& :hover": {
          backgroundColor: "transparent",
        }
      },
      "& li:last-child": {
        borderBottom: "none",
      },
      "& .Mui-selected": {
        color: "#e5b632",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
  invoiceCard: {
    background: "#FFFFFF",
    border: "1px solid #EFF0F6",
    borderRadius: "10px",
    padding: "25px",
    width: "100%",
    minHeight: "394px",
    "& .invoiceChartContainer": {
      justifyContent: "center",
      columnGap: "160px",
      rowGap: "45px",
      flexWrap: "wrap",
      flexDirection: "row",
      alignItems: "center",
      display: "flex",
      color:'red'
    },
    "& .invoiceTitle": {
      wordWrap: "break-word",
      fontSize: "18px",
      fontFamily: "Plus Jakarta Sans",
      color: "#202A45",
      fontWeight: 700,
      textAlign: "center",
      marginBottom: "25px",
    },
  },
  '@media (max-width: 600px)': {
    responsiveProvider: {
      width: "100%",
    },
  },
};