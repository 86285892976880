export const styles: any = {
  mainDiv: {
    width: "100%",
    marginTop:'5px'
  },
  mainDivForm1: {
    display: "grid",
    gridTemplateColumns: "265px 1fr",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
  },
  rightMainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0 50px 50px",
    overflowY: "auto",
    flexGrow: 1,
  },
  pageTitle: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "27px",
    letterSpacing: "-0.36px",
    color: "#2E2A40",
    margin: 0,
  },
  pageSubTitle: {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: 500,
    color: "#757575",
    letterSpacing: "-0.24px",
    padding: "4px 0 15px",
    margin: 0,
  },
  divider: {
    background: "#E7E7E7",
    width: "100%",
  },
  inputLabel: {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: 500,
    color: "#202A45",
    marginTop: "16px",
  },
  disableLabel: {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: 500,
    color: "#C7C7CA",
    marginTop: "16px",
  },
  inputField: {
    margin: 0,
    marginTop: "10px",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "18px",
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#757575",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
    "& .MuiFormHelperText-root": {
      color: "#ea2f2f",
      margin: 0,
      marginTop: "5px",
    },
  },
  disableInputField: {
    margin: 0,
    marginTop: "10px",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "18px",
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#C7C7CA",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e7e7e7",
      },
    },
  },
  selectContainer:{
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
  },
  selectField: {
    margin: 0,
    marginTop: "10px",
    "& .MuiSelect-root": {
      padding: "18px",
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: 500,
      color: "#757575",
    },
  },
  selectCountryField: {
    margin: 0,
    marginTop: "10px",
    "& .MuiSelect-root": {
      padding: "11px 18px",
    },
    "& .MuiSelect-iconOutlined": {
       left: '50px',
       right: 'auto !important'
    },
    "& .MuiInputAdornment-root": {
       position:  "absolute",
       left: "78px",
       marginTop: '2px',
       "& p":{
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#757575",
       },
    }
  },
  menuPaper: {
    marginTop: "5px",
    border: "1px solid #E7E7E7",
    boxShadow: "2px 2px 3px 1px rgba(0,0,0,0.2)",
    borderRadius: "4px",
    "& .MuiMenu-list": {
      padding: "5px 12px",
      "& li": {
        backgroundColor: "transparent !important",
        fonteight: 500,
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "-0.24px",
        color: "#757575",
        borderBottom: "1px solid #E7E7E7",
        "& :hover":{
          backgroundColor: "transparent",
        }
      },
      "& li:last-child": {
        borderBottom: "none",
      },
      "& .Mui-selected": {
        color: "#e5b632",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
  helperText: {
    color: "#ea2f2f",
    margin: 0,
    marginTop: "5px",
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
  saveBtn: {
    color: "#fff",
    backgroundColor: "#e5b632",
    margin: "23px 0 0",
    padding: "10.5px 16px",
    boxShadow: "none",
    fontWeight: 600,
    textTransform: "capitalize",
    width: "400px",
    height: "60px",
    float: "right",
  },
  checkbox: {
    padding: 0,
    paddingTop: "20px",
    paddingRight: "12px",
  },
  checkedIcon: {
    color: "#e5b632",
  },
  checkboxLabel: {
    fontSize: "14px",
    lineHeight: "17.5px",
    fontWeight: 500,
    color: "#202A45",
    margin: 0,
    marginTop: "21px",
    textAlign:'justify',
  },
  MuiModalRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  modalBox: {
    backgroundColor: "white",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    width: "85%",
    margin: "auto",
    borderRadius: "10px",
    marginTop: "35vh",
  },

  modalText:{
    padding:'0 150px',
    textAlign:'center',
    fontFamily : 'Plus Jakarta Sans',
    fontWeight : 600,
    fontSize : '16px',
    lineHeight : '24px',
  },

  modalOverlay: {
    backgroundColor: "#D8D8D8",
    opacity: "0.33",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "-1",
  },

  doneButton: {
    backgroundColor: "#E5B632",
    color: "white",
    padding: "8px 16px",
    borderRadius: "4px",
    cursor: "pointer",
    width: "210px",
  },
  '@media (max-width: 600px)': {
    saveBtn: {
      width: "auto",
    },
    rightMainDiv:{
      padding: "0 40px 50px",
      marginTop:'25px'
    },
    modalText:{
      padding:"0 15px"
    }
  },
  '@media (min-width: 601px) and (max-width: 960px)': {
    saveBtn: {
      width: "50%", 
    },
    modalText:{
      padding:"0 50px"
    }
  },
  '@media (min-width: 961px)': {
    saveBtn: {
      width: "35%", 
    },
  },
};
