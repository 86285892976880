import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid,Select , MenuItem } from "@material-ui/core";
import { styles } from "./AdminStyles.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AdminTopCardWeb from "../../../components/src/Card/AdminTopCard.web";
import AdminDashbordInvoicePieChart from "../../../components/src/Charts/AdminDashbordInvoicePieChart.web";
import { timelineChartOptions, menuSelectProps } from "../../../components/src/Utils/constant";
import TruncatedTextWithTooltipWeb from "../../../components/src/Tooltip/TruncatedTextWithTooltip.web";
import AdminTimelineChart from "../../../components/src/Charts/AdminTimelineChart.web";
// @ts-ignore
import AdminDashboardDisbursedRevenueChart from "../../../components/src/Charts/AdminDashboardDisbursedRevenueChart.web"
// Customizable Area End

import AdminDashboardController, {
  Props,
} from "./AdminDashboardController.web";
class AdminDashboard extends AdminDashboardController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { notificationData, timelineOption, timelineChartData, invoice_statistics} = this.state;
    const { 
      total_paid_as_a_percentage,
      total_paid_as_a_monetary_value,
      total_overdue_as_a_percentage,
      total_overdue_as_a_monetary_value
    } = invoice_statistics
    const menuTimelineOptionsProps: any = {
      classes: { paper: classes.menuTimelineOptionPaper },
      ...menuSelectProps
    };

    const menuProps: any = {
      classes: { paper: classes.menuPaper },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
    };
    return (
      <Box
        sx={{
          marginTop: "15px",
          width:'calc(100vw - 286px)'
        }}
        className={classes.responsiveProvider}
      >
        <Grid item>
          <Box
            sx={{
              padding: "0 0 32px",
            }}
            className={classes.mainContainer}
          >
            <div className="topCards">
              <div className="filter">
                <Select
                  className={classes.selectField}
                  value={this.state.defaultFilter}
                  onChange={this.handleChange}
                  MenuProps={menuProps}
                  data-test-id="filter-days"
                >
                  <MenuItem value={30}>Last 30 Days</MenuItem>
                  <MenuItem value={60}>Last 60 Days</MenuItem>
                  <MenuItem value={90}>Last 90 Days</MenuItem>
                </Select>
              </div>
              <Grid container spacing={2} className="cardContainer">
                {this.state.cardsData.map((item: any) => {
                  return (
                    <Grid item lg={3} md={6} xs={12} sm={12} key={item}>
                      <AdminTopCardWeb
                        data={item}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <div className="bottomDiv">
              <div className="bottomChild">
                <Grid container spacing={2} className="firstContent">
                  <Grid item lg={7} md={12} style={{width:'100%'}}>
                    <Box className="card areaChartCard">
                      <Grid container justifyContent="space-between">
                        <Select
                          IconComponent={ExpandMoreIcon}
                          id="timelineId"
                          data-test-id="select-timeline"
                          variant="outlined"
                          value={Number(timelineOption.id)}
                          onChange={(e) => this.handleSelectTimeline(e)}
                          className={classes.selectTimelineField}
                          MenuProps={menuTimelineOptionsProps}
                        >
                          <MenuItem
                            value={0}
                            disabled
                            style={{ display: "none" }}
                          >
                            Sector
                          </MenuItem>
                          {timelineChartOptions.map((o: any, index) => (
                            <MenuItem
                              value={Number(o.id)}
                              key={`${o.id + index}`}
                            >
                              {o.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <Grid item className="monthTitle">
                          Last 12 Months
                        </Grid>
                      </Grid>
                      <AdminTimelineChart data-test-id="timeline-graph" data={timelineChartData} keyValue={timelineOption.key} />
                    </Box>
                  </Grid>
                  <Grid item lg={5} md={12} style={{width:'100%'}}>
                    <Box className="notificationSection">
                      <p className="title">{"Notifications"}</p>
                      <div className="container">
                        {notificationData.length > 0 ? notificationData.map((obj: any) => {
                          return (
                            <div 
                              className="notification" 
                              data-test-id="notification-click"
                              key={obj.id} 
                              onClick={()=>this.gotoNotificationReview(obj)}
                            >
                              <div className="circle" style={{ backgroundColor: obj.color }}></div>
                              <div className="textContent">
                                <TruncatedTextWithTooltipWeb data-test-id="notification-title" text={obj.title} maxWidth={"100%"} classNames="title" />
                                <span className="duration">{obj.time}</span>
                              </div>
                            </div>
                          );
                        }) : <p>Notifications not available</p>
                        }
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
              {this.state.isLoading ? "loading" : <AdminDashboardDisbursedRevenueChart revenueChartData={this.state.revenueData} globalChartData={this.state.globalData} entireData={this.state.chartData} />}
              <div className={classes.invoiceCard}>
                <div className="invoiceTitle">Invoice statistics</div>
                <div className="invoiceChartContainer">
                  <AdminDashbordInvoicePieChart data-test-id="left-pie-chart" name="Total Overdue" percentage={total_overdue_as_a_percentage} monetary_value={total_overdue_as_a_monetary_value} />
                  <AdminDashbordInvoicePieChart data-test-id="right-pie-chart" name="Total Paid" percentage={total_paid_as_a_percentage} monetary_value={total_paid_as_a_monetary_value} />
                </div>
              </div>
            </div>
          </Box>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(AdminDashboard);
// Customizable Area End
