// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  formatDate2,
  getPastDueEndpoint,
  getPastDueCsvEndpoint
} from "../../../components/src/Utils/utils";
import {
  parseDataIntoOptions,
  parseSectorIntoOptions,
  shouldUpdateGlobalRevenueTable,
} from "../../../components/src/Utils/parseData";
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface PastOption {
  name: string;
  id: number | string | null;
}

interface S {
  pastToken: string | null;
  isPastDateRangeOpen: boolean;
  date_to: string;
  totalPastCount: number;
  sector: PastOption;
  pastTableData: any;
  isPastClientsLoad: boolean;
  page: number;
  isPastLoadTable: boolean;
  date_from: string;
  csvPastURL: string;
  anchorPastEl: null | HTMLElement;
  pastClientOptions: any[];
  per_page: number;
  client: PastOption;
  pastSectorOptions: any[];
}

interface SS {
  id: any;
}

export default class PastDueController extends BlockComponent<Props, S, SS> {
  getPastTableDataApi: string = "";
  getPastSectorApi: string = "";
  getPastClientsApi: string = "";
  getPastURLApi: string = "";
  pastDebounce: any = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isPastLoadTable: false,
      totalPastCount: 0,
      client: { id: "", name: "" },
      pastClientOptions: [],
      pastToken: "",
      isPastDateRangeOpen: false,
      csvPastURL: "",
      page: 1,
      pastTableData: { data: [], grand_total_data: {} },
      date_from: moment().startOf('year').format('MMM DD, YYYY'),
      pastSectorOptions: [],
      per_page: 10,
      isPastClientsLoad: false,
      sector: { id: "", name: "" },
      anchorPastEl: null,
      date_to: moment().format('MMM DD, YYYY'),
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentWillUnmount(): any {
    if (this.pastDebounce) {
      clearTimeout(this.pastDebounce);
    }
  }

  async componentWillMount() {
    const pastToken = localStorage.getItem("auth_token");
    if (pastToken) {
      this.setState({ pastToken: pastToken, isPastLoadTable: true });
      const pastEndPoint = getPastDueEndpoint(this.state);
      this.getPastTableDataApi = apiCall(pastToken, pastEndPoint, "GET");
      this.getPastSectorApi = apiCall(pastToken, `industries`, "GET");
      this.getPastURLApi = apiCall(
        pastToken,
        "past_due_report_excel",
        "GET"
      );
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const isUpdateState = shouldUpdateGlobalRevenueTable(prevState, this.state);
    if (prevState.client.name !== this.state.client.name) {
      if (this.pastDebounce) {
        clearTimeout(this.pastDebounce);
      }
      this.pastDebounce = setTimeout(() => {
        this.getPastSearchOptions(this.state.client.name);
      }, 400);
    }
    if (isUpdateState) {
      this.setState({ isPastLoadTable: true });
      const pastEndPoint1 = getPastDueEndpoint(this.state);
      const pastDueCsvEndPoint = getPastDueCsvEndpoint(this.state);
      this.getPastTableDataApi = apiCall(
        this.state.pastToken,
        pastEndPoint1,
        "GET"
      );
      this.getPastURLApi = apiCall(
        this.state.pastToken,
        pastDueCsvEndPoint,
        "GET"
      );
    }
  }

  clearPastDueFilters = () => {
    this.setState(
      {
        page: 1,
        client: { id: "", name: "" },
        isPastLoadTable: true,
        date_from: moment().startOf('year').format('MMM DD, YYYY'),
        per_page: 10,
        sector: { id: "", name: "" },
        date_to: moment().format('MMM DD, YYYY'),
      },
      () => {
        const pastClearEndPoint2 = getPastDueEndpoint(this.state);
        this.getPastTableDataApi = apiCall(
          this.state.pastToken,
          pastClearEndPoint2,
          "GET"
        );
      }
    );
  }

  handlePastDateRangeFilter = (sDate: string, eDate: string) => {
    this.setState({
      date_from: formatDate2(sDate),
      date_to: formatDate2(eDate),
    });
    this.handlePastDateRangeClose();
  };

  handlePastDateRangeOpen = (event: any) => {
    this.setState({
      anchorPastEl: event.currentTarget,
      isPastDateRangeOpen: true,
    });
  };

  handlePastDateRangeClose = () => {
    this.setState({
      isPastDateRangeOpen: false,
      anchorPastEl: null,
    });
  };

  handlePastClientInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pastInputValue = e.target.value;
    if (pastInputValue === "") {
      this.setState({ pastClientOptions: [] });
    }
    this.setState({ client: { id: 0, name: pastInputValue } });
  };

  handlePastSelectClient = (event: any, pastClient: any) => {
    if (pastClient.id) {
      this.setState(
        {
          page: 1,
          client: pastClient,
          isPastLoadTable: true,
          per_page: 10,
        },
        () => {
          const pastEndPoint2 = getPastDueEndpoint(this.state);
          this.getPastTableDataApi = apiCall(
            this.state.pastToken,
            pastEndPoint2,
            "GET"
          );
        }
      );
    } else {
      this.setState({ client: { id: 0, name: "" } });
    }
  };

  handlePastChangeReportPage = (e: any, pastPage: any) => {
    window.scrollTo(0, 0);
    this.setState({
      page: pastPage,
    });
  };

  handlePastSector = (e: any) => {
    const pastOption = this.state.pastSectorOptions.filter(
      (opt) => opt.id == e.target.value
    );
    if (pastOption.length > 0) {
      this.setState({ sector: pastOption[0] });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiCallId && resJson) {
        this.getPastResponseApis(apiCallId, resJson);
      } else {
        this.setState({ isPastLoadTable: false, isPastClientsLoad: false });
      }
    }
  }

  getPastResponseApis = (id: any, resp: any) => {
    if (id === this.getPastTableDataApi) {
      this.getPastTableDataResponse(resp);
    }
    if (id === this.getPastURLApi) {
      resp?.url && this.setState({ csvPastURL: resp.url });
    }
    if (id === this.getPastClientsApi) {
      this.getPastClientOptionsRes(resp);
    }
    if (id === this.getPastSectorApi) {
      this.getPastSectorOptionsRes(resp);
    }
  };

  getPastClientOptionsRes = (clientRes: any) => {
    if (clientRes.data) {
      const parsedOpt = parseDataIntoOptions(clientRes.data);
      this.setState({ pastClientOptions: parsedOpt });
    } else {
      this.setState({ pastClientOptions: [] });
    }
    this.setState({ isPastClientsLoad: false });
  };

  getPastSectorOptionsRes = (sectorRes: any) => {
    if (sectorRes.data) {
      const parsedData = parseSectorIntoOptions(sectorRes.data);
      this.setState({
        pastSectorOptions: parsedData,
      });
    } else {
      this.setState({ pastSectorOptions: [] });
    }
  };

  getPastTableDataResponse = (tableRes: any) => {
    if (tableRes?.reports || tableRes?.total_count || tableRes?.grand_total) {
      const pastCeil = Math.ceil(
        tableRes.total_count / this.state.per_page || 1
      );
      this.setState({
        pastTableData: {
          data: tableRes?.reports ? tableRes?.reports :  [tableRes?.grand_total],
          grand_total_data: tableRes?.grand_total,
        },
        isPastLoadTable: false,
        totalPastCount: pastCeil,
      });
    } else {
      this.setState({
        pastTableData: { data: [], grand_total_data: {} },
        totalPastCount: 0,
        isPastLoadTable: false,
      });
    }
  };

  getPastSearchOptions = (pastValue: any) => {
    const pastheader = {
      token: this.state.pastToken,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getPastClientsApi = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(pastheader)
    );

    this.setState({ isPastClientsLoad: true });

    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `client_advance_search?search=${pastValue}`
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };
}
// Customizable Area End
