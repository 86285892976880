// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { logoutUser } from "../../../components/src/Utils/utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  role: any;
  handleDrawerToggle: any;
  isBack: any;
}

interface S {
  open: boolean;
  anchorEl: any;
  count: number | string;
}

interface SS {
  id: any;
}

export default class TopNavController extends BlockComponent<
  Props,
  S,
  SS
> {
  notificationAPI: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      open: false,
      anchorEl: null,
      count:0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
      open: false,
    });
  }

  logOut = () =>{
    this.handleClose();
    logoutUser();
  }

  componentWillMount():any {
    const token = localStorage.getItem("auth_token");
    const role = localStorage.getItem("account_role")
    if (token && role !== "client") {
      this.getNotificationCount(token)
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.notificationAPI) {
          this.getDataResponse(responseJson);
        }
      } 
    }
  }

  getDataResponse = (res:any) => {
    if (res?.notification_status_count) {
      const notify_count = res?.notification_status_count?.toString();
      this.setState({count: res.notification_status_count})
      localStorage.setItem("admin_notification_count", notify_count);
    }
  }

  getNotificationCount = (token: any) => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token,
    };

    const path = `document_title=&review_status=&sort_column=&sort_direction=&page=${1}&per_page=${10}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `search_notification_advance?${path}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
