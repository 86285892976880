import React, { Component, RefObject  } from 'react';
import { Tooltip, Typography, withStyles} from "@material-ui/core";

interface Props {
 text: any;
 title?: string;
 maxWidth: string;
 classes?: any;
 direction?: any;
 classNames?: any;
 isOverflowProps?: boolean;
}

interface State {
  isTextOverflowing: boolean;
}

const tStyles:any = ({
  arrow: {
    "&::before": {
      boxShadow: "0 0px 5px rgba(0, 0, 0, 0.15)"
    },
    color: "white",
  },
  tooltip: {
    fontStyle: "normal",
    border: "0.5px solid rgb(250,250,250)",
    textAlign: "center",
    borderRadius: "8px",
    backgroundColor: "white",
    color: "#000000",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
    padding: "12px",
    "&::before": {
        borderColor: "#000000 transparent transparent",
        content: "",
        position: "absolute",
        transform: "translateX(-50%)",
        borderStyle: "solid",
        top: "-10px",
        left: "50%",
        borderWidth: "8px 8px 0",
    },
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
  },
});

class TruncatedTextWithTooltip extends Component<Props, State> {
  textRef: RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      isTextOverflowing: false,
    };
    this.textRef = React.createRef();
  }

  componentDidMount() {
    if(this.props.isOverflowProps){
      this.setState({ isTextOverflowing: true });
    } else {
      this.checkTextOverflow();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.text !== this.props.text) {
      this.checkTextOverflow();
    }
  }

  checkTextOverflow() {
    if (this.textRef.current) {
      const isOverflowing =
        this.textRef.current.clientWidth < this.textRef.current.scrollWidth;
      this.setState({ isTextOverflowing: isOverflowing });
    }
  }

  render() {
    const { text, maxWidth, classes, direction, classNames, title } = this.props;
    const { isTextOverflowing } = this.state;

    const tipTitle = title ? title : isTextOverflowing ? text : ''

    return (
      <Tooltip title={tipTitle} arrow classes={classes} placement={direction ? direction : "bottom"}>
        <Typography
          variant="body1"
          style={{
            maxWidth: maxWidth,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          className={classNames}
          ref={this.textRef}
        >
          {text}
        </Typography>
      </Tooltip>
    );
  }
};

export default withStyles(tStyles)(TruncatedTextWithTooltip);