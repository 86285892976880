import { MAIN_DIV_WIDTH } from "../../../components/src/Utils/constant";

export const styles: any = {
  mainDiv: {
    height: "100%",
    display: "flex",
    marginTop: "2px",
    rowGap: "20px",
    padding: "0 50px 50px",
    width: MAIN_DIV_WIDTH,
    flexDirection: "column",
    "& .pageTitle": {
      color: "#2E2A40",
      fontStyle: "normal",
      margin: 0,
      fontWeight: 600,
      fontSize: "18px",
      letterSpacing: "-0.36px",
      lineHeight: "27px",
    },
    "& .searchContainer": {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      "& .clearTableFilter":{
        marginLeft: "auto",
        alignContent: "center",
        display: "grid",
        width: "auto !important",
        height: "auto !important",
        "& .MuiButton-label":{
          padding:"2px 10px 0px !important",
          letterSpacing: "0px",
          whiteSpace: "nowrap",
          fontSize: "13px !important",
        }
      },
    },
    "& .downloadContainer": {
      "& img": {
        marginLeft: "28px",
        cursor: "pointer",
      },
    },
  },
  headerClasses: {
    background: "#fff",
    "& tr": {
      "& th": {
        color: "#9F9D9D !important",
        fontSize: "12px !important",
        lineHeight: "15px !important",
        fontWeight: "500 !important",
        "& .sortFilContainer":{
          justifyContent: "start !important",
          gap:'50px',
        },
        "&:last-child": {
          display:'flex',
          justifyContent: "end !important",
        }
      },
    },
  },
  notificationHeaderClasses: {
    background: "#fff",
    "& tr": {
      "& th": {
        color: "#9F9D9D !important",
        fontSize: "12px !important",
        lineHeight: "15px !important",
        fontWeight: "500 !important",
        "& .sortFilContainer":{
          justifyContent: "start !important",
          gap:'50px',
        },
      },
    },
  },
  bodyClasses: {
    "& tr": {
      "& td": {
        "& .statusBtn": {
          "&:last-child": {
            float: 'right !important'
          }
        }
      },
    },
  },

  "@media only screen and (min-width: 600px) and (max-width: 940px)":{
    mainDiv:{
      "& .searchContainer":{
        "& .clearTableFilter":{
          margin:'none'
        }
      }
    },
  },
  '@media (max-width: 600px)': {
    mainDiv: {
      width: "100%",
      marginTop:'20px',
      "& .searchContainer":{
        "& .clearTableFilter":{
          margin:'none'
        }
      }
    },
  },
};
