import React from "react";

import {
  Box,
  Button,
  // Customizable Area Start
  Grid,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Formik } from "formik";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./AdminChangeRequestStyles.web";
import Dropzone from "react-dropzone";
import {
  MAIN_DIV_WIDTH,
  reasonsOfRequest,
} from "../../../components/src/Utils/constant";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import AdminAdvanceSearch from "../../../components/src/Search/AdminAdvanceSearch.web";
import CommonModalWeb from "../../../components/src/Modal/CommonModal.web";
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";
// @ts-ignore
import FactoringAgreement from "../../../components/src/FactoringAgreementTable/FactoringAgreement.web";
import { onKeyDown } from "../../../components/src/Utils/utils";
const icons = require("./assets");
// Customizable Area End

import AdminChangeRequestController, {
  Props,
} from "./AdminChangeRequestController.web";

export class AdminChangeRequest extends AdminChangeRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const { classes } = this.props;
    const {
      FileDetails,
      isAdmin,
      isFactorAgreementSelected,
      factorAgreementData,
      isModalOpen,
      isLoading,
      reasonForRequest,
      descriptionOfRequest,
      isAutocompleteLoading,
    } = this.state;
    const { open, message, severity } = this.state.snackbar;

    const isDisabled = reasonForRequest === "" || descriptionOfRequest === "";

    const menuProps: any = {
      classes: { paper: classes.menuPaper },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    };

    const printTitle = () => {
      if (isAdmin) {
        return "Change Request Form";
      } else {
        return isFactorAgreementSelected
          ? "Factoring Agreement"
          : "Change Request Form";
      }
    };

    return (
      // Customizable Area Start
      <Grid container className={classes.boxContainer}>
        <Grid item xs={12} md={12}>
          {isLoading && <CustomLoader open={isLoading} />}
          <Box
            sx={{
              padding: "50px",
              width: '100%',
            }}
            className={classes.root}
          >
            <div className={classes.header} data-test-id="header-title">
              {printTitle()}
            </div>
            <div className={classes.searchAutoCompleate}>
              {isAdmin && (
                <AdminAdvanceSearch
                  options={this.state.options}
                  loading={isAutocompleteLoading}
                  searchValue={this.state.searchValue}
                  handleInputChange={this.handleInputChange}
                  handleSelectSearch={this.handleSelectSearch}
                  width={"60%"}
                />
              )}
            </div>
            {isFactorAgreementSelected && !isAdmin ? (
              // @ts-ignore
              <FactoringAgreement sentData={factorAgreementData} />
            ) : (
              <Formik
                enableReinitialize={true}
                validateOnBlur={true}
                initialValues={{
                  reasonForRequest: this.state.reasonForRequest,
                  descriptionOfRequest: this.state.descriptionOfRequest,
                }}
                onSubmit={this.handleFormSubmit}
              >
                {({ errors, values, handleSubmit }) => {
                  return (
                      <Grid item xs={12}>
                        <form
                          onSubmit={handleSubmit}
                          onKeyDown={onKeyDown}
                          className={classes.userForm}
                        >
                          <Grid container className={classes.reasonDescription}>
                            <Grid item xs={12} md={8} className={classes.reasonHolder}>
                              <span className={classes.reasonDescText}>
                                {" "}
                                Reason for request*{" "}
                              </span>
                              <Select
                                labelId="reason-lable"
                                id="reason-lable"
                                variant="outlined"
                                className={classes.reasonSelectField}
                                fullWidth
                                value={values.reasonForRequest}
                                MenuProps={menuProps}
                                onChange={(e) =>
                                  this.handleResonChange(e.target.value)
                                }
                              >
                                {reasonsOfRequest.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.value}
                                  >
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item xs={12} className={classes.DescriptionHolder}>
                              <span className={classes.reasonDescText}>
                                {" "}
                                Description*
                              </span>
                              <TextField
                                variant="outlined"
                                className={classes.descriptionField}
                                multiline
                                minRows={4}
                                maxRows={4}
                                value={values.descriptionOfRequest}
                                onChange={(e) =>
                                  this.handleDescription(e.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <div className={classes.dropzoneHolder}>
                            <span style={{ fontSize: "14px", fontWeight: 700 }}>
                              Upload Files
                            </span>
                            <Dropzone
                              multiple={false}
                              onDrop={(files) => this.handleFileUpload(files)}
                              data-test-id="FileDropFunction"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  {...getRootProps()}
                                  className={classes.dropzone}
                                >
                                  <img
                                    src={icons.image_Vuesax}
                                    alt=""
                                    className={classes.modalIcon}
                                  />
                                  <input {...getInputProps()} />
                                  <p style={{textAlign:'center'}}>Drop files here or click to browse</p>
                                  <Button
                                    variant="contained"
                                    className={classes.select}
                                  >
                                    Browse
                                  </Button>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                          <div className={classes.manageFiles}>
                            <span style={{ fontSize: "14px", fontWeight: 700 }}>
                              Manage File's
                            </span>
                            <TableContainer>
                              <Table>
                                <TableBody className={classes.fileViewTable}>
                                  {FileDetails.map((item) => (
                                    <TableRow key={item.id}>
                                      <TableCell>{item.file.name}</TableCell>
                                      <TableCell
                                        onClick={() => this.deleteFile(item.id)}
                                      >
                                        <img src={icons.Delete_icon} alt="" />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                          <div style={{ width: "100%" }}>
                            <Button
                              data-test-id={"btnSubmit1"}
                              variant="contained"
                              fullWidth
                              className={classes.submitBtn}
                              disabled={isDisabled}
                              type="submit"
                            >
                              Submit
                            </Button>
                          </div>
                        </form>
                      </Grid>
                  );
                }}
              </Formik>
            )}
          </Box>
        </Grid>
        <CommonModalWeb
          open={isModalOpen}
          title={
            isAdmin
              ? "Submitted. Go to notifications to action the request."
              : "Thanks for sending in your request! We'll get back to you soon."
          }
          btnText="Done"
          handleModelClose={this.handleModalClose}
          handleSubmitModal={this.handleModalClose}
          widthToAdd="80%"
        />
        <CustomSnackbar
          open={open}
          onCloseSnack={this.closeSnackbar}
          message={message}
          severity={severity}
        />
      </Grid>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export default withStyles(styles)(AdminChangeRequest);
// Customizable Area End
