export const styles: any = {
    invoiceMainDiv: {
      padding: "0 30px 50px",
      display: "flex",
      marginTop: "2px",
      rowGap: "20px",
      maxWidth: "1256px",
      flexDirection: "column",
      "& .reportTitle": {
        fontSize: "18px",
        color: "#2E2A40",
        fontWeight: 600,
        letterSpacing: "-0.36px",
        lineHeight: "27px",
        fontStyle: "normal",
        margin: 0,
      },
    },
    headerInvoicePOClasses: {
      background: "#F6EACC",
      "& tr":{
        "& th":{
          fontSize: "12px !important",
          lineHeight: "17px !important",
          fontWeight: "700 !important",
        }
      }
    },
    clientSearch: {
      "& .MuiInputBase-root": {
        borderRadius: "4px !important"
      }
    },
    clearTableFilter : {
      "& .MuiButton-label":{
        whiteSpace: "nowrap",
        fontSize: "13px !important",
        letterSpacing: "0px",
        padding:"2px 10px 0px !important",
      },
      width: "110px !important",
      display: "grid",
      marginBottom: "2px",
      alignContent: "center",
      height: "30px !important",
      marginRight: "7px",
    },
    selectFieldSector: {
      height: "50px",
      "& .MuiSelect-root": {
        fontWeight: 500,
        padding: "16px",
        fontSize: "14px",
        color: "#757575",
      },
      margin: 0,
    },
    sectorContainer:{
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e5b632",
        },
      },
    },
    dateRangeContainer: {
      fontSize: '12px',
      position: "relative",
      "& .dateViewer": {
        alignItems: 'center',
        color: '#2E2A40',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        fontSize: '12px',
        lineHeight: "15px",
        justifyContent: 'space-between',
        padding: '5px 20px 5px 17px',
        display: 'flex',
        height: '50px',
        cursor: 'pointer'
      },
      "& .popperHolder":{
        top:'auto'
      },
      "& .calendorIcon": {
          height: '22px',
          width: '20px'
      },
    },
 
    menuSectorPaper: {
      marginTop: "5px",
      border: "1px solid #E7E7E7",
      borderRadius: "5px",
      maxHeight: "120px",
      padding: "8px 20px",
      boxShadow: "0px 2px 3px rgba(41, 41, 41, 0.13)",
      "& .MuiMenu-list": {
        padding: "0 10px 0 0",
        "& .Mui-selected": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          color: "#e5b632",
        },
        "& li": {
          lineHeight: "15px",
          color: "#757575",
          backgroundColor: "transparent !important",
          fontWeight: 400,
          padding: "4px 0 !important",
          fontSize: "12px",
          "& :hover":{
            backgroundColor: "white",
          },
          "& :active":{
            backgroundColor: "white",
          }
        },
      },
      "&::-webkit-scrollbar": {
        width: "3px",
      },
      "&::-webkit-scrollbar-track": {
        background: "white",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#BABABA",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
    downloadHolder:{
      display:'flex',
      justifyContent: 'flex-end',
      alignItems:'flex-end',
    },
    DownloadPositionProvider1: {
      gridColumnGap: "8px",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      display: "flex",
      padding: '8px'
  },
    '@media (max-width: 600px)': {
      clientSearch: {
        width: "100%",
      },
      selectFieldSector: {
        width: "100%",
      },
      responsiveProvider:{
        width:'100%'
      }
    },
    '@media (min-width: 601px) and (max-width: 960px)': {
      clientSearch: {
        width: "100%", 
      },
    },
    '@media (min-width: 961px)': {
      clientSearch: {
        width: "100%", 
      },
    },
  };
  