import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  CircularProgress,
  IconButton,
  Menu,
  Box
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from "@material-ui/core/styles";
import tStyles from "./CommonTableStyles.web";
import { sortingOptions } from "../Utils/dummyData";
import { handleRedirectOnStatus, formatDate, reFormatDate } from "../Utils/utils";
import { review_now_arr, menuItems } from "../Utils/constant";
import CustomTooltip from "../Tooltip/CustomTooltip.web";
import TwoDateSelector from "../CustomDateSelector/TwoDateSelector.web"
const icons = require('../../../blocks/BaselineReporting/src/assets')
const SortIcon = require("../Assets/sort.png");

interface TableProps {
  columns: any[];
  data: any[];
  headerClasses: any;
  classes: any;
  handleSort?: any;
  handleFilter?: any;
  sort_direction?: any;
  filter?: any;
  loadingTableData: boolean;
  navigate?: any;
  handleRowClick?: any;
  handleMenuItemClick?: any;
  handleMenuOpen?: any;
  anchorEl?: any;
  handleMenuClose?: any;
  selectedClient?: any;
  handleApplyDateFuntion?: any;
  handleDateChanged?: any;
  startDate?: any;
  endDate?: any;
  extraClassnames?: any;
  isThreeDotsMenuNotRequired?: any;
  isMoreDatesRequired?: any;
  dateObject?: any;
  handleTwoDateChange? : any
}

interface SS {
  open: boolean;
  anchorEl: any;
  options: any;
  column: any;
  isDateFilterVisable: any;
  isDateFilterPopperOpen: any;
  dateRange : any
}

class ClientReportTable extends Component<TableProps, SS> {

  constructor(props: TableProps) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      options: [],
      column: {},
      isDateFilterVisable: false,
      isDateFilterPopperOpen: false,
      dateRange : {
        startDate:'',
        endDate:''
      }
    };
  }

  handleClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
      options: [],
    });
  }

  handleOptions = (e: any, col: any, text: string) => {
    if (text === "sort") {
      this.setState({
        options: sortingOptions,
        column: { text: "sort", name: col.render }
      })
    } else if (col.filter && col?.filterOptions) {
      this.setState({
        options: col.filterOptions,
        column: { text: "filter", name: col.render }
      })
    } else if (col.idDateFilterRequired) {
      if(this.props.isMoreDatesRequired){
        if(col.label === "Disbursement Date"){
          this.setState({
            isDateFilterVisable: true,
            isDateFilterPopperOpen: true,
            anchorEl: e.currentTarget,
            column: col.label,
            dateRange:{
              startDate : this.props.dateObject.disbursmentStartDate,
              endDate : this.props.dateObject.disbursmentEndDate
            }
          })
        }else if (col.label === "Due Date"){
          this.setState({
            column: col.label,
            isDateFilterVisable: true,
            isDateFilterPopperOpen: true,
            dateRange:{
              startDate : this.props.dateObject.dueStartDate,
              endDate : this.props.dateObject.dueEndDate
            },
            anchorEl: e.currentTarget,
          })
        }
      }else {
        this.setState({
            isDateFilterVisable: true,
            isDateFilterPopperOpen: true,
            anchorEl: e.currentTarget,
            dateRange:{
          startDate : this.props.startDate,
          endDate : this.props.endDate
        }
      })
    }

      return
    }

    this.setState({
      open: true,
      anchorEl: e.currentTarget,
    });
  }

  handleSortFilter = (opt: any) => {
    const { column } = this.state;
    if (column.text === "sort" && opt.value) {
      this.props.handleSort(column.name, opt.value)
    } else if (column.text === "filter" && opt.value) {
      this.props.handleFilter(column.name, opt.value)
    }
  }

  handleRedirect = (col: any, row: any) => {
    if (row[col.render] === "review_now" || row[col.render] === "in_review") {
      this.props.navigate && handleRedirectOnStatus(review_now_arr, row, this.props.navigate)
    }
  }

  updateColorForRow = (row: any , col: any) => {
    let styleObject : any ={
      color:'black',
      fontSize : 500,
      isCapatlize : false
    }
    const obj : any = {
      Paid:'#20BB6E',
      PAID:'#20BB6E',
      Overdue:'#F96262',
      OVERDUE:'#F96262',
      Pending:'black',
      PENDING:'black'
    }
    if ( col.render === "CCI_settlement_date" && (row[col.render] === "Paid" || row[col.render] === "Overdue" || row[col.render] === "Pending" ) ){
     styleObject = {
      color : obj[row[col.render]],
      fontSize : 600,
      isCapatlize : true
    }
     return styleObject
    }
    return styleObject
  }

  renderRowCell = (row: any, col: any) => {
    let shouldBold = false;
    const requiredColor = this.updateColorForRow(row, col);
  
    switch (col.render) {
      case 'company_name':
        return (
          <div style={{ justifyContent: `${col.alignTableData}`, display: 'flex', gap: '10px', width: row[col.render] === 'PAYMENT' || row[col.render] === 'CREDIT RETURN' || row[col.render] === 'CREDIT'  ? '220%' : 'auto' }}>
            <div style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {shouldBold ? <strong>{row[col.render]}</strong> : row[col.render]}
            </div>
            {col?.render === 'company_name' ? (row?.is_delinquent_enable ? <img src={icons.delinquentVector} alt="" /> : '') : ''}
          </div>
        );
      case 'Amount_to_be_Settled_to_CCI':
        shouldBold = row[col.render].includes('(');
        return (
          <div style={{ color: col.isDataColorChanged ? "#757575" : requiredColor.color, fontSize: shouldBold ? "14px" : "12px", fontWeight: col.isBold ? 600 : requiredColor.fontSize, textTransform: requiredColor.isCapatlize ? "uppercase" : "capitalize" }}>
            {shouldBold ? <strong>{row[col.render]}</strong> : row[col.render]}
          </div>
        );
        case 'disbursement_date':
          shouldBold = row[col.render].includes('(');
          const formattedText1 = shouldBold ? row[col.render].replace(/[()]/g, '') : row[col.render];
          return (
            <div style={{ color: col.isDataColorChanged ? "black" : requiredColor.color, fontSize: "12px", fontWeight: col.isBold ? 600 : requiredColor.fontSize, textTransform: requiredColor.isCapitalize ? "uppercase" : "capitalize" }}>
              {shouldBold ? <strong>{formattedText1}</strong> : formattedText1}
            </div>
          );
          case 'amount_disbursed':
          shouldBold = row[col.render].includes('(');
          const formattedText = shouldBold ? row[col.render].replace(/[()]/g, '') : row[col.render];
          return (
            <div style={{ color: col.isDataColorChanged ? "#757575" : requiredColor.color, fontSize: "12px", fontWeight: col.isBold ? 600 : requiredColor.fontSize, textTransform: requiredColor.isCapitalize ? "uppercase" : "capitalize" }}>
              {shouldBold ? <strong>{formattedText}</strong> : formattedText}
            </div>
          );
      case 'amount_disbursed':
      shouldBold = row[col.render].includes('(');
      return (
        <div style={{ color: col.isDataColorChanged ? "#757575" : requiredColor.color, fontSize: shouldBold ? "14px" : "12px", fontWeight: col.isBold ? 600 : requiredColor.fontSize, textTransform: requiredColor.isCapatlize ? "uppercase" : "capitalize" }}>
          {shouldBold ? <strong>{row[col.render]}</strong> : row[col.render]}
        </div>
      );
      case 'total_amount_due':
        return (
          <div style={{ color: row.is_paid ? "rgba(32, 187, 110, 1)" : requiredColor.color, fontSize: shouldBold ? "14px" : "12px", fontWeight: col.isBold ? 600 : requiredColor.fontSize, textTransform: requiredColor.isCapatlize ? "uppercase" : "capitalize" }}>
            {shouldBold ? <strong>{row[col.render]}</strong> : row[col.render]}
          </div>
        );
      default:
        shouldBold = row[col.render] === 'CREDIT' || row[col.render] === 'PAYMENT' || row[col.render] === 'CREDIT RETURN' ;
        return (
          <div style={{ justifyContent: `${col.alignTableData}`, display: 'flex', gap: '10px', width: row[col.render] === 'CREDIT' ||  row[col.render] === 'PAYMENT' || row[col.render] === 'CREDIT RETURN'  ? '220%' : 'auto' }}>
            <div style={{ color: col.isDataColorChanged ? "#757575" : requiredColor.color, fontSize: "12px", fontWeight: col.isBold ? 600 : requiredColor.fontSize, textTransform: requiredColor.isCapatlize ? "uppercase" : "capitalize" }}>
              {shouldBold ? <strong>{row[col.render]}</strong> : row[col.render]}
            </div>
          </div>
        );
    }
  }

  handleDateSelectorClose = () => {
    this.setState({
      isDateFilterVisable: false,
      isDateFilterPopperOpen: false,
      anchorEl: null,
    });
  }

  handleDateApply = (date1: any, date2: any) => {
    this.props.isMoreDatesRequired ? this.props.handleTwoDateChange(formatDate(date1), formatDate(date2) , this.state.column) : this.props.handleDateChanged(formatDate(date1), formatDate(date2))
    this.handleDateSelectorClose()
  }

  render() {
    const { columns, data, headerClasses, classes, loadingTableData, handleRowClick, anchorEl, isThreeDotsMenuNotRequired, handleMenuOpen, handleMenuItemClick, handleMenuClose, selectedClient, handleApplyDateFuntion, handleDateChanged, extraClassnames } = this.props;
    return (
      <Box className={extraClassnames}>
        <TableContainer component={Paper} className={`${classes?.container} ${classes?.overRideContainer}`}>
          <Table>
            <TableHead className={`header ${headerClasses}`}>
              <TableRow>
                {columns.map((col) => {
                  const text = col.sort ? "sort" : "filter"
                  return (<TableCell key={col.id} style={{ minWidth: col?.width ? col.width : "auto" }}>
                    {col?.sort || col?.filter || col.idDateFilterRequired ? (
                      <div className="sortFilContainer">
                        {/* @ts-ignore */}
                        <p style={{ fontSize: "12px", textAlign: `${col.alignItem}`, display: 'flex', alignItems: 'center', gap: '5px' }}>{col.label}</p>
                        <img src={SortIcon} onClick={(e: any) => this.handleOptions(e, col, text)} />
                        <Popper
                          open={this.state.open}
                          anchorEl={this.state.anchorEl}
                          placement="bottom-end"
                          className={classes.settingPopper}
                          transition
                          disablePortal
                        >
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <Paper className="papper">
                              <MenuList
                                autoFocusItem={this.state.open}
                                id="menu-list-grow"
                                className="menuList"
                              >
                                {this.state.options?.map((opt: any) => {
                                  return (<MenuItem key={`opt-${opt.id}`} onClick={() =>
                                    this.handleSortFilter(opt)
                                  }
                                  onTouchEnd={() => {
                                    this.handleSortFilter(opt);
                                  }}
                                  >
                                    <p>{opt.label}</p>
                                  </MenuItem>)
                                })}
                              </MenuList>
                            </Paper>
                          </ClickAwayListener>
                        </Popper>
                        {this.state.isDateFilterVisable &&
                          <Popper
                            open={this.state.isDateFilterPopperOpen}
                            anchorEl={this.state.anchorEl}
                            placement="bottom-end"
                            className="settingPopper"
                            transition
                            disablePortal
                          >
                            <Paper>
                              <TwoDateSelector handleClose={this.handleDateSelectorClose} applyFunction={this.handleDateApply} startDate={reFormatDate(this.state.dateRange.startDate)} endDate={reFormatDate(this.state.dateRange.endDate)} />
                            </Paper>
                          </Popper>
                        }
                      </div>
                    ) : (
                      // @ts-ignore
                      <p style={{ fontSize: "12px", textAlign: `${col.alignItem}`, display: 'flex', alignItems: 'center', gap: '5px' }}>{col.label} {col.isToolTipEnabled ?
                        <CustomTooltip
                          tooltipText={col.toolTipMessage}
                          direction="top"
                          height="10px"
                          width="10px"
                        />
                        : ""}</p>
                    )}
                  </TableCell>)
                })}
                {!isThreeDotsMenuNotRequired ?
                  <TableCell>{""}</TableCell>
                  :
                  ""
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingTableData ?
                <TableRow>
                  <TableCell colSpan={columns.length ?? 5}>
                    <div className="loaderContainer">
                      <CircularProgress color="inherit" />
                    </div>
                  </TableCell>
                </TableRow>
                : Array.isArray(data) && data.length > 0 ? (
                  data.map((row) => (
                    <TableRow key={row.id}
                      onClick={() => { handleRowClick ? handleRowClick(row.id) : "" }}
                      style={{ cursor: handleRowClick ? "pointer" : "default" }}
                    >
                      {columns.map((col) => (
                        <TableCell
                          key={`${row.id}-${col.id}`}
                          className={col.isGray ? "grayTd" : ""}
                          style={{ width: col.width ?? "auto" }}
                        >
                          {this.renderRowCell(row, col)}
                        </TableCell>
                      ))}
                      {!isThreeDotsMenuNotRequired ?
                        <TableCell>
                          <IconButton data-test-id="Menu-open-event" onClick={(event) => handleMenuOpen(event, row)}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            //@ts-ignore 
                            open={Boolean(anchorEl && selectedClient?.id === row.id)}
                            onClose={() => handleMenuClose()}
                            data-test-id="Menu-Close-event"
                            className={classes.menuItemClass}
                          >
                            {menuItems.map(item =>
                              <MenuItem key={item.value} onClick={() => handleMenuItemClick(item.value)}>
                                {item.label}
                              </MenuItem>)}
                            <MenuItem onClick={() => handleMenuItemClick("markDelinquent")}> {row.is_delinquent_enable ? "Unmark as Delinquent" : "Mark Delinquent"} </MenuItem>
                          </Menu>
                        </TableCell>
                        :
                        ""
                      }
                    </TableRow>
                  ))
                ) :
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={0}>
                      No Data Available
                    </TableCell>
                  </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
}

export default withStyles(tStyles)(ClientReportTable);
