export const styles: any = {
  rootContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  activeButton: {
    border: "none",
    backgroundColor: "#fff",
    width: "45%",
    fontSize: "10px",
    fontWeight: "bold",
    cursor: "pointer",
    pointerEvents: "none",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    color: "#000000",
    display: "flex",
    padding:'0px'
  },
  inActiveButton: {
    border: "none",
    width: "45%",
    fontSize: "10px",
    fontWeight: 500,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    backgroundColor: "#fff",
    color: "#8F92A1",
    display: "flex",
    cursor: "pointer",
    padding:'0px'
  },
  containerDiv: {
    color: "white",
    display: "flex",
    width: "680px",
    height: "460px",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)"
  },
  buttonsHolder: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    width: "96px",
    padding: "12px",
    borderRight: "1px solid #BABABA",
    gap:'5px'
  },
  mainCalendarDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  monthDivider: {
    rotate: "90deg",
    width: "275px",
    position: "absolute",
    margin: "-86px 100px 0px 134px"
  },
  calenderContainer: {
    height: "440px",
    width: "545px",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    boxShadow: "0 1px 4px rgba(0, 0, 0, 0.4)"
  },
  btnContainer: {
    display: "flex",
    padding: "10px",
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "flex-end"
  },
  dateField: {
    height: "27px",
    width: "147px",
    color: "#000000",
    fontSize: "12px",
    border: "1px solid #D8D8D8"
  },
  dateContainer: {
    width: "95px",
    height: "35px",
    "& .MuiTypography-root": {
      width: "inherit",
      height: "inherit",
      fontSize: "12px",
      display: "flex",
      justifyContent: "center",
      borderRadius: "5px",
      alignItems: "center"
    }
  },
  dateBox: {
    display: "flex",
    gap: "20px",
    padding: "65px 25px"
  },
  testProvider: {
    position: "relative",
    "& .rdrDateRangePickerWrapper": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      "& .rdrCalendarWrapper": {
        height: "270px",
        width: "535px",
        "& .rdrMonths": {
          "& .rdrMonth": {
            "& .rdrDays": {
              "& .rdrDay": {
                "& .rdrInRange": {
                  backgroundColor: "#FFF6DE"
                },
                "& .rdrDayNumber": {
                  "& span": {
                    color: "black"
                  }
                }
              },
              "& .rdrDay.rdrStartEdge .rdrDayNumber>span": {
                color: "blue"
              },
              "& .rdrDay.rdrEndEdge .rdrDayNumber>span": {
                color: "red"
              },
              "& .rdrDayPassive": {
               "& .rdrDayNumber > span":{
                color :'gray !important'
               }
              }
              
            }
          }
        },
        "& .rdrMonthAndYearWrapper": {
          "& .rdrMonthAndYearPickers": {
            width: "49%",
            justifyContent: "flex-start",
            flex: "unset"
          }
        }
      },
      "& .rdrDefinedRangesWrapper": {
        display: "none"
      }
    }
  },
  calenderBodyDiv: {
    height: "375px"
  },
  cancelButton: {
    background: "white !important",
    height: "27px !important",
    width: "97px !important",
    border: "none !important",
    color: "#979797 !important",
    fontSize: "10px !important"
  },
  applyButton: {
    backgroundColor: "#E5B632 !important",
    height: "27px !important",
    width: "97px !important",
    border: "none !important",
    color: "white !important",
    fontSize: "10px !important",
    borderRadius: "5px !important"
  },
  buttonHrTag: {
    position: "absolute",
    width: "100%",
    top: "4px",
    background: "#D8D8D8",
    height: "1px",
    border: "none"
  }
};
