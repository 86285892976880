import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import {
  getCommissionByScore,
  getScoreFromFormula,
} from "../../../components/src/Utils/utils";
import {
  parseTableData,
  parseValueChainData,
} from "../../../components/src/Utils/parseData";
import { removeTextAndSpecialChars } from "../../../components/src/Utils/dummyData";


interface RiskFactor {
  chain_evaluation_criteria_id: number;
  ranking: number;
  score: number;
  id: number;
}

interface CommissionRange {
  range: string;
  fee: string;
}

type commissionType = string | number | null
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  role: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  object: { [key: string]: RiskFactor };
  commission: commissionType;
  manualCommission: commissionType;
  isValueLoading: boolean;
  totalScore: number;
  create_risk_factor: RiskFactor[];
  data: any[];
  score_range: CommissionRange[];
  formula: string;
  isUpdate: boolean;
  factor_limit: number | string | null;
  client_name: string;
  account_id: number | null;
  token: any;
  saveOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ValueChainEvaluationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataApi: string = "";
  saveFactorAPI: string = "";
  getScoreApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      object: {},
      commission: null,
      manualCommission: null,
      totalScore: 0,
      create_risk_factor: [],
      data: [],
      score_range: [],
      factor_limit: 0,
      isValueLoading: false,
      formula: "a*b",
      client_name: "",
      isUpdate: false,
      account_id: null,
      token: "",
      saveOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidUpdate(prevProps: any, prevState: S): void {
    // Customizable Area Start
    if (prevState.object !== this.state.object) {
      const obj = this.state.object;
      const create_risk_factor: RiskFactor[] = Object.values(obj);
      let total = 0;
      for (const key in obj) {
        total += Number(obj[key].score);
      }
      this.setState({
        create_risk_factor,
        totalScore: Number(total.toFixed(2)),
      });
        const com =
        this.state.score_range.length &&
        getCommissionByScore(this.state.score_range, total);
        this.setState({ commission: com });
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      // Customizable Area Start
      if (apiRequestCallId && responseJson) {  
        this.getResponse(apiRequestCallId, responseJson);
      }
      // Customizable Area End
    }
  }

  // Customizable Area Start

  componentDidMount(): any {
    const token = localStorage.getItem("auth_token");
    const url = window.location.pathname;
    const parts = url.split("/");
    const lastId = parts[parts.length - 1];
    if (token && lastId) {
      this.setState({
        account_id: Number(lastId),
        token: token,
        isValueLoading: true,
      });
      this.getDataApi = this.getData(
        token,
        `bx_block_admin/chain_evaluation_criterias?account_id=${Number(lastId)}`
      );
    }
  }

  convertIntoFloat=(value:any)=>{
      const commissionPercentageStr = value.toString(); 
      const commissionPercentage = parseFloat(commissionPercentageStr);
      const roundedPercentage = commissionPercentage.toFixed(2); 
      const formattedPercentage = roundedPercentage + '%'; 
      return formattedPercentage
  }
  
  handleCloseModal = () => {
    this.setState({ saveOpen: false });
  };

  validationValueSchema = Yup.object().shape({
    manualCommission: Yup.number().nullable().required("Commission is required").moreThan(0, "Commission must be greater than 0%!"),
    factor_limit: Yup.number()
      .nullable()
      .required("Factor limit is required")
      .moreThan(0, "Factor limit must be greater than 0!"),
  });

  handleSubmitModal = () => {
    this.setState({ saveOpen: false });
    this.props.navigation.navigate("FactorAgreemenetReview", {
      id: this.state.account_id,
    });
  };

  handleRankingChange = (e: any, w: number, id: string, updateId: any) => {
    const { value, name } = e.target;
    const parsedNumber = parseFloat(value);
    const score = getScoreFromFormula(
      this.state.formula,
      w * 0.01,
      parsedNumber
    );

    let newState: any = {};
    if (parsedNumber >= 1 && parsedNumber <= 10) {
      newState = {
        [name]: {
          chain_evaluation_criteria_id: id,
          ranking: parsedNumber,
          score: Number(score),
          ...( updateId ? { id: updateId } : {}),
        },
      };
    } else {
      newState = {
        [name]: { chain_evaluation_criteria_id: id, ranking: 0, score: 0 },
      };
    }

    this.setState((prevState) => ({
      object: { ...prevState.object, ...newState },
    }));
  };

  getResponse = (id: any, response: any) => {
    if (id === this.saveFactorAPI) {
      response.created_risk_factors &&
        this.setState({ isValueLoading: false, saveOpen: true });
    }
    if (id === this.getDataApi) {
      this.receiveDataRangeFormula(response);
    }
    if (id === this.getScoreApi) {
      this.handleScoreRanking(response);
    }
  };

  receiveDataRangeFormula = (res: any): void => {
    if (res?.data) {
      const result = parseTableData(res.data);
      this.getScoreApi = this.getData(
        this.state.token,
        `get_client_chain_evaluation_criteria?account_id=${this.state.account_id}`
      );
      this.setState({ data: result });
    }
    if (res?.meta) {
      const {
        indicative_comission_fee,
        score_calculate_formula,
        company_name,
      } = res.meta;
      indicative_comission_fee &&
        this.setState({ score_range: indicative_comission_fee });
      score_calculate_formula &&
        this.setState({ formula: score_calculate_formula[0].formula });
      company_name && this.setState({ client_name: company_name });
    }
    this.setState({ isValueLoading: false });
  };

  handleScoreRanking = (arr: any) => {
    if(!!arr && Array.isArray(arr)) {
      this.setState({ isUpdate: true });
    }
    const updatedArr = parseValueChainData(arr, this.state.data);
    const newState = updatedArr?.map((item) => {
      const subState =
        item?.criteria && item.criteria.toLowerCase().replace(" ", "_");
      return {
        [subState]: {
          chain_evaluation_criteria_id: item.id,
          ranking: item.ranking,
          score: item.score,
          ...(item.updateId ? { id: item.updateId } : {}),
        },
      };
    });
    const obj: any = {};
    for (const item of newState) {
      for (const [key, value] of Object.entries(item)) {
        obj[key] = value;
      }
    }
    this.setState({
      data: updatedArr,
      manualCommission: removeTextAndSpecialChars(updatedArr[0]?.commission) ?? null,
      factor_limit: updatedArr[0]?.factor_limit ?? 0,
      object: obj,
    });
  };

  getData = (token: string, endPoint: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": "application/json",
      token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  saveData = (values:any) => {
    const {
      create_risk_factor,
      totalScore,
      isUpdate,
      account_id,
    } = this.state;

    const endPointValue = isUpdate
      ? `update_risk_factor`
      : `create_risk_factor`;
    const methodValue = isUpdate ? "PUT" : "POST";

    this.setState({ isValueLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveFactorAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointValue
    );
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const httpBody = {
      create_risk_factor,
      commission: values?.manualCommission || this.state.manualCommission,
      total_score: totalScore,
      account_id,
      factor_limit: values?.factor_limit || this.state.factor_limit,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodValue
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
