import React from "react";
import { styles } from "./EKYCStyles.web";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography, TableContainer , TableHead, TableBody, TableCell, TableRow , LinearProgress, Table} from "@material-ui/core";
import EKYC3Controller, { Props } from "./EKYC3Controller.web";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";
import CloseIcon from "@material-ui/icons/Close";
import Dropzone from 'react-dropzone';
import { downloadFiles } from '../../../components/src/Utils/utils'
import CommonModalWeb from "../../../components/src/Modal/CommonModal.web";
const icons = require('./assets')

export class EKYC3 extends EKYC3Controller {
  constructor(props: Props) {
    super(props);

  }
  render() {
    const { classes } = this.props;
    const { 
      errorHandler, 
      isUploadModalOpen , 
      isValidResponse , 
      uploadedFile , 
      isViewModalOpen , 
      isLoading,
      isModalOpen
    } = this.state
    const { open, message, severity } = this.state.snackbar


    return (
      <Box className={classes.boxContainer}>
        <Grid item xs={12}>
          <Box sx={{ margin: '0px 16px', padding: "0 16px 16px" }} className={classes.root}>
            <div className={classes.headerDiv}>
              <Typography>Mandatory Documentation</Typography>
            </div>
            {isLoading ? <CustomLoader open={isLoading} /> :
              <>
                <TableContainer className={classes.borderRemover}>
                  <Table>
                    <TableBody>
                      {this.state.staticArray.length !== 0 && this.state.staticArray.map((item) => (
                        <TableRow className={classes.borderProvider} key={item.id}>
                        <TableCell
                          className={
                            errorHandler && !item.fileData
                              ? classes.errorClass
                              : classes.defaultClass
                          }
                        >
                          {item.value}
                        </TableCell>
                        <TableCell className={classes.buttonCell}>
                          <Button
                              className={classes.viewButton}
                              onClick={() => this.handleViewModalOpen(item.id)}
                              disabled={!item.fileData}
                              data-test-id={`FileViewButton-${item.id}`}
                            >
                              <span className={classes.viewButtonBorder}>
                                View
                              </span>
                            </Button>
                            <Button
                              variant="contained"
                              className={
                                item.fileData
                                  ? classes.uploaded
                                  : classes.browseButton
                              }
                              onClick={() => this.handleOpen(item.id)}
                              data-test-id={`FileBrowseButton-${item.id}`}
                            >
                              {item.fileData ? "Uploaded" : "Browse"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.buttonDiv}>
                  <Box>
                    <Button
                      className={classes.nextButton}
                      variant="contained"
                      data-test-id='saveAndNextButton'
                      onClick={this.setSubmit}
                    >
                      Save & Next
                    </Button>
                  </Box>
                </div>
              </>
            }
          </Box>
          <CustomSnackbar open={open} onCloseSnack={this.closeSnackbar} message={message} severity={severity} />
        </Grid>
        {isViewModalOpen && (
          <div className={classes.ModalContainer}>
            <div className={classes.tableModalDiv}>
              <div className={classes.tableHeaderDiv}>
                <span style={{ fontSize: '18px' }}><b>View  Uploaded  Documents</b></span>
                <div style={{ cursor: 'pointer' }} onClick={this.handleViewModalClose}>
                  <CloseIcon />
                </div>
              </div>
              <Box className={classes.overFlowProvider}>
                <TableContainer>
                  <TableHead className={classes.viewModalTable}>
                    <TableRow className={classes.tableHead}>
                      <TableCell className={classes.FileHeader}>
                        File name
                      </TableCell>
                      <TableCell className={classes.FileHeaders}>Date</TableCell>
                      <TableCell className={classes.FileHeaders} style={{ paddingLeft: '25px' }}>Time</TableCell>
                      <TableCell className={classes.FileHeaders}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uploadedFile.map((file, index) => (
                      <TableRow className={classes.tableBody} key={`TableRow-${index}`}>
                        <TableCell >
                          <div className={classes.FileDataRowName}>
                            <img src={icons.file_upload_icon} alt="" className={classes.fileUploadIcon} />
                            {file.name}
                          </div>
                        </TableCell>
                        <TableCell className={classes.FileDataRowDate}>{file.date}</TableCell>
                        <TableCell className={classes.FileDataRow}>{file.time}</TableCell>
                        <TableCell className={classes.FileDataRowIcon}>
                          <img
                            src={icons.Delete_Icon}
                            alt="" className={classes.fileUploadIconDelete}
                            data-test-id={`FileDeleteButton-${index}`}
                            onClick={() => this.callDeleteAPI(file.id)}
                          />
                          <img src={icons.Download_Icon} alt="" className={classes.fileUploadIconDownload} data-test-id={`FileDownloadButton-${index}`} onClick={() => downloadFiles(file.url)} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TableContainer>
              </Box>
            </div>
          </div>
        )}
        {isUploadModalOpen &&
          <div className={classes.ModalContainer}>
            <div className={classes.ModalDiv}>
              <div className={classes.HeaderDiv}>
                <span style={{ fontSize: '16px' }}><b>Upload Files</b></span>
                <div style={{ cursor: 'pointer' }} onClick={this.handleClose}>
                  <CloseIcon />
                </div>
              </div>
              <Grid container spacing={1} className={classes.modalAndFileHolder}>
                <Grid item md={6} xs={12} sm={6} lg={6} className={classes.modalHolder}>
                  <div className={classes.modal}>

                    <div className={classes.dropzoneHolder}>
                      <Dropzone
                        multiple={false}
                        onDrop={(files) => this.uploadedFileArray(files)}
                        data-test-id="FileDropFunction"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className={classes.dropzone}>
                            <img src={icons.image_Vuesax} alt="" className={classes.modalIcon} />
                            <input {...getInputProps()} />
                            <p style={{ fontSize: '12px' }}>Drop files here or click to browse</p>
                            <Button variant="contained" className={classes.select}>
                              Browse
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                  <div className={classes.modalFooter}>
                    <span>Maximum file size: <b>20 MB.</b></span>
                    <div>Supported file type: <b>JPG, PNG, PDF, Word, Excel</b></div>
                  </div>
                </Grid>
                <Grid  item md={6} xs={12} sm={6} lg={6} className={classes.fileHolder}>
                  {isValidResponse &&
                    uploadedFile.map((file, index) =>
                      <div key={`Div-${index}`}>
                        <div className={classes.fileContainer}>
                          <div className={classes.fileDetails}>
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <img src={icons.file_upload_icon} alt="" className={classes.fileUploadIcon} />
                              <div style={{ fontSize: '12px', width: '100%' }}>{file.name} </div>
                            </div>
                            <div>
                              <CloseIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.callDeleteAPI(file.id)}
                              />
                            </div>
                          </div>
                          <div className={classes.fileProgressBar}></div>
                          <div className={classes.fileUploadSize}>
                            <div style={{ fontSize: '10px' }}> {file.size} MB </div>
                            <div style={{ fontSize: '10px' }}> 100% </div>
                          </div>
                        </div>
                      </div>
                    )}
                </Grid>
            </Grid>
            </div>
          </div>
        }
        <CommonModalWeb
          open={isModalOpen}
          title="Changes have been saved."
          btnText="Done"
          handleModelClose={this.handleModalClose}
          handleSubmitModal={this.handleRedirection}
          widthToAdd='850px'
        />
      </Box>
    );
  }
}

export default withStyles(styles)(EKYC3);
