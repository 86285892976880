export const styles: any = {
    newsHeader: {
        marginBottom:'50px',
        fontSize:'18px',
        fontWeight:700
    },
    responsiveHeaderController:{
        width:"calc(100vw - 286px)"
    },
    '@media (max-width: 600px)': {
        responsiveHeaderController:{
            width:"100%"
        },
    },
}