export const styles: any = {
    boxContainer: {
      display: "grid",
      gridTemplateColumns: "100% 1fr",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexWrap: "nowrap",
      height: "100%",
      marginTop:'5px'
    },
    header:{
      fontSize:'18px',
      fontWeight:600
    },
    searchAutoCompleate:{
      width:'100%',
      marginTop:'30px'
    },
    autoCompleteField:{
      width:'50vw',
      "& .MuiSelect-root": {
        padding: "10px 8px !important",
        fontSize: "12px ",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#757575",
      },
      "& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd": {
        padding: "10px 8px !important",
        fontSize: "12px ",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#757575",
      },
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e5b632",
        },
      }
    },
    userForm:{
      height: "100%",
      width:'100%',
      display:'flex',
      flexDirection:'column',
      gap:'20px'
    },
    menuPaper: {
      marginTop: "5px",
      border: "1px solid #E7E7E7",
      boxShadow: "2px 2px 3px 1px rgba(0,0,0,0.2)",
      borderRadius: "4px",
      "& .MuiMenu-list": {
        padding: "5px 12px",
        "& li": {
          backgroundColor: "transparent !important",
          fonteight: 500,
          fontSize: "12px",
          lineHeight: "15px",
          letterSpacing: "-0.24px",
          color: "#757575",
          borderBottom: "1px solid #E7E7E7",
          "& :hover":{
            backgroundColor: "transparent",
          }
        },
        "& li:last-child": {
          borderBottom: "none",
        },
        "& .Mui-selected": {
          color: "#e5b632",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    reasonHolder:{
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      justifyContent: 'flex-start',
      marginTop:'20px',
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e5b632",
        },
      },
    },
    reasonDescText:{
      alignSelf: 'flex-start',
      fontSize:'14px',
      fontWeight:700
    },
   
    reasonSelectField: {
      margin: 0,
      height: '50px !important',
      marginTop: "10px",
      "& .MuiSelect-root": {
        padding: "10px 8px",
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#757575",
      },
    },
    
    
    DescriptionHolder:{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      justifyContent: 'flex-start',
      marginTop:'20px'
    },
    descriptionField:{
      margin: 0,
      "& .MuiInputBase-root": {
        "& .MuiInputBase-input": {
          padding: "18px",
          fontSize: "12px",
          lineHeight: "15px",
          fontWeight: 500,
          color: "#757575",
        },
      },
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e5b632",
        },
      },
      "& .MuiFormHelperText-root": {
        color: "#ea2f2f",
        margin: 0,
        marginTop: "5px",
      },
    },
    documentUpload:{
      marginTop: '20px',
      width: '95%',
    },
    dropzoneHolder: {
      "& .MuiDropzoneArea-root": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      },
      "& .MuiDropzoneArea-icon": {
        display: "none !important"
      },
      "& .MuiDropzoneArea-text": {},
      "& .MuiGrid-root.MuiDropzonePreviewList-root.MuiGrid-container.MuiGrid-spacing-xs-8": {
        display:'none'
      },
      
    },
    dropzone:{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      border: '2px dashed grey',
      borderRadius: '12px',
      padding: '60px',
      marginTop:'10px'
    },
    select: {
      backgroundColor: '#E5B632 !important',
      color: '#fff !important',
      fontFamily: 'Plus Jakarta Sans !important',
      fontSize: '12px !important',
      fontWeight: '600 !important',
      lineHeight: '15px !important',
      letterSpacing: '-0.23999999463558197px !important',
      textAlign: 'center !important',
      height: '15px',
      width: '38px',
      padding: '15px 50px !important',
      textTransform:'capitalize'
    },
    manageFiles:{
      display:'flex',
      flexDirection:'column',
      gap:'20px'
    },
    fileViewTable:{
      '& .MuiTableRow-root':{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px solid rgba(224, 224, 224, 1)'
      },
      '& .MuiTableCell-root':{
        border:'none'
      },

    },
    submitBtn: {
      color: "#fff",
      backgroundColor: "#e5b632",
      margin: "23px 0 0",
      padding: "10.5px 16px",
      boxShadow: "none",
      fontWeight: 600,
      textTransform: "capitalize",
      width: "280px",
      height: "60px",
      float: "right",
    },
    tabNavigate:{
      display: 'flex',
      justifyContent: 'space-evenly',
      gap: '30px',
      height:'56px'
    },
    navButtonActive:{
      border: '1px solid #e5b632',
      backgroundColor: '#fff',
      width: '45%',
      color: '#e5b632',
      fontSize:'16px',
      fontWeight:500,
      borderRadius:'8px',
      cursor:'initial',
      pointerEvents:'none'
    },
    navButtonNotActive:{
      width: '45%',
      fontSize:'16px',
      fontWeight:500,
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '@media (max-width: 600px)': {
      submitBtn: {
        width: "auto",
      },
    },
    '@media (min-width: 601px) and (max-width: 960px)': {
      submitBtn: {
        width: "50%", 
      },
    },
    '@media (min-width: 961px)': {
      submitBtn: {
        width: "35%", 
      },
    },

  };
  