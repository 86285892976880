// Customizable Area Start
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
const logo = require("./logo.png");

interface Props {
  classes?: any;
}

const styles: any = {
  root: {
    backgroundColor: "#e5b632",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    position:'relative'
  },
  logo: {
    height: "150px",
    width: "150px",
  },
  title: {
    textAlign: "center",
    fontWeight: "600",
  },
  reservedTag: {
    margin: 0,
    textAlign: "center",
    position: "absolute",
    bottom: "2.5rem",
    fontSize: "13px",
  },
};

class LeftLoginSection extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes?.root}>
        <img src={logo} className={classes?.logo} />
        <h1 className={classes?.title}>
          Carbyne Capital <br />
          Investments Limited
        </h1>
        <p className={classes?.reservedTag}>@2023 Carbyne All Right Reserved</p>
      </div>
    );
  }
}

export default withStyles(styles)(LeftLoginSection);
// Customizable Area End