import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "./styles.web";


interface SearchProps {
    open : boolean,
    classes : any
  }

class CustomLoader extends Component<SearchProps> {
  constructor(props : SearchProps) {
    super(props);
  }


  render() {
    const { open , classes } = this.props;

    return (
      <div>
        <Backdrop 
          style={{
            zIndex: 1,
            color: '#fff',
            visibility: 'visible',
            opacity: 1,
            }} 
            open={open}
          >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(styles)(CustomLoader);
