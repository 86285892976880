import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Table,
  TableContainer,
  Box,
  Grid
} from '@material-ui/core';
import { styles } from "./FactoringAgreementStyles.web";
import { downloadFiles } from '../Utils/utils'
const icons = require('./assets')

interface Row {
  id: number;
  name: string;
  type: string
  attributes: any
  documents: any
}

interface Props {
  classes: any;
  data: any;
}


const updateFactorType = (type: string) => {
  const updatedType = type.split("_").join(" ")
  return updatedType
}
//@ts-ignore
const FactorAgreement = ({ classes, sentData }: any) => {
  const data = sentData.data
  const [openRows, setOpenRows] = useState<number[]>([]);

  const toggleRow = (rowId: number) => {
    const isOpen = openRows.includes(rowId);
    if (isOpen) {
      setOpenRows(openRows.filter((id) => id !== rowId));
    } else {
      setOpenRows([...openRows, rowId]);
    }
  };

  return (
    <div className={classes?.FactorAgreementDiv}>
      <TableContainer className={data && classes.tableHolder}>
        <Table style={{ background: 'inherit' }}>
          { data && <TableBody>
            {
              data.attributes.documents.length > 0 ?
                (
                  <>
                    <TableRow
                      style={{
                        transition: 'border-bottom-width 2s ease-in-out',
                        border:'none'
                      }}
                    >
                      <TableCell
                        style={{
                          border:'none'
                        }}
                      >
                        <div className={classes?.tableDataHolder}>
                          <div
                            className={classes?.leftDiv}
                            style={{ gap: '120px' }}
                            onClick={() => toggleRow(data.id)}
                          >
                            <Typography
                              style={{ textTransform: 'capitalize', fontWeight: 600 }}
                              className="docTitle"
                            >
                              {data?.type.split("_").join(" ")}
                            </Typography>
                            <IconButton
                              disabled={(data?.attributes?.documents?.length <= 0)}
                              aria-label="expand row" size="small"
                            >
                              {openRows.includes(data.id) ? (
                                <img src={icons.ArrowUP} alt="Up Arrow" />
                              ) : (
                                <img src={icons.ArrowDown} alt="Down Arrow" />
                              )}
                            </IconButton>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={{
                        border:'none'
                      }}
                    >
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          border: 'none'
                        }}
                        colSpan={4}
                      >
                        <Collapse
                          in={openRows.includes(data.id)}
                          timeout="auto"
                          unmountOnExit
                          style={{ width: '100%' }}
                        >
                          <div className="collapseContainer">
                            {
                              data?.attributes?.documents.map((document: any) => (
                                <Grid container className={classes?.collapse} key={document.id}>
                                  <Grid item xs={6} sm={6} md={6} className={classes.widthProviderCell1}>
                                    <Typography variant="body1" gutterBottom>
                                      <div className={classes?.fileNameWithIcon}>
                                        <img src={icons.file_upload_icon} alt="File Icon" /> {document.name}
                                      </div>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4} sm={4} md={4} className={classes.widthProviderCell2}>
                                    <Typography variant="body1">
                                      {document.date}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} md={2} className={classes.widthProviderCell3}>
                                    <img
                                      src={icons.Download}
                                      style={{ cursor: 'pointer', marginRight: '20px' , float: 'right' }}
                                      onClick={() => downloadFiles(document.url)}
                                      alt="Download"
                                      />
                                  </Grid>
                                </Grid>
                              ))
                            }
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ) : <> No Data available  </>}
          </TableBody>}
        </Table>
      </TableContainer>
    </div>
  );
};

export default withStyles(styles)(FactorAgreement);