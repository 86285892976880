import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

interface BalanceTableProps {
  classes: any;
  data: any[];
}

class InvoiceBalanceTable extends Component<BalanceTableProps> {
  constructor(props: BalanceTableProps) {
    super(props);
  }

  render() {
    const { classes, data } = this.props;
    return (
      <div className={classes?.invoiceTableContainer}>
        {data?.map((obj: any, index) => {
          return (
            <div className="row" key={index} 
             style={{
              height: obj.height ?? "55px",
              backgroundColor: obj.bgColor ?? "#ffffff",
              borderBottom: obj.borderBtm ?? "none",
             }}
             >
              <div 
                className="rowCell label"
                style={{
                  fontSize: obj.label.fontSize?? "12px",
                  color: obj.label.color ?? "#2E2A40",
                  fontWeight: obj.label.fontWeight?? "800",
                }}
              >{obj.label.value} </div>
              <div 
                className="rowCell"
                style={{
                  fontSize: obj.render.fontSize?? "12px",
                  color: obj.render.color ?? "#2E2A40",
                  fontWeight: obj.render.fontWeight?? "800",
                }}
              >{obj.render.value}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

const tStyles: any = {
  invoiceTableContainer: {
    alignItems: "center",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxShadow: "1px 1px 5px 2px rgba(0,0,0,0.1)",
    justifyContent: "center",
    "& .row": {
      justifyContent: "center",
      display: "flex",
      flexDirection: "row",
      width:'100%',
      "& .rowCell": {
        width: "200px",
        textAlign: "end",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "8px 0",
        paddingRight: "20px",
        flexDirection: "row",
        display: "flex",
        fontFamily: 'Plus Jakarta Sans',
      },
      "& .label": {
        width: "291px !important",
        justifyContent: "flex-start !important",
        textAlign: "start",
        borderRight: "1px solid #D8D8D8",
        paddingLeft: "20px",
      }
    },
  },
};

export default withStyles(tStyles)(InvoiceBalanceTable);
