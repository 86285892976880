// Customizable Area Start
import React from "react";
import {
  Grid,
  Box,
  Select,
  MenuItem,
  withStyles,
  Paper,
  Popper,
} from "@material-ui/core";
import GlobalRevenueReportController, {
  Props,
} from "./GlobalRevenueReportController.web";
import { styles } from "./ClientInvoiceStyles.web";
import CommonTable from "../../../components/src/Table/CommonTable.web";
import { globalRevenueColumns } from "../../../components/src/Utils/dummyData";
import {
  parseGlobalTableData,
  parseGlobalTotalData,
} from "../../../components/src/Utils/parseData";
import AdvanceSearch from "../../../components/src/Search/AdvanceSearch.web";
import { downloadExcel } from "./assets";
import {
  downloadDocs,
  printListItemsMsg,
} from "../../../components/src/Utils/utils";
import CustomPagination from "../../../components/src/Pagination/CustomPagination.web";
import TwoDateSelectorWeb from "../../../components/src/CustomDateSelector/TwoDateSelector.web";
import CommonButtonWeb from "../../../components/src/Button/CommonButton.web";
const calenderIcon = require("../../../components/src/Assets/calender.png");

class GlobalRevenueReport extends GlobalRevenueReportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {
      globalTableData,
      isGlobalLoadTable,
      globalClientOptions,
      client,
      page,
      per_page,
      totalGlobalCount,
      csvGlobalURL,
      isGlobalClientsLoad,
      globalSectorOptions,
      sector,
      date_from,
      date_to,
      isDateRangeOpen,
      anchorGlobalEl,
    } = this.state;

    const menuGlobalSectorProps: any = {
      getContentAnchorEl: null,
      transformOrigin: {
        horizontal: "left",
        vertical: "top",
      },
      classes: { paper: classes.menuSectorPaper },
      anchorOrigin: {
        horizontal: "left",
        vertical: "bottom",
      },
    };

    let data: any = parseGlobalTableData(globalTableData.sectors);
    const grand_total_data: any = parseGlobalTotalData(globalTableData);

    return (
      // Required for all blocks
      <Box sx={{ padding: "0 32px 32px", width: "calc(100vw - 285px)" }} className={classes.responsiveProvider}>
        <Grid container spacing={2} style={{ paddingBottom: '20px'}}>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={4} lg={4} sm={12} xs={12}>
                <AdvanceSearch
                  placeHolder="Client"
                  loading={isGlobalClientsLoad}
                  searchValue={client}
                  handleInputChange={this.handleGlobalClientInput}
                  classNames={classes.clientSearch}
                  options={globalClientOptions}
                  handleSelectSearch={this.handleGlobalSelectClient}
                  width="100%"
                />
              </Grid>
              <Grid item md={4} lg={4} sm={12} xs={12}>
                <div className={classes.sectorContainer}>
                  <Select
                    id="sectorGlobalId"
                    variant="outlined"
                    className={classes.selectFieldSector}
                    fullWidth
                    MenuProps={menuGlobalSectorProps}
                    value={Number(sector.id)}
                    onChange={(e) => this.handleGlobalSector(e)}
                    data-test-id="select-sector"
                  >
                    <MenuItem value={0} style={{ display: "none" }}>
                      Sector
                    </MenuItem>
                    {!!globalSectorOptions.length &&
                      globalSectorOptions.map((o: any, index) => (
                        <MenuItem value={Number(o.id)} key={`${o.id + index}`}>
                          {o.name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </Grid>
              <Grid item md={4} lg={4} sm={12} xs={12}>
                <div className={classes.dateRangeContainer}>
                  <div className="dateViewer" onClick={this.handleDateRangeOpen}>
                    {date_from} - {date_to}
                    <img className="calendorIcon" src={calenderIcon} alt="" />
                  </div>
                  <Popper
                    open={isDateRangeOpen}
                    anchorEl={anchorGlobalEl}
                    placement="top"
                    transition
                    disablePortal
                    className="popperHolder"
                    style={{ zIndex: 100 }}
                  >
                    <Paper>
                      <TwoDateSelectorWeb
                        handleClose={this.handleDateRangeClose}
                        applyFunction={this.handleDateRangeFilter}
                        startDate={date_from}
                        endDate={date_to}
                      />
                    </Paper>
                  </Popper>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container lg={5} md={5} sm={12} xs={12} className={classes.downloadHolder}>
                <CommonButtonWeb btnText="Clear Filters" data-test-id="clear-filter" classNames={classes.clearTableFilter} handleClick={this.clearGlobalFilters} />
                <img
                  src={downloadExcel}
                  data-test-id="download-csv"
                  onClick={() => downloadDocs(csvGlobalURL)}
                  style={{ cursor: "pointer" }}
                />        
          </Grid>
        </Grid>
        <Box>
            <CommonTable
              columns={globalRevenueColumns}
              data={data}
              grand_total_data={grand_total_data}
              headerClasses={classes.headerInvoicePOClasses}
              isFirstVertical={true}
              loadingTableData={isGlobalLoadTable}
              handleFilter={this.handleGlobalReportFilter}
              isCollapsibleTable={true}
              isMoreVert={true}
              />
        </Box>
        <Box sx={{ marginTop : '35px'}}>
          <CustomPagination
            count={totalGlobalCount}
            page={page}
            onPaginationChange={this.handleGlobalChangeReportPage}
            msg={printListItemsMsg(totalGlobalCount, page, per_page)}
          />
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(GlobalRevenueReport);
// Customizable Area End
