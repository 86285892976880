// Customizable Area Start
import * as Yup from "yup";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setLocalforAdmin, setLocalforClient } from "../../../components/src/Utils/utils";
import { KYC_Status } from "../../../components/src/Utils/constant";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  password: string;
  email: string;
  apiError: string;
  enablePasswordField: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  isAdmin: boolean
}

interface SS {
  id: any;
}

export default class EmailLoginController extends BlockComponent<Props, S, SS> {
  loginApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      apiError: "",
      enablePasswordField: true,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      isAdmin: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    const Url = window?.location?.pathname || ''
    const token = localStorage.getItem("auth_token") || "";
    const role = localStorage.getItem("account_role") || "";
    if (Url.includes('admin/login')) {
      this.setState({ isAdmin: true });
      return
    }
    if (!!token && !!role) {
      if ((role === "admin" || role === "analyst") && this.state.isAdmin) {
        this.props.navigation.navigate("AdminDashboard");
        return 
      } else if (role === "client") {
        this.props.navigation.navigate("ClientDashboard");
        return 
      }
    }
    localStorage.clear();
  }

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  goToSignUp = () => {
    this.props.navigation.navigate("EmailRegistration");
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      this.getLoginResponse4(apiRequestCallId, responseJson);
    }
  }

  getLoginResponse4 = (id: any, response: any) => {
    if (id && response) {
      if (id === this.loginApiCallId) {
        if (response.data || response.meta) {
          !!this.state.apiError && this.setState({ apiError: "" });
          this.state.isAdmin ?
            this.handleAdminRedirection(response?.meta)
            : this.handleClientRedirection(response?.meta);
        } else {
          const errorMessages =
            response?.errors
              ?.map((error: any) => Object.values(error))
              .flat()
              .join(", ") || "";
          this.setState({ apiError: errorMessages });
        }
      }
    }
  };

  LoginSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/\d/, 'Password requires a number')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .test(
        "special-characters",
        "Password requires a special character",
        (value) => {
          return /[^\w]/.test(value) || /_/.test(value);
        }
      ),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
  });

  handleClientRedirection = (res: any) => {
    const { token, kyc_status } = res;
    if (token) {
      switch (kyc_status) {
        case KYC_Status.inProcess:
          setLocalforClient(res);
          this.props.navigation.navigate("KycDetails1");
          break;
        case KYC_Status.pending:
          this.setState({ apiError: configJSON.pendingMessage });
          break;
        case KYC_Status.approve:
          setLocalforClient(res);
          this.props.navigation.navigate("ClientDashboard");
          break;
        default:
          break;
      }
    }
  }

  handleAdminRedirection = (res: any) => {
    if (res?.token) {
      setLocalforAdmin(res)
      this.props.navigation.navigate("AdminDashboard");
    }
  };

  goToForgotPassword() {
    this.props.navigation.navigate(this.state.isAdmin ? "AdminForgotPassword" : "ForgotPassword");
  }

  doEmailLogIn = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isAdmin ? "admin_login" : "login"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
