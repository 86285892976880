// Customizable Area Start

import React from "react";
import {
  Box,
  Grid,
  Typography,
  InputLabel,
  TextField,
  Divider,
  Button,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import { styles } from "./KycFormStyles.web";
import KycForm1Controller, { Props } from "./KycForm1Controller.web";
import { jamaicaIcon, usaIcon } from "./assets";
import {onKeyDown} from "../../../components/src/Utils/utils"
import { KycForm1Schema } from "../../../components/src/Utils/validations";
import NumberFormatCustom from "../../../components/src/CustomTextField/NumberFormatCustom"

const countries = [
  {
    id: 1,
    value: "Jamaica",
    render: <img src={jamaicaIcon} width="28px" height="28px" />,
  },
  {
    id: 2,
    value: "US",
    render: <img src={usaIcon} width="28px" height="28px" />,
  },
];

class KycForm1 extends KycForm1Controller {
  constructor(props: Props) {
    super(props);
  }

  handleSwitch = (flag: boolean, setField: any) => {
    if (flag) {
      setField("zip_code", "");
      setField("tax_identification_no", "");
    } else {
      setField("postal_code", "");
      setField("tax_registration_no", "");
    }
  };

  render() {
    const { classes } = this.props;
    const initialKyc1Values = {
      account_block_corporate_types_id:
        this.state.account_block_corporate_types_id,
      industry_ids: this.state.industry_ids,
      company_registration_number: this.state.company_registration_number,
      company_name: this.state.company_name,
      tax_registration_no: this.state.tax_registration_no,
      tax_identification_no: this.state.tax_identification_no,
      registered_business_address: this.state.registered_business_address,
      operating_address: this.state.operating_address,
      country_name: this.state.country_name,
      postal_code: this.state.postal_code,
      zip_code: this.state.zip_code,
      new_phone_number: this.state.new_phone_number,
      email: this.state.email,
      primary_contact_name: this.state.primary_contact_name,
      primary_contact_email: this.state.primary_contact_email,
      primary_contact_telephone: this.state.primary_contact_telephone,
      secondary_contact_name: this.state.secondary_contact_name,
      secondary_contact_email: this.state.secondary_contact_email,
      secondary_contact_telephone: this.state.secondary_contact_telephone,
      average_annual_earnings: this.state.average_annual_earnings,
      number_of_employees_full_time: this.state.number_of_employees_full_time,
      number_of_employees_part_time: this.state.number_of_employees_part_time,
    };

    const menuProps: any = {
      classes: { paper: classes.menuPaper },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    };

    const handleCountry = (value: any) => {
      const tax = {
        disableTRN: value !== "Jamaica",
        disableTIN: value !== "US",
      };
      return tax;
    };

    return (
      <div className={classes.mainDiv}>
        <Grid className={classes.rightMainDiv}>
          <Box>
            <Typography variant="h3" className={classes.pageTitle}>
              KYC - Details
            </Typography>
            <Typography variant="subtitle1" className={classes.pageSubTitle}>
              (please fill out the below form)
            </Typography>
          </Box>
          <Divider className={classes.divider} />
          <Formik
            enableReinitialize={true}
            validateOnBlur={true}
            initialValues={initialKyc1Values}
            validationSchema={KycForm1Schema}
            onSubmit={(values: any) => this.saveFirstKyc(values)}
          >
            {({
              errors,
              values,
              touched,
              handleSubmit,
              handleChange,
              setFieldValue,
            }) => {
              const tax_no = handleCountry(values.country_name);

              return (
                <form onSubmit={handleSubmit} onKeyDown={onKeyDown} style={{ width: "100%" }}>
                  <Grid container spacing={3} direction={"row"}>
                    <Grid
                      item
                      xs={12} sm={12} md={4}
                      className={classes.selectContainer}
                    >
                      <InputLabel className={classes.inputLabel}>
                        Corporate Type*
                      </InputLabel>
                      <Select
                        labelId="corporateId-label"
                        id="corporateId"
                        variant="outlined"
                        name="account_block_corporate_types_id"
                        value={values.account_block_corporate_types_id}
                        placeholder={"limited_liability"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className={classes.selectField}
                        MenuProps={menuProps}
                        error={this.handleErrors(
                          errors.account_block_corporate_types_id,
                          touched.account_block_corporate_types_id
                        )}
                        fullWidth
                      >
                        {!!this.state.corporateOptions.length &&
                          this.state.corporateOptions.map((o: any) => (
                            <MenuItem value={o.id} key={o.id}>
                              {o.attributes.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {this.handleErrors(
                        errors.account_block_corporate_types_id,
                        touched.account_block_corporate_types_id
                      ) && (
                        <FormHelperText className={classes.helperText}>
                          {errors.account_block_corporate_types_id}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12} sm={12} md={4}
                      className={classes.selectContainer}
                    >
                      <InputLabel className={classes.inputLabel}>
                        Industry*
                      </InputLabel>
                      <Select
                        labelId="industryId"
                        id="industryId"
                        variant="outlined"
                        name="industry_ids"
                        value={values.industry_ids}
                        placeholder={"agriculture, foresty, fishing"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className={classes.selectField}
                        MenuProps={menuProps}
                        error={this.handleErrors(
                          errors.industry_ids,
                          touched.industry_ids
                        )}
                        fullWidth
                      >
                        {!!this.state.industryOptions.length &&
                          this.state.industryOptions.map((o: any) => (
                            <MenuItem value={Number(o.id)} key={o.id}>
                              {o.attributes.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {this.handleErrors(
                        errors.industry_ids,
                        touched.industry_ids
                      ) && (
                        <FormHelperText className={classes.helperText}>
                          {errors.industry_ids}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Company Registration number*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputCompanyRegistrationNo"
                        type="text"
                        variant="outlined"
                        placeholder="e.g. R15277399277390"
                        name="company_registration_number"
                        value={values.company_registration_number}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.company_registration_number,
                          touched.company_registration_number
                        )}
                        helperText={this.handleHelperText(
                          errors.company_registration_number,
                          touched.company_registration_number
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} direction={"row"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Company Name*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputCompanyName"
                        type="text"
                        variant="outlined"
                        placeholder={"e.g. Tech SolutionsLLC"}
                        name="company_name"
                        value={values.company_name}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.company_name,
                          touched.company_name
                        )}
                        helperText={this.handleHelperText(
                          errors.company_name,
                          touched.company_name
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel
                        className={
                          tax_no.disableTRN
                            ? classes.disableLabel
                            : classes.inputLabel
                        }
                      >
                        Tax Registration No (Jamaica)*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputTaxRegistrationNumber"
                        type="text"
                        variant="outlined"
                        name="tax_registration_no"
                        placeholder={"e.g. 987654321"}
                        value={values.tax_registration_no}
                        onChange={(e) => {
                          setFieldValue("tax_identification_no", null);
                          handleChange(e);
                        }}
                        fullWidth
                        className={
                          tax_no.disableTRN
                            ? classes.disableInputField
                            : classes.inputField
                        }
                        error={this.handleErrors(
                          errors.tax_registration_no,
                          touched.tax_registration_no
                        )}
                        helperText={this.handleHelperText(
                          errors.tax_registration_no,
                          touched.tax_registration_no
                        )}
                        disabled={tax_no.disableTRN}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel
                        className={
                          tax_no.disableTIN
                            ? classes.disableLabel
                            : classes.inputLabel
                        }
                      >
                        Tax Identification No (US)*
                      </InputLabel>
                      <TextField
                        id="txtInputTaxIdentificationNumber"
                        type="text"
                        variant="outlined"
                        name="tax_identification_no"
                        placeholder={"e.g. 987654321"}
                        value={values.tax_identification_no}
                        onChange={(e) => {
                          setFieldValue("tax_registration_no", null);
                          handleChange(e);
                        }}
                        fullWidth
                        className={
                          tax_no.disableTIN
                            ? classes.disableInputField
                            : classes.inputField
                        }
                        error={this.handleErrors(
                          errors.tax_identification_no,
                          touched.tax_identification_no
                        )}
                        helperText={this.handleHelperText(
                          errors.tax_identification_no,
                          touched.tax_identification_no
                        )}
                        disabled={tax_no.disableTIN}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} direction={"row"}>
                    <Grid
                      item
                      xs={12} sm={12} md={4}
                      className={classes.selectContainer}
                    >
                      <InputLabel className={classes.inputLabel}>
                        Country*
                      </InputLabel>
                      <Select
                        id="countryName"
                        variant="outlined"
                        name="country_name"
                        defaultValue={this.state.country_name}
                        value={values.country_name}
                        placeholder="e.g. Jamaica"
                        onChange={(e) => {
                          this.handleSwitch(tax_no.disableTRN, setFieldValue);
                          handleChange(e);
                        }}
                        MenuProps={menuProps}
                        className={classes.selectCountryField}
                        error={this.handleErrors(
                          errors.country_name,
                          touched.country_name
                        )}
                        fullWidth
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {values.country_name === "Jamaica" ? (
                                <img src={jamaicaIcon} />
                              ) : (
                                <img src={usaIcon} />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        endAdornment={
                          <InputAdornment position="start">
                            {values.country_name}
                          </InputAdornment>
                        }
                      >
                        {countries.map((menu: any) => {
                          return (
                            <MenuItem value={menu.value} key={menu.id}>
                              {menu.render}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {this.handleErrors(
                        errors.country_name,
                        touched.country_name
                      ) && (
                        <FormHelperText className={classes.helperText}>
                          {errors.country_name}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel
                        className={
                          tax_no.disableTRN ? classes.disableLabel : classes.inputLabel
                        }
                      >
                        Postal Code*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputPostalCode"
                        type="text"
                        variant="outlined"
                        name="postal_code"
                        placeholder={"e.g. 4567"}
                        value={values.postal_code}
                        onChange={(e) => {
                          setFieldValue("zip_code", null);
                          handleChange(e);
                        }}
                        fullWidth
                        className={
                          tax_no.disableTRN
                            ? classes.disableInputField
                            : classes.inputField
                        }
                        error={this.handleErrors(
                          errors.postal_code,
                          touched.postal_code
                        )}
                        helperText={this.handleHelperText(
                          errors.postal_code,
                          touched.postal_code
                        )}
                        disabled={tax_no.disableTRN}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel
                        className={
                          tax_no.disableTIN ? classes.disableLabel : classes.inputLabel
                        }
                      >
                        Zip Code*
                      </InputLabel>
                      <TextField
                        id="txtInputZipCode"
                        type="text"
                        variant="outlined"
                        name="zip_code"
                        placeholder={"e.g. 08865"}
                        value={values.zip_code}
                        onChange={(e) => {
                          setFieldValue("postal_code", null);
                          handleChange(e);
                        }}
                        fullWidth
                        className={
                          tax_no.disableTIN
                            ? classes.disableInputField
                            : classes.inputField
                        }
                        error={this.handleErrors(
                          errors.zip_code,
                          touched.zip_code
                        )}
                        helperText={this.handleHelperText(
                          errors.zip_code,
                          touched.zip_code
                        )}
                        disabled={tax_no.disableTIN}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} direction={"row"}>
                    <Grid item xs={12} sm={12} md={6}>
                      <InputLabel className={classes.inputLabel}>
                        Registered Business Address*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInput_registered_business_address"
                        type="text"
                        variant="outlined"
                        name="registered_business_address"
                        placeholder={
                          "e.g. 123 Main Street, Suit 100, Anytown, USA"
                        }
                        value={values.registered_business_address}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.registered_business_address,
                          touched.registered_business_address
                        )}
                        helperText={this.handleHelperText(
                          errors.registered_business_address,
                          touched.registered_business_address
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <InputLabel className={classes.inputLabel}>
                        Actual/Operating Address*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputoperating_address"
                        type="text"
                        variant="outlined"
                        name="operating_address"
                        placeholder={"e.g. 156 Oak Avenue, Anytown, USA"}
                        value={values.operating_address}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.operating_address,
                          touched.operating_address
                        )}
                        helperText={this.handleHelperText(
                          errors.operating_address,
                          touched.registered_business_address
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    direction={"row"}
                    className={classes.selectContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Phone*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputphone"
                        type="text"
                        variant="outlined"
                        name="new_phone_number"
                        placeholder={"e.g. +1(555)123-4567"}
                        value={values.new_phone_number}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.new_phone_number,
                          touched.new_phone_number
                        )}
                        helperText={this.handleHelperText(
                          errors.new_phone_number,
                          touched.new_phone_number
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Email*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputEmail"
                        type="email"
                        variant="outlined"
                        name="email"
                        placeholder={"e.g. info@techsolution.com"}
                        value={values.email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(errors.email, touched.email)}
                        helperText={this.handleHelperText(
                          errors.email,
                          touched.email
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} direction={"row"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Primary Contact Name*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputprimary_contact_name"
                        type="text"
                        variant="outlined"
                        name="primary_contact_name"
                        placeholder={"e.g. John Smith"}
                        value={values.primary_contact_name}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.primary_contact_name,
                          touched.company_name
                        )}
                        helperText={this.handleHelperText(
                          errors.primary_contact_name,
                          touched.primary_contact_name
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Primary Contact Email*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputprimary_contact_email"
                        type="email"
                        variant="outlined"
                        name="primary_contact_email"
                        placeholder={"e.g. john.smith@techsolution.com"}
                        value={values.primary_contact_email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.primary_contact_email,
                          touched.primary_contact_email
                        )}
                        helperText={this.handleHelperText(
                          errors.primary_contact_email,
                          touched.primary_contact_email
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Primary Contact Telephone*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputprimary_contact_telephone"
                        type="text"
                        variant="outlined"
                        name="primary_contact_telephone"
                        placeholder={"e.g. +1(555)123-456"}
                        value={values.primary_contact_telephone}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.primary_contact_telephone,
                          touched.primary_contact_telephone
                        )}
                        helperText={this.handleHelperText(
                          errors.primary_contact_telephone,
                          touched.primary_contact_telephone
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} direction={"row"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Secondary Contact Name
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputsecondary_contact_name"
                        type="text"
                        variant="outlined"
                        placeholder={"e.g. Sarah Brown"}
                        name="secondary_contact_name"
                        value={values.secondary_contact_name}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.secondary_contact_name,
                          touched.secondary_contact_name
                        )}
                        helperText={this.handleHelperText(
                          errors.secondary_contact_name,
                          touched.secondary_contact_name
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Secondary Contact Email
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputsecondary_contact_email"
                        type="email"
                        variant="outlined"
                        name="secondary_contact_email"
                        placeholder={"e.g. sarah.brown@techsolution.com"}
                        value={values.secondary_contact_email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.secondary_contact_email,
                          touched.secondary_contact_email
                        )}
                        helperText={this.handleHelperText(
                          errors.secondary_contact_email,
                          touched.secondary_contact_email
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Secondary Contact Telephone
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputsecondary_contact_telephone"
                        type="text"
                        variant="outlined"
                        name="secondary_contact_telephone"
                        placeholder={"e.g. +1(444)123-456"}
                        value={values.secondary_contact_telephone}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.secondary_contact_telephone,
                          touched.secondary_contact_telephone
                        )}
                        helperText={this.handleHelperText(
                          errors.secondary_contact_telephone,
                          touched.secondary_contact_telephone
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} direction={"row"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Average Annual Earnings*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputaverage_annual_earnings"
                        variant="outlined"
                        placeholder={"J$ 5,000,000.00"}
                        name="average_annual_earnings"
                        value={values.average_annual_earnings}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                          inputProps:{
                            style:{
                              MozAppearance:"textfield"
                            },
                          },
                        }}
                        className={classes.inputField}
                        error={this.handleErrors(
                          errors.average_annual_earnings,
                          touched.average_annual_earnings
                        )}
                        helperText={this.handleHelperText(
                          errors.average_annual_earnings,
                          touched.average_annual_earnings
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Number of employees(Full-Time)*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputnumber_of_employees_full_time"
                        type="number"
                        variant="outlined"
                        placeholder={"e.g. 50"}
                        name="number_of_employees_full_time"
                        value={values.number_of_employees_full_time}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        InputProps={{
                          inputProps:{
                            style:{
                              MozAppearance:"textfield"
                            }
                          }
                        }}
                        error={this.handleErrors(
                          errors.number_of_employees_full_time,
                          touched.number_of_employees_full_time
                        )}
                        helperText={this.handleHelperText(
                          errors.number_of_employees_full_time,
                          touched.number_of_employees_full_time
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Number of employees(Part-Time)*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputnumber_of_employees_part_time"
                        type="number"
                        variant="outlined"
                        placeholder={"e.g. 10"}
                        name="number_of_employees_part_time"
                        value={values.number_of_employees_part_time}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        fullWidth
                        className={classes.inputField}
                        InputProps={{
                          inputProps:{
                            style:{
                              MozAppearance:"textfield"
                            }
                          }
                        }}
                        error={this.handleErrors(
                          errors.number_of_employees_part_time,
                          touched.number_of_employees_part_time
                        )}
                        helperText={this.handleHelperText(
                          errors.number_of_employees_part_time,
                          touched.number_of_employees_part_time
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ width: "100%" }}>
                    <Button
                      data-test-id={"btnSaveAndNext1"}
                      variant="contained"
                      fullWidth
                      className={classes.saveBtn}
                      type="submit"
                    >
                      Save & Next
                    </Button>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(KycForm1);
// Customizable Area End
