import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
import { apiCall, handleApiError , revenueChartData ,globalChartData, refinedCardsData , handleColorAdder, handleRedirectOnStatus} from "../../../components/src/Utils/utils";
import { logoutUser } from '../../../framework/src/Utilities';
import { covertDashboardNotifications } from '../../../components/src/Utils/parseData';
import { closedNotifyMessage, review_now_arr, timelineChartOptions } from "../../../components/src/Utils/constant";

type cardData = {

}

type token = string | null
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface InvoiceStatistics {
  total_paid_as_a_percentage: number | null;
  total_paid_as_a_monetary_value: string | null;
  total_overdue_as_a_percentage: number | null;
  total_overdue_as_a_monetary_value: string | null
}

interface S {
  // Customizable Area Start
  notificationData: any;
  timelineOption: {
    id: number;
    name: string;
    key: string;
  };
  timelineChartData: any;
  dashboardToken: string | null | undefined;
  invoice_statistics: InvoiceStatistics;
  revenueData : any;
  globalData: any;
  isLoading:boolean;
  cardsData : cardData[];
  defaultFilter : number;
  chartData:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdminDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  notificationsApi: string = "";
  timelineApi: string = "";
  getAdminDashboardDataAPI: string = "";
  getAdminSectorDataAPI: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      notificationData: [],
      timelineOption: timelineChartOptions[0],
      timelineChartData: [],
      dashboardToken: "",
      invoice_statistics: {
        total_paid_as_a_percentage: 0,
        total_paid_as_a_monetary_value: "J$ 0",
        total_overdue_as_a_percentage: 0,
        total_overdue_as_a_monetary_value: "J$ 0",
      },
      revenueData:[],
      globalData: [],
      isLoading:false,
      cardsData:[],
      defaultFilter:30,
      chartData:[],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentWillMount() {
    const Dashtoken = localStorage.getItem("auth_token");
    if(Dashtoken) {
      this.setState({
        isLoading: true,
        dashboardToken: Dashtoken
      })
      this.timelineApi = apiCall(Dashtoken , "timeline_graph_data", "GET");
      this.getAdminDashboardDataAPI = apiCall(Dashtoken , "admin_dashboard" , "GET")
      this.getAdminSectorDataAPI = apiCall(Dashtoken , "get_sector_data" , "GET")
      this.notificationsApi = apiCall(Dashtoken , "search_notification_advance?per_page=20" , "GET")
    } else {
      toast.warn("Something went wrong")
      logoutUser();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const { timelineOption, dashboardToken} = this.state
    if(prevState.timelineOption.id !== timelineOption.id){
      this.timelineApi = apiCall(dashboardToken , "timeline_graph_data", "GET");
    }
  }

  handleSelectTimeline = (e:any) =>{
    const option = timelineChartOptions.filter(
      (opt) => opt.id == e.target.value
    );
    if (option.length > 0) {
      this.setState({ timelineOption: option[0] });
    }
  }
  
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      if (apiRequestCallId && responseJson) {
        this.responseAPI(apiRequestCallId, responseJson);
      }
    }
  }

  responseAPI = (id:any, res:any) =>{
    if(this.notificationsApi === id ) {
      this.notificationsResponse(res)
    }
    if(this.timelineApi === id) {
      this.timelineResponse(res)
    }
    if(this.getAdminDashboardDataAPI === id ) {
      this.handleAdminChartsData(res)
    }
    if(this.getAdminSectorDataAPI === id ) {
      this.handleAdminSectorChartData(res)
    }
  }

  notificationsResponse = (res:any) => {
    if(res?.notifications?.data){
      const data = covertDashboardNotifications(res.notifications.data)
      this.setState({ notificationData: data})
    } else if( res.status !== 200) {
      toast.warn("Something went wrong")
    } else {
      handleApiError(res.errors)
    }
  }

  timelineResponse = (res:any) => {
    if(res?.monthly_data && res?.invoice_statistics){
      const data = Object.values(res.monthly_data)
      this.setState({ timelineChartData: data, invoice_statistics: res.invoice_statistics})
    } else if( res.status !== 200) {
      toast.warn("Something went wrong")
    } else {
      handleApiError(res.errors)
    }
  }

  gotoNotificationReview = (obj:any) => {
    const { status } = obj
    if (status === "review_now" || status === "in_review") {
     handleRedirectOnStatus(review_now_arr, obj, this.props.navigation.navigate);
    } else {
      toast.warn(closedNotifyMessage)
    }
  }

  handleAdminChartsData = (res:any) => {
    const updatedCardData = refinedCardsData(res)
    const updatedFilter = res?.filter
    this.setState({
      cardsData: updatedCardData,
      defaultFilter : updatedFilter,
    })
  }

  handleAdminSectorChartData = (res : any) => {
    const globalData = globalChartData(res)
    const revenueData = revenueChartData(res)
    const refinedEntireData = handleColorAdder(res)
    this.setState({
      globalData: globalData,
      revenueData: revenueData,
      chartData:refinedEntireData,
      isLoading: false,
    })
  }

  handleChange = (event:any) =>{
    this.setState({
      defaultFilter:event.target.value
    })
    this.getAdminDashboardDataAPI = apiCall(this.state.dashboardToken , `admin_dashboard?previous_period=${event.target.value}` , "GET")
  }

  // Customizable Area End
}
