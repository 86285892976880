import React, { Component } from "react";
import {
    Paper,
    Popper,
    MenuItem,
    MenuList,
    ClickAwayListener,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

interface PopperProps {
    open: boolean;
    options: any[];
    anchorEl: any;
    handleClose: any;
    handleMenuClick: any;
    placement?: any;
    classes: any;
    classNames?: any,
}

class CommonPopper extends Component<PopperProps> {
    constructor(props: PopperProps) {
        super(props);
    }

    render() {
        const {
            open,
            anchorEl,
            options,
            handleClose,
            classes,
            classNames,
            handleMenuClick,
            placement,
        } = this.props;

        return <Popper
            open={open}
            anchorEl={anchorEl}
            placement={placement ?? "bottom-end"}
            className={`${classes?.settingPopper} ${classNames}`}
            transition
            disablePortal
        >
            <ClickAwayListener onClickAway={handleClose}>
                <Paper className="papper">
                    <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        className="menuList"
                    >
                        {options?.map((opt: any) => {
                            return (<MenuItem key={`opt-${opt.id*4}`} onClick={() =>
                                handleMenuClick(opt)
                            }
                            onTouchEnd={() => {
                                handleMenuClick(opt);
                              }}
                            >
                                <p>{opt.label}</p>
                            </MenuItem>)
                        })}
                    </MenuList>
                </Paper>
            </ClickAwayListener>
        </Popper>
    }
}

const styles: any = {
    settingPopper: {
        top: "15px !important",
        "& .papper": {
            borderRadius: "4px",
            boxSizing: "border-box",
            border: "1px solid #E9EAED",
            padding: "6px 10px",
            "& .menuList": {
                padding: "0px",
                display: "flex",
                flexDirection: "column",
                "& li": {
                    borderBottom: "1px solid #E7E7E7",
                    minHeight: "auto",
                    "&:last-child": {
                        borderBottom: "none",
                    },
                    "& p": {
                        margin: "0",
                        fontSize: "12px",
                        lineHeight: "15px",
                        fontWeight: 500,
                        letterSpacing: "-0.14px",
                        color: "#757575",
                    },
                },
            },
        },
    },
};

export default withStyles(styles)(CommonPopper);
