export const styles: any = {
  root: {
    '& .MuiTextField-root': {
      marginBottom: '10px',
    },
    '& .css-ahj2mt-MuiTypography-root': {
      fontSize: '12px !important'
    },
    '& .css-zpphrz-MuiTypography-root': {
      fontSize: '12px !important'
    },
    '& .EKYC3-root-1.MuiBox-root.css-1wcaknn': {
      backgroundColor: 'red !important'
    },
    '& MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl': {
      outline: '#e5b632 !important',
      outlineColor: '#e5b632 !important',
    }
  },
  divider: {
    background: "#E7E7E7",
    marginRight:'18px',
    position:'relative',
    top:'-20px'
  },
  boxContainer: {
    width: "100%",
    marginTop:'5px'
  },
  rowDivInside: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '15px',
    gap: '20px'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '32px',
  },
  insideRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    justifyContent: 'left',
    gap: '8px',
  },
  insideRowUpdated: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    justifyContent: 'left',
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
  },
  nextButton: {
    color: "#FFF !important",
    fontSize: "14px !important",
    margin: "23px 0px 35px 15px !important",
    backgroundColor: '#e5b632 !important',
    float: "right !important",
    cursor: "pointer !important",
    height: '60px',
    borderRadious: '4px',
    width: '400px'
  },
  gridItem: {
    padding: '0',
    height: '100vh !important'
  },
  gridContainer: {
    width: "100%",
    gap: '75px'
  },
  secondGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0 50px 50px",
    overflowY: "auto",
    flexGrow: 1,
  },
  textField97: {
    width: '100%',
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "18px",
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#757575",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
  },
  textFieldSelect: {
    width: '100%',
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        height: '60px',
        padding: "18px",
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#757575",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
  },
  FontClass: {
    fontSize: '12px !important',
    fontStyle: 'normal',
    fontWeight: '500',
    '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
      fontSize: '12px !important',
      fontStyle: 'normal',
      fontWeight: '500',
    }
  },
  menuPaper: {
    marginTop: "5px",
    border: "1px solid #E7E7E7",
    boxShadow: "2px 2px 3px 1px rgba(0,0,0,0.2)",
    borderRadius: "4px",
    "& .MuiMenu-list": {
      padding: "5px 12px",
      "& li": {
        backgroundColor: "transparent !important",
        fonteight: 500,
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "-0.24px",
        color: "#757575",
        borderBottom: "1px solid #E7E7E7",
        "& :hover": {
          backgroundColor: "transparent",
        }
      },
      "& li:last-child": {
        borderBottom: "none",
      },
      "& .Mui-selected": {
        color: "#e5b632",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
  selectField: {
    margin: 0,
    marginTop: "8px",
    height: '50px',
    width: '100%',
    "& .MuiSelect-root": {
      padding: "18px 14px",
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: 500,
      color: "#757575",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
  },
  textField190: {
    width: '100%',
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "18px",
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#757575",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
  },
  textField196: {
    width: '100%',
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "18px",
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#757575",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
  },
  textField450: {
    width: '450px !important',
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "18px",
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#757575",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
  },
  buttonHolder: {
    width: '70vw !important'
  },
  helperText: {
    color: "#ea2f2f",
    margin: 0,
    marginTop: "5px",
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
  headerDiv: {
    display:'flex',
    justifyContent:'space-between',
    padding:'10px',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px !important',
    fontWeight: '600',
  },
  uploadTextHolder:{
    fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px !important',
      fontWeight: '400',
  },
  uploaded: {
    backgroundColor: '#20BB6E !important',
    color: '#fff !important',
    fontFamily: 'Plus Jakarta Sans !important',
    fontSize: '12px !important',
    fontWeight: '600 !important',
    lineHeight: '15px !important',
    letterSpacing: '-0.23999999463558197px !important',
    textAlign: 'center !important',
    height: '15px',
    width: '38px',
    padding: '10px 40px !important',
    textTransform:'capitalize'
  },
  viewButton: {
    backgroundColor: 'inherit',
    color: '#979797 !important',
    fontFamily: 'Plus Jakarta Sans !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
    lineHeight: '15px !important',
    letterSpacing: '-0.23999999463558197px !important',
    textAlign: 'center !important',
    height: '15px',
    width: '38px',
    padding: '10px 40px !important',
    textTransform:'capitalize'
  },
  viewButtonBorder:{
    borderBottom : '1px solid #979797'
  },
  select: {
    backgroundColor: '#E5B632 !important',
    color: '#fff !important',
    fontFamily: 'Plus Jakarta Sans !important',
    fontSize: '12px !important',
    fontWeight: '600 !important',
    lineHeight: '15px !important',
    letterSpacing: '-0.23999999463558197px !important',
    textAlign: 'center !important',
    height: '23px',
    width: '38px',
    padding: '15px 50px !important',
    textTransform:'capitalize'
  },
  browseButton: {
    backgroundColor: '#E5B632 !important',
    color: '#fff !important',
    fontFamily: 'Plus Jakarta Sans !important',
    fontSize: '12px !important',
    fontWeight: '600 !important',
    lineHeight: '15px !important',
    letterSpacing: '-0.23999999463558197px !important',
    textAlign: 'center !important',
    height: '15px',
    width: '38px',
    padding: '10px 40px !important',
    textTransform:'capitalize'
  },
  buttonCell: {
    display: 'flex !important',
    gap: '5px !important',
    justifyContent: 'right',
    alignItems:'center'
  },

  errorClass: {
    color: 'red',
    fontSize: '16px !important',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '-0.32px',
  },

  defaultClass: {
    color: '#757575',
    fontSize: '16px !important',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '-0.32px',
  },

  radioController: {
    fontSize: '12px !important',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '-0.32px',
    '& input.PrivateSwitchBase-input-37': {
      color: '#E5B632 !important',
      fontSize: '12px !important',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '-0.32px',
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#E5B632 !important'
      },
      '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
        fontSize: '12px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        letterSpacing: '-0.32px',
      }
    }
  },
  checkedRadio: {
    color: '#E5B632 !important',
    fontSize: '12px !important',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '-0.32px',
    '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
      fontSize: '12px !important',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '-0.32px',
    }
  },
  ModalDiv: {
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
    position: "relative",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    minHeight:'395px',
    padding: "24px",
  },
  modalAndFileHolder:{
    display: "flex",
    justifyContent: "space-between",
  },
  HeaderDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 10px"
  },
  modalHolder: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center"
  },
  modal: {
    position: "relative"
  },
  modalButton: {
    position: "absolute",
    bottom: "20%",
    left: "45%"
  },

  modalFooter:{
    padding:'10px 0',
    display:'flex',
    flexDirection:'column',
    gap:'10px',
    fontSize:'12px'
  },
  dropzoneHolder: {
    "& .MuiDropzoneArea-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "& .MuiDropzoneArea-icon": {
      display: "none !important"
    },
    "& .MuiDropzoneArea-text": {},
    "& .MuiGrid-root.MuiDropzonePreviewList-root.MuiGrid-container.MuiGrid-spacing-xs-8": {
      display:'none'
    },
    
  },

  dropzone:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px dashed grey',
    borderRadius: '12px',
    padding: '60px',
  },

  fileHolder: {
    overflowY: 'auto',
    padding: '10px',
    maxHeight: '230px',
    scrollbarWidth: 'thin',
    scrollbarColor: '#888888 #f0f0f0',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888888',
    },
  },
  fileContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginBottom: "15px",
    width: "95%",
    margin: "auto"
  },
  fileDetails: {
    display: "flex",
    justifyContent: "space-between",
    gap:'15px'
  },
  fileProgressBar: {
    borderBottom: "5px solid #E5B632",
    borderRadius:'4px'
  },
  fileUploadSize: {
    display: "flex",
    justifyContent: "space-between",
  },
  ModalContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 100,
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.33)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableModalDiv: {
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
    position: "relative",
    width: "90%",
    maxWidth: "90%",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    maxHeight:'425px'
  },
  overFlowProvider:{
    overflowX:'scroll'
  },
  tableHeaderDiv: {
    display:'flex',
    justifyContent:'space-between',
    padding:'16px 20px 0px 25px',
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 101,
    width: '100%',
    margin: '0 auto',
  },
  FileHeader: {
    width: "450px",
    fontWeight : 700
  },
  FileHeaders: {
    fontWeight : 700
  },
  viewModalTable:{
    '& th.MuiTableCell-root.MuiTableCell-head.EKYC3-FileHeaders':{
      paddingLeft:'40px'
    },
    '& th.MuiTableCell-root.MuiTableCell-head':{
      paddingLeft:'40px'
    },
    '& td.MuiTableCell-root.MuiTableCell-body.EKYC3-FileDataRow-77':{
      paddingLeft:'40px'
    },
  },
  tableHead: {
    backgroundColor: "#F6EACC"
  },
  FileDataRow:{
    fontWeight:'400',
    paddingLeft:'40px',
    '& td.MuiTableCell-root.MuiTableCell-body.EKYC3-FileDataRow-77':{
      paddingLeft:'40px'
    }
  },
  FileDataRowDate:{
    fontWeight:'400',
    paddingLeft:'20px'
  },
  fileUploadIconDelete:{
    cursor:'pointer',
    marginLeft: '20px',
    height: '25px',
  },
  fileUploadIconDownload:{
    cursor:'pointer',
    marginLeft: '10px',
    height: '25px',
  },
  FileDataRowName:{
    paddingLeft:'20px',
    display:'flex',
    gap:'15px'
  },

  borderRemover:{
    '& .MuiTableCell-root': {
      border: 'none'
    },
  },
  borderProvider:{
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },

  '@media (max-width: 600px)': {
    nextButton: {
      width: "auto",
    },
    ModalDiv:{
      height:'80%',
      overflowY:'scroll',
    }
  },
  '@media (min-width: 601px) and (max-width: 960px)': {
    nextButton: {
      width: "50%", 
    },
  },
  '@media (min-width: 961px)': {
    nextButton: {
      width: "35%", 
    },
  },

}

