const drawerWidth = "265px";
export const styles: any = (theme: any) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  barPaper: {
    width: drawerWidth,
    backgroundColor: "#e5b632",
    color: "black",
    border: "none",
    borderRadius: "0px 12px 12px 0px",
    boxShadow: "none",
    zIndex:'0',
    "& .rootDiv": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "10% 35px 0",
      margin: "5% 5px 10%",
      height: "100%",
      overflowX: "hidden",
      "& .logoDiv": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        rowGap: "25px",
        marginBottom: "34px",
        textAlign: "center",
        "& .logoImg": {
          height: "67px",
          width: "67px",
        },
        "& .logoText": {
          textAlign: "center",
          margin: 0,
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "12px",
          lineHeight: "15px",
          letterSpacing: "-0.24px",
        },
      },
      "& .menuContainer": {
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        flexDirection: "column",
        position: "relative",
        display: "flex",
        "& .menuTitle": {
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "23px",
          letterSpacing: "-0.36px",
          color: "#202A45",
        },
        "& .menuGroup": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "12px",
          marginBottom: "12px",
          "& .menu": {
            background: "rgba(255, 215, 103, 0.409746)",
            borderRadius: "8px",
            height: "30px",
            width: "180px",
            textDecoration: "none",
            "& .menuText": {
              fontSize: "12px",
              lineHeight: "15px",
              fontWeight: 700,
              color: "#202A45",
              padding: "8px 7px 7.5px",
              margin: 0,
              cursor: "pointer",
            },
          },
          "& .activeMenu": {
            background: "#fff !important",
          },
        },
        "& .logoutBtn": {
          background: "rgba(255, 215, 103, 0.409746)",
          borderRadius: "8px",
          height: "30px",
          width: "180px",
          fontSize: "12px",
          lineHeight: "15px",
          color: "#202A45",
          marginTop: "auto",
          fontWeight: 700,
          fontFamily: "'Plus Jakarta Sans'",
          "& .MuiButton-label": {
            justifyContent: "flex-start",
            textTransform: "none",
          },
        },
      },
      "& .bottomDiv": {
        position: "absolute",
        bottom: "10%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        rowGap: "30px",
        padding: "20px 15px",
        textAlign: "center",
        "& .bottomTitle": {
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "23px",
          letterSpacing: "-0.36px",
          margin: 0,
        },
        "& .bottomBody": {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "18px",
          letterSpacing: "-0.28px",
          margin: 0,
        },
      },
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f1f1f1',
      "&::-moz-scrollbar-thumb:hover": {
        backgroundColor: "#888",
      },
      "&::-webkit-scrollbar": {
        width: "0.4rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#ccc",
        borderRadius: "0.25rem",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#aaa",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1",
        borderRadius: "0.25rem",
      },
    },
    
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  MuiModalRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalBox: {
    width: "90%",
    maxWidth: "900px",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    margin: "auto",
    borderRadius: "10px",
    marginTop: "35vh",
    "& p":{
      color: "black",
      fontWeight: 500,
    }
  },
  modalOverlay: {
    backgroundColor: "#D8D8D8",
    opacity: "0.33",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "-1",
  },
  doneButton: {
    backgroundColor: "#FFFFFF",
    color: "#E5B632",
    fontWeight: 600,
    fontFamily: "'Plus Jakarta Sans'",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.36px",
    textTransform: "capitalize",
    padding: "8px 16px",
    borderRadius: "4px",
    cursor: "pointer",
    width: "200px",
    margin: "0 10px",
    border: "1px solid #E5B632",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  noBtn: {
    backgroundColor: "#F7F7F7",
    color: "black",
    border: "1px solid #F7F7F7",
    "&:hover": {
      backgroundColor: "#F7F7F7",
    },
  },
  logoutButtonsHolder:{
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  '@media (max-width: 600px)': {
    content: {
      width: "100%",
    },
  },
});
