// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  withStyles,
} from "@material-ui/core";
import NewsClientDetailsController, { Props } from "./NewsClientDetailsController.web";
import { nStyles } from "./NewsClientDetailsStyles.web";
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";

class NewsClientDetails extends NewsClientDetailsController {
  constructor(props: Props) {
    super(props);
  }

render() {
  const { classes } = this.props;
  const { body, title, imgUrl,newsDate, isLoadNews  } = this.state
  return (
    <Box>
      { isLoadNews && <CustomLoader open={isLoadNews} />}
      <Grid item xs={12}>
        <Box sx={{ padding: "0 32px 32px" }} className={classes.responsiveHeaderController}>
          <Box className={classes.backNews} data-test-id="back-news" onClick={()=>this.props.navigation.goBack()}> {`<  Back to all News`}</Box>
          <Box sx={{ marginBottom: '28px', fontWeight: 700, fontSize: '24px' }}>{title}</Box>
          <Box className={classes.imgContainer}>
            { imgUrl && <img src={imgUrl} alt="News"/>}
          </Box>
          <div className={classes.newsDate}>{newsDate}</div>
          <div className={classes.htmlContent} dangerouslySetInnerHTML={{ __html: body }} />
        </Box>
      </Grid>
    </Box>
  );
  }
}

export default withStyles(nStyles)(NewsClientDetails);
// Customizable Area End
