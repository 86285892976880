// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Table,
  TableContainer,
  Paper,
  Button,
} from "@material-ui/core";

import EKYCNotificationController, {
  Props,
} from "./EKYCNotificationController.web";
import { styles } from "./EKYCNotificationStyles.web";
import { downArrow, upArrow } from "./assets";
import CommonModalWeb from "../../../components/src/Modal/CommonModal.web";
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";
import ReviewRejectModalWeb from "../../../components/src/Modal/ReviewRejectModal.web";
import { downloadFiles , isFileRevied} from "../../../components/src/Utils/utils"
const fileIcon = require("../../../components/src/Assets/file.png");
const downloadIcon = require("./assets")

class EKYCNotification extends EKYCNotificationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { openRows, rejectOpen, approveOpen, document, comment, kycDocuments, companyName, approveResTitle, createdAtDate ,isLoading } = this.state;
    const isFileReviewed = isFileRevied(kycDocuments)
    return (
      <div className={classes.mainDiv}>
        {isLoading && <CustomLoader open={isLoading}/>}
        <Typography className="title">
          EKYC form + Mandatory Document
        </Typography>
        <Box className="subTitleContainer">
          <Typography variant="h3" className="title">
            {companyName}
          </Typography>
          <Typography variant="subtitle1" className="date">
            {createdAtDate}
          </Typography>
        </Box>
        <TableContainer component={Paper} className="tableContainer">
          <Table>
            <TableBody>
              {kycDocuments.map((row , index) =>
               {
                return (
                <React.Fragment key={row?.id}>
                  <TableRow
                    style={{
                      borderBottom: openRows.includes(row?.id)
                        ? "none"
                        : "1px solid #E7E7E7",
                      transition: "border-bottom-width 2s ease-in-out",
                    }}
                  >
                    <TableCell>
                      <div
                        className="leftDiv"
                        data-test-id={`toogleRow-${index}`}
                        onClick={() => this.toggleRow(row?.id)}
                      >
                        <Typography style={{textTransform:'capitalize'}}>{row.title}</Typography>
                        <IconButton aria-label="expand row" size="small">
                          {openRows.includes(row.id) ? (
                            <img src={upArrow} />
                          ) : (
                            <img src={downArrow} />
                          )}
                        </IconButton>
                      </div>
                      <div className="rightDiv">
                        <img 
                          src={downloadIcon.Download} 
                          style={{ cursor: 'pointer' }} 
                          data-test-id={`downloadAllFiles-${index}`}
                          onClick={()=>this.downloadAllFiles(row.files)}
                          alt="Download" 
                        />
                        <Button 
                          data-test-id={`updateFileReview-${index}`}
                          onClick={()=>this.updateFileReview(row.status,row.titleToSend)} 
                          className={row?.status ? classes.isReviewed : classes.notReviewed }
                        >
                          {row?.status ? 'Reviewed' : 'Review'}
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        borderBottom: openRows.includes(row.id)
                          ? "1px solid #E7E7E7"
                          : "none",
                      }}
                      colSpan={4}
                    >
                      <Collapse
                        in={openRows.includes(row.id)}
                        timeout="auto"
                        unmountOnExit
                        style={{ width: "100%" }}
                      >
                        <div className="collapseContainer">
                          {/* @ts-ignore */}
                          {row?.files?.map((row, index) => {
                            return (<div className="collapse" key={row.id}>
                              <Typography variant="body1" gutterBottom>
                                <img src={fileIcon} /> {row.name}
                              </Typography>
                              <img
                                src={downloadIcon.Download}
                                style={{ cursor: 'pointer', marginRight: '35px' }}
                                data-test-id={`downloadFiles-${index}-2`}
                                onClick={() => downloadFiles(row.url)}
                                alt="Download"
                              />
                            </div>)
                          })}
                        </div>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )})}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="btnContainer">
          <Button
            className={`btn approveBtn ${isFileReviewed ? '' : 'disabled'}`}
            data-test-id={`handleApproveClick-2`}
            onClick={() => this.handleApproveClick()}
            disabled={!isFileReviewed}
          >
            Approve
          </Button>
          <Button
            className={`btn ${isFileReviewed ? '' : 'disabled'}`}
            data-test-id={`handleApproveClick-3`}
            onClick={() =>  this.setState({ rejectOpen: true })}
            disabled={!isFileReviewed}
          >
            Reject
          </Button>
        </div>
        <CommonModalWeb
          open={approveOpen}
          title={approveResTitle}
          description=""
          btnText="Proceed"
          handleSubmitModal={this.approveRequest}
          handleModelClose={this.handleModalClose}
          widthToAdd="85%"
          isKycModal={true}
        />
        <ReviewRejectModalWeb
          open={rejectOpen}
          document={document}
          comment={comment}
          handleChange={this.handleRejectInputChange}
          handleSubmitModal={this.rejectRequest}
          handleModelClose={this.handleModalClose}
          widthToAdd="85%"
        />
      </div >
    );
  }
}

export default withStyles(styles)(EKYCNotification);
// Customizable Area End
