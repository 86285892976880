export const styles: any = {
  invoiceReportDiv: {
    padding: "0 50px 50px",
    display: "flex",
    marginTop: "2px",
    height: "100%",
    rowGap: "20px",
    flexDirection: "column",
    "& .reportTitle": {
      fontSize: "18px",
      color: "#2E2A40",
      fontWeight: 600,
      letterSpacing: "-0.36px",
      lineHeight: "27px",
      fontStyle: "normal",
      margin: 0,
    },
  },
  headerInvoiceClasses: {
    background: "#F6EACC",
  },
};
