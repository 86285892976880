export const styles: any = {
    boxContainerInvoice: {
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexWrap: "nowrap",
        height: "100%",
        marginTop:'5px',
        width:'calc(100vw - 285px)'
      },

      headerInvoice:{
        fontSize:'18px',
        fontWeight:600
      },

      searchAutoCompleateInvoice:{
        width:'100%',
        marginTop:'30px'
      },

      autoCompleteFieldInvoice:{
        width:'50vw',
        "& .MuiSelect-root": {
          padding: "10px 8px !important",
          fontSize: "12px ",
          lineHeight: "15px",
          fontWeight: 500,
          color: "#757575", 
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd": {
          padding: "10px 8px !important",
          fontSize: "12px ",
          lineHeight: "15px",
          fontWeight: 500,
          color: "#757575",
        },
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5b632",
          },
        }
      },

      userFormInvoice:{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        gap:'20px'
      },

      menuPaperInvoice: {
        marginTop: "5px",
        border: "1px solid #E7E7E7",
        boxShadow: "2px 2px 3px 1px rgba(0,0,0,0.2)",
        borderRadius: "4px",
        "& .MuiMenu-list": {
          padding: "5px 12px",
          "& li": {
            backgroundColor: "transparent !important",
            fonteight: 500,
            fontSize: "12px",
            lineHeight: "15px",
            letterSpacing: "-0.24px",
            color: "#757575",
            borderBottom: "1px solid #E7E7E7",
            "& :hover":{
              backgroundColor: "transparent",
            }
          },
          "& li:last-child": {
            borderBottom: "none",
          },
          "& .Mui-selected": {
            color: "#e5b632",
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        },
      },

      reasonHolderInvoice:{
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        justifyContent: 'flex-start',
        marginTop:'20px',
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5b632",
          },
        },
      },

      reasonDescTextInvoice:{
        alignSelf: 'flex-start',
        fontSize:'14px',
        fontWeight:700
      },
     
      reasonSelectFieldInvoice: {
        margin: 0,
        height: '50px !important',
        marginTop: "10px",
        "& .MuiSelect-root": {
          padding: "10px 8px",
          fontSize: "12px",
          lineHeight: "15px",
          fontWeight: 500,
          color: "#757575",
        },
      },
      
      DescriptionHolderInvoice:{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        justifyContent: 'flex-start',
        marginTop:'20px'
      },

      descriptionFieldInvoice:{
        margin: 0,
        "& .MuiInputBase-root": {
          "& .MuiInputBase-input": {
            padding: "18px",
            fontSize: "12px",
            lineHeight: "15px",
            fontWeight: 500,
            color: "#757575",
          },
        },
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5b632",
          },
        },
        "& .MuiFormHelperText-root": {
          color: "#ea2f2f",
          margin: 0,
          marginTop: "5px",
        },
      },

      documentUploadInvoice:{
        marginTop: '20px',
        width: '95%',
      },

      dropzoneHolderInvoice: {
        display:'flex',
        flexDirection:'column',
        gap:'20px',
        height:'251px',
        "& .MuiDropzoneArea-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .MuiDropzoneArea-icon": {
          display: "none !important"
        },
        "& .MuiDropzoneArea-text": {},
        "& .MuiGrid-root.MuiDropzonePreviewList-root.MuiGrid-container.MuiGrid-spacing-xs-8": {
          display:'none'
        },
      },

      dropzoneInvoice:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        border: '1px dashed #979797',
        borderRadius: '12px',
        height:'160px',
      },

      dropzoneTextInvoice:{
        textAlign:'center',
        fontSize:'12px'
      },

      selectInvoice: {
        backgroundColor: '#E5B632 !important',
        color: '#fff !important',
        fontFamily: 'Plus Jakarta Sans !important',
        fontSize: '12px !important',
        fontWeight: '600 !important',
        lineHeight: '15px !important',
        letterSpacing: '-0.24px !important',
        textAlign: 'center !important',
        height: '15px',
        width: '38px',
        padding: '15px 50px !important',
        textTransform:'capitalize'
      },

      manageFilesInvoice:{
        display:'flex',
        flexDirection:'column',
        gap:'20px'
      },

      fileViewTableInvoice:{
        '& .MuiTableRow-root':{
          display:'flex',
          justifyContent:'space-between',
          borderBottom:'1px solid rgba(224, 224, 224, 1)'
        },
        '& .MuiTableCell-root':{
          border:'none'
        },
  
      },

      submitBtnInvoice: {
        color: "#fff",
        backgroundColor: "#e5b632",
        margin: "23px 0 0",
        padding: "10.5px 16px",
        boxShadow: "none",
        fontWeight: 600,
        textTransform: "capitalize",
        width: "320px",
        height: "60px",
        float: "right",
      },

      navigationButtonInvoice:{
        display: 'flex',
        gap: '30px',
        height:'56px',
        paddingLeft:'10px'
      },
      
      navButtonActiveInvoice:{
        border: '1px solid #e5b632',
        backgroundColor: '#fff',
        width: '350px',
        height:'56px',
        color: '#e5b632',
        fontSize:'18px',
        fontWeight:600,
        borderRadius:'8px',
        cursor:'initial',
        pointerEvents:'none',
        textTransform:'capitalize'
      },

      navButtonNotActiveInvoice:{
        width: '350px',
        height:'56px',
        fontSize:'18px',
        fontWeight:500,
        backgroundColor: '#F7F7F7',
        textTransform:'capitalize'
      },

      inputMapHolderInvoice:{
        display: 'flex', 
        flexWrap: 'wrap',
        marginTop: '25px'
      },

      FieldLableHolderInvoice:{
        width: '50%', 
        padding: '10px',
        display:'flex', 
        flexDirection:'column',
        gap:'5px'
      },

      fieldLableInvoice:{
        fontSize:'12px',
        color:'#202A45',
        fontWeight:600,
        display: 'flex',
        alignItems: 'center'
      },
      
      inputTextFieldInvoice: {
        "& .MuiInputBase-root": {
          "& .MuiInputBase-input": {
            padding: "18px",
            fontSize: "12px",
            lineHeight: "15px",
            fontWeight: 700,
            color: "#2E2A40",
          },
        },
        "& .MuiOutlinedInput-adornedStart": {
          fontSize: "12px",
          fontWeight: 700,
          color: "#2E2A40",
          lineHeight: "15px",
          "& .MuiInputBase-input": {
           paddingLeft: "5px !important",
          }
        }, 
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5b632",
          },
        },
        "& .MuiFormHelperText-root": {
          color: "#ea2f2f",
          margin: 0,
          marginTop: "5px",
        },
      },
      '@media (max-width: 600px)': {
        submitBtnInvoice: {
          width: "50%",
        },
        navButtonActiveInvoice:{
          width:'100%'
        },
        navButtonNotActiveInvoice:{
          width:'100%'
        },
        navigationButtonInvoice:{
          flexWrap:'wrap',
          height:'auto'
        },
        boxContainerInvoice:{
          width:'100%'
        }
      },
      '@media (min-width: 601px) and (max-width: 960px)': {
        submitBtnInvoice: {
          width: "50%", 
        },
        navigationButtonInvoice:{
          flexWrap:'wrap',
          height:'auto'
        },
        navButtonActiveInvoice:{
          width:'100%'
        },
        navButtonNotActiveInvoice:{
          width:'100%'
        },
      },
      '@media (min-width: 961px)': {
        submitBtnInvoice: {
          width: "35%", 
        },
      },
  };
  