import React, { Component } from "react";
import {
  TextField,
  Modal,
  Button,
  Box,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import * as Yup from "yup";
import CommonButtonWeb from "../Button/CommonButton.web";

interface ModalProps {
  classes: any;
  open: boolean;
  document?:string;
  comment: string;
  btnText?: string;
  handleChange: any;
  handleSubmitModal: any;
  handleModelClose: any;
  title?: string;
  widthToAdd? :any;
}

class ReviewRejectModal extends Component<ModalProps> {
  constructor(props: ModalProps) {
    super(props);
  }

  validationSchema = Yup.object().shape({
    comment: Yup.string()
      .nullable()
      .required("Reasons are required for send request"),
  });

  render() {
    const {
      open,
      classes,
      comment,
      btnText,
      handleModelClose,
      handleSubmitModal,
      handleChange,
      title,
      widthToAdd
    } = this.props;

    return (
      <Modal
        classes={{ root: classes?.MuiModalRoot }}
        open={open}
        onClose={() => handleModelClose()}
      >
        <div>
          <Formik
            enableReinitialize={true}
            validateOnBlur={true}
            initialValues={{comment: comment }}
            validationSchema={this.validationSchema}
            onSubmit={() => handleSubmitModal()}
          >
            {({ errors, values, handleSubmit, isValid }) => {
              return (
                <form className={classes?.modalBox} onSubmit={handleSubmit} style={{width:widthToAdd ? widthToAdd : 'max-content'}}>
                  <IconButton
                    className={classes?.closeButton}
                    onClick={() => handleModelClose()}
                  >
                    <CloseIcon />
                  </IconButton>
                  <p className="title">{title || "Are you sure you want to reject this?"}</p>
                  <Box>
                    <InputLabel className={"inputLabel"}>Reason for rejecting:</InputLabel>
                    <TextField
                      variant="outlined"
                      className="inputField"
                      name="comment"
                      value={values.comment}
                      onChange={(e: any) => handleChange(e)}
                      placeholder="Please provide the reason for the rejection here..."
                      error={Boolean(errors.comment)}
                      helperText={errors.comment}
                      multiline
                      fullWidth
                    />
                  </Box>
                  <div className="rejectBtnContainer">
                    <CommonButtonWeb
                      btnText="Cancel"
                      handleClick={handleModelClose}
                    />
                    <CommonButtonWeb
                      btnText={btnText || "Reject"}
                      handleClick={() => { }}
                      backgroundColor="#F27151"
                      disabled={!isValid}
                      type="submit"
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
          <div className={classes?.modalOverlay} />
        </div>
      </Modal>
    );
  }
}

const styles: any = {
  MuiModalRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "50px",
  },
  closeButton: {
    position: "absolute",
    top: "5px",
    right: "25px",
    "& svg": {
      fill: "black",
      fontSize: "1.85rem",
    },
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "900px",
    width: "100%",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    padding: "55px 38px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "28px",
    margin: "auto",
    borderRadius: "12px",
    "& .title": {
      fontSize: "18px",
      textAlign: "center",
      color: '#202A45',
      fontFamily: 'Plus Jakarta Sans',
      margin: 0,
      fontWeight: '600',
      wordWrap: 'break-word'
    },
    "& .MuiBox-root": {
      width: "100%",
      "& .inputLabel": {
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: "500",
        color: "#202A45",
      },
      "& .inputField": {
        margin: 0,
        marginTop: "15px",
        "& .MuiInputBase-root": {
          "& .MuiInputBase-input": {
            padding: "18px",
            fontStyle: "normal",
            lineHeight: "15px",
            letterSpacing: "-0.24px",
            color: "#000000",
            fontWeight: "500",
            fontSize: "13px",
          },
          "& .MuiInputBase-inputMultiline": {
            padding: 0,
            height: "100% !important",
            minHeight: "160px",
            maxHeight: "160px",
          },
        },
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5b632",
          },
        },
        "& .MuiFormHelperText-root": {
          color: "#ea2f2f",
          margin: 0,
          marginTop: "5px",
        },
      },
    },
   "& .rejectBtnContainer":{
      flexDirection: "row",
      gap: "20px",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      flexWrap:'wrap'
   }
  },
  modalOverlay: {
    backgroundColor: "#D8D8D8",
    opacity: "0.33",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "-1",
  },
};

export default withStyles(styles)(ReviewRejectModal);
