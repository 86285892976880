import React, { Component } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

interface AnchorProps {
  vertical: 'top' | 'bottom' | 'center';
  horizontal: 'left' | 'right' | 'center';
}

interface SnackbarProps {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
  onCloseSnack: () => void;
  hideDuration?: number;
  anchorOrigin?: AnchorProps;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CustomSnackbar extends Component<SnackbarProps> {
  render() {
    const { open, message, severity, onCloseSnack, hideDuration, anchorOrigin } = this.props;
    const anchor:any = !!anchorOrigin ? anchorOrigin : { vertical: "top", horizontal: "center" }
    return (
      <Snackbar
        open={open}
        autoHideDuration={hideDuration || 4000}
        onClose={onCloseSnack}
        anchorOrigin={anchor}
      >
        <Alert onClose={onCloseSnack} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    );
  }
}

export default CustomSnackbar;
