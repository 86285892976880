// Customizable Area Start
import React from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Formik } from "formik";
import EmailLoginController, { Props } from "./EmailLoginController.web";
import LeftLoginSection from "../../../components/src/LeftLoginSection.web";
import { styles } from "./EmailLoginStyles.web";
const pdfLogoIcon = require("../../../components/src/Assets/Login_Logo.svg");

class EmailLogin extends EmailLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {
      email,
      placeHolderEmail,
      password,
      placeHolderPassword,
      enablePasswordField,
      apiError,
    } = this.state;

    return (
      // Required for all blocks
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.leftLoginSection}>
          <LeftLoginSection />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.loginRightSection}>
          <Box className={classes.mobileLogoHolder}> <img src={pdfLogoIcon}/> </Box>
          <Box sx={{ maxWidth: "425px", width: "100%", minWidth: "280px" }}>
            <Typography variant="h4" className={classes.title}>
              Welcome to Carbyne
            </Typography>
            <Typography variant="body1" className={classes.subTitle}>
              Login to your account
            </Typography>
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={{ email: email, password: password }}
              validationSchema={this.LoginSchema}
              onSubmit={() => this.doEmailLogIn()}
            >
              {({ errors, values, handleSubmit, setFieldValue }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Box>
                      <InputLabel className={classes.inputLabel}>
                        Email
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputEmail"
                        type="email"
                        variant="outlined"
                        placeholder={placeHolderEmail}
                        value={values.email}
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                          this.setEmail(e.target.value);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={Boolean(errors.email) || !!apiError}
                        helperText={errors.email}
                      />
                    </Box>
                    <Box>
                      <InputLabel className={classes.inputLabel}>
                        Password
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputPassword"
                        type={enablePasswordField ? "password" : "text"}
                        variant="outlined"
                        placeholder={placeHolderPassword}
                        value={values.password}
                        onChange={(e) => {
                          setFieldValue("password", e.target.value);
                          this.setPassword(e.target.value);
                        }}
                        fullWidth
                        className={classes.inputField}
                        error={Boolean(errors.password) || !!apiError}
                        helperText={errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                edge="end"
                              >
                                {enablePasswordField ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box
                      id={"btnForgotPassword"}
                      onClick={() => this.goToForgotPassword()}
                      className={classes.forgotPass}
                    >
                      Forgot Password?
                    </Box>
                    {!!apiError && (
                      <Box
                        data-test-id={"errorMessage"}
                        className={classes.errorMsg}
                      >
                        {apiError}
                      </Box>
                    )}
                    <Box>
                      <Button
                        data-test-id={"btnEmailLogIn"}
                        variant="contained"
                        fullWidth
                        className={classes.loginBtn}
                        type="submit"
                      >
                        Log In
                      </Button>
                    </Box>

                    {!this.state.isAdmin && <Box
                      data-test-id={"btn"}
                      className={classes.endText}
                    >
                      Create an account?{" "}
                      <span className="redirect" onClick={() => this.goToSignUp()}>Sign Up</span>
                    </Box>}
                  </form>
                );
              }}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(EmailLogin);
// Customizable Area End
