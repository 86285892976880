import React from "react";
import { withStyles } from "@material-ui/core/styles";

interface Props {
  classes: any;
  password: string;
}

const styles: any = {
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  bar: {
    height: "5px",
    width: "75px",
    backgroundColor: "#ddd",
    margin: "0 4px",
    borderRadius: "2px",
  },
  message: {
    marginTop: "4px",
    fontSize: "12px",
    textAlign: "center",
    fontWeight: "bold",
  },
  strength1: {
    backgroundColor: "#ff0000"
  },
  strength2: {
    backgroundColor: "#ffa700"
  },
  strength3: {
    backgroundColor: "#fff400"
  },
  strength4: {
    backgroundColor: "#a3ff00"
  },
  strength5: {
    backgroundColor: "#2cba00"
  }
};

const strengthColors = ["#ff0000", "#ffa700", "#fff400", "#a3ff00", "#2cba00"];
const strengthMessages = ["Weak", "Fair", "Strong", "Very strong", "Excellent"];

function regexMatch(arr: RegExp[], password: string) {
  let count = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].test(password)) count++;
  }
  return count;
}

function PasswordStrength({ password = "", classes }: Props) {
  const strengthLevel = regexMatch([
    /[A-Z]/,
    /\d/,
    /^[\s\S]{8,32}$/,
    /[a-z]/,
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
  ], password);

  if (password === "") {
    return null;
  }

  const barStyle = {
    height: "5px",
    width: "75px",
    backgroundColor: strengthColors[strengthLevel - 1],
    margin: "0",
    borderRadius: "2px",
  };

  return (
    <div>
      <div className={classes?.container}>
        {[...Array(5)].map((_, i) => {
          return (
            <div key={i}>
              <div className={classes?.bar}>
                <div className={classes?.one} style={i < strengthLevel ? barStyle : {}}></div>
              </div>
              {i === 4 && (
                <div className={classes?.message} style={{ color: strengthColors[strengthLevel - 1] }}>
                  {strengthMessages[strengthLevel - 1]}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default withStyles(styles)(PasswordStrength);
