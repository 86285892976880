import { MAIN_DIV_WIDTH } from "../../../components/src/Utils/constant";

export const styles: any = {
  headingTitle5: {
    fontFamily: "Plus Jakarta Sans",
    color: "#2E2A40",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "23px",
    marginBottom: "20px",
  },
  inputLabel5: {
    fontSize: "12px",
    lineHeight: "15.12px",
    letterSpacing: "-0.24px",
    fontWeight: 500,
    color: "#202A45",
    marginTop: "4px",
  },
  inputField5: {
    margin: 0,
    marginTop: "12px",
    width: "100%",
    maxWidth: "533px",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        letterSpacing: "-0.24px",
        padding: "18px 12px 17px",
        lineHeight: "15px",
        fontWeight: 700,
        fontSize: "12px",
      },
    },
    "& .MuiOutlinedInput-adornedStart": {
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "15px",
      "& .MuiInputBase-input": {
        paddingLeft: "5px !important",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#ea2f2f",
      marginTop: "5px",
    },
  },
  mainRootDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0 50px 50px",
    gap: "28px",
    overflowY: "auto",
    width: MAIN_DIV_WIDTH,
    flexGrow: 1,
    "& .mainPaymentTitle": {
      color: "#2E2A40",
      fontSize: "24px",
      wordWrap: "break-word",
      marginBottom: "14px",
      margin: "0 12px 0 0",
      fontFamily: "Plus Jakarta Sans",
      fontWeight: "700",
    },
  },
  btnContainer5: {
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column",
    marginTop: "5px",
    rowGap: "42px",
    display: "flex",
  },
  leftTitle: {
    color: "#2E2A40",
    fontSize: "18px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: "700",
    wordWrap: "break-word",
    margin: "0 0 28px",
  },
  rightTitle: {
    fontFamily: "Plus Jakarta Sans",
    color: "#2E2A40",
    fontWeight: "800",
    margin: "0 12px 0 0",
    fontSize: "18px",
    wordWrap: "break-word",
    textAlign: "center"
  },
  rightBtn: {
    padding: "10px 32px 8px",
    "& .MuiButton-label": {
      fontSize: "12px !important",
      fontFamily: "Plus Jakarta Sans",
      fontWeight: "800 !important",
      lineHeight: "21px",
    },
  },
  tableContainer: {
    width: "100%",
    marginTop: "15px",
    "& .tableHeader": {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#F6EACC",
      justifyContent: "center",
      height: "55px",
      width: "100%",
      alignItems: "center",
      "& .headCell": {
        textAlign: "center",
        width: "266px",
        color: "#2E2A40",
        fontSize: "12px",
        fontFamily: "Plus Jakarta Sans",
        fontWeight: "700",
        wordWrap: "break-word",
      },
    },
    "& .tableBody": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection:'column',
      boxShadow: "0px 3px 5px 1px rgba(0, 0, 0, 0.1)",
      padding: "7px 0",
      "& .tableRow": {
        flexDirection: "row",
        display: "flex",
        flexWrap: "nowrap",
        width:'100%',
        justifyContent: "center",
        "& .cell": {
          maxWidth: "266px",
          width:'50%',
          TextAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "8px 0",
          flexDirection: "row",
          display: "flex",
        },
        "& .editCell": {
          borderRight: "1px solid #E7E7E7",
          "& .field": {
            "& .MuiInputBase-root": { 
                width:'110px',
                "& .MuiInputBase-input": {
                lineHeight: "15px",
                color: "#979797",
                fontSize: "12px",
                fontFamily: "Plus Jakarta Sans",
                fontWeight: "600",
                wordWrap: "break-word",
                textOverflow: "ellipsis",
                padding: "0",
              },
            },
            "& .MuiInput-underline.Mui-disabled::before":{
              borderBottomStyle: "none",
            },
            "& .MuiInput-underline":{
              "&::after":{
                borderBottom: "1px solid #E5B632",
              },
              "&::before":{
                borderBottom: "1px solid #979797",
              }
            },
            "& .Mui-disabled":{
              color: "#2E2A40",
              "& .MuiInputBase-input": {
                color: "#2E2A40",
              }
            },
            "& .MuiInputBase-adornedStart": {
              "& span": {
                fontWeight: 600,
                lineHeight: "15px",
                fontSize: "12px",
                marginRight: "1px",
                color: "#2E2A40",
              },
            },
          },
          "& .icon": {
            marginLeft: "8px",
            "& img": {
              cursor: "pointer",
            },
          },
        },
        "& .dateCell": {
          color: "#757575",
          fontSize: "12px",
          fontWeight: "500",
          wordWrap: "break-word",
          fontFamily: "Plus Jakarta Sans",
        },
      },
    },
  },
  FontClass: {
    fontSize: '12px !important',
    fontStyle: 'normal',
    fontWeight: '500',
    '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
      fontSize: '12px !important',
      fontStyle: 'normal',
      fontWeight: '500',
    }
  },
  radioController: {
    fontSize: '12px !important',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '-0.32px',
    '& input.PrivateSwitchBase-input-37': {
      color: '#E5B632 !important',
      fontSize: '12px !important',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '-0.32px',
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#E5B632 !important'
      },
      '& .MuiRadio-colorSecondary:hover': {
        color: '#E5B632 !important'
      },
      '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
        fontSize: '12px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        letterSpacing: '-0.32px',
      }
    }
  },
  checkedRadio: {
    color: '#E5B632 !important',
    fontSize: '12px !important',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '-0.32px',
    '& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
      fontSize: '12px !important',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '-0.32px',
    }
  },
  '@media (max-width: 600px)': {
    mainRootDiv: {
      width: "100%",
      marginTop:'25px'
    },
  },
  
};
