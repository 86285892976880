import React, { Component } from "react";
import { Box, Divider } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import DoneIcon from '@material-ui/icons/Done';

interface StepperProps {
  steps: any[];
  classes: any;
  activeStep: number;
}

class CustomizedStepper extends Component<StepperProps> {
  constructor(props: StepperProps) {
    super(props);
  }

  render() {
    const {
      steps,
      activeStep,
      classes
    } = this.props;

    return (
      <Box className={classes?.stepRoot}>
        {
          steps?.map((step, index) => {

            return (
              <React.Fragment key={index}>
                <div className="icon-div">
                  <div className={`icon ${index <= activeStep ? "colorIcon" : ""}`}>
                    {index >= activeStep ? <span className="icon-text" style={{ color: index === activeStep ? "white" : "#D1D5DB" }}>{index + 1}</span> : <DoneIcon style={{color: "white", height:"20px"}}/>}
                  </div>
                  <p>{step}</p>
                </div>
                {index < steps.length - 1 &&
                  <Divider className="HRline" style={{ backgroundColor: index < activeStep ? "#F3B229" : "#D1D5DB" }} />
                }
              </React.Fragment>
            )
          })
        }
      </Box>
    );
  }
}

const styles: any = {
  stepRoot: {
    width: '100%',
    display: "flex",
    flexDirection: "row",
    "& .icon-div": {
      display: "flex",
      flexDirection: "column",
      rowGap: "12px",
      position: "relative",
      justifyContent: "flex-start",
      alignItems: "center",
      "& .icon": {
        justifyContent: "center",
        width: '26px',
        height: '26px',
        background: 'white',
        display: "flex",
        borderRadius: "50%",
        border: '2px #D1D5DB solid',
        alignItems: "center",
        "& .icon-text": {
          fontSize: "12px",
          fontFamily: 'Plus Jakarta Sans',
          fontWeight: '800',
          wordWrap: 'break-word'
        },
      },
      "& .colorIcon": {
        background: '#F3B229 !important',
        border: '2px #F3B229 solid !important',
      },
      "& p": {
        color: '#2E2A40',
        fontSize: "12px",
        position: "absolute",
        bottom: "-23px",
        lineHeight: "15.12px",
        margin: "0",
        fontWeight: "bold",
        wordWrap: 'break-word'
      }
    },
    "& .HRline": {
      width: "100%",
      maxWidth: "500px",
      minWidth: "20px",
      backgroundColor: '#D1D5DB',
      height: "2px",
      borderRadius: 1,
      marginTop: "13px",
      '@media (min-width: 960px) and (max-width: 1440px)': {
        width: "45%",
      },
      '@media (max-width: 960px)': {
        width: "40%",
      },
    }
  },
  // '@media (max-width: 600px)': {
  //   invoiceDiv: {
  //     width: "100%",
  //     marginTop:'15px'
  //   },
  //   '@media (min-width: 960) and (max-width: 1440px)': {
  //     nextButton: {
  //       width: "50%", 
  //     },
  //   },
  // },
};

export default withStyles(styles)(CustomizedStepper);
