import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { InputLabel, TextField ,Box } from "@material-ui/core";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
const calender = require("../Assets/calender.png");

interface DateProps {
  classes: any;
  value: any;
  handleChange: any;
  name: string;
  error?: any;
  helperText?: any;
  disabled?: boolean;
  width?: string;
  min?:Date;
}

class DateTextField extends Component<DateProps> {
  constructor(props: DateProps) {
    super(props);
  }

  render() {
    const {
      classes,
      value,
      handleChange,
      name,
      error,
      helperText,
      disabled,
      width,
      min
    } = this.props;

    const formats = ['YYYY-MM-DD', 'DD-MM-YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD', 'MMMM DD, YYYY','MMMMDD, YYYY', 'MMMMDD,YYYY'];

    let date: Date | null = null;
    for (let i = 0; i < formats.length; i++) {
      const format = formats[i];
      const parsedDate = moment(value, format, true);
      if (parsedDate.isValid()) {
        date = parsedDate.toDate();
        break;
      }
    }
    
    return (
      <Box style={{ width: width ?? "533px"}} className={classes?.datePicker1}>
        <DatePicker
          customInput={<TextField
            type="text"
            value={date ? moment(date).format("MMMM DD, YYYY") : ""}
            onChange={handleChange}
            variant="outlined"
            error={error}
            helperText={helperText}
            className={classes?.inputDateField}
            style={{ width: width ?? "533px"}}
            InputProps={{
              endAdornment: <img src={calender} alt="calendar" />,
            }}
            placeholder="e.g. July 15, 2020"
          />}
          minDate={min}
          name={name}
          dateFormat="MMMM dd, yyyy"
          selected={date}
          disabled={disabled || false} 
          onChange={(date) => handleChange({target:{name:name, value: moment(date).format("MMMM DD, YYYY")}})}
          className={classes?.datePicker}
          showPopperArrow={false}
        />
      </Box>
    );
  }
}

const styles: any = {
  datePicker:{
    width: "100%",
  },
  datePicker1:{
    "& .react-datepicker-wrapper":{
      width:'inherit',
    }
  },
  inputDateField: {
    margin: 0,
    marginTop: "12px",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        letterSpacing: "-0.24px",
        lineHeight: "15px",
        fontWeight: 700,
        padding: "18px 12px 17px",
        fontSize: "12px",
      },
    },
    "& .MuiOutlinedInput-adornedStart": {
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "15px",
      "& .MuiInputBase-input": {
       paddingLeft: "5px !important",
      }
    }, 
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#ea2f2f",
      marginTop: "5px",
    },
  },
};

export default withStyles(styles)(DateTextField);
