const tStyles: any = {
  container: {
    boxShadow: "0px 3px 4px rgba(90, 88, 88, 0.09)",
    borderRadius: "0px",
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    "& table": {
      "& thead": {
        "& tr": {
          "& th": {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "18px",
            fontFamily: "Plus Jakarta Sans",
            color: "#2E2A40",
            height: "55px",
            "& .sortFilContainer":{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "& img": {
                margin: "0 10px",
                cursor: "pointer"
              },
              "& p":{
                margin: 0,
              },
            },
            "&:first-child":{
              paddingLeft: "30px"
            },
            "&:last-child": {
              paddingRight: "30px",
            }
          },
        },
      },
    },
    "& tbody": {
      "& .loaderContainer": {
        height: "250px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& tr": {
        "& .grayTd":{
          color: "#757575 !important"
        },
        "& td": {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "15px",
          fontFamily: "Plus Jakarta Sans",
          letterSpacing: "-0.24px",
          height: "55px",
          color: "#2E2A40",
          maxWidth: "325px",
          "& .adminHighlight": {
            background: "#FFF4D7",
            borderRadius: "5px",
            padding: "8px 16px",
            width: "max-content",
          },
          "& .statusBtn":{
            background: "#F27151",
            borderRadius: "4px",
            padding: "4px 6px",
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: "12px",
            textAlign: 'center',
            letterSpacing: "-0.24",
            color: '#FFFFFF',
            width: "92px",
            cursor: "pointer",
          },
          "& .morevertPopper": {
            zIndex: "1000",
            right: "-100px !important",
            top: "0 !important",
            left: "unset !important",
            "& .papper": {
              padding: "8px 11px 5px !important",
              "& .menuList": {
                "& li": {
                  "& p": {
                    "&:hover":{
                      color: "#2E2A40 !important"
                    }
                  },
                },
              },
            },
          },
          "& .delinquentContainer":{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center",
            flexDirection: "row",
            "& img": {
              paddingLeft: "20px"
            }
          },
          "& .collapseIconContainer": {
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            columnGap:"25px",
            flexWrap: "nowrap",
          },
          "&:first-child":{
            paddingLeft: "30px"
          },
          "&:last-child": {
            paddingRight: "30px",
          }
        },
        "&:last-child":{
          "& td": {
            borderBottom: "none"
          }
        }
      },
      "& .grand-total-row": {
        height: "75px",
        "& .grand-first-cell": {
          fontSize: "18px !important",
          fontWeight: "800 !important",
        },
        "& td": {
          borderBottom: "1px solid transparent !important",
          "& .grand-btn":{
            width: "auto",
            borderRadius: "4px",
            textAlign: "center",
            padding: "14px 15px",
            fontSize: "10px",
            fontWeight: "800",
            height: "42px",
            backgroundColor: "#FEF1CB;"
          }
        }
      }
    },
    "&::-webkit-scrollbar": {
      height: "10px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#BABABA",
      borderRadius: "8px",
    },
  },
  overRideContainer: {
    overflowX: 'auto',
    width: '100%',
    "& table": {
      "& thead": {
        "& tr": {
          "& th": {
            "&:first-child": {
              position: 'sticky',
              background: '#F6EACC',
              left: 0,
              zIndex: 1,
              "&::after": {
                content: "''",
                position: 'absolute',
                top: 0,
                right: 0,
                height: '100%',
                width: '0.6px',
                background: '#706f6c',
                zIndex:1
              },
            },
            "&:last-child": {
              padding: "0",
              position: 'sticky',
              background: '#F6EACC',
              right: 0,
              zIndex: 1,
            },
            "& .sortFilContainer":{
              justifyContent: 'center',
              "& img": {
                marginRight: "8px",
                marginLeft: "8px",
                cursor: "pointer",
                width:'14px',
                height:'9px',
              },
            },
            padding: '16px 8px',
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            fontSize:'12px',
            fontWeight:'500',
            padding: '16px 8px',
            "&:first-child": {
              position: 'sticky',
              background: '#fff',
              fontSize:'12px',
              fontWeight:'600',
              left: 0,
              zIndex: 1,
              "&::after": {
                content: "''",
                position: 'absolute',
                top: 0,
                right: 0,
                height: '100%',
                width: '0.6px',
                background: '#706f6c',
                zIndex:1
              },
            },
            "&:last-child": {
              padding: "12px 0",
              position: 'sticky',
              background: '#fff',
              right: 0,
              zIndex: 1,
            }
          },
        },
      },
    },
    '&::-webkit-scrollbar': {
      height: '8px',
    },
  
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
      borderRadius: '5px',
    },
  
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: "#555",
    },
  },
  menuItemClass:{
    "& .MuiMenu-paper":{
      boxShadow:'0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 2px 10px 0px rgba(0,0,0,0.12) !important',
      "& ul":{
        "& li":{
          "&:hover":{
            backgroundColor: 'transparent',
            color:'black'
          },
          color:'#757575',
          fontSize:'12px',
          "& span":{
            borderBottom:'1px solid #E7E7E7',
            width:'90%',
            margin:'auto'
          },
          "&:last-child":{
            "& span":{
              border:'none'
            }
          }
        }
      }
    }
  },
  settingPopper: {
    top: "15px !important",
    "& .papper": {
        borderRadius: "4px",
        boxSizing: "border-box",
        border: "1px solid #E9EAED",
        padding: "6px 10px",
        "& .menuList": {
            padding: "0px",
            display: "flex",
            flexDirection: "column",
            "& li": {
                borderBottom: "1px solid #E7E7E7",
                "&:last-child": {
                    borderBottom: "none",
                },
                "& p": {
                    margin: "0",
                    fontSize: "12px",
                    lineHeight: "15px",
                    fontWeight: 500,
                    letterSpacing: "-0.14px",
                    color: "#757575",
                },
            },
        },
    },
},
};
export default tStyles;
