import React, { Component } from "react";
import moment from "moment";
import { customPDFData, getStatementStatusColor } from "../Utils/utils";
const pdfLogoIcon = require("../Assets/pdfLogoo.png");

interface pdfProps {
  pdfData: any;
  isShow: any;
  settlementDateValue:any;
}

interface DataItem {
  tile1: string;
  tile2: string;
  tile3: string;
  tile4: string;
  tile5: string;
  tile6: string;
  tile7: string;
  tile8: string;
  tile9: string;
  tile10: string;
  tile11: string;
}

const styles = {
  reset: {
    margin: 0,
    padding: 0,
    boxSizing: "border-box",
  },
  pdfContainer: {
    width: "830px",
    height: "auto",
    padding: "3px",
  },
  content: {
    display: "flex",
    gap: 6
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: 'center',
    height: "auto",
    width: "15%",
    padding: '7px'
  },
  image: {
    width: 100,
    height: 60,
    background: "transparent"
  },
  logoTitleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoTitle: {
    fontSize: 9,
  },
  itemsTitleContainer: {
    fontSize: 9,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 4,
    lineHeight: '2',
  },
  itemLabel: {
    fontWeight: "light",
    fontSize: 9,
    color: '#585c61'
  },
  itemsValue: {
    fontSize: 9,
  },
  itemsValue2: {
    fontSize: 9,
  },
  itemsWeb: {
    fontSize: 9,
    borderBottom: '1px solid black',
  },
  totalPendingButton: {
    color: "#2E2A40",
    fontSize: 9,
    height: 'auto',
    width: 'auto',
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "demibold",
    backgroundColor: "#FED86C",
    borderRadius: 2,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: 'center',
    height: "auto",
    width: "830px",
    backgroundColor: "#F6EACC",
    borderBottom: '1px solid #E7E7E7',
  },
  header: {
    textAlign: 'center',
    fontWeight: 400,
    color: "black",
    fontSize: 10,
    width: '70px',
    padding: "5px 7px",
  },
  header1: {
    textAlign: 'center',
    fontWeight: 400,
    color: "black",
    fontSize: 10,
    width: '90px',
    padding: "5px 7px",
  },
  row1: {
    display: "flex",
    flexDirection: "row",
    alignItem: "center",
    textAlign: 'center',
    height: "auto",
    width: "830px",
    borderBottom: '0.5px solid #E7E7E7'
  },
  cellnew: {
    width: '70px',
    fontSize: 9,
    textAlign: "center",
    color: "rgba(117, 117, 117, 1)",
    padding: "7px 0px",
  },
  cellnew2: {
    width: '90px',
    fontSize: 9,
    textAlign: "center",
    color: "rgba(117, 117, 117, 1)",
    padding: "7px 0px",
  }
};

class ClientStatementPDFTemplate extends Component<pdfProps> {
  constructor(props: pdfProps) {
    super(props);
  }

  getCurrentTime = () => {
    const currentTime = moment();
    const jamaicanTime = currentTime.tz("America/Jamaica");
    const formattedTime = jamaicanTime.format("HH:mm");
    const formattedDate = jamaicanTime.format("DD-MMM-YYYY");
    const formattedDateTime = `${formattedTime} (EST) ${formattedDate}`;
    return formattedDateTime;
  };

  generateTableData = (data: DataItem[]) => {
    return data.map((item, index) => (
      <tr key={`${index * 7}-i`} style={styles.row1 as React.CSSProperties}>
        <td style={{ ...(styles?.cellnew2 as React.CSSProperties) }} >{item?.tile1}</td>
        <td style={{ ...(styles?.cellnew as React.CSSProperties) }}>{item?.tile2}</td>
        <td style={{ ...(styles?.cellnew2 as React.CSSProperties) }}>{item?.tile3}</td>
        <td style={{ ...(styles?.cellnew as React.CSSProperties) }}>{item?.tile4}</td>
        {this.props.isShow.length > 0 && <td style={{ ...(styles?.cellnew as React.CSSProperties) }}>{item?.tile5}</td>}
        <td style={{ ...(styles?.cellnew2 as React.CSSProperties), color: "black" }}>{item?.tile6}</td>
        <td style={{ ...(styles?.cellnew as React.CSSProperties) }}>{item?.tile7}</td>
        <td style={{ ...(styles?.cellnew2 as React.CSSProperties) }}>{item?.tile8}</td>
        <td style={{ ...(styles?.cellnew as React.CSSProperties), color: "black" }}>{item?.tile9}</td>
        <td style={{ ...(styles?.cellnew2 as React.CSSProperties) }}>{item?.tile10}</td>
        <td
          style={{
            ...styles.cellnew2,
            color: getStatementStatusColor(item?.tile11),
            fontSize: 9,
            textAlign: "center",
          }}
        >
          {item?.tile11}
        </td>
      </tr>
    ));
  };

  render() {
    const {
      company_name,
      address,
      contact_name,
      total_payment_pending,
      is_paid,
    } = this.props.pdfData;
    const isDelinquentFeeAvailable = this.props.isShow;

    return (
      // @ts-ignore
      <div style={{ ...(styles?.pdfContainer as React.CSSProperties) }} >
        <div style={{ ...(styles?.content as React.CSSProperties) }}>
          <div style={styles.logoContainer as React.CSSProperties} >
            <img
              style={styles.image}
              src={pdfLogoIcon}
              alt="PDF Logo"
            />
            <div style={styles.logoTitleContainer as React.CSSProperties} >
              <span style={styles.logoTitle as React.CSSProperties}>Carbyne Capital</span>
              <span style={styles.logoTitle as React.CSSProperties}>Investments Limited</span>
            </div>
          </div>
          <div style={{ width: '30%', marginTop: '9px' }}>
            <div style={styles.itemsTitleContainer as React.CSSProperties}>
              <span style={styles.itemLabel as React.CSSProperties}>Email:</span>
              <span style={styles.itemsValue as React.CSSProperties}>info@ccija.com</span>
            </div>
            <div style={styles.itemsTitleContainer as React.CSSProperties}>
              <span style={styles.itemLabel as React.CSSProperties} >Phone:</span>
              <span style={styles.itemsValue as React.CSSProperties} >876-631-7661</span>
            </div>
            <div style={styles.itemsTitleContainer as React.CSSProperties}>
              <span style={styles.itemLabel as React.CSSProperties}>Website:</span>
              <span style={styles.itemsWeb as React.CSSProperties}>www.ccija.com</span>
            </div>
            <div style={styles.itemsTitleContainer as React.CSSProperties}>
              <span style={styles.itemLabel as React.CSSProperties}>Address:</span>
              <span style={styles.itemsValue as React.CSSProperties}>
                8 Lady Musgrave Rd Kingston, Jamaica
              </span>
            </div>
          </div>
          <div style={{ width: '35%', marginTop: '9px' }}>
            <div style={styles.itemsTitleContainer as React.CSSProperties}>
              <span style={styles.itemLabel as React.CSSProperties}>Contact Name:</span>
              <span style={styles.itemsValue as React.CSSProperties}>
                {contact_name ?? "-"}
              </span>
            </div>
            <div style={styles.itemsTitleContainer as React.CSSProperties}>
              <span style={styles.itemLabel as React.CSSProperties}>Company Name:</span>
              <span style={styles.itemsValue as React.CSSProperties}>
                {company_name ?? "-"}
              </span>
            </div>
            <div style={styles.itemsTitleContainer as React.CSSProperties}>
              <span style={styles.itemLabel as React.CSSProperties}>Address:</span>
              <span style={styles.itemsValue as React.CSSProperties}>
                {address ?? "-"}
              </span>
            </div>
          </div>
          <div style={{ width: '30%', marginTop: '9px' }}>
            <div style={{ ...styles.itemsTitleContainer as React.CSSProperties, marginBottom: "2px" , gap: 2, }}>
              <span style={styles.itemLabel as React.CSSProperties}>
                Total Payment Pending:
              </span>
              <div style={{ ...styles.totalPendingButton as React.CSSProperties }}  >
                <p
                  style={{ ...styles?.itemsValue2, fontWeight: "bold", color: is_paid ? "#20BB6E" : "black", margin: '0px', padding: '2px 6px' }}
                >
                  {is_paid ? "( " : ""}
                  {total_payment_pending ?? "0"}
                  {is_paid ? " )" : ""}
                </p>
              </div>
            </div>
            <div style={{ ...styles.itemsTitleContainer as React.CSSProperties }}>
              <span style={{ ...styles.itemLabel as React.CSSProperties, marginLeft: "15px" }}>As at:</span>
              <span style={{ ...styles?.itemsValue }}>
                {this.getCurrentTime()}
              </span>
            </div>

          </div>
        </div>
        <div style={{ width: "auto" }}>
          <table style={{ width: "100%" }}>
            <thead>
              <tr style={styles.row as React.CSSProperties}>
                <th style={{ ...styles?.header1 as React.CSSProperties }}>Disbursement Date</th>
                <th style={{ ...styles?.header as React.CSSProperties }}>Due Date</th>
                <th style={{ ...styles?.header1 as React.CSSProperties }}>Amount Disbursed</th>
                <th style={{ ...styles?.header as React.CSSProperties }}>Factor Fee</th>
                {isDelinquentFeeAvailable.length > 0 && <th style={{ ...styles?.header as React.CSSProperties }}>Delinquent Fee</th>}
                <th style={{ ...styles?.header1 as React.CSSProperties }}>Amount to be Settled to CCI</th>
                <th style={{ ...styles?.header as React.CSSProperties }}>Invoice/PO #</th>
                <th style={{ ...styles?.header1 as React.CSSProperties }}>Invoice Amount</th>
                <th style={{ ...styles?.header as React.CSSProperties }}>Vendor/Buyer</th>
                <th style={{ ...styles?.header1 as React.CSSProperties }}>Invoice/PO Date</th>
                <th style={{ ...styles?.header1 as React.CSSProperties }}>CCI Settlement Date</th>
              </tr>
            </thead>
            <tbody>{this.generateTableData(customPDFData(this?.props?.pdfData,this?.props?.settlementDateValue))}
            </tbody>
          </table>
        </div>
        <span
          data-test-id="test_id"
          style={{
            textAlign: "center",
            width: "100%",
            height: 20.25,
            marginTop: "10px",
            fontSize: 10.5,
          }}
        />
      </div>
    )
  }
}

export default ClientStatementPDFTemplate;




