import React, { Component } from "react";
import { Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";

interface CustomAvatarProps {
  initials: string;
  to: string;
  classNames?: any;
  height?: string;
  width?: string;
  classes?: any;
}

interface CustomAvatarState {}

const styles:any = {
  avatar: {
    background: "#E7BC45",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '25px',
    leadingTrim: 'both',
    textEdge: 'cap',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '-1.08px',
    color: '#2E2A40',
  },
  link: {
    textDecoration: 'none',
  }
};

class CustomAvatar extends Component<CustomAvatarProps, CustomAvatarState> {
  constructor(props: CustomAvatarProps) {
    super(props);
    this.state = {};
  }

  renderAvatarar = () => {
    const { initials, height, width, classes} = this.props;
    const isNavLink = !!this.props.to && typeof this.props.to === "string" && this.props.to.length > 0;
    return (
      <Avatar
        className={classes?.avatar}
        style={{
          height: height || "42px",
          width: width || "42px",
          cursor: isNavLink ? "pointer" : "default",
        }}
      >
        {initials}
      </Avatar>
    );
  };

  render() {
    const { to, classNames, classes} = this.props;
    const isNavLink = !!to && typeof to === "string" && to.length > 0;
    return (
      <div className={classNames || ""}>
        {isNavLink ? (
          <NavLink to={to} exact className={classes?.link}>
            {this.renderAvatarar()}
          </NavLink>
        ) : (
          this.renderAvatarar()
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CustomAvatar);
