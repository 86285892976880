export const styles : any = {
    fullscreen: {
        padding: '0 40px 40px',
        textAlign:'center',
      },
      GridContainer:{
        height: '100%',
        width: "100%",
      },
      formDiv:{
        fontFamily: 'Plus Jakarta Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '-0.28px',
        color: '#2E2A40',
      },
      userProfile:{

      },
      userForm:{

      },
      formBox:{
        
      },
      insideFormBox:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap:'10px',
      },
      lableAndFieldContainer:{
        width: '100%',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        gap:'5px',
        marginBottom:'10px'
      },
      profileLable:{
        fontSize: '14px !important',
        lineHeight: '21px !important',
        letterSpacing: '-0.28px !important',
        color: '#2E2A40 !important',
        fontWeight: 600,
      },
      profileTextField:{
        "& .MuiInputBase-root": {
        "& .MuiInputBase-input": {
            padding: "18px",
            fontSize: "12px",
            lineHeight: "15px",
            fontWeight: 500,
            color: "#757575",
        },
        },
        "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5b632",
        },
        },
        "& .MuiFormHelperText-root": {
        color: "#ea2f2f",
        margin: 0,
        marginTop: "5px",
        }, 
      },
      twoDivHolder:{
        display: 'flex',
        gap:'30px',
        width:'100%'
      },
      fourDivHolder:{
        display: 'flex',
        gap:'15px',
        width:'100%'
      },
      profileSelectField:{
        height:'50px',
        "& .MuiSelect-root": {
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 500,
        color: "#757575",
        },
        "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5b632",
        },
    },
      },
      profileButtonAlign:{
        width:'100%',
        marginTop:'20px'
      },
      profileSaveButton:{
        color: "#fff",
        backgroundColor: "#e5b632",
        margin: "23px 0 0",
        padding: "10.5px 16px",
        fontWeight: 600,
        textTransform: "capitalize",
        width: "300px",
        height:'60px',
        float: "right",
      },
      imageAndIconHolder:{
        position: 'relative',
      },
      editIconPosition:{
        position: 'absolute',
        bottom: '5%',
        left: '60%',
      },
      editIcon:{
        backgroundColor: '#fff',
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        padding: '5px',
        cursor: 'pointer',
      },
      userImage:{
        height:'100px',
        width:'100px',
        borderRadius: '60px',
      },
      profileHolder:{
        display: 'flex',
        flexDirection: 'column',
        gap:'10px'
      },
      selectHelperText: {
        color: "#ea2f2f",
        margin: 0,
        marginTop: "5px",
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: 1.66,
        letterSpacing: "0.03333em",
        "& .MuiSelect-root": {
          padding: "18px 14px",
          fontSize: "12px",
          lineHeight: "15px",
          fontWeight: 500,
          color: "#757575",
        },
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e5b632",
          },
        },
      },
}
  
  