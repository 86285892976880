import React from "react";

import {
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  InputLabel,
  Modal
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
const pdfLogoIcon = require("../../../components/src/Assets/Login_Logo.svg");

// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";
import LeftLoginSection from "../../../components/src/LeftLoginSection.web";
import { styles } from "./EmailLoginStyles.web";
import { Formik } from "formik";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});




// 
export class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {apiError} = this.state
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={12} sm={6} md={6} lg={6} className={classes.leftLoginSectionForgot}>
            {/*   */}
            <LeftLoginSection />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className={classes.loginRightSectionForgot}>
            <Box className={classes.mobileLogoHolder}> <img src={pdfLogoIcon}/> </Box>
            <Box sx={{ maxWidth: "425px", width: "100%", minWidth: '280px' }}>
              <Typography variant="h4" className={classes.titleForgot}>
                Forgot Password
              </Typography>
              <Typography variant="body1" className={classes.subTitleForgot}>
                We will send link to your email please re-set your password
              </Typography>
              <Formik
                enableReinitialize={true}
                validateOnBlur={true}
                initialValues={{ email: this.state.email }}
                validationSchema={this.ForgotPasswordSchema}
                //
                onSubmit={() => this.passwordForgot()}
              >
                {({ errors, values, handleSubmit, setFieldValue }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                       {/*   */}
                      <Box>
                        <InputLabel className={classes.inputLabelForgot}>Email</InputLabel>
                        <TextField
                          data-test-id="txtInputEmailReset"
                          type="email"
                          name='email'
                          variant="outlined"
                          placeholder={this.state.placeHolderEmail}
                          value={values.email}
                          onChange={(e) => {
                            setFieldValue("email", e.target.value);
                            this.setState({ email: e.target.value });
                          }}
                          fullWidth
                          className={classes.inputFieldForgot}
                          required
                          error={Boolean(errors.email) || !!apiError}
                          helperText={errors.email}
                        />
                      </Box>

                      {!!apiError && (
                        // 
                        <Box
                          data-test-id={"errorMessage"}
                          className={classes.errorMsgForgot}
                        >
                          {apiError}
                        </Box>
                      )}
                         {/*   */}
                      <Box sx={{ marginTop: '10px' }} >
                        <Button
                          data-test-id={"btnForgotPassword"}
                          variant="contained"
                          fullWidth
                          className={classes.loginBtnForgot}
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Box>
                    </form>
                  );
                }}

              </Formik>
            </Box>
          </Grid>
          <Modal
            classes={{ root: classes.MuiModalRootForgot }}
            open={this.state.isOpen}
            onClose={this.handleModelClose}
          >
            <div>
              <div className={classes.modalBoxForgot}>
                <Typography>
                A password change link has been sent to your email, Please check!
                </Typography>
                <Button
                  className={classes.doneButtonForgot}
                  onClick={this.handleModelClose}
                >
                  Done
                </Button>
              </div>
              <div className={classes.modalOverlayForgot} />
            </div>
          </Modal>
        </Grid>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
