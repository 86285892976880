import { MAIN_DIV_WIDTH } from "../../../components/src/Utils/constant";

export const styles: any = {
  mainDiv: {
    width: MAIN_DIV_WIDTH,
    padding: "0 50px 50px",
    height: "100%",
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    "& .pageTitle": {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "27px",
      letterSpacing: "-0.36px",
      color: "#2E2A40",
      margin: 0,
    },
  },
  downloadContainer: {
    "& img": {
      cursor: "pointer",
      marginLeft: "10px",
    },
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "flex-end",
    "& .clearActTableFilter": {
      display: "grid",
      marginRight: "2px",
      marginBottom: "2px",
      width: "auto !important",
      alignContent: "center",
      "& .MuiButton-label": {
        whiteSpace: "nowrap",
        letterSpacing: "0px",
        fontSize: "13px !important",
        padding: "2px 10px 0px !important",
      },
      height: "auto !important",
    },
    display: "flex",
  },
  headerClasses: {
    background: "#F6EACC",
  },
  '@media (max-width: 600px)': {
    mainDiv: {
      width: '100%',
      marginTop: "25px"
    }
  },
  '@media (min-width: 601px) and (max-width: 960px)': {

  },
  '@media (min-width: 961px)': {

  },
};
