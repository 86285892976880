// Customizable Area Start
import * as Yup from "yup";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  email: string;
  password: string;
  enablePasswordField: boolean;
  apiError: string;
}

export interface SS {
  id: any;
}

export default class EmailRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  signUpApiCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      apiError: "",
    };

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
  }

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  goBack = () => {
    window.history.back();
  };

  goToLogin = () => {
    this.props.navigation.navigate("EmailLogin")
  };

  SignUpSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(/\d/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .test(
        "special-characters",
        "Password requires a special character",
        (value) => {
          return /[^\w]/.test(value) || /_/.test(value);
        }
      )
  });

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.signUpApiCallId) {
          if(responseJson.data){
            if(!!responseJson?.meta?.token) {
              localStorage.setItem("auth_token", responseJson.meta.token);
              localStorage.setItem("account_role", responseJson.data.attributes.account_role);
              this.props.navigation.navigate("KycDetails1")
            } else {
              this.props.navigation.navigate("EmailLogin")
            }
            !!this.state.apiError && this.setState({apiError: ""})
          }else {
            const errorMessages = responseJson?.errors?.map((error:any) => Object.values(error)).flat().join(", ") || '';
            this.setState({apiError: errorMessages})
          }
        }
      }
    }
  }

  signUpAPI = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.signUpApiCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "signup"
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  };
}
// Customizable Area End
