import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FileUploadValidationSchema } from "../../../components/src/Utils/validations";
import { refinedClientData } from "../../../components/src/Utils/utils";
import { userRoles } from "../../../components/src/Utils/constant";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export type arrayUploadedFile = {
  id: number;
  name: string;
  url: string;
  size: string;
  date: string;
  time: string;
};

export type arrayOfFileToShow = {
  id: number;
  file: File;
};

export type token = string | null;
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  token: token;
  isOpen: boolean;
  uploadedFile: arrayUploadedFile[];
  FileDetails: arrayOfFileToShow[];
  snackbar: any;
  isAdmin: boolean;
  isFactorAgreementSelected: boolean;
  reasonForRequest: string;
  descriptionOfRequest: string;
  factorAgreementData: any;
  openRows: any[];
  rejectOpen: boolean;
  approveOpen: boolean;
  document: string;
  comment: string;
  requestId: number;
  isModalOpen: boolean;
  searchValue: { id: string | number; companyName: string };
  options: any[];
  isAutocompleteLoading: boolean;
  accountId: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminChangeRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changeRequestFormApi: string = "";
  getFactorAgreementApi: string = "";
  handleClientsearchAPI: string = "";
  debounceTimeout: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      snackbar: {
        open: false,
        message: "",
        severity: "",
      },
      token: "",
      isOpen: false,
      uploadedFile: [],
      FileDetails: [],
      isAdmin: false,
      isFactorAgreementSelected: false,
      reasonForRequest: "",
      descriptionOfRequest: "",
      factorAgreementData: {},
      openRows: [],
      rejectOpen: false,
      approveOpen: false,
      document: "",
      comment: "",
      requestId: 0,
      isModalOpen: false,
      searchValue: { id: 0, companyName: "" },
      options: [],
      isAutocompleteLoading: false,
      accountId: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else {
      const token = localStorage.getItem("auth_token");
      const role = localStorage.getItem("account_role");
      this.setState({ token: token });
      const Url = window?.location?.pathname || "";
      if (role !== userRoles.client || Url.includes("admin")) {
        this.setState({ isAdmin: true });
      } else {
        if (Url.includes("/factoring-agreement")) {
          this.setState({ isFactorAgreementSelected: true });
          this.getFactorAgreementApi = this.apiCall(
            token,
            "bx_block_documentation/factoring_agreements",
            "GET"
          );
          this.setState({ isLoading: true });
        }
      }
    }
  }

  componentWillUnmount(): any {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  }

  handleFileUpload = (Files: File[]) => {
    Files.forEach((fileToUpload: any) => {
      FileUploadValidationSchema.validate({ file: fileToUpload })
        .then(() => {
          const existingFileIds = this.state.FileDetails.map((file) => file.id);
          const availableIds = [1, 2, 3, 4, 5].filter(
            (id) => !existingFileIds.includes(id)
          );
          const setFileDetails: arrayOfFileToShow[] = [
            ...this.state.FileDetails,
            ...Files.map((file, index) => ({
              id: availableIds[index],
              file: file,
            })),
          ];

          if (setFileDetails.length > 5) {
            this.setState({
              snackbar: {
                open: true,
                message: "Maximum File Limit is 5",
                severity: "error",
              },
            });
            return;
          }

          this.setState({
            FileDetails: setFileDetails,
          });
        })
        .catch((error: any) => {
          this.setState({
            snackbar: {
              open: true,
              message: error.message,
              severity: "error",
            },
          });
        });
    });
  };

  handleFactoringAgreemrntCall = (response: any) => {
    const data = response;
    this.setState({
      factorAgreementData: data,
      isLoading: false,
    });
  };

  deleteFile = (id: number) => {
    const updatedFileDetails = this.state.FileDetails.filter(
      (file) => file.id !== id
    );
    this.setState({
      FileDetails: updatedFileDetails,
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: "",
        severity: "",
      },
    });
  };

  handleResonChange = (reason: any) => {
    this.setState({
      reasonForRequest: reason,
    });
  };

  handleDescription = (description: string) => {
    this.setState({
      descriptionOfRequest: description,
    });
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();
    this.setState({ searchValue: { id: 1, companyName: inputValue } });
    if (inputValue === "") {
      this.setState({ options: [] });
      return;
    }

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.handleClientsearchAPI = this.apiCall(
        this.state.token,
        `client_advance_search?search=${inputValue}`,
        "get"
      );
      this.setState({ isAutocompleteLoading: true });
    }, 300);
  };

  handleSelectSearch = (event: any, value: any) => {
    if (value?.id) {
      this.setState({ searchValue: value, requestId: value?.id });
    } else {
      this.setState({ searchValue: { id: "", companyName: "" } });
    }
  };

  handleClientSearchApiResponse = (response: any) => {
    this.setState({
      isAutocompleteLoading: false,
    });
    if (response.data?.length < 1) {
      this.setState({
        options: [],
      });
    } else {
      const refinedResponse = refinedClientData(response.data);
      this.setState({ options: refinedResponse });
    }
  };

  handleFormSubmit = () => {
    const { requestId, isAdmin } = this.state;
    this.setState({
      isLoading: true,
    });
    const fileArray: string[] = [];
    this.state.FileDetails.forEach((item) => {
      // @ts-ignore
      fileArray.push(item?.file);
    });
    // @ts-ignore
    const userId = JSON.parse(localStorage.getItem("user"));
    const userIdToSend = userId.id;
    const fileToSend = {
      description: this.state.descriptionOfRequest,
      id: isAdmin ? requestId : userIdToSend,
      reason: this.state.reasonForRequest,
      fileToUpload: fileArray,
    };
    this.changeRequestFormApi = this.apiCall(
      this.state.token,
      "bx_block_documentation/factoring_agreements",
      "POST",
      fileToSend
    );
  };

  handleChangeRequestResponse = () => {
    const { isAdmin } = this.state;
    this.setState({
      isModalOpen: true,
      isLoading: false,
      reasonForRequest: "",
      descriptionOfRequest: "",
      factorAgreementData: {},
      FileDetails: [],
      searchValue: { id: 0, companyName: "" },
      options: [],
    });
    if (isAdmin) {
      return;
    }
  };

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.changeRequestFormApi) {
      this.handleChangeRequestResponse();
    }
    if (apiRequestCallId === this.getFactorAgreementApi) {
      this.handleFactoringAgreemrntCall(responseJson);
    }
    if (apiRequestCallId === this.handleClientsearchAPI) {
      this.handleClientSearchApiResponse(responseJson);
    }
  };

  apiCall = (token: token, endPoint: string, method: string, body?: any) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const bodyHeader = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(bodyHeader)
      );

      const image = body.fileToUpload;

      const fd = new FormData();

      fd.append("account_id", body.id);
      fd.append("reason_for_request", body.reason);
      fd.append("description", body.description);
      for (const key of Object.keys(image)) {
        fd.append("documents[]", image[key]);
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        fd
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End
}
