export const dummyDeclareText =
  "The undersigned hereby authorizes Carbyne Capital Investments Limited (CCI), to obtain any information as it may require concerning this application including financial information and certifies that the above information is correct to the best of their knowledge, information and belief. The undersigned agrees to immediately update the Company of any change to the above information or material change to their financial position such that same may affect the transaction herein. The undersigned agrees to the company";

export const sortingOptions = [
  {
    id: 1,
    value: "asc",
    label: "Ascending",
  },
  { id: 2, value: "desc", label: "Descending" },
];

export const activityLogColumns = [
  {
    id: 1,
    label: "User",
    render: "user",
    sort: true,
    heighlight: true,
    width: "173px",
  },
  { id: 2, label: "Client", render: "client", width: "160px" },
  {
    id: 3,
    label: "Event",
    render: "event",
    filter: true,
    filterOptions: [
      {
        id: 11,
        value: ["admin"],
        label: "Admin and Analyst User Activity",
      },
      { id: 12, value: ["client"], label: "Client Activity" },
    ],
    width: "360px",
  },
  {
    id: 4,
    label: "Date",
    render: "created_at",
    sort: true,
    isGray: true,
    width: "150px",
  },
  { id: 5, label: "Time", render: "time", isGray: true, width: "100px" },
];

export const notificationColumns = [
  {
    id: 1,
    label: "Document Title",
    render: "document_title",
    filter: true,
    filterOptions: [
      {
        id: 1,
        value: "EKYC form + Mandatory Document",
        label: "EKYC form + Mandatory Document",
      },
      { id: 2, value: "Change Request form", label: "Change Request form" },
      { id: 3, value: "Invoice pending", label: "Invoice Pending" },
      {
        id: 4,
        value: "Purchase Order pending",
        label: "Purchase Order Pending",
      },
      {
        id: 5,
        value: "PO Disbursement Pending",
        label: "PO Disbursement Pending",
      },
      {
        id: 6,
        value: "Invoice Disbursement Pending",
        label: "Invoice Disbursement Pending",
      },
    ],
    width: "238px",
  },
  { id: 2, label: "Client", render: "client", sort: true, width: "203px" },
  {
    id: 3,
    label: "Submited Date",
    render: "created_at",
    sort: true,
    isGray: true,
    width: "158px",
  },
  { id: 4, label: "Time", render: "time", isGray: true, width: "100px" },
  {
    id: 5,
    label: "Status",
    render: "review_status",
    filter: true,
    isStatusBtn: true,
    filterOptions: [
      { id: 1, value: "review_now", label: "Review Now", bgColor: "#F27151" },
      { id: 2, value: "in_review", label: "In Review", bgColor: "#E5B632" },
      { id: 3, value: "closed", label: "Closed", bgColor: "#20BB6E" },
    ],
    width: "115px",
  },
];

export const evaluationColumns = [
  { id: 1, label: "Criteria", render: "criteria" },
  { id: 2, label: "Indicator", render: "indicator" },
  { id: 3, label: "Weighting (%)", render: "weighting", width: "165px" },
  {
    id: 4,
    label: "Ranking (1-10)",
    render: "ranking",
    isEdit: "true",
    width: "160px",
  },
  {
    id: 5,
    label: "Score",
    render: "score",
    width: "150px",
    tooltip:
      "The score is automatically calculated based on weightings and ranking.",
  },
];

export const removeTextAndSpecialChars = (value: any) => {
  if (typeof value === "string") {
    const regex = /[-+]?\d+(\.\d+)?/g;
    const res = value.match(regex);
    return res ? Number(res) : null;
  } else {
    return value;
  }
}

export const clientInvoicesColumns = [
  {
    id: 1,
    label: "Invoice/PO",
    render: "order_number",
  },
  {
    id: 2,
    label: "Status",
    render: "status",
    width: "180px",
  },
];

export const ClientReportColumns = [
  { id: 1, alignItem: 'left', alignTableData: 'left', label: "Client", render: "company_name", width: "160px", ifFixed: true },
  { id: 2, alignItem: 'left', alignTableData: 'left', label: "Sector", render: "sector_Name", width: "140px", },
  { id: 3, alignItem: 'left', alignTableData: 'center', label: "Factor Limit", render: "factor_limit", width: "120px", isToolTipEnabled: true, toolTipMessage: 'The maximum amount that can be disbursed.', isCurrencyTrue: true },
  { id: 4, alignItem: 'left', alignTableData: 'center', label: "Agreement Date", render: "agreement_date", width: "130px", fontColor: '#757575', isDataColorChanged: true, },
  { id: 5, alignItem: 'left', alignTableData: 'center', label: "Commission collected", render: "commission_collected", width: "170px", isToolTipEnabled: true, toolTipMessage: "Actual Factor Commission Received from Client.", isCurrencyTrue: true },
  { id: 6, alignItem: 'left', alignTableData: 'center', label: "Commission Accrued", render: "commission_accrued", width: "170px", isToolTipEnabled: true, toolTipMessage: "Factor Commission earned/accumulated but not yet collected by Carbyne.", isCurrencyTrue: true },
  { id: 7, alignItem: 'left', alignTableData: 'center', label: "Commission Due", render: "commission_due", width: "145px", isToolTipEnabled: true, toolTipMessage: "The commission amount due to the factor.", isCurrencyTrue: true },
  { id: 8, alignItem: 'left', alignTableData: 'center', label: "Commission Past Due", render: "commission_past_due", width: "170px", isToolTipEnabled: true, toolTipMessage: "The commission amount that is past the factor due date.", isCurrencyTrue: true },
  { id: 9, alignItem: 'left', alignTableData: 'center', label: "Total Commission Due", render: "total_commission_due", width: "190px", isToolTipEnabled: true, toolTipMessage: "The sum of Factor Commission Due and Factor Commission Past Due.", isCurrencyTrue: true },
  { id: 10, alignItem: 'left', alignTableData: 'center', label: "Delinquent Fee", render: "delinquent_fee", width: "140px", isCurrencyTrue: true },
  { id: 11, alignItem: 'left', alignTableData: 'center', label: "Total Amount Due", render: "total_amount_due", width: "140px", isCurrencyTrue: true },
  { id: 12, alignItem: 'left', alignTableData: 'center', label: "Amount Disbursed", render: "amount_disbursed", width: "145px", isToolTipEnabled: true, toolTipMessage: "The amount disbursed by the factor.", isCurrencyTrue: true },
  { id: 13, alignItem: 'left', alignTableData: 'center', label: "Factor Commission Rate", render: "factor_commission_rate", width: "175px", isToolTipEnabled: true, toolTipMessage: "Commission rate (%) charged on amounts disbursed.", isPrecentage: true },
  { id: 14, alignItem: 'center', alignTableData: 'center', label: "Amount Coming Due in 5 Days", render: "amount_coming_due_in_5_days", width: "175px", isCurrencyTrue: true },
  { id: 15, alignItem: 'center', alignTableData: 'center', label: "Date Onboarding Completed", render: "date_onboarding_completed", width: "160px", idDateFilterRequired: true },
  { id: 16, alignItem: 'left', alignTableData: 'left', label: "Country", render: "country_name", width: "90px", filter: true, filterOptions: [{ id: 1, value: "JAM", label: "JAM" }, { id: 2, value: "US", label: "US" }], },
];

export const invoicePOColumns = [
  {
    id: 1,
    label: "Client",
    render: "company_name",
    postFix: true,
    headerCellStyle: {
      position: "sticky",
      left: 0,
      zIndex: 1,
      backgroundColor: "#F6EACC",
    },
    width: '150px',
    bodyCellStyle: {
      position: "sticky",
      left: 0,
      zIndex: 1,
      backgroundColor: "white",
    },
  },
  {
    id: 2,
    label: "Sector",
    render: "sector",
    width: "140px"
  },
  {
    id: 3,
    label: "Disbursement Date",
    render: "disbursement_date",
    isGray: true,
    tooltip: "The date when the disbursement, or factor payment was made.",
    dateFilter: true,
    headerCellStyle: {
      textAlign: "center",
    },
    width: '200px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 4,
    label: "Factor Due Date",
    render: "factor_due_date",
    isGray: true,
    tooltip:
      "The date by which the factor payment is due, calculated by adding the Payment terms to the Disbursement date",
    dateFilter: true,
    headerCellStyle: {
      textAlign: "center",
    },
    width: '185px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 5,
    label: "# of days Past Due",
    render: "of_days_past_due",
    tooltip: "The number of days past the Factor Due Date, calculated as Today's date - Factor Due Date (showing '0' if not yet past due).",
    isGray: true,
    filter: true,
    filterOptions: [
      { id: 1, value: "0", label: "0" },
      { id: 2, value: "1-30", label: "1-30" },
      { id: 3, value: "31-60", label: "31-60" },
      { id: 4, value: "61-90", label: "61-90" },
      { id: 5, value: "91+", label: "91+" },
    ],
    headerCellStyle: {
      textAlign: "center",
    },
    width: '195px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 6,
    label: "Commission collected",
    render: "commission_collected",
    tooltip: "Actual Factor Commission Received from Client",
    headerCellStyle: {
      textAlign: "center",
    },
    width: '180px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 7,
    label: "Commission Due",
    render: "commission_due",
    tooltip: "The commission amount due to the factor.",
    headerCellStyle: {
      textAlign: "center",
    },
    width: '150px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 8,
    label: "Commission Past Due",
    render: "commission_past_due",
    tooltip: "The commission amount that is past the factor due date.",
    headerCellStyle: {
      textAlign: "center",
    },
    width: '175px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 9,
    label: "Total Commission Due",
    render: "total_commission_due",
    tooltip: "The sum of Factor Commission Due and Factor Commission Past Due.",
    headerCellStyle: {
      textAlign: "center",
    },
    width: '180px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 10,
    label: "Delinquent Fee",
    render: "delinquent_fee",
    headerCellStyle: {
      textAlign: "center",
    },
    width: '140px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 11,
    label: "Total Amount Due",
    render: "total_amount_due",
    headerCellStyle: {
      textAlign: "center",
    },
    width: '140px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 12,
    label: "Amount Disbursed",
    render: "original_amount_disbursed",
    tooltip: "The amount disbursed by the factor",
    headerCellStyle: {
      textAlign: "center",
    },
    width: '155px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 13,
    label: "Invoice/PO #",
    render: "invoice_po",
    headerCellStyle: {
      textAlign: "center",
    },
    width: '110px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 14,
    label: "Invoice/PO Amount",
    render: "invoice_po_amount",
    tooltip: "The total amount stated on the invoice or purchase order",
    headerCellStyle: {
      textAlign: "center",
    },
    width: '165px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 15,
    label: "Buyer",
    render: "buyer_name",
    headerCellStyle: {
      textAlign: "center",
    },
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 16,
    label: "Factor Commission Rate",
    render: "factor_commission_rate",
    tooltip: "Commission rate (%) charged on amounts disbursed",
    isRate: true,
    headerCellStyle: {
      textAlign: "center",
    },
    width: '190px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 17,
    label: "Coverage(%)",
    render: "coverage",
    tooltip:
      "The percentage of the invoice/PO amount covered by the disbursement (Disbursement amount / Invoice/PO amount).",
    isRate: true,
    headerCellStyle: {
      textAlign: "center",
    },
    width: '130px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 18,
    label: "Settlement Date",
    render: "settement_date",
    headerCellStyle: {
      textAlign: "center",
    },
    width: '130px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 19,
    label: "Payment Credit Terms",
    render: "payment_terms",
    tooltip: "The number of days for payment of an invoice or purchase order",
    headerCellStyle: {
      textAlign: "center",
    },
    width: '175px',
    bodyCellStyle: {
      textAlign: "center",
    },
  },
  {
    id: 20,
    label: "Country",
    render: "country",
    filter: true,
    filterOptions: [
      { id: 1, value: "Jamaica", label: "JAM" },
      { id: 2, value: "US", label: "US" },
    ],
    headerCellStyle: {
      textAlign: "center",
    },
    bodyCellStyle: {
      textAlign: "center",
    },
  },
];

export const globalRevenueColumns = [
  {
    id: 1,
    label: "Client",
    render: "company_name",
    width: "145px",
    headerCellStyle: {
      position: "sticky",
      left: 0,
      zIndex: 1,
      backgroundColor: "#F6EACC",
    },
    bodyCellStyle: {
      position: "sticky",
      left: 0,
      zIndex: 1,
      backgroundColor: "white",
      fontWeight: 700,
    },
    subBodyCellStyle: {
      position: "sticky",
      left: 0,
      zIndex: 1,
      backgroundColor: "white",
      fontWeight: 600
    }
  },
  {
    id: 2,
    label: "Sector",
    render: "sector",
    width: "180px",
    isCollapse: true,
    bodyCellStyle: {
      fontWeight: 700,
    },
    subBodyCellStyle: {
      fontWeight: 500
    }
  },
  {
    id: 3,
    label: "Commission Rate",
    render: "factor_commission_rate",
    tooltip: "Commission rate (%) charged on amounts disbursed",
    isRate: true,
    width: "152px",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 700,
    },
    subBodyCellStyle: {
      textAlign: "center",
      fontWeight: 500
    }
  },
  {
    id: 4,
    label: "Factor Commission Accrued",
    render: "commission_accrued",
    width: "200px",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 700,
    },
    subBodyCellStyle: {
      textAlign: "center",
      fontWeight: 400
    }
  },
  {
    id: 5,
    label: "Commission collected",
    render: "commission_collected",
    width: "166px",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 700,
    },
    subBodyCellStyle: {
      textAlign: "center",
      fontWeight: 400
    }
  },
  {
    id: 6,
    label: "Factor Limit",
    render: "factor_limit",
    width: "200px",
    tooltip: "The maximum amount that can be disbursed",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 700,
    },
    subBodyCellStyle: {
      textAlign: "center",
      fontWeight: 400
    }
  },
  {
    id: 7,
    label: "Factor Amount Disbursed",
    render: "amount_disbursed",
    width: "200px",
    tooltip: "The amount disbursed by the factor",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 700,
    },
    subBodyCellStyle: {
      textAlign: "center",
      fontWeight: 400
    }
  },
  {
    id: 8,
    label: "Factor Amount Available",
    render: "factor_amount_available",
    width: "180px",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 700,
    },
    subBodyCellStyle: {
      textAlign: "center",
      fontWeight: 400
    }
  },
  {
    id: 9,
    label: "Country",
    render: "country_name",
    width: "130px",
    filter: true,
    filterOptions: [
      { id: 1, value: "jamaica", label: "JAM" },
      { id: 2, value: "US", label: "US" },
    ],
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 700,
    },
    subBodyCellStyle: {
      color: "black",
      textAlign: "center",
      fontWeight: 700
    }
  },
];

export const VelocityTableColumn = [
  { id: 1, alignItem: 'left', alignTableData: 'left', label: "Sector", render: "sector", width: "250px" },
  { id: 2, alignItem: 'center', alignTableData: 'center', label: "Total disbursed ", render: "total_disbrused", width: "310px", },
  { id: 3, alignItem: 'center', alignTableData: 'center', label: "Total disbursed (Comparison Period )", render: "total_disbrused_comparison_period", width: "310px" },
  { id: 4, alignItem: 'center', alignTableData: 'center', label: "Percentage (%) Change", render: "percentage_change", width: "210px", sort: true },
];
export const pastDueColumns = [
  {
    id: 1,
    label: "Client",
    width: '160px',
    render: "company_name",
    headerCellStyle: {
      position: "sticky",
      left: 0,
      zIndex: 1,
      backgroundColor: "#F6EACC",
    },
    bodyCellStyle: {
      position: "sticky",
      left: 0,
      zIndex: 1,
      backgroundColor: "white",
      fontWeight: 600,
    },
  },
  {
    id: 2,
    label: "Sector",
    render: "sector",
    headerCellStyle: {
      paddingLeft: "60px"
    },
    bodyCellStyle: {
      fontWeight: 500,
      paddingLeft: "60px"
    },
    width: '180px',
  },
  {
    id: 3,
    label: "Factor Commission",
    render: "factor_commission",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    },
    width: '180px',
  },
  {
    id: 4,
    label: "Disbursed Factor Amount",
    render: "disbursed_factor_amount",
    tooltip: "The amount disbursed by the factor.",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    },
    width: '210px',
  },
  {
    id: 5,
    label: "Current Principal",
    render: "principal_current",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    },
  },
  {
    id: 6,
    label: "Current Commission",
    render: "commission_current",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    },
  },
  {
    id: 7,
    label: "1-30 Principal",
    render: "principal_1_30",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    },
  },
  {
    id: 8,
    label: "1-30 Commission",
    render: "commission_1_30",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    },
  },
  {
    id: 9,
    label: "31-60 Principal",
    render: "principal_31_60",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    }
  },
  {
    id: 10,
    label: "31-60 Commission",
    render: "commission_31_60",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    },
    width: '180px',
  },
  {
    id: 11,
    label: "61-90 Principal",
    render: "principal_61_90",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    },
  },
  {
    id: 12,
    label: "61-90 Commission",
    render: "commission_61_90",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    },
    width: '150px',
  },
  {
    id: 13,
    label: "> 91 Principal",
    render: "principal_more_90",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    },
    width: '120px',
  },
  {
    id: 14,
    label: "> 91 Commission",
    render: "commission_more_90",
    headerCellStyle: {
      textAlign: "center",
      justifyContent: "center"
    },
    bodyCellStyle: {
      textAlign: "center",
      fontWeight: 500,
    },
    width: '150px',
  },
]


export const ClientStatementHeader = [
  { id: 1, alignItem: 'left', alignTableData: 'left', label: "Disbursement Date", render: "disbursement_date", width: "180px", idDateFilterRequired: true, isDataColorChanged: true, },
  { id: 2, alignItem: 'center', alignTableData: 'center', label: "Due Date", render: "due_date", width: "170px", idDateFilterRequired: true, isDataColorChanged: true, },
  { id: 3, alignItem: 'center', alignTableData: 'center', label: "Amount Disbursed", render: "amount_disbursed", width: "170px" },
  { id: 4, alignItem: 'center', alignTableData: 'center', label: "Factor Fee", render: "factor_fee", width: "170px" },
  { id: 5, alignItem: 'center', alignTableData: 'center', label: "Delinquent Fee", render: "delinquent_fee", width: "170px" },
  { id: 6, alignItem: 'center', alignTableData: 'center', label: "Amount to be Settled to CCI", render: "Amount_to_be_Settled_to_CCI", width: "230px" },
  { id: 7, alignItem: 'center', alignTableData: 'center', label: "Invoice/PO #", render: "invoice_PO", width: "150px" },
  { id: 8, alignItem: 'center', alignTableData: 'center', label: "Invoice Amount", render: "invoice_amount", width: "180px", isBold: true },
  { id: 9, alignItem: 'center', alignTableData: 'center', label: "Vendor/Buyer", render: "vendor_Buyer", width: "180px" },
  { id: 10, alignItem: 'center', alignTableData: 'center', label: "Invoice/PO Date", render: "invoice_PO_Date", width: "180px", isDataColorChanged: true, },
  {
    id: 11, alignItem: 'center', alignTableData: 'center', label: "CCI Settlement Date", render: "CCI_settlement_date", width: "180px", filter: true, filterOptions: [
      { id: 1, value: "PAID", label: "PAID" },
      { id: 2, value: "OVERDUE", label: "OVERDUE" },
      { id: 3, value: "PENDING", label: "PENDING" },
    ],
  },
];

export const ClientStatementHeaderCondition = [
  { id: 1, alignItem: 'left', alignTableData: 'left', label: "Disbursement Date", render: "disbursement_date", width: "180px", idDateFilterRequired: true, isDataColorChanged: true, },
  { id: 2, alignItem: 'center', alignTableData: 'center', label: "Due Date", render: "due_date", width: "170px", idDateFilterRequired: true, isDataColorChanged: true, },
  { id: 3, alignItem: 'center', alignTableData: 'center', label: "Amount Disbursed", render: "amount_disbursed", width: "170px" },
  { id: 4, alignItem: 'center', alignTableData: 'center', label: "Factor Fee", render: "factor_fee", width: "170px" },
  { id: 5, alignItem: 'center', alignTableData: 'center', label: "Amount to be Settled to CCI", render: "Amount_to_be_Settled_to_CCI", width: "230px" },
  { id: 6, alignItem: 'center', alignTableData: 'center', label: "Invoice/PO #", render: "invoice_PO", width: "150px" },
  { id: 7, alignItem: 'center', alignTableData: 'center', label: "Invoice Amount", render: "invoice_amount", width: "180px", isBold: true },
  { id: 8, alignItem: 'center', alignTableData: 'center', label: "Vendor/Buyer", render: "vendor_Buyer", width: "180px" },
  { id: 9, alignItem: 'center', alignTableData: 'center', label: "Invoice/PO Date", render: "invoice_PO_Date", width: "180px", isDataColorChanged: true, },
  {
    id: 10, alignItem: 'center', alignTableData: 'center', label: "CCI Settlement Date", render: "CCI_settlement_date", width: "180px", filter: true, filterOptions: [
      { id: 1, value: "PAID", label: "PAID" },
      { id: 2, value: "OVERDUE", label: "OVERDUE" },
      { id: 3, value: "PENDING", label: "PENDING" },
    ],
  },
];

export const coloursArray = [
  "#EDC001",
  "#C39103",
  "#DAA521",
  "#D2B45A",
  "#DEC98A",
  "#BF9C3B",
  "#D5B70B",
  "#DECA57",
  "#978942",
  "#FFDF86",
  "#E2B610",
  "#E3C070",
  "#D4A622",
  "#CFAE17",
  "#E1BD7C",
  "#D4AE46",
  "#C1A655",
  "#E6D080",
  "#E1C03B",
  "#D6C83A",
  "#E5BB19",
  "#CFBB00",
  "#FFFF99",
  "#FFD700",
  "#FFDB58",
  "#F0E68C",
  "#FFEE33",
  "#FFD300",
  "#FFC300",
  "#FFB300"
];