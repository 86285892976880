// Customizable Area Start
import React from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  InputLabel,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Formik } from "formik";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import EmailRegistrationController, {
  Props,
} from "./EmailRegistrationController.web";
import LeftLoginSection from "../../../components/src/LeftLoginSection.web";
import { styles } from "./EmailRegistrationStyles.web";
const pdfLogoIcon = require("../../../components/src/Assets/Login_Logo.svg");

class EmailRegistration extends EmailRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { email, password, enablePasswordField, apiError } = this.state;
    return (
      // Required for all blocks
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={12} sm={6} md={6} lg={6} className={classes.leftLoginSectionSignup}>
            <LeftLoginSection />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className={classes.loginRightSection}>
            <Box className={classes.mobileLogoHolder}> <img src={pdfLogoIcon}/> </Box>
            <Box sx={{ maxWidth: "425px", width: "100%" }}>
              <KeyboardBackspaceIcon
                className={classes.backArrow}
                onClick={() => this.goBack()}
              />
              <Typography variant="h4" className={classes.title}>
                Create Account
              </Typography>
              <Typography variant="body1" className={classes.subTitle}>
                Let's create your account
              </Typography>

              <Formik
                enableReinitialize={true}
                validateOnBlur={true}
                initialValues={{ email: email, password: password }}
                validationSchema={this.SignUpSchema}
                onSubmit={() => this.signUpAPI()}
              >
                {({ errors, values, handleSubmit, setFieldValue }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box>
                        <InputLabel className={classes.inputLabel}>
                          Email
                        </InputLabel>
                        <TextField
                          data-test-id="txtInputEmail"
                          type="email"
                          variant="outlined"
                          placeholder={"Enter your Email"}
                          value={values.email}
                          onChange={(e) => {
                            setFieldValue("email", e.target.value);
                            this.setState({ email: e.target.value });
                          }}
                          fullWidth
                          className={classes.inputField}
                          error={Boolean(errors.email) || !!apiError}
                          helperText={errors.email}
                        />
                      </Box>
                      <Box>
                        <InputLabel className={classes.inputLabel}>
                          Password
                        </InputLabel>
                        <TextField
                          data-test-id="txtInputPassword"
                          type={enablePasswordField ? "password" : "text"}
                          variant="outlined"
                          placeholder={"Enter your password"}
                          value={values.password}
                          onChange={(e) => {
                            setFieldValue("password", e.target.value);
                            this.setState({ password: e.target.value });
                          }}
                          fullWidth
                          className={classes.inputField}
                          error={Boolean(errors.password) || !!apiError}
                          helperText={errors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  edge="end"
                                >
                                  {enablePasswordField ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      {!!apiError && (
                        <Box
                          data-test-id={"errorMessage"}
                          className={classes.errorMsg}
                        >
                          {apiError}
                        </Box>
                      )}
                      <Box>
                        <Button
                          data-test-id={"btnEmailRegistration"}
                          fullWidth
                          className={classes.loginBtn}
                          variant="contained"
                          type="submit"
                        >
                          Sign Up
                        </Button>
                      </Box>
                      <Box
                        data-test-id={"btnForgotPassword"}
                        className={classes.endText}
                      >
                        Have an account?{" "}
                        <span className="redirect" onClick={() => this.goToLogin()}>Sign In</span>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </Box>
          </Grid>
        </Grid>
     
    );
  }
}
export { EmailRegistration };
export default withStyles(styles)(EmailRegistration);
// Customizable Area End
