// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import { Message } from "../../../framework/src/Message";
import { FormikErrors, FormikTouched } from "formik";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export type DocumentsArray = { [x: string]: any }[];

export type token = string | null;

export interface Fields {
  buyer_name: string | number;
  purchase_order_amount: string | number;
  disbursement_date: string | null;
  amount_disbursed: string | number;
  purchase_order_no: string | number;
  payment_terms: number | null;
  purchase_order_date: string;
}

interface S {
  token: token;
  rejectOpen3: boolean;
  activeStep3: number;
  approveOpen3: boolean;
  factor_limit4: number;
  comment3: string;
  requestId3: number;
  account_id3: number | null;
  isBalancetable3: boolean;
  attributes3: any;
  isLoading3: boolean;
  purchaseDocuments: DocumentsArray;
  companyName3: string;
  createdAtDate3: string;
  approveResTitle3: string;
  fields: Fields;
}

interface SS {
  id: any;
}

export default class EKYCNotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPurchaseDetailsAPI: string = "";
  purchaseVerificationAPI: string = "";
  updateDisbursementPOAPI: string = "";
  updatePurchaseDocuments: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      token: "",
      rejectOpen3: false,
      approveOpen3: false,
      factor_limit4: 0,
      isBalancetable3: false,
      comment3: "",
      requestId3: 0,
      account_id3: null,
      isLoading3: false,
      purchaseDocuments: [],
      companyName3: "",
      createdAtDate3: "",
      approveResTitle3: "",
      activeStep3: 0,
      attributes3: {},
      fields: {
        buyer_name: "",
        purchase_order_amount: "",
        purchase_order_no: "",
        disbursement_date: "",
        amount_disbursed: 0,
        payment_terms: 0,
        purchase_order_date: "",
      },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentWillMount(): any {
    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else {
      const path: any[] = window.location.pathname.split("/");
      const token = localStorage.getItem("auth_token");
      const id = path[path.length - 1];
      this.setState({
        requestId3: Number(id),
        isLoading3: true,
        token: token,
      });
      this.getPurchaseDetailsAPI = this.apiCallPurchase(
        token,
        `bx_block_invoicebilling/purchase_orders/${id}`,
        "GET"
      );
    }
  }

  showNoteOnCompDate = (disbursement_date: string, payment_terms: number) => {
    if (disbursement_date) {
      let disbursementDate = new Date(disbursement_date);
      disbursementDate.setDate(disbursementDate.getDate() + payment_terms);
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate()-1);
      return currentDate >= disbursementDate; 
    }
    return false;
  }

  handlepurchaseDocumentsResponse = (response: any) => {
    const companyName3 = response.data.attributes.company_name;
    const createdDate = response.data.attributes.created_at;
    const {
      documents,
      buyer_name,
      purchase_order_amount,
      purchase_order_no,
      disbursement_date,
      original_amount_disbursed,
      status,
      payment_terms,
      factor_limit,
      purchase_order_date,
      account_id,
    } = response.data.attributes;
    const path: any[] = window.location.pathname.split("/");
    if (path.includes("client-reports") && status === "disbursed") {
      this.setState({ isBalancetable3: true })
    }
    const obj = {
      purchase_order_amount: purchase_order_amount,
      disbursement_date: disbursement_date,
      amount_disbursed: Number(original_amount_disbursed),
      purchase_order_no: purchase_order_no,
      buyer_name: buyer_name,
      payment_terms: payment_terms,
      purchase_order_date: purchase_order_date,
    };
    this.setState({
      purchaseDocuments: documents,
      factor_limit4: factor_limit ?? 0,
      fields: obj,
      isLoading3: false,
      attributes3: response.data.attributes,
      account_id3: account_id,
      companyName3: companyName3,
      createdAtDate3: createdDate,
    });
    if (status === "approve") {
      this.setState({
        activeStep3: 2,
      });
    } else if (status === "disbursed") {
      this.setState({
        activeStep3: 3,
      });
    }
  };

  handleModalClosePurchase = () => {
    this.setState({ rejectOpen3: false, approveOpen3: false });
  };

  handlePurchaseHelperText2 = (text: any) => {
    if (text === undefined) {
      const error = "Error : Disbursed ammount exceded to factor limit"
      return error
    }
    return `Error : ${text}`
  };

  handlePOErrors = (error: any, touch: any) => {
    return touch && Boolean(error);
  };

  handlePOHelperText = (text: any, touch: any) => {
    return touch && text;
  };

  handlePurchaseReview = (id: any) => {
    const { token, requestId3 } = this.state;
    this.updatePurchaseDocuments = this.apiCallPurchase(
      token, `update_attachment_status?attachment_id=${id}&status=reviewed&purchase_order_id=${requestId3}`, "PUT"
    );
    this.setState({ isLoading3: true });
  }

  handlePurchaseDocumentStatusUpdate = () => {
    const { token, requestId3 } = this.state;
    this.getPurchaseDetailsAPI = this.apiCallPurchase(
      token, `bx_block_invoicebilling/purchase_orders/${requestId3}`, "GET"
    );
  }
  getPaymentTermsHelperText = (
    values: { payment_terms: number | null; },
    errors: FormikErrors<{ payment_terms: number | null; }>,
    touched: FormikTouched<{ payment_terms: number | null; }>,
    handleInvoiceHelperText: { (text: string | undefined, touch: boolean | undefined): string | undefined; (arg0: string | undefined, arg1: boolean | undefined): string | undefined; }
  ) => {
    if (values.payment_terms && values.payment_terms < 30) {
      return "Payment terms cannot be less than 30 days";
    }
    return handleInvoiceHelperText(errors.payment_terms, touched.payment_terms);
  };
  handleRejectInputChangePurchase = (e: any) => {
    const { value, name } = e.target;
    const newState: any = {
      [name + `3`]: value,
    };
    this.setState(newState);
  };

  updateDisbursementPO = (values: any) => {
    const { token } = this.state;
    this.updateDisbursementPOAPI = this.apiCallPurchase(
      token,
      "update_purchase_order_desbursed",
      "PUT",
      JSON.stringify(values)
    );
  };

  rejectRequestPurchase = () => {
    const { token, requestId3, comment3, account_id3 } = this.state;
    const data = {
      account_id: account_id3,
      id: requestId3,
      status: "rejected",
      comment: comment3,
    };

    this.purchaseVerificationAPI = this.apiCallPurchase(
      token,
      "approve_reject_purchase_order",
      "POST",
      JSON.stringify(data)
    );
    this.setState({ rejectOpen3: false, isLoading3: true });
  };

  handleApproveClickPurchase = () => {
    const { token, requestId3, account_id3 } = this.state;
    const data = {
      account_id: account_id3,
      id: requestId3,
      status: "approve",
    };
    this.purchaseVerificationAPI = this.apiCallPurchase(
      token,
      "approve_reject_purchase_order",
      "POST",
      JSON.stringify(data)
    );
    this.setState({
      isLoading3: true,
      approveOpen3: false,
    });
  };

  handleRejectClickPurchase = () => {
    this.setState({ rejectOpen3: true });
  };

  hadleResPurchaseVerificationAPI = (response: any) => {
    this.setState({
      approveOpen3: true,
      isLoading3: false,
      approveResTitle3: response.account,
    });
    let com4 = this.state.isBalancetable3 ? "ActiveTabs" : "AdminNotifications"
    this.props.navigation.navigate(com4);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getPurchaseDetailsAPI) {
      this.handlepurchaseDocumentsResponse(responseJson);
    }
    if (apiRequestCallId === this.purchaseVerificationAPI || apiRequestCallId === this.updateDisbursementPOAPI) {
      this.hadleResPurchaseVerificationAPI(responseJson);
    }
    if (apiRequestCallId === this.updatePurchaseDocuments) {
      this.handlePurchaseDocumentStatusUpdate();
    }
  };

  apiCallPurchase = (
    token: token,
    endPoint: string,
    method: string,
    body?: any
  ) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const bodyHeader = {
      token: token,
      "Content-Type": "application/json",
    };

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(bodyHeader)
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };
}
// Customizable Area End
