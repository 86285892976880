export enum EKYCpath {
  page1 = "/EKYC1",
  page2 = "/EKYC2",
  page3 = "/EKYC3",
  page4 = "/EKYC4",
}

export const ekycPaths = ['/EKYC1','/EKYC2','/EKYC3','/EKYC4']

export const clientMenus = [
  { id: 1, label: "Dashboard", link: "/" },
  { id: 2, label: "Factoring Agreement", link: "/factoring-agreement" },
  { id: 3, label: "Change Request", link: "/change-request-form" },
  { id: 4, label: "Submit Invoice/PO", link: "/submit-invoice" },
  { id: 5, label: "Statements", link: "/statements" },
  { id: 6, label: "Carbyne News", link: "/news" },
];

const clientCheckerFunction = (lable:string) => {
  if (lable === "Submit Invoice/PO" ){
    return true
  }
  return false
}

export function clientUrlCondition(data:any) {
  return clientCheckerFunction(data.label) ? window.location.pathname : data.link
}

export const analystMenus = [
  { id: 1, label: "Dashboard", link: "/admin" },
  { id: 2, label: "Notifications", link: "/admin/notifications" },
  {
    id: 3,
    label: "Client account reports",
    link: "/admin/client-reports",
  },
  { id: 4, label: "Company reports", link: "/admin/company-reports" },
  { id: 5, label: "Submit Invoice/PO", link: "/admin/submit-invoice" },
  { id: 6, label: "Change request form", link: "/admin/change-request-form" },
  { id: 7, label: "Activity log", link: "/admin/activity-log" },
];

export const adminMenus = [
  { id: 1, label: "Dashboard", link: "/admin" },
  { id: 2, label: "Notifications", link: "/admin/notifications" },
  {
    id: 3,
    label: "Client account reports",
    link: "/admin/client-reports",
  },
  { id: 4, label: "Company reports", link: "/admin/company-reports" },
  { id: 5, label: "Submit Invoice/PO", link: "/admin/submit-invoice" },
  { id: 6, label: "Change request form", link: "/admin/change-request-form" },
  { id: 7, label: "Activity log", link: "/admin/activity-log" },
];

const checkerFunction = (data:any) => {
  const currentPath = window.location.pathname;

  switch (data.label) {
    case "Client account reports":
      if (currentPath === "/admin/client-reports/clients") {
        return "/admin/client-reports/clients";
      } else {
        return data.link;
      }
    case "Company reports":
      if (currentPath === "/admin/company-reports/velocity") {
        return "/admin/company-reports/velocity";
      } else if (currentPath === "/admin/company-reports/past-due") {
        return "/admin/company-reports/past-due";
      } else {
        return data.link;
      }
    case "Submit Invoice/PO":
      if (currentPath === "/admin/purchase-order") {
        return "/admin/purchase-order";
      } else {
        return data.link;
      }
    default:
      return data.link;
  }
}

export function urlCondition(data:any) {
  return checkerFunction(data)
}

export enum userRoles {
  client = "client",
  admin = "admin",
  analyst = "analyst",
}

export const reasonsOfRequest = [
  {
    id: 1,
    name: "New agreement submission",
    value: "new_agreement_submission",
  },
  { id: 2, name: "Increase factor limit", value: "increase_factor_limit" },
  { id: 3, name: "Payment term / Tenure", value: "payment_term_tenure" },
  { id: 4, name: "Factor Commission rate", value: "factor_commission_rate" },
  {
    id: 5,
    name: "Update to Mandatory Document",
    value: "update_to_mandatory_document",
  },
  { id: 6, name: "Others", value: "others" },
];

export const keyNameArray = [
  {id: 1 ,name: "articles_of_incorporations", value: "Articles of Incorporation"},
  {id: 2 ,name: "certificates_of_incorporatrions", value: "Certificate of Incorporation"},
  {id: 3 ,name: "certificates_of_registrations", value : "Certificate of Registration"},
  {id: 4 ,name: "auditated_financial_statements", value : "Audited Financial Statements"},
  {id: 5 ,name: "management_accounts", value : "Management Accounts"},
  {id: 6 ,name: "invoices", value : "Invoice/Contract/Purchase Order"},
  {id: 7 ,name: "bank_statements", value : "Bank Statements"},
  {id: 8 ,name: "proof_of_payments", value: "Proof of Payment"},
  {id: 9 ,name: "driving_licences", value: "Driver's License"},
  {id: 10 ,name: "tax_registration_numbers", value: "Tax Registration Number/ Tax Identification Number"},
]

export const SubmitInvoiceFields = [
  {
    lable:"Buyer Name*:",
    placeHolder:'e.g. ABC Inc.',
    name:'buyer_name',
    id:1,
    type:'text'
  },
  {
    lable:"Payment Terms (In Days)*:",
    placeHolder:'e.g. 30',
    name:'payment_terms',
    id:2,
    type:'number'
  },
  {
    lable:"Invoice Number*:",
    placeHolder:'e.g. 123456',
    name:'invoice_number',
    id:3,
    type:'text'
  },
  {
    lable:"Invoice Date*:",
    placeHolder:'e.g. 23/06/2020',
    name:'invoice_date',
    type:'date',
    id:4
  },
  {
    lable:"Invoice Amount*:",
    placeHolder:'e.g. J$ 3,000',
    name:'invoice_amount',
    id:5,
    type:'text',
    prefix: "J$",
    isTooltipRequired:true,
    toolTipText: "Amount needs to be in Jamaican Dollars. Please convert the amount using the current market rate."
  },
]

export const PurchaseOrderFields = [
  {
    lable:"Buyer Name*:",
    placeHolder:'e.g. ABC Inc.',
    name:'buyer_name',
    id:1,
    type:'text'
  },
  {
    lable:"Payment Terms (In Days)*:",
    placeHolder:'e.g. 30',
    name:'payment_terms',
    id:2,
    type:'number'
  },
  {
    lable:"Purchase Order Number*:",
    placeHolder:'e.g. 123456',
    name:'purchase_order_no',
    id:3,
    type:'text'
  },
  {
    lable:"Purchase Order Date*:",
    placeHolder:'e.g. 23/06/2020',
    name:'purchase_order_date',
    type:'date',
    id:4
  },
  {
    lable:"Purchase Order Amount*: ",
    placeHolder:'e.g. J$ 3,000',
    name:'purchase_order_amount',
    id:5,
    type:'text',
    prefix: "J$",
    isTooltipRequired:true,
    toolTipText: "Amount needs to be in Jamaican Dollars. Please convert the amount using the current market rate."
  },
]

export const review_now_arr = [
  {
    title: ["EKYC form + Mandatory Document"],
    component: "AdminEKYCreview",
    renderId: "account_id",
  },
  {
    title: ["Change Request form"],
    component: "AdminChangeRequestReview",
    renderId: "factoring_agreement_id",
  },
  {
    title: ["Invoices", "Invoice pending", "Invoice Disbursement Pending"],
    component: "AdminInvoiceReview",
    renderId: "invoice_detail_id",
  },
  {
    title: ["Purchase order", "Purchase Order pending", "PO Disbursement Pending"],
    component: "AdminPurchaseOrderReview",
    renderId: "purchase_order_id",
  },
];

export const in_review_arr = [
  {
    title: "Change Request form",
    component: "AdminChangeRequestReview",
    renderId: "factoring_agreement_id",
  },
]

export const invoicePO_steps = ["Pending","Approved","Disbursed"]


export const clients = [
  {
    id: 1,
    clientName:"asdwasd1",
    kycFormId: 101,
    valueChainEvaluationId: 201,
    factorAgreementId: 301,
    paymentsId: 401,
    invoicesId: 501,
    delinquent: false
  },
  {
    id: 2,
    clientName:"asdwasd1",
    kycFormId: 102,
    valueChainEvaluationId: 202,
    factorAgreementId: 302,
    paymentsId: 402,
    invoicesId: 502,
    delinquent: true
  },
  {
    id: 3,
    clientName:"asdwasd3",
    kycFormId: 103,
    valueChainEvaluationId: 203,
    factorAgreementId: 303,
    paymentsId: 403,
    invoicesId: 503,
    delinquent: false
  },
  {
    id: 4,
    clientName:"asdwasd4",
    kycFormId: 104,
    valueChainEvaluationId: 204,
    factorAgreementId: 304,
    paymentsId: 404,
    invoicesId: 504,
    delinquent: false
  },
  {
    id: 5,
    clientName:"asdwasd5",
    kycFormId: 105,
    valueChainEvaluationId: 205,
    factorAgreementId: 305,
    paymentsId: 405,
    invoicesId: 505,
    delinquent: false
  },
  {
    id: 6,
    clientName:"asdwasd55",
    kycFormId: 106,
    valueChainEvaluationId: 206,
    factorAgreementId: 306,
    paymentsId: 406,
    invoicesId: 506,
    delinquent: false
  },
  {
    id: 7,
    clientName:"asdwasd77",
    kycFormId: 107,
    valueChainEvaluationId: 207,
    factorAgreementId: 307,
    paymentsId: 407,
    invoicesId: 507,
    delinquent: false
  },
  {
    id: 8,
    clientName:"asdwasd88",
    kycFormId: 108,
    valueChainEvaluationId: 208,
    factorAgreementId: 308,
    paymentsId: 408,
    invoicesId: 508,
    delinquent: false
  },
  {
    id: 9,
    clientName:"asdwasd474",
    kycFormId: 109,
    valueChainEvaluationId: 209,
    factorAgreementId: 309,
    paymentsId: 409,
    invoicesId: 509,
    delinquent: false
  },
  {
    id: 10,
    clientName:"asdwasd74",
    kycFormId: 110,
    valueChainEvaluationId: 210,
    factorAgreementId: 310,
    paymentsId: 410,
    invoicesId: 510,
    delinquent: false
  },
  {
    id: 11,
    clientName:"asdw78asd",
    kycFormId: 111,
    valueChainEvaluationId: 211,
    factorAgreementId: 311,
    paymentsId: 411,
    invoicesId: 511,
    delinquent: false
  },
  {
    id: 12,
    clientName:"asdwa7547sd",
    kycFormId: 112,
    valueChainEvaluationId: 212,
    factorAgreementId: 312,
    paymentsId: 412,
    invoicesId: 512,
    delinquent: false
  },
  {
    id: 13,
    clientName:"a/9678sdwasd",
    kycFormId: 113,
    valueChainEvaluationId: 213,
    factorAgreementId: 313,
    paymentsId: 413,
    invoicesId: 513,
    delinquent: false
  },
  {
    id: 20,
    clientName:"asdwas*-*53d",
    kycFormId: 120,
    valueChainEvaluationId: 220,
    factorAgreementId: 320,
    paymentsId: 420,
    invoicesId: 520,
    delinquent: false
  },
  {
    id: 21,
    clientName:"asdw453459++asd",
    kycFormId: 121,
    valueChainEvaluationId: 221,
    factorAgreementId: 321,
    paymentsId: 421,
    invoicesId: 521,
    delinquent: true
  },
  {
    id: 22,
    clientName:"asdw--++4564asd",
    kycFormId: 122,
    valueChainEvaluationId: 222,
    factorAgreementId: 322,
    paymentsId: 422,
    invoicesId: 522,
    delinquent: false
  },
];

export const menuItems = [
  { label: 'KYC Form', value: 'kycForm' },
  { label: 'Value Chain Evaluation', value: 'valueChain' },
  { label: 'Factor Agreement', value: 'factorAgreement' },
  { label: 'Payments', value: 'payments' },
  // { label: 'Invoices', value: 'invoices' },
];

export const timelineChartOptions = [
  { id:1, name: 'Revenue/Commission', key:"revenue_commission" },
  { id:2, name: 'Disbursed Amount', key:"disbursed_amount" },
  { id:3, name: 'Number of onboarded clients', key:"number_of_onboarded_clients" },
  { id:4, name: 'Clients not yet onboarded', key:"clients_not_yet_onboarded" },
];

export const menuSelectProps = {
  transformOrigin: {
    horizontal: "left",
    vertical: "top",
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    horizontal: "left",
    vertical: "bottom",
  },
}

export const maxAmountChar = 9999999999999999;
export const maxEmployeeChar = 999999999;

export const PUBLISHED_NEWS_STATUS = "published";

export const MAIN_DIV_WIDTH = "calc(100vw - 285px)";

export const closedNotifyMessage = "Cannot perform action. Notification already closed"

export enum KYC_Status {
  inProcess = 'in_process',
  rejected = 'rejected',
  approve = 'approve',
  pending = 'pending',
}
