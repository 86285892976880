export const styles: any = {
    navigationTabsBox: {
        display: 'flex',
        height: 'auto',
    },
    activeButton: {
        border: '1px solid #e5b632',
        backgroundColor: '#fff',
        width: '100%',
        minWidth: "220px",
        color: '#e5b632',
        fontSize: '16px',
        fontWeight: 500,
        borderRadius: '8px',
        cursor: 'initial',
        pointerEvents: 'none',
        textTransform:'capitalize',
        fontFamily:'Plus Jakarta Sans !important'
    },
    inActiveButton: {
        width: '100%',
        fontSize: '16px',
        minWidth: "220px",
        fontWeight: 500,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        textTransform:'capitalize',
        fontFamily:'Plus Jakarta Sans !important'
    },
    '@media (max-width: 600px)': {
      responsiveProvider1:{
        width:'100%'
      }
    },
}