// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  formatDate2,
  getGlobalReportEndpoint,
  getGlobalReportCSVEndpoint
} from "../../../components/src/Utils/utils";
import {
  parseDataIntoOptions,
  parseSectorIntoOptions,
  shouldUpdateGlobalRevenueTable,
} from "../../../components/src/Utils/parseData";
import moment from "moment";

export const configJSON = require("./config");

export type globalToken = string | null;

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface GlobalOption {
  name: string;
  id: number | string | null;
}

interface S {
  globalToken: string | null;
  isGlobalClientsLoad: boolean;
  country: string;
  client: GlobalOption;
  totalGlobalCount: number;
  page: number;
  sector: GlobalOption;
  globalTableData: any;
  isGlobalLoadTable: boolean;
  csvGlobalURL: string;
  globalClientOptions: any[];
  per_page: number;
  globalSectorOptions: any[];
  anchorGlobalEl: null | HTMLElement;
  isDateRangeOpen: boolean;
  date_from: string;
  date_to: string;
}

interface SS {
  id: any;
}

export default class GlobalRevenueReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  getGlobalTableDataApi: string = "";
  getGlobalSectorApi: string = "";
  getGlobalClientsApi: string = "";
  getGlobalURLApi: string = "";
  globalDebounce: any = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      globalToken: "",
      isGlobalLoadTable: false,
      page: 1,
      globalTableData: { sectors: [], grand_total_data: {} },
      totalGlobalCount: 0,
      globalClientOptions: [],
      csvGlobalURL: "",
      globalSectorOptions: [],
      client: { id: "", name: "" },
      sector: { id: "", name: "" },
      country: "",
      per_page: 10,
      isGlobalClientsLoad: false,
      anchorGlobalEl: null,
      isDateRangeOpen: false,
      date_from: moment().startOf('year').format('MMM DD, YYYY'),
      date_to: moment().format('MMM DD, YYYY'),
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentWillUnmount(): any {
    if (this.globalDebounce) {
      clearTimeout(this.globalDebounce);
    }
  }

  async componentWillMount() {
    const globalToken = localStorage.getItem("auth_token");
    if (globalToken) {
      this.setState({ globalToken: globalToken, isGlobalLoadTable: true });
      const globalEndPoint = getGlobalReportEndpoint(this.state);
      this.getGlobalTableDataApi = apiCall(globalToken, globalEndPoint, "GET");
      this.getGlobalSectorApi = apiCall(globalToken, `industries`, "GET");
      this.getGlobalURLApi = apiCall(
        globalToken,
        "global_revenue_report_excel",
        "GET"
      );
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const isUpdate = shouldUpdateGlobalRevenueTable(prevState, this.state);
    if (isUpdate) {
      this.setState({ isGlobalLoadTable: true });
      const globalEndPoint = getGlobalReportCSVEndpoint(this.state);
      const globalEndPoint1 = getGlobalReportEndpoint(this.state);
      this.getGlobalTableDataApi = apiCall(
        this.state.globalToken,
        globalEndPoint1,
        "GET"
      );
      this.getGlobalURLApi = apiCall(
        this.state.globalToken,
        globalEndPoint,
        "GET"
      );
    }
    if (prevState.client.name !== this.state.client.name) {
      if (this.globalDebounce) {
        clearTimeout(this.globalDebounce);
      }
      this.globalDebounce = setTimeout(() => {
        this.getGlobalSearchOptions(this.state.client.name);
      }, 400);
    }
  }

  clearGlobalFilters = () => {
    this.setState({
      page: 1,
      client: { id: "", name: "" },
      sector: { id: "", name: "" },
      date_from: moment().startOf('year').format('MMM DD, YYYY'),
      country: "",
      date_to: moment().format('MMM DD, YYYY'),
      per_page: 10,
      isGlobalLoadTable: true,
    }, () => {
      const globalClearEndPoint = getGlobalReportEndpoint(this.state);
      this.getGlobalTableDataApi = apiCall(this.state.globalToken, globalClearEndPoint, "GET");
    })
  }

  handleDateRangeFilter = (startDate: string, endDate: string) => {
    this.setState({
      date_from: formatDate2(startDate),
      date_to: formatDate2(endDate),
    });
    this.handleDateRangeClose();
  };

  handleDateRangeOpen = (e: any) => {
    this.setState({
      anchorGlobalEl: e?.currentTarget,
      isDateRangeOpen: true,
    });
  };

  handleDateRangeClose = () => {
    this.setState({
      isDateRangeOpen: false,
      anchorGlobalEl: null,
    });
  };

  handleGlobalClientInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const globalInputValue = e.target.value;
    if (globalInputValue === "") {
      this.setState({ globalClientOptions: [] });
    }
    this.setState({ client: { id: 0, name: globalInputValue } });
  };

  handleGlobalSelectClient = (event: any, globalClient: any) => {
    if (globalClient.id) {
      this.setState(
        {
          page: 1,
          client: globalClient,
          isGlobalLoadTable: true,
          country: "",
          per_page: 10,
        },
        () => {
          const globalEndPoint2 = getGlobalReportEndpoint(this.state);
          this.getGlobalTableDataApi = apiCall(
            this.state.globalToken,
            globalEndPoint2,
            "GET"
          );
        }
      );
    } else {
      this.setState({ client: { id: 0, name: "" } });
    }
  };

  handleGlobalChangeReportPage = (e: any, globalPage: any) => {
    window.scrollTo(0, 0);
    this.setState({
      page: globalPage,
    });
  };

  handleGlobalSector = (e: any) => {
    const globalOption = this.state.globalSectorOptions.filter(
      (opt) => opt.id == e.target.value
    );
    if (globalOption.length > 0) {
      this.setState({ sector: globalOption[0] });
    }
  };

  handleGlobalReportFilter = (globalCol: any, globalOpt: any) => {
    if (globalCol === "country_name") {
      if (this.state.country === globalOpt) {
        this.setState({
          country: "",
        });
      } else {
        this.setState({
          country: globalOpt==="US" ? "us" : globalOpt,
        });
      }
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.getResponseApis(apiRequestCallId, responseJson);
      } else {
        this.setState({ isGlobalLoadTable: false, isGlobalClientsLoad: false });
      }
    }
  }

  getResponseApis = (id: any, resp: any) => {
    if (id === this.getGlobalTableDataApi) {
      this.getTableDataResponse(resp);
    }
    if (id === this.getGlobalClientsApi) {
      this.getGlobalClientOptionsRes(resp);
    }
    if (id === this.getGlobalURLApi) {
      resp?.url && this.setState({ csvGlobalURL: resp.url });
    }
    if (id === this.getGlobalSectorApi) {
      this.getGlobalSectorOptionsRes(resp);
    }
  };

  getGlobalClientOptionsRes = (resp: any) => {
    if (resp.data) {
      const parsedOpt = parseDataIntoOptions(resp.data);
      this.setState({ globalClientOptions: parsedOpt });
    } else {
      this.setState({ globalClientOptions: [] });
    }
    this.setState({ isGlobalClientsLoad: false });
  };

  getGlobalSectorOptionsRes = (response: any) => {
    if (response.data) {
      const parsedData = parseSectorIntoOptions(response.data);
      this.setState({
        globalSectorOptions: parsedData,
      });
    } else {
      this.setState({ globalSectorOptions: [] });
    }
  };

  getTableDataResponse = (resp: any) => {
    if (resp?.sectors || resp?.total_count) {
      const globalCeil = Math.ceil(resp.total_count / this.state.per_page || 1);
      this.setState({
        globalTableData: resp,
        isGlobalLoadTable: false,
        totalGlobalCount: globalCeil,
      });
    } else {
      this.setState({
        globalTableData: { sectors: [], grand_total_data: {} },
        totalGlobalCount: 0,
        isGlobalLoadTable: false,
      });
    }
  };

  getGlobalSearchOptions = (globalValue: any) => {
    const globalheader = {
      token: this.state.globalToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGlobalClientsApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(globalheader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    this.setState({ isGlobalClientsLoad: true });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `client_advance_search?search=${globalValue}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
