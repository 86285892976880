import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";


export interface Props {
  classes: any;
  navigation: any;
}

type token = string | null

interface S {
    isInvoiceSelected: boolean;
    isClientReportsSelected: boolean;
}

interface SS {
  id: any;
}

export default class ActiveTabsController extends BlockComponent<Props, S, SS> {
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
        isInvoiceSelected: false,
        isClientReportsSelected: false,
    };
  }

  async componentDidMount() {
    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else{
      const urlPath = window.location.pathname
      if(urlPath.includes("/admin/client-reports/clients")) {
        this.setState({ isClientReportsSelected: true });
      } else {
        this.setState({ isInvoiceSelected: true });
      }
    }
  }

  handleInvoiceSelection = () => {
    this.props.navigation.navigate("ActiveTabs")
  }

  handleClientReportsSelection = () => {
    this.props.navigation.navigate("ActiveTabsClients")
  }

}
