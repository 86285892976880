import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
  inputRef?: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  prefix?: string;
}

class NumberFormatCustom extends Component<NumberFormatCustomProps> {
  render() {
    const { inputRef, onChange, name, prefix, ...other } = this.props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        prefix={prefix || "J$ "}
      />
    );
  }
}

export default NumberFormatCustom;