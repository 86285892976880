// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { Formik } from "formik";

import PurchaseOrderReviewController, {
  Props,
} from "./PurchaseOrderReviewController.web";
import { styles } from "./PurchaseOrderReviewStyles.web";
import InvoiceBalanceTable from "../../../components/src/Table/InvoiceBalanceTable.web";
import {
  parseInvoiceBalanceData1,
  parseInvoiceBalanceData2,
} from "../../../components/src/Utils/parseData";
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";
import ReviewRejectModalWeb from "../../../components/src/Modal/ReviewRejectModal.web";
import { convertIntoCurrency, onKeyDown, downloadFiles } from "../../../components/src/Utils/utils";
import ReviewApproveModalWeb from "../../../components/src/Modal/ReviewApproveModal.web";
import { invoicePO_steps } from "../../../components/src/Utils/constant";
import CustomizedStepperWeb from "../../../components/src/Stepper/CustomizedStepper.web";
import CommonButtonWeb from "../../../components/src/Button/CommonButton.web";
import DateTextfieldWeb from "../../../components/src/Datepicker/DateTextfield.web";
import { purchaseReviewSchema } from "../../../components/src/Utils/validations";
import NumberFormatCustom from "../../../components/src/CustomTextField/NumberFormatCustom"
const fileIcon = require("../../../components/src/Assets/file.png");
const downloadIcon = require("./assets");

class PurchaseOrderReview extends PurchaseOrderReviewController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {
      rejectOpen3,
      activeStep3,
      approveOpen3,
      attributes3,
      comment3,
      purchaseDocuments,
      companyName3,
      factor_limit4,
      isBalancetable3,
      approveResTitle3,
      createdAtDate3,
      fields,
      isLoading3,
      requestId3,
    } = this.state;
    const {
      buyer_name,
      purchase_order_no,
      purchase_order_amount,
      payment_terms,
      purchase_order_date,
      disbursement_date,
      amount_disbursed,
    } = fields;

    const renderPOButtons = (docs: any) => {
      const disabled4 = docs.every((items: any) => items.status === "reviewed");
      if (activeStep3 === 0) {
        return (
          <div className="btnContainerLeft3">
            <Button
              className={disabled4 ? "leftbtn3" : "leftbtn3 disabled3"}
              data-test-id={`handleApproveClick-3`}
              onClick={() => this.setState({ rejectOpen3: true })}
              disabled={!disabled4}
            >
              Reject
            </Button>
            <Button
              className={
                disabled4 ? "leftbtn3 approveBtn3" : "leftbtn3 disabled3"
              }
              data-test-id={`handleApproveClick-2`}
              onClick={() => this.setState({ approveOpen3: true })}
              disabled={!disabled4}
            >
              Approve
            </Button>
          </div>
        );
      }
    };

    const renderPODisbursement = (
      handleChange: any,
      errors: any,
      values: any,
      touched: any,
      handleBlur: any
    ) => {
      const isDisableBtn =
        isBalancetable3 &&
        values.payment_terms === initialPurchaseValues.payment_terms;
      const factorLimit = isBalancetable3 ? factor_limit4 : factor_limit4 - values.amount_disbursed
      return (
        activeStep3 >= 1 && (
          <React.Fragment>
            <Typography variant="h3" className={classes.headingTitle3}>
              Disbursement :
            </Typography>
            <Grid container spacing={3} direction={"column"}>
              <Grid item sm={12} md={12}>
                <InputLabel className={classes.inputLabel3}>
                  Amount disbursed *:
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="amount_disbursed"
                  value={isBalancetable3 ? convertIntoCurrency(values.amount_disbursed) : values.amount_disbursed}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    inputProps: {
                      min: 0,
                      style: {
                        MozAppearance: "textfield",
                      },
                    },
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  disabled={isBalancetable3}
                  className={classes.inputField3}
                  error={this.handlePOErrors(
                    errors.amount_disbursed,
                    touched.amount_disbursed
                  )}
                />
                <FormHelperText className={classes.helperInvoiceText3}>{errors.amount_disbursed ? this.handlePurchaseHelperText2(errors.amount_disbursed) : `Note: J$ ${convertIntoCurrency(factorLimit)} remaining until Factoring Limit reached`}</FormHelperText>
              </Grid>
              <Grid container sm={12} lg={12} md={12} className={classes.disbursementDateProvider1}>
                <InputLabel className={classes.inputLabel3}>
                  Disbursement Date*:
                </InputLabel>
                <DateTextfieldWeb
                 data-test-id="buttonTest"
                  value={values.disbursement_date}
                  min={new Date(purchase_order_date)}
                  width="100%"
                  name="disbursement_date"
                  handleChange={handleChange}
                  error={this.handlePOErrors(
                    errors.disbursement_date,
                    touched.disbursement_date
                  )}
                  helperText={this.handlePOHelperText(
                    errors.disbursement_date,
                    touched.disbursement_date
                  )}
                  disabled={isBalancetable3}
                />

                {!isDisableBtn && this.showNoteOnCompDate(values.disbursement_date, values.payment_terms) && (<FormHelperText data-test-id={'FormHelperText'} className={classes.helperInvoiceText3}>Note: Invoice/PO is past due. Risk of incorrect past due calculation.</FormHelperText>)}
              </Grid>
            </Grid>
            <div className="btnContainerLeft3">
              <CommonButtonWeb
                btnText="Save"
                type="submit"
                disabled={isDisableBtn}
              />
            </div>
          </React.Fragment>
        )
      );
    };

    const initialPurchaseValues = {
      amount_disbursed: amount_disbursed,
      purchase_order_id: requestId3,
      payment_terms: payment_terms,
      disbursement_date: disbursement_date,
    };

    const poSchema = purchaseReviewSchema(this.state);
    return (
      <div className={classes.purchaseDiv}>
        {isLoading3 && <CustomLoader open={isLoading3} />}
        <Typography className="maintitle3">Purchase Order</Typography>
        {!isBalancetable3 && (
          <CustomizedStepperWeb
            steps={invoicePO_steps}
            activeStep={activeStep3}
          />
        )}
        <Box className="subTitleContainer3">
          <Typography variant="h3" className="title3">
            {companyName3}
          </Typography>
          <Typography variant="subtitle1" className="date3">
            {createdAtDate3}
          </Typography>
        </Box>
        <Formik
          enableReinitialize={true}
          validateOnBlur={true}
          initialValues={initialPurchaseValues}
          validationSchema={poSchema}
          onSubmit={(values) => this.updateDisbursementPO(values)}
        >
          {({
            errors,
            values,
            handleSubmit,
            handleChange,
            touched,
            handleBlur,
          }) => {
            return (
              <form onSubmit={handleSubmit} onKeyDown={onKeyDown} className={classes.formWidthProvider1}>
                <Grid
                  container
                  spacing={3}
                  direction={"row"}
                  style={{ width: "auto" }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel className={classes.inputLabel3}>
                      Buyer Name*:
                    </InputLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      name="buyer_name"
                      value={buyer_name}
                      className={classes.inputField3}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel className={classes.inputLabel3}>
                      Payment Terms (In Days)*:
                    </InputLabel>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      name="payment_terms"
                      value={values.payment_terms}
                      disabled={activeStep3 === 0}
                      error={this.handlePOErrors(
                        errors.payment_terms,
                        touched.payment_terms
                      )}
                      inputProps={{
                        min: 0,
                        style: {
                          MozAppearance: "textfield",
                        },
                      }}
                      onChange={handleChange}
                      helperText={this.getPaymentTermsHelperText(values, errors, touched, this.handlePOHelperText)}
                      className={classes.inputField3}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputLabel className={classes.inputLabel3}>
                      Purchase Order Number*:
                    </InputLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      name="purchase_order_no"
                      value={purchase_order_no}
                      fullWidth
                      className={classes.inputField3}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputLabel className={classes.inputLabel3}>
                      Purchase Order Date*:
                    </InputLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      name="purchase_order_date"
                      value={purchase_order_date}
                      className={classes.inputField3}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputLabel className={classes.inputLabel3}>
                      Purchase Order Amount*:
                    </InputLabel>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      name="purchase_order_amount"
                      value={convertIntoCurrency(purchase_order_amount)}
                      className={classes.inputField3}
                      InputProps={{
                        startAdornment: purchase_order_amount ? "J$" : "",
                      }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.bottomDiv3}>
                  <Grid item md={6} lg={6} xs={12} sm={12}>
                    <div className={classes.collapseContainer3}>
                      <Typography variant="h3" className="collapseTitle3">
                        Find the attached file below:
                      </Typography>
                      {purchaseDocuments.map((item, index) => {
                        const isReviewed1 = item.status === "reviewed";
                        return (
                          <div className="collapse3" key={item.id}>
                            <Typography variant="body1" gutterBottom>
                              <img src={fileIcon} /> {item.name}
                            </Typography>
                            <div className="dwnContainer3">
                              <img
                                src={downloadIcon.Download}
                                style={{ cursor: "pointer" }}
                                // data-test-id={`downloadFiles-${index}-2`}
                                onClick={() => { downloadFiles(item.url); !isReviewed1 && this.handlePurchaseReview(item.id) }}
                                alt="Download"
                              />
                              <Button
                                data-test-id={`reviewedBtns1-${index}-2`}
                                className={`btn ${isReviewed1 ? "reviewedBtn1" : ""
                                  }`}
                              >
                                {item.status}
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {renderPODisbursement(
                      handleChange,
                      errors,
                      values,
                      touched,
                      handleBlur
                    )}
                    {renderPOButtons(purchaseDocuments)}
                  </Grid>
                  {isBalancetable3 && (
                    <Grid item md={6} lg={6} xs={12} sm={12}>
                      <Box className={classes.rightBtmDiv3}>
                        <div className={classes.rightBtmHeader3}>
                          <h4 className="rightBtmTitle">
                            Remaining Balance Due on PO
                          </h4>
                          <CommonButtonWeb
                            btnText={`J$ ${convertIntoCurrency(attributes3.remaining_balance_due_on_invoice)}`}
                            color="#2E2A40"
                            backgroundColor="#FED86C"
                            width="auto"
                            classNames="rightBtmBtn"
                          />
                        </div>
                        <InvoiceBalanceTable
                          data={parseInvoiceBalanceData1(attributes3)}
                        />
                        <InvoiceBalanceTable
                          data={parseInvoiceBalanceData2(attributes3)}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </form>
            );
          }}
        </Formik>
        <ReviewApproveModalWeb
          open={approveOpen3}
          title={approveResTitle3}
          handleSubmitModal={this.handleApproveClickPurchase}
          handleModelClose={this.handleModalClosePurchase}
          widthToAdd="85%"
        />
        <ReviewRejectModalWeb
          open={rejectOpen3}
          comment={comment3}
          handleChange={this.handleRejectInputChangePurchase}
          handleSubmitModal={this.rejectRequestPurchase}
          handleModelClose={this.handleModalClosePurchase}
          widthToAdd="85%"
        />
      </div>
    );
  }
}

export default withStyles(styles)(PurchaseOrderReview);
// Customizable Area End
