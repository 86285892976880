// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import { Formik } from "formik";
import UserProfileController, { Props } from "./UserProfileController.web";
import { styles } from "./UserProfileStyles.web";
import EditIcon from "@material-ui/icons/Edit";

export class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    const { defaultImage } = this.state;

    return (
      <Box className={classes.fullscreen}>
            <div className={classes.formDiv}>
              <Grid className={classes.GridContainer} container spacing={0}>
                <Formik
                  enableReinitialize={true}
                  validateOnBlur={true}
                  initialValues={{
                    displayName: this.state.displayName,
                    email: this.state.email,
                    companyName: this.state.companyName,
                    address: this.state.address,
                    phoneNumber: this.state.phoneNumber,
                    primaryAccountHolderName: this.state
                      .primaryAccountHolderName,
                    primaryAccountHolderEmail: this.state
                      .primaryAccountHolderEmail,
                    secondaryAccountHolderName: this.state
                      .secondaryAccountHolderName,
                    secondaryAccountHolderEmail: this.state
                      .secondaryAccountHolderEmail,
                    industry_ids: this.state.industry_ids,
                    uniqueId: this.state.uniqueId
                    // image:this.state.image,
                  }}
                  validationSchema={this.validationSchema}
                  onSubmit={() => this.handleSave()}
                >
                  {({ errors, values, handleSubmit }) => {
                    return (
                      <>
                        <Grid item className={classes.userProfile} xs={2}>
                          <div className={classes.profileHolder}>
                            <Typography className={classes.profileLable}>
                              {" "}
                              Photo Profile{" "}
                            </Typography>
                            <div className={classes.imageAndIconHolder}>
                              <img
                                className={classes.userImage}
                                src={defaultImage}
                                alt="USER"
                              />
                              <label
                                className={classes.editIconPosition}
                                htmlFor="image-upload"
                              >
                                <EditIcon className={classes.editIcon} />
                              </label>
                              <input
                                id="image-upload"
                                type="file"
                                accept="image/*"
                                onChange={this.handleImageUpload}
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item className={classes.userForm} xs={10}>
                          <form onSubmit={handleSubmit}>
                            <Box className={classes.formBox}>
                              <div className={classes.insideFormBox}>
                                <div className={classes.lableAndFieldContainer}>
                                  <Typography className={classes.profileLable}>
                                    Display Name:
                                  </Typography>{" "}
                                  <TextField
                                    id="outlined-basic"
                                    placeholder="E.g. John Smith"
                                    variant="outlined"
                                    className={classes.profileTextField}
                                    onChange={e =>
                                      this.setDisplayName(e.target.value)
                                    }
                                    fullWidth
                                    error={Boolean(errors.displayName)}
                                    helperText={errors.displayName}
                                  />
                                </div>
                                <div className={classes.lableAndFieldContainer}>
                                  <Typography className={classes.profileLable}>
                                    Email:
                                  </Typography>{" "}
                                  <TextField
                                    id="outlined-basic"
                                    placeholder="E.g. johnsmith@gmail.com"
                                    variant="outlined"
                                    className={classes.profileTextField}
                                    onChange={e =>
                                      this.setEmail(e.target.value)
                                    }
                                    fullWidth
                                    error={Boolean(errors.email)}
                                    helperText={errors.email}
                                  />
                                </div>
                                <div className={classes.lableAndFieldContainer}>
                                  <Typography className={classes.profileLable}>
                                    Company Name:
                                  </Typography>{" "}
                                  <TextField
                                    id="outlined-basic"
                                    placeholder="E.g. Tech Solutions LLC"
                                    variant="outlined"
                                    className={classes.profileTextField}
                                    onChange={e =>
                                      this.setCompanyName(e.target.value)
                                    }
                                    fullWidth
                                    error={Boolean(errors.companyName)}
                                    helperText={errors.companyName}
                                  />
                                </div>
                                <div className={classes.twoDivHolder}>
                                  <div
                                    className={classes.lableAndFieldContainer}
                                  >
                                    <Typography
                                      className={classes.profileLable}
                                    >
                                      Address
                                    </Typography>{" "}
                                    <TextField
                                      id="outlined-basic"
                                      placeholder="E.g.123 Main Street, Suite 100, Anytown, USA"
                                      variant="outlined"
                                      className={classes.profileTextField}
                                      onChange={e =>
                                        this.setAddress(e.target.value)
                                      }
                                      fullWidth
                                      error={Boolean(errors.address)}
                                      helperText={errors.address}
                                    />
                                  </div>
                                  <div
                                    className={classes.lableAndFieldContainer}
                                  >
                                    <Typography
                                      className={classes.profileLable}
                                    >
                                      Phone no.:
                                    </Typography>{" "}
                                    <TextField
                                      id="outlined-basic"
                                      placeholder="E.g. +1 (555) 123-4567"
                                      variant="outlined"
                                      className={classes.profileTextField}
                                      onChange={e =>
                                        this.setPhoneNumber(e.target.value)
                                      }
                                      fullWidth
                                      error={Boolean(errors.phoneNumber)}
                                      helperText={errors.phoneNumber}
                                    />
                                  </div>
                                </div>
                                <div className={classes.fourDivHolder}>
                                  <div
                                    className={classes.lableAndFieldContainer}
                                  >
                                    <Typography
                                      className={classes.profileLable}
                                    >
                                      Primary account holder name:
                                    </Typography>{" "}
                                    <TextField
                                      id="outlined-basic"
                                      placeholder="E.g. John Smith"
                                      variant="outlined"
                                      className={classes.profileTextField}
                                      onChange={e =>
                                        this.setPrimaryAccountHolderName(
                                          e.target.value
                                        )
                                      }
                                      fullWidth
                                      error={Boolean(
                                        errors.primaryAccountHolderName
                                      )}
                                      helperText={
                                        errors.primaryAccountHolderName
                                      }
                                    />
                                  </div>
                                  <div
                                    className={classes.lableAndFieldContainer}
                                  >
                                    <Typography
                                      className={classes.profileLable}
                                    >
                                      Primary account holder email:
                                    </Typography>{" "}
                                    <TextField
                                      id="outlined-basic"
                                      placeholder="E.g. johnsmith@gmail.com"
                                      variant="outlined"
                                      className={classes.profileTextField}
                                      onChange={e =>
                                        this.setPrimaryAccountHolderEmail(
                                          e.target.value
                                        )
                                      }
                                      fullWidth
                                      error={Boolean(
                                        errors.primaryAccountHolderEmail
                                      )}
                                      helperText={
                                        errors.primaryAccountHolderEmail
                                      }
                                    />
                                  </div>
                                  <div
                                    className={classes.lableAndFieldContainer}
                                  >
                                    <Typography
                                      className={classes.profileLable}
                                    >
                                      Secondary account holder name:
                                    </Typography>{" "}
                                    <TextField
                                      id="outlined-basic"
                                      placeholder="E.g. Sarah Brown"
                                      variant="outlined"
                                      className={classes.profileTextField}
                                      onChange={e =>
                                        this.setSecondaryAccountHolderName(
                                          e.target.value
                                        )
                                      }
                                      fullWidth
                                      error={Boolean(
                                        errors.secondaryAccountHolderName
                                      )}
                                      helperText={
                                        errors.secondaryAccountHolderName
                                      }
                                    />
                                  </div>
                                  <div
                                    className={classes.lableAndFieldContainer}
                                  >
                                    <Typography
                                      className={classes.profileLable}
                                    >
                                      Secondary account holder email:
                                    </Typography>{" "}
                                    <TextField
                                      id="outlined-basic"
                                      placeholder="E.g. sarahb@gmail.com"
                                      variant="outlined"
                                      className={classes.profileTextField}
                                      onChange={e =>
                                        this.setsecondaryAccountHolderEmail(
                                          e.target.value
                                        )
                                      }
                                      fullWidth
                                      error={Boolean(
                                        errors.secondaryAccountHolderEmail
                                      )}
                                      helperText={
                                        errors.secondaryAccountHolderEmail
                                      }
                                    />
                                  </div>
                                </div>
                                <div className={classes.twoDivHolder}>
                                  <div
                                    className={classes.lableAndFieldContainer}
                                  >
                                    <Typography
                                      className={classes.profileLable}
                                    >
                                      Industry:
                                    </Typography>{" "}
                                    <Select
                                      labelId="industryId"
                                      id="industryId"
                                      variant="outlined"
                                      name="industry_ids"
                                      value={values.industry_ids}
                                      placeholder={
                                        "agriculture, foresty, fishing"
                                      }
                                      multiple
                                      onChange={e => this.setIndustryOptions(e)}
                                      className={classes.profileSelectField}
                                      fullWidth
                                    >
                                      {!!this.state.industryOptions.length &&
                                        this.state.industryOptions.map(
                                          (o: any) => (
                                            <MenuItem value={o.id} key={o.id}>
                                              {o.attributes.name}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                    {Boolean(errors.industry_ids) && (
                                      <FormHelperText
                                        className={classes.selectHelperText}
                                      >
                                        {errors.industry_ids}
                                      </FormHelperText>
                                    )}
                                  </div>
                                  <div
                                    className={classes.lableAndFieldContainer}
                                  >
                                    <Typography
                                      className={classes.profileLable}
                                    >
                                      Unique Identifier{" "}
                                      {`(TRN - Jamaica / TIN - other jurisdictions)`}
                                      :
                                    </Typography>{" "}
                                    <TextField
                                      id="outlined-basic"
                                      placeholder="E.g. +1 (555) 123-4567 "
                                      variant="outlined"
                                      className={classes.profileTextField}
                                      onChange={e =>
                                        this.setUniqueId(e.target.value)
                                      }
                                      fullWidth
                                      error={Boolean(errors.uniqueId)}
                                      helperText={errors.uniqueId}
                                    />
                                  </div>
                                </div>
                                <Box className={classes.profileButtonAlign}>
                                  <Button
                                    className={classes.profileSaveButton}
                                    variant="contained"
                                    type="submit"
                                  >
                                    Save
                                  </Button>
                                </Box>
                              </div>
                            </Box>
                          </form>
                        </Grid>
                      </>
                    );
                  }}
                </Formik>
              </Grid>
            </div>
      </Box>
    );
  }
}

export default withStyles(styles)(UserProfile);
// Customizable Area Ed
