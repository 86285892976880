// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { refineResponseData, downloadFiles } from "../../../components/src/Utils/utils"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export type DocumentsArray = { [x: string]: any }[];

export type token = string | null;

interface S {
  token: token;
  openRows: any[];
  rejectOpen: boolean;
  approveOpen: boolean;
  document: string;
  comment: string;
  requestId: number;
  isLoading: boolean;
  kycDocuments: DocumentsArray;
  companyName: string;
  createdAtDate: string;
  approveResTitle: string;
}

interface SS {
  id: any;
}

export default class EKYCNotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getKycDetailsAPI: string = '';
  updateFileReviewAPI: string = '';
  kycVerificationAPI: string = ''

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      token: '',
      openRows: [],
      rejectOpen: false,
      approveOpen: false,
      document: "",
      comment: "",
      requestId: 0,
      isLoading: false,
      kycDocuments: [],
      companyName: '',
      createdAtDate: '',
      approveResTitle: ''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else {
      const token = localStorage.getItem("auth_token");
      const path: any[] = window.location.pathname.split("/");
      const id = path[path.length - 1];
      this.setState({
        requestId: Number(id),
        token: token,
        isLoading: true
      });
      this.getKycDetailsAPI = this.apiCall(token, `get_mendatory_documents?id=${id}`, 'GET')
    }
  }


  toggleRow = (rowId: number) => {
    const { openRows } = this.state;
    const isOpen = openRows.includes(rowId);
    if (isOpen) {
      this.setState({ openRows: openRows.filter((id) => id !== rowId) });
    } else {
      this.setState({ openRows: [...openRows, rowId] });
    }
  };

  handleKycDocumentsResponse = (response: any) => {
    const companyName = response.data.attributes.company_name
    const createdDate = response.data.attributes.created_at
    const refinedData = refineResponseData(response.data.attributes)
    this.setState({
      kycDocuments: refinedData,
      isLoading: false,
      companyName: companyName,
      createdAtDate: createdDate
    })
  }

  handleModalClose = () => {
    this.setState({ rejectOpen: false, approveOpen: false })
  }

  handleRejectInputChange = (e: any) => {
    const { value, name } = e.target;
    const newState: any = {
      [name]: value,
    };
    this.setState(newState);
  };

  rejectRequest = () => {
    const { token, requestId, document, comment } = this.state

    const data = {
      "account_id": requestId,
      "kyc_status": "rejected",
      "document_type": document,
      "comment": comment
    }

    this.kycVerificationAPI = this.apiCall(token, 'approve_reject_ekyc_form', 'POST', JSON.stringify(data))
    this.setState({ rejectOpen: false , isLoading:true})
    this.props.navigation.navigate("AdminNotifications");

  };

  approveRequest = () => {
    this.setState({
      approveOpen:false
    })
    window.location.href = `/admin/EKYC-evaluation/${this.state.requestId}`
  };

  downloadAllFiles = (file: any) => {
    file.map((item: any) => {
      downloadFiles(item.url)
    })
  }

  handleApproveClick = () => {
    const { token, requestId } = this.state
    const URL = window.location.origin;
    const data = {
      "account_id": requestId,
      "kyc_status": "approve",
      "link": URL
    }
    this.kycVerificationAPI = this.apiCall(token, 'approve_reject_ekyc_form', 'POST', JSON.stringify(data))
    this.setState({
      isLoading:true
    })
  }
  handleRejectClick = () => {
    this.setState({ rejectOpen: true })
  }

  handleResUpdateFileReviewAPI = () => {
    const { token, requestId } = this.state
    this.setState({
      isLoading:true
    })
    this.getKycDetailsAPI = this.apiCall(token, `get_mendatory_documents?id=${requestId}`, 'GET')
  }

  hadleResKycVerificationAPI = (response: any) => {
    this.setState({
      approveOpen : true,
      isLoading:false,
      approveResTitle: response.account
    })
  } 

  updateFileReview = (status: boolean, name: string) => {
    const fileName = `${name.split(" ").join("_")}_status`
    const { token, requestId } = this.state
    const body = {
      account_id: requestId,
      [fileName]: true
    }
    this.updateFileReviewAPI = this.apiCall(token, 'update_account_review_status', 'PUT', JSON.stringify(body))
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getKycDetailsAPI) {
      this.handleKycDocumentsResponse(responseJson)
    }
    if (apiRequestCallId === this.updateFileReviewAPI) {
      this.handleResUpdateFileReviewAPI()
    }
    if (apiRequestCallId === this.kycVerificationAPI) {
      this.hadleResKycVerificationAPI(responseJson)
    }
  }

  apiCall = (token: token, endPoint: string, method: string, body?: any) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const bodyHeader = {
      token: token,
      "Content-Type": "application/json",
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(bodyHeader)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      )

    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
  }
}
// Customizable Area End
