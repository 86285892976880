import { Route, Redirect} from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";
import SideNavigationMenu from "../../../blocks/navigationmenu/src/SideNavigationMenu.web";

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack },
    closeModal
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

const WebRoutesGenerator = ({ routeMap }) => {
  const isAuthenticated = localStorage.getItem('auth_token');
  const role = localStorage.getItem("account_role");
  return Object.keys(routeMap).map((route, index) => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path+`-`+index}
          path={currentRoute.path}
          component={(props) => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      if (currentRoute.isProtected) {
        if (currentRoute.roles.split(",").includes(role)) {
          return (
            <Route
              key={currentRoute.path+`-`+index}
              path={currentRoute.path}
              exact={currentRoute.exact}
              render={(props) =>
                isAuthenticated ? (
                  <Wrapper
                    element={ <SideNavigationMenu
                      children={
                        <Wrapper
                          element={<Component />}
                          {...props}
                          routeMap={routeMap}
                        />
                       
                      }
                      isBack={currentRoute.isBack}
                      role={role}
                    /> }
                    {...props}
                    routeMap={routeMap}
                  />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
          );
        }else {
          if(isAuthenticated !== null || currentRoute.roles.split(",").includes(role)){
            if(window.location.pathname === "/statements" && (role === "admin" || role === "analyst")){
              localStorage.clear()
              return (<Redirect to="/login" />)
            }else {
              return true
            }
          
          }
          const pathSegments = window.location.pathname.split("/");
          const isAdminPath = pathSegments[1] === 'admin';
          if (isAdminPath && pathSegments[2] === 'CreatePassword') {
            const queryString = window.location.search;
            const redirectUrl = `/admin/CreatePassword${queryString}`;
            return <Redirect to={redirectUrl} />;
          } else if (!isAdminPath && pathSegments[1] === 'CreatePassword') {
            const queryString = window.location.search;
            const redirectUrl = `/CreatePassword${queryString}`;
            return <Redirect to={redirectUrl} />;
          } else if (isAdminPath && (pathSegments[2] === 'ForgotPassword' || pathSegments[2] === 'forgotpassword'))  {
            return <Redirect to="/admin/ForgotPassword" />;
          } else if (!isAdminPath && (pathSegments[1] === 'ForgotPassword'|| pathSegments[1] === 'forgotpassword')) {
            return <Redirect to="/ForgotPassword" />;
          } else if (!isAdminPath && pathSegments[1] === 'sign-up') {
            return <Redirect to="/sign-up" />;
          }else if (!isAdminPath && (pathSegments[1] === 'ekyc1' || pathSegments[1] === 'EKYC1')) {
            return <Redirect to="/EKYC1" />;
          }else if (!isAdminPath && (pathSegments[1] === 'ekyc2' || pathSegments[1] === 'EKYC2')) {
            return <Redirect to="/EKYC2" />;
          }else if (!isAdminPath && (pathSegments[1] === 'ekyc3' || pathSegments[1] === 'EKYC3')) {
            return <Redirect to="/EKYC3" />;
          }else if (!isAdminPath && (pathSegments[1] === 'ekyc4' || pathSegments[1] === 'EKYC4')) {
            return <Redirect to="/EKYC4" />;
          }else if (isAdminPath) {
            return <Redirect to="/admin/login" />;
          } else {
            return <Redirect to="/login" />;
          }
        }
      } else {
        return (
          <Route
            key={currentRoute.path+`-`+index}
            path={currentRoute.path}
            exact={currentRoute.exact}
            render={(props) => (
              <Wrapper element={<Component />} {...props} routeMap={routeMap} />
            )}
          />
        );
      }
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
