import * as Yup from "yup";
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";

export interface Props {
  navigation: any;
  classes: any;
}

export type arrayItemType = {
  id: number;
  value: string;
  fileData: boolean;
  name: string;
};

export type arrayUploadedFile = {
  id: number;
  name: string;
  url: string;
  size: string,
  date: string,
  time: string,
};

interface S {
  fileReader: FileReader;
  staticArray: arrayItemType[];
  errorHandler: boolean;
  textColor: string;
  apiError: string;
  token: string | null;
  navigation: any;
  snackbar: any;
  soleProprietor: boolean,
  isUploadModalOpen: boolean,
  uploadedFile: arrayUploadedFile[],
  fileId: number,
  isValidResponse: boolean,
  isViewModalOpen: boolean,
  isLoading: boolean,
  isFileLimitExceded: boolean;
  isAdmin: boolean;
  accountId:string;
  isModalOpen:boolean
}

interface SS {
  id: number;
}

export const arrayItem: arrayItemType[] = [
  {
    id: 1,
    value: "Articles of Incorporation*",
    fileData: false,
    name: "articles_of_incorporations"

  },
  {
    id: 2,
    value: "Certificate of Incorporation*",
    fileData: false,
    name: "certificates_of_incorporatrions"
  },
  {
    id: 3,
    value: "Certificate of Registration*",
    fileData: false,
    name: "certificates_of_registrations"
  },
  {
    id: 4,
    value: "Audited Financial Statements*",
    fileData: false,
    name: "auditated_financial_statements"
  },
  {
    id: 5,
    value: "Management Accounts*",
    fileData: false,
    name: "management_accounts"
  },
  {
    id: 6,
    value: "Invoice/Contract/Purchase Order*",
    fileData: false,
    name: "invoices"
  },
  {
    id: 7,
    value: "Bank Statements*",
    fileData: false,
    name: "bank_statements"
  },
  {
    id: 8,
    value: "Proof of Payment*",
    fileData: false,
    name: "proof_of_payments"
  },
  {
    id: 9,
    value: "Driver's License*",
    fileData: false,
    name: "driving_licences"
  },
  {
    id: 10,
    value: "Tax Registration Number/ Tax Identification Number*",
    fileData: false,
    name: "tax_registration_numbers"
  },
];

export default class EKYC3Controller extends BlockComponent<Props, S, SS> {
  getCorporateAPI: string = '';
  uploadKycDocumentsApi: string = '';
  getKycDocumentsApi: string = '';
  deleteApiResponseApi: string = ''
  handleDeleteResponseApi: string = ''

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      fileReader: new FileReader(),
      staticArray: [...arrayItem],
      errorHandler: false,
      textColor: "black",
      apiError: "",
      token: "",
      navigation: "",
      snackbar: {
        open: false,
        message: "",
        severity: "",
      },
      soleProprietor: false,
      isUploadModalOpen: false,
      uploadedFile: [],
      fileId: 0,
      isValidResponse: false,
      isViewModalOpen: false,
      isLoading: true,
      isFileLimitExceded: false,
      isAdmin: false,
      accountId:'',
      isModalOpen:false,
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0)

    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else {
      const token = localStorage.getItem("auth_token");
      const role = localStorage.getItem("account_role");
      const pathCheck = window.location.pathname
      this.setState({ token: token });
      if ( role === "admin" || role === "analyst" || pathCheck.includes('admin') ) {
        const path = pathCheck.split('/')
        const id = path[path.length - 1];
        this.setState({
          isAdmin: true,
          accountId:id
        });
        this.getCorporateAPI = this.apiCall(token, `get_ekyc_detail?account_id=${id}` , "GET");
      }else{
      this.getCorporateAPI = this.apiCall(token,"get_ekyc_detail","GET")
      }
    }
  }

  validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .test(
        "fileSize",
        "File size is too large (maximum is 20MB)",
        (value) => value && value.size < 20971520
      )
      .test(
        "fileType",
        "Invalid file type. Only PDF, Word, Excel, JPG, and PNG files are allowed",
        (value) => {
          if (value) {
            return [
              "application/pdf",
                "application/msword",
                "application/vnd.ms-excel",
                "image/jpeg",
                "image/png",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ].includes(value.type);
          }
          return true;
        }
      ),
  });

  handleResponseData = (data: any) => {
    const responseData = data
    const fileNameToSend = this.findRequiredFile()
    const uploadedResponseFiles = responseData?.[fileNameToSend]
    if (uploadedResponseFiles?.length > 0) {
      this.setState({
        uploadedFile: uploadedResponseFiles,
        isValidResponse: true,
        isLoading: false
      })
      if (uploadedResponseFiles.length > 4) {

        this.setState({
          isFileLimitExceded: true,
        })
      } else {
        this.setState({
          isFileLimitExceded: false,
        })
      }

    } else {
      this.setState({
        isViewModalOpen: false,
        isLoading: false,
        uploadedFile: [],
      })
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: "",
        severity: "",
      },
    });
  };

  callDeleteAPI = (id : number) => {
    this.setState({
      isLoading:true
    })
    this.deleteApiResponseApi = this.apiCall(this.state.token,`kyc_document_delete?attachment_id=${id}`,"PUT")
  }

  findRequiredFile = () => {
    const { staticArray, fileId } = this.state
    const foundItem = staticArray.find(item => item.id === fileId);
    return foundItem?.name || ''
  }

  uploadedFileArray = (file: any) => {
    const { fileId, isFileLimitExceded } = this.state

    if (isFileLimitExceded) {
      this.setState({
        snackbar: {
          open: true,
          message: "Maximum File Limit is 5",
          severity: "error",
        },
        isLoading: false
      })
      return
    }
    this.setState({
      uploadedFile: [],
      isLoading: true,
    })
    file.forEach((fileToUpload: any) => {
      const fileNameToSend = this.findRequiredFile()
      const { token , isAdmin , accountId } = this.state
      this.validationSchema
        .validate({ file: fileToUpload })
        .then(() => {
          const fileToUploadBody = {
            name: fileNameToSend,
            file: fileToUpload
          }
          const method = isAdmin ? `/upload_ekyc_document?account_id=${accountId}` : `/upload_ekyc_document`
          this.uploadKycDocumentsApi = this.apiCall(token, method , 'PUT', fileToUploadBody)
          const newImageData = this.state.staticArray.map((data) => {
            if (data.id === fileId) {
              return { ...data, fileData: true };
            }
            return data;
          });
          this.setState({
            staticArray: newImageData,
            isFileLimitExceded: false
          })
        })
        .catch((error: any) => {
          this.getKycDocumentsApi = this.apiCall(token, "get_ekyc_detail", "GET")
          this.setState({
            snackbar: {
              open: true,
              message: error.message,
              severity: "error",
            },
            isLoading: false
          });
        });
    });
  }

  handleOpen = (id: number) => {
    const { token , isAdmin , accountId } = this.state
    this.setState(prevState => ({
      isUploadModalOpen: !prevState.isUploadModalOpen
    }))
    this.setState({
      fileId: id,
      isLoading: true,
      isFileLimitExceded:false
    })
    const endPoint = isAdmin ? `get_ekyc_detail?account_id=${accountId}` : "get_ekyc_detail"
    this.getKycDocumentsApi = this.apiCall(token, endPoint , "GET")
  }

  handleClose = () => {
    this.setState({ isUploadModalOpen: false })
  }

  handleViewModalOpen = (id: number) => {
    const { token , isAdmin , accountId } = this.state
    this.setState(prevState => ({
      isViewModalOpen: !prevState.isViewModalOpen
    }))
    this.setState({
      fileId: id,
      isLoading: true
    })

    const endPoint = isAdmin ? `get_ekyc_detail?account_id=${accountId}` : "get_ekyc_detail"
    this.getKycDocumentsApi = this.apiCall(token, endPoint , "GET")
  }

  handleViewModalClose = () => {
    this.setState({ isViewModalOpen: false })
  }

  handleRedirection = () => {
    this.props.navigation.navigate("ActiveTabs")
  }

  receiveKycResponse = (response: any) => {
    const tempName = response?.data?.attributes?.account_block_corporate_types?.name
    if (tempName === "Sole proprietor") {
      const index = this.state.staticArray.findIndex(item => item.id === 1);
      if (index !== -1) {
        const updatedArray = [...this.state.staticArray]
        updatedArray.splice(index, 1)
        this.setState({
          soleProprietor: true,
          staticArray: updatedArray,
          isLoading: false
        });
      }
    } else {
      const updatedArray = [...this.state.staticArray]
      updatedArray.splice(2, 1)
      this.setState({
        soleProprietor: false,
        staticArray: updatedArray,
        isLoading: false
      });
    }
    const updatedStaticArray = this.state.staticArray.map(staticItem => {
      const name = staticItem.name;
      if (response?.data?.attributes[name]?.length) {
        return { ...staticItem, fileData: true };
      } else {
        return staticItem;
      }
    });
    this.setState({ staticArray: updatedStaticArray });
  };

  handleDeleteResponse = (responseMessage: string) => {
    const { token , isAdmin , accountId } = this.state
    if (responseMessage === 'Attachment removed successfully') {
      const endPoint = isAdmin ? `get_ekyc_detail?account_id=${accountId}` : "get_ekyc_detail"
      this.handleDeleteResponseApi = this.apiCall(token, endPoint, "GET")
    }
  }

  UpdateUploadBrowseButton = (response: any) => {
    this.handleResponseData(response.data.attributes)
    const updatedStaticArray = this.state.staticArray.map(staticItem => {
      const name = staticItem.name;
      if (response?.data?.attributes[name]?.length == 0) {
        return { ...staticItem, fileData: false };
      } else {
        return staticItem;
      }
    });
    this.setState({
      staticArray: updatedStaticArray,
      isLoading: false
    });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getCorporateAPI) {
      this.receiveKycResponse(responseJson);
    }
    if (apiRequestCallId === this.uploadKycDocumentsApi) {
      this.handleResponseData(responseJson?.data?.attributes)
    }
    if (apiRequestCallId === this.getKycDocumentsApi) {
      this.handleResponseData(responseJson?.data?.attributes)
    }
    if (apiRequestCallId === this.deleteApiResponseApi) {
      this.handleDeleteResponse(responseJson.attachment)
    }
    if (apiRequestCallId === this.handleDeleteResponseApi) {
      this.UpdateUploadBrowseButton(responseJson)
    }
  }

  setSubmit = () => {
    const { staticArray , isAdmin } = this.state
    const isFileDataFalse = staticArray.find((item) => item.fileData === false)
    if (isFileDataFalse) {
      this.setState({
        errorHandler: true,
        snackbar: {
          open: true,
          message: "Upload All files Before Continuing",
          severity: "error",
        },
      })
    } else {
      isAdmin ? this.setState({ isModalOpen: true }) : this.props.navigation.navigate("KycDetails4");
    }
  }

  handleModalClose = () => {
    this.setState({ isModalOpen: false})
  }

  apiCall = (token: any, endPoint: string, method: string, body?: any) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const bodyHeader = {
      token: token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    if (body) {

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(bodyHeader)
      );

      const fd = new FormData()
      fd.append(body.name, body.file);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        fd
      )
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
  }
}
