// Customizable Area Start
import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Popper,
  Switch,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { NavLink } from "react-router-dom";

import { styles } from "./TopNavStyles.web";
import TopNavController, { Props } from "./TopNavController.web";
import CustomAvatar from "../../../components/src/CustomAvatar.web";
const link = require("../../../framework/src/config.js").baseURL
const leftArrowIcon = require("../../../components/src/Assets/left-arrow.png")
const redirectConsole = `${[link]}/admin`;

class TopNav extends TopNavController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { open, anchorEl, count} = this.state;
    const userJson = localStorage.getItem("user");
    const user = userJson !== null && JSON.parse(userJson);
    const initials = user?.name?.charAt(0).toUpperCase() || "A";
    const adminCount = count || localStorage.getItem("admin_notification_count")
    return (
      <AppBar position="fixed" classes={{ root: classes?.appbar }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={this.props.handleDrawerToggle}
            className={classes?.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {this.props.isBack && (
              <img src={leftArrowIcon} data-test-id="back-btn" className={classes?.backBtn} alt="back" onClick={()=> {window.history.back()}}/>
          )}
          <div className="navContainer">
            {this.props.role !== "client" && (
              <>
                <NavLink to="/admin/notifications">
                  <IconButton color="inherit">
                    <Badge badgeContent={adminCount} classes={{ badge:classes?.customBadge}}>
                      <NotificationsNoneIcon classes={{ root: "icon" }} />
                    </Badge>
                  </IconButton>
                </NavLink>
                <a href={redirectConsole} target="_blank">
                  <SettingsIcon classes={{ root: "icon" }} />
                </a>
              </>
            )}
            <CustomAvatar to="" initials={initials} />
            <Popper
              open={false}
              anchorEl={anchorEl}
              placement="bottom"
              className="settingPopper"
              transition
              disablePortal
            >
              <ClickAwayListener onClickAway={this.handleClose}>
                <Paper className="papper">
                  <div className="titleContainer">
                    <p className="title">John Doe</p>
                    <p className="subTitle">Business account</p>
                  </div>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    className="menuList"
                  >
                    <MenuItem>
                      <PersonIcon className="icon" />
                      <p>Your details</p>
                    </MenuItem>
                    <MenuItem>
                      <SettingsIcon className="icon" />
                      <p>Account settings</p>
                    </MenuItem>
                    <MenuItem onClick={this.logOut}>
                      <ExitToAppIcon className="icon" />
                      <p>Logout</p>
                    </MenuItem>
                  </MenuList>
                  <div className="modeContainer">
                    <p>Dark mode</p>
                    <Switch color="default" />
                  </div>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(TopNav);
// Customizable Area End
