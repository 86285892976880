const drawerWidth = "265px";
export const styles: any = (theme: any) => ({
  appbar: {
    zIndex:'0',
    position:'absolute',
    width: "calc(100% - 265px)",
    background: "white",
    alignItems: "space-between",
    boxShadow: "none",
    "& .MuiToolbar-gutters": {
      paddingRigth: "45px",
    },
    "& .MuiToolbar-root": {
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 45px 0",
      width:'100%',
      "& .navContainer": {
        display: "flex",
        marginLeft: "auto",
        alignItems: "center",
        flexDirection: "row",
        columnGap: "40px",
        justuifyContent: "space-between",
        "& h6": {
          color: "black",
        },
        "& .icon": {
          fill: "#8F92A1",
          width: "27px",
          height: "27px",
          cursor: "pointer",
          "& path": {
            width: "100%",
            height: "100%",
          },
          "& :hover": {
            fill: "#E5B632",
          },
        },
        "& .profileIcon": {
          width: "50px !important",
          height: "54px !important",
        },
        "& .settingPopper": {
          top: "15px !important",
          right: "115px",
          left: "auto !important",
          "& .papper": {
            borderRadius: "10px",
            padding: "18px 15px 10px",
            minWidth: "180px",
            boxShadow: "0px 1px 3px 1px rgba(0,0,0,0.2)",
            "& .titleContainer": {
              display: "flex",
              paddingBottom: "15px",
              flexDirection: "column",
              borderBottom: "1px solid #E7E7E7",
              "& p": {
                margin: 0,
              },
              "& .title": {
                fontSize: "14px",
                lineHeight: "18px",
                fontWeight: 600,
                marginBottom: "3px",
              },
              "& .subTitle": {
                fontSize: "10px",
                lineHeight: "14px",
              },
            },
            "& .menuList": {
              padding: "0px",
              borderBottom: "1px solid #E7E7E7",
              "& li": {
                margin: "7px 0",
                padding: "3px 0",
                "& p": {
                  margin: "2px 0",
                  fontSize: "12px",
                  lineHeight: "16px",
                },
                "& .icon": {
                  fill: "#8F92A1",
                  width: "18px",
                  height: "18px",
                  marginRight: "10px",
                  marginLeft: "-2px",
                },
              },
            },
            "& .modeContainer": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "5px",
              "& p": {
                margin: 0,
                fontSize: "12px",
                lineHeight: "16px",
              },
            },
          },
        },
      },
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    color: "#E5B632",
  },
  backBtn: {
    height: "29px",
    width: "30px",
    cursor: "pointer",
    marginBottom:'0'
  },
  customBadge: {
    top: "15%",
    right: "15%",
    backgroundColor: "#E5B632",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "8px",
    lineHeight: "10px",
    leadingTrim: "both",
    textEdge: "cap",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
  '@media (max-width: 600px)': {
    appbar: {
      width: "100%",
    },
  },
});
