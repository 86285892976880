import React, { Component } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { withStyles } from "@material-ui/core/styles";
import { formatCurrencySufWithPrefix } from "../Utils/utils";
import { parseTimelineChartData } from "../Utils/parseData";

interface Props {
  data: any;
  keyValue: string;
  classes: any;
}

const yAxisProps = {
  tick: {
    fill: "rgba(0,0,0,0.40)",
    fontSize: 12,
    fontWeight: 300,
    dx: 2,
    dy: -3,
  },
  tickLine: false,
  strokeWidth: 0,
  allwoDataOverflow: false,
};

const xAxisProps = {
  dataKey:"xName",
  tick: {
    fill: "#838383",
    fontSize: 10,
    fontWeight: 500,
    dy: 12,
    dx: 0,
  },
  interval: 0,
  tickCount: 12,
  tickLine: false,
  strokeWidth: 0.5,
  stroke: "#ccc",
  fontSize: 10,
  tickSize: 3,
};
 
const areaProps = {
  dataKey: "yName",
  stroke: "white",
  fill: "url(#customGradient)",
};

class AdminTimelineChart extends Component<Props> {

  renderTooltip = (props:any, classes:any) => {
    const { active, payload} = props;
    if (active && payload && payload.length) {
      const date = payload[0].payload.formatted_date; 
      const value = payload[0].payload[payload[0].payload.key]
      return (
        <div className={classes?.customTooltip}>
          <p className="value">{value}</p>
          <span className="date">{date}</span>
          <div className="arrowStyle" ></div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { data, keyValue, classes } = this.props;
    const chartData = parseTimelineChartData(data, keyValue);
    const ticks = chartData.map((entry:any)=> entry.yName);
    const maxValue = Math.max(...ticks);
    const isIntYvalue = ["number_of_onboarded_clients", "clients_not_yet_onboarded"].includes(keyValue)
    const renderActionDot = (data:any) => {
      return (
        <g>
           <defs>
          <filter id="f1" x="-20%" y="-20%" width="200%" height="200%">
            <feOffset result="offOut" in="SourceGraphic" dx="2" dy="2" />
            <feColorMatrix
              result="matrixOut"
              in="offOut"
              type="matrix"
              values="0.7 0 0 0 0 0 0.7 0 0 0 0 0 0.7 0 0 0 0 0 1 0"
            />
            <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="3" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
          <circle
            cx={data.cx}
            cy={data.cy}
            r={7}
            strokeWidth={2.5}
            filter="url(#f1)"
            fill={"white"}
          />
          <circle
            cx={data.cx}
            cy={data.cy}
            r={4}
            strokeWidth={2.5}
            fill="#E5B632"
          />
        </g>
      );
      
    };
    return (
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart data={chartData} margin={{ top: 30, right: 10, left: 0, bottom: 0 }} style={{width:'100%'}}>   
          <CartesianGrid
            horizontal={true}
            vertical={false}
            stroke="#ccc"
            strokeWidth={0.5}
          />
          <XAxis {...xAxisProps} />
          <YAxis {...yAxisProps} interval="preserveStartEnd" tickCount={isIntYvalue ? maxValue+1 : 5}
            tickFormatter={(tick: number) => formatCurrencySufWithPrefix(tick)} />
          <Tooltip content={(e) => this.renderTooltip(e, classes)} cursor={{ stroke: "#E5B632", strokeWidth: 1, strokeDasharray: 5 }} />
          <Area type="monotone" activeDot={renderActionDot} {...areaProps} />
          <defs>
            <linearGradient
              id="customGradient"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            > 
              <stop offset="50%" stopColor="#E5B632" stopOpacity={1} />
              <stop offset="100%" stopColor="#E5B632" stopOpacity={0.3} />
            </linearGradient>
          </defs>
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

const chartStyles: any = {
  customTooltip:{
    backgroundColor: "white",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
    color: "#000000",
    padding: "8px",
    fontStyle: "normal",
    fontWeight: 400,
    borderRadius: "8px",
    border: "0.5px solid rgb(250,250,250)",
    flexDirection: "column",
    alignItems: "flex-start",
    display:"flex",
    "& .value":{
      color: '#11263C',
      fontSize: "16px",
      textAlign: "first",
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: '600',
      lineHeight: "20px",
      margin: "0",
      marginBottom: "2px",
    },
    "& .date":{
      color: 'rgba(60,60,67,0.60)',
        fontSize: "11px",
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: '400',
        lineHeight: "13px",
        textAlign: "first",
        float: "left",
        wordWrap: 'break-word',
        margin: 0
    }
  }
}

export default  withStyles(chartStyles)(AdminTimelineChart);

