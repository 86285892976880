import React from "react";
import { 
  Box,
  // Customizable Area Start
  Grid,
  TextField,
  Button,
  Table, 
  TableBody, 
  TableCell, 
  TableRow, 
  TableContainer
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import { styles } from "./InvoiceStyles.web";
import Dropzone from 'react-dropzone';
import { SubmitInvoiceFields, userRoles } from "../../../components/src/Utils/constant"
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CommonModalWeb from "../../../components/src/Modal/CommonModal.web";
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";
import CustomTooltipWeb from "../../../components/src/Tooltip/CustomTooltip.web"
import {getUserRole, onKeyDown} from "../../../components/src/Utils/utils"
import AdminAdvanceSearchWeb from "../../../components/src/Search/AdminAdvanceSearch.web";
import NumberFormatCustom from "../../../components/src/CustomTextField/NumberFormatCustom"
const icons = require('./assets')
// Customizable Area End

import InvoiceController, { Props } from "./InvoiceController.web";

export class SubmitInvoice extends InvoiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const { classes } = this.props;
    const { 
      FileDetails , 
      isPurchaseOrderActive,
      isModalOpen,
      isLoading,
      isSubmitInvoiceActive ,
      formDataObject,
      searchedClients, 
      selectedClient, 
      isClientSearchLoaded 
    } = this.state
    const { open, message, severity } = this.state.snackbar

    return (
      // Customizable Area Start
      <Box className={classes.boxContainerInvoice}>
        <Grid item xs={12} md={12} sm={12}>
        {isLoading && <CustomLoader open={isLoading}/>}
          <Box sx={{ 
              padding: "32px 50px", 
              // width: "100%" 
            }} 
            className={classes.rootInvoice}>
            <div className={classes.navigationButtonInvoice}>
                  <Button 
                    data-test-id='submitNavigation'
                    className={
                      isSubmitInvoiceActive 
                      ? classes.navButtonActiveInvoice 
                      : classes.navButtonNotActiveInvoice
                    } 
                    onClick={()=>this.navigateToSubmitInvoice()}
                  >
                    Submit Invoice
                  </Button>
                  <Button 
                    data-test-id='productNavigation'
                    className={
                      isPurchaseOrderActive 
                      ? classes.navButtonActiveInvoice
                      : classes.navButtonNotActiveInvoice
                    } 
                    onClick={()=>this.navigateToProductOrder()}
                  >
                    Submit purchase order
                  </Button>
            </div>
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={ formDataObject }
              validationSchema={this.submitInvoiceSchema}
              onSubmit={(values: any, { resetForm }) => this.handleFormSubmit(values , resetForm)}
            >
              {({ errors, values, handleChange, handleSubmit, touched }) => {
                return (
                    <Grid item xs={12}>
                      <form onSubmit={handleSubmit} onKeyDown={onKeyDown} className={classes.userFormInvoice}>
                        <div className={classes.FormHolderInvoice}>
                          <div className={classes.inputMapHolderInvoice} >
                          {getUserRole() !== userRoles.client && <Grid
                            className={classes.FieldLableHolderInvoice}
                            item
                            sm={12} 
                            xs={12}
                            md={12}
                          >
                            <span id="invoice-search-client" className={classes.fieldLableInvoice}>Client*:</span>
                            <AdminAdvanceSearchWeb
                              data-test-id="invoiceClientSelect"
                              options={searchedClients}
                              loading={isClientSearchLoaded}
                              searchValue={selectedClient}
                              handleInputChange={this.handleClientInputChange}
                              handleSelectSearch={
                                this.handleInvoiceClientSearch
                              }
                              width="49%"
                            />
                          </Grid>}
                          {SubmitInvoiceFields.map((item, index )=>{
                            return (
                            <Grid key={item.id} className={classes.FieldLableHolderInvoice} item sm={12} xs={12} md={index < 2 ? 6 : 4} >
                                <span className={classes.fieldLableInvoice}>{item.lable}{item.isTooltipRequired ? <Box sx={{marginLeft:'5px'}}><CustomTooltipWeb tooltipText={item.toolTipText} height="15px" width="15px" direction="top"/></Box>:""}</span>
                                <TextField
                                  data-test-id={`Submit-invoice-id-${item.id}`}
                                  placeholder={item.placeHolder}
                                  name={item.name}
                                  InputProps={{
                                    inputComponent: item.prefix ? NumberFormatCustom as any : undefined,
                                    inputProps: {
                                      max: new Date().toISOString().split("T")[0], 
                                    },
                                  }}
                                  type={item.type}
                                  variant="outlined"
                                  //@ts-ignore
                                  error={this.handleErrors(errors?.[item.name],touched?.[item.name])}
                                  //@ts-ignore
                                  //@ts-ignore
                                  value={values[item.name]}
                                  className={classes.inputTextFieldInvoice}
                                  onChange={(event : any)=>handleChange(event)}
                                  helperText={this.getHelperText(item, values, errors, touched)}/>
                            </Grid>
                          )})}
                          </div>
                        </div>
                        <div className={classes.dropzoneHolderInvoice}>
                            <span style={{fontSize:'12px',fontWeight:600}}>Upload Document*: </span>
                            <span style={{fontSize:'12px'}}>File  should be PDF, Excel, JPG, PNG.doc or .docx, and the maximum file size should not exceed 20MB.</span>
                            <Dropzone
                                multiple={false}
                                onDrop={(files) => this.handleInvoiceFileUpload(files)}
                                data-test-id = "FileDropFunction"
                             >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} className={classes.dropzoneInvoice}>
                                    <img src={icons.image_Vuesax} alt="" className={classes.modalIconInvoice}/>
                                    <input {...getInputProps()} />
                                    <p className={classes.dropzoneTextInvoice}>Drop files here or Browse  to upload</p>
                                    <Button variant="contained" className={classes.selectInvoice}>
                                        Browse
                                    </Button>
                                </div>
                                )}
                            </Dropzone>
                        </div>
                        <div className={classes.manageFilesInvoice}>
                          <span style={{fontSize:'14px',fontWeight:700}}>Uploaded files</span>
                          <TableContainer>
                            <Table>
                              <TableBody className={classes.fileViewTableInvoice}>
                                {FileDetails.map((item) => (
                                  <TableRow key={item.id}>
                                    <TableCell>
                                      {item.file.name}
                                    </TableCell>
                                    <TableCell 
                                      onClick={
                                        ()=>this.deleteFile(item.id)
                                        }
                                    >
                                    <img 
                                      src=
                                      {
                                        icons.Delete_icon
                                      } 
                                      alt=""
                                    />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                        <div style={{ width: "100%" }}>
                          <Button data-test-id={"btnSubmit1"} variant="contained" fullWidth className={classes.submitBtnInvoice} type="submit">
                            Submit Invoice
                          </Button>
                        </div>
                      </form >
                    </Grid>
                );
              }}
            </Formik>
          </Box>
        </Grid>
        <CommonModalWeb
          open={isModalOpen}
          title="Thank you for submitting your invoice!"
          btnText="Done"
          handleModelClose={this.handleModalClose} 
          handleSubmitModal={this.handleModalClose}  
          widthToAdd="80%"      
        />
        <CustomSnackbar open={open} onCloseSnack={this.closeSnackbar} message={message} severity={severity} />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(SubmitInvoice);
// Customizable Area End