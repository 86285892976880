// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { parseDataIntoOptions } from "../../../components/src/Utils/parseData";
import { handleRedirectOnStatus, urlParamsToState } from "../../../components/src/Utils/utils";
import { closedNotifyMessage, review_now_arr } from "../../../components/src/Utils/constant";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  sort_column: string;
  sort_direction: string;
  review_status: string;
  search: { id: string | number; name: string };
  document_title: string;
  page: number;
  per_page: number;
  titleArr: any[];
  token: any;
  notificationData: [];
  count: number;
  isLoading: boolean;
  searchOptions: any[];
  loadingSearch: boolean;
}

interface SS {
  id: any;
}

export default class NotificationSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getDataApi: string = "";
  getOptionsApi: string = "";
  debounceTimeout: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      sort_column: "",
      sort_direction: "",
      document_title: "",
      review_status: "",
      page: 1,
      per_page: 10,
      titleArr: [],
      notificationData: [],
      count: 0,
      token: "",
      isLoading: false,
      search: { id: "", name: "" },
      searchOptions: [],
      loadingSearch: false,
    };
    this.debounceTimeout = null;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.sort_direction !== this.state.sort_direction ||
      prevState.review_status !== this.state.review_status ||
      prevState.page !== this.state.page ||
      prevState.per_page !== this.state.per_page ||
      prevState.document_title !== this.state.document_title ||
      prevState.sort_column !== this.state.sort_column ||
      prevState.titleArr.length !== this.state.titleArr.length
    ) {
      this.state.token && this.getTableData(this.state.token);
    }

    if( prevState.search.name !== this.state.search.name) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.getSearchOptions(this.state.search.name);
      }, 300);
    }

    const {
      page,
      sort_column,
      review_status,
      per_page,
      sort_direction,
      document_title,
      search,
    } = this.state;

    const notificationQuery = `search=${search.name ? search.name : ""}&document_title=${document_title}&review_status=${review_status}&sort_column=${sort_column}&sort_direction=${sort_direction}&page=${page}&per_page=${per_page}`;
    const upUrl = `${window.location.pathname}?${notificationQuery}`;
    window.history.replaceState(null, '', upUrl);
  }

  componentWillUnmount(): any {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    this.setState({ search: { id: 0, name: inputValue } });
    if (inputValue === "") {
      this.setState({ searchOptions: [] });
    }
  };

  handleSelectSearch = (event: any, value: any) => {
    if (value?.id) {
      this.setState({ search: value }, () => {
        this.handleSearch();
      });
    } else {
      this.setState({ search: { id: 0, name: "" } });
    }
  };

  handleNotificationRowClick = (row: any) => {
    const { review_status } = row
    if (review_status === "review_now" || review_status === "in_review") {
      handleRedirectOnStatus(review_now_arr, row, this.props.navigation.navigate);
    } else {
      toast.warn(closedNotifyMessage)
    }
  }

  handleSearch = () => {
    this.setState({
      page: 1,
      per_page: 10,
      sort_column: "",
      document_title:"",
      sort_direction: "",
    });
    if (this.state.token) {
      this.getTableData(this.state.token);
    }
  };

  async componentDidMount() {
    const setURL = urlParamsToState(window.location.search)
    this.setState(setURL,
      ()=>{
        const token = localStorage.getItem("auth_token");
        if (token) {
          this.getTableData(token);
          this.setState({ token: token });
        }
      }
    )
  }

  handleFilter = (col: any, opt: any) => {
    if (col === "document_title") {
      if (this.state.document_title === opt) {
        this.setState({
          document_title: "",
        });
      } else {
        this.setState({
          document_title: opt,
        });
      }
    } else if (col === "review_status") {
      if (this.state.review_status === opt) {
        this.setState({
          review_status: "",
        });
      } else {
        this.setState({
          review_status: opt,
        });
      }
    }
  };

  handleChangePage = (event: any, newPage: any) => {
    this.setState({
      page: newPage,
    });
    window.scrollTo(0, 0);
  };

  clearNotificationFilter = () => {
    this.setState({
      page: 1,
      per_page: 10,
      sort_column: "",
      document_title:"",
      search: { id: "", name: "" },
      sort_direction: "",
      review_status: "",
    }, () => this.getTableData(this.state.token));
  }

  handleSort = (columnName: string, direction: string) => {
    this.setState({
      sort_column: columnName,
      sort_direction: direction,
    });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getDataApi) {
          this.getDataResponse(responseJson);
        }
        if (apiRequestCallId === this.getOptionsApi) {
          this.getClientOptionResponse(responseJson);
        }
      } else {
        this.setState({ isLoading: false, loadingSearch: false });
      }
    }
  }

  getClientOptionResponse = (res: any) => {
    if (res?.data) {
      const parsedOptions = parseDataIntoOptions(res.data);
      this.setState({ searchOptions: parsedOptions });
    } else {
      this.setState({ searchOptions: [] });
    }

    this.setState({ loadingSearch: false });
  };

  getDataResponse = (res: any) => {
    const { per_page } = this.state;
    if (
      (res?.notifications &&
      res?.total_count) ||
      res.notification_status_count
    ) {
      const ceil = Math.ceil(res.total_count / per_page || 1);
      this.setState((prevState) => ({
        count: ceil,
        notificationData: res.notifications.data,
        isLoading: false,
      }));
      const notify_count = res?.notification_status_count?.toString();
      localStorage.setItem("admin_notification_count", notify_count);
    } else {
      this.setState((prevState) => ({
        count: 0,
        notificationData: [],
        isLoading: false,
      }));

      localStorage.setItem("admin_notification_count", "0");
    }
  };

  getSearchOptions = (value: any) => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.notificationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({ loadingSearch: true });
    this.getOptionsApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `client_advance_search?search=${value}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTableData = (token: any) => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token,
    };

    const {
      sort_column,
      sort_direction,
      page,
      per_page,
      review_status,
      document_title,
      search,
    } = this.state;

    const path = `search=${search.name}&document_title=${document_title}&review_status=${review_status}&sort_column=${sort_column}&sort_direction=${sort_direction}&page=${page}&per_page=${per_page}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({ isLoading: true });

    this.getDataApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `search_notification_advance?${path}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
