import * as Yup from "yup";
import { maxAmountChar, maxEmployeeChar } from "./constant";

export const FileUploadValidationSchema = Yup.object().shape({
    file: Yup.mixed()
      .test(
        "fileSize",
        "File size is too large (maximum is 20MB)",
        (value) => value && value.size < 20971520
      )
      .test(
        "fileType",
        "Invalid file type. Only PDF, Word, Excel, JPG, and PNG files are allowed",
        (value) => {
            if (value) {
                return [
                "application/pdf",
                "application/msword",
                "application/vnd.ms-excel",
                "image/jpeg",
                "image/png",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                ].includes(value.type);
            }
            return true;
        }
      ),
  });

  export const invoiceReviewSchema = ( state:any ) => {
    return Yup.object().shape({
      payment_terms: Yup.number()
        .min(30,"Payment terms cannot be less than 30 days")
        .nullable()
        .required("Payment Terms is required"),
      disbursement_date: Yup.string()
        .nullable()
        .required("Disbursement Date is required"),
      amount_disbursed: Yup.number()
        .nullable()
        .required("Amount Disbursed is required")
        .moreThan(0, 'Amount Disbursed is required')
        .test('exceeds_invoice', 'Amount disbursed can not be greater than the invoice amount . ',  (value) => {
          const { invoice_amount } = state.fields;
          return value <= invoice_amount;
        })
        .max(
          state.factor_limit,
          "The amount disbursed cannot be larger than the factor amount available."
        ), 
    });
  }

  export const purchaseReviewSchema = (state:any) => {
    return Yup.object().shape({
      disbursement_date: Yup.string()
        .nullable()
        .required("Disbursement Date is required"),
      payment_terms: Yup.number()
        .min(30,"Payment terms cannot be less than 30 days")
        .nullable()
        .required("Payment Terms is required"),
      amount_disbursed: Yup.number()
        .nullable()
        .required("Amount Disbursed is required")
        .moreThan(0, 'Amount Disbursed is required')
        .test('exceeds_invoice', 'Amount disbursed can not be greater than the purchase order amount.',  (value) => {
          const { purchase_order_amount } = state.fields;
          return value <= purchase_order_amount;
        })
        .max(
          state.factor_limit4,
          "The amount disbursed cannot be larger than the factor amount available."
        ),
    });
  }

export const KycForm1Schema = Yup.object().shape({
  account_block_corporate_types_id: Yup.mixed()
    .nullable()
    .required("Corporate type is required"),
  //@ts-ignore
  industry_ids: Yup.mixed().nullable().required("Industry is required"),
  company_name: Yup.string()
    .nullable()
    .max(100, 'Maximum 100 characters allowed')
    .matches(/^[^\s].*[^\s]$/, "Name can't start or end with a space")
    .matches(/^[^\s]+(\s[^\s]+)*$/, 'Consecutive spaces are not allowed.')
    .required("Company name is required"),
  country_name: Yup.mixed().nullable().required("Country is required"),
  company_registration_number: Yup.string()
    .nullable()
    .required("Registration number is required")
    .matches(/^\S*$/, 'Spaces are not allowed')
    .max(30, 'Maximum 30 characters allowed'),
  postal_code: Yup.string()
    .nullable()
    .when("country_name", {
      is: "Jamaica",
      then: Yup.string()
      .min(6, 'Minimum 6 characters allowed')
      .max(9, 'Maximum 9 characters allowed')
      .matches(/^\S*$/, 'Spaces are not allowed')
      .required("Postal code is required"),
    }),
  zip_code: Yup.string()
    .nullable()
    .when("country_name", {
      is: "US",
      then: Yup.string()
      .min(5, 'Minimum 5 characters allowed')
      .max(9, 'Maximum 9 characters allowed')
      .matches(/^\S*$/, 'Spaces are not allowed')
      .matches(/^[A-Za-z0-9-]*$/, 'Should only contain Alphanumeric ')
      .required("Zip code is required"),
    }),
  tax_registration_no: Yup.string()
    .nullable()
    .when("country_name", {
      is: "Jamaica",
      then: Yup.string().required("TRN is required")
      .matches(/^[^\s]{9}$/, 'Must be exactly 9 characters with no spaces'),
    }),
  tax_identification_no: Yup.string()
    .nullable()
    .when("country_name", {
      is: "US",
      then: Yup.string().required("Tax identification number is required")
      .matches(/^[^\s]{9}$/, 'Must be exactly 9 characters with no spaces'),
    }),
  registered_business_address: Yup.string()
    .nullable()
    .required("Business address is required")
    .max(200, 'Maximum of 200 characters allowed')
    .matches(/^[^\s].*[^\s]$/, "Address can't start or end with a space"),
  operating_address: Yup.string()
    .nullable()
    .required("Actual address is required")
    .max(200, 'Maximum of 200 characters allowed')
    .matches(/^[^\s].*[^\s]$/, "Address can't start or end with a space"),
  new_phone_number: Yup.string()
    .nullable()
    .max(12, 'Maximum of 12 characters allowed')
    .matches(/^\S*$/, 'Spaces are not allowed')
    .when('country_name', {
      is: 'US',
      then: Yup.string()
        .matches(/^\+?\d*$/, "Only numeric characters allowed")
        .matches(/^\+1\d{10}$|\d{10}$/, 'Enter valid U.S. phone number ( e.g. +1XXXXXXXXXX or XXXXXXXXXX )')
    })
    .when('country_name', {
      is: 'Jamaica',
      then: Yup.string()
        .matches(/^\+?\d*$/, "Only numeric characters allowed")
        .matches(/^\+1876\d{7}$|876\d{7}$/, 'Enter valid Jamaican phone number ( e.g. +1876XXXXXXX or 876XXXXXXX )')
    })
    .required('Phone number is required'),
  email: Yup.string()
    .email("Invalid email address")
    .nullable()
    .required("Email is required")
    .max(254, 'Email must be 254 characters or less')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email address')
  .matches(/^[^\s]*$/, "Whitespace is not allowed in the email address"),
    primary_contact_name: Yup.string()
    .nullable()
    .max(70, "Maximum of 70 characters allowed")
    .matches(/^[A-Za-z0-9_\-"' ]*$/, 'Only A-Z, a-z, _, -, and quotes are allowed')
    .matches(/^[^\s].*[^\s]$/, "Name can't start or end with a space")
    .matches(/^[^\s]+(\s[^\s]+)*$/, 'Consecutive spaces are not allowed.')
    .required("Primary name is required"),
  primary_contact_email: Yup.string()
    .email("Invalid email address")
    .nullable()
    .required("Primary email is required")
    .max(254, 'Email must be 254 characters or less')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email address')
  .matches(/^[^\s]*$/, "Whitespace is not allowed in the email address"),
  primary_contact_telephone: Yup.string()
    .nullable()
    .max(12, 'Maximum of 12 characters allowed')
    .matches(/^\S*$/, 'Spaces are not allowed')
    .when('country_name', {
      is: 'US',
      then: Yup.string()
        .matches(/^\+?\d*$/, "Only numeric characters allowed")
        .matches(/^\+1\d{10}$|\d{10}$/, 'Enter valid U.S. telephone ( e.g. +1XXXXXXXXXX or XXXXXXXXXX )')
    })
    .when('country_name', {
      is: 'Jamaica',
      then: Yup.string()
        .matches(/^\+?\d*$/, "Only numeric characters allowed")
        .matches(/^\+1876\d{7}$|876\d{7}$/, 'Enter valid Jamaican telephone ( e.g. +1876XXXXXXX or 876XXXXXXX )')
    })
    .required("Telephone is required"),
  secondary_contact_name: Yup.string()
    .nullable()
    .max(50, "Maximum of 35/50 characters allowed")
    .matches(/^[A-Za-z0-9_\-"' ]*$/, 'Only A-Z, a-z, _, -, and quotes are allowed')
    .matches(/^[^\s].*[^\s]$/, "Name can't start or end with a space")
    .matches(/^[^\s]+(\s[^\s]+)*$/, 'Consecutive spaces are not allowed.'),
  secondary_contact_email: Yup.string()
    .email("Invalid email address")
    .max(254, 'Email must be 254 characters or less')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email address')
    .nullable(),
  secondary_contact_telephone: Yup.string()
    .nullable()
    .max(12, 'Maximum of 12 characters allowed')
    .matches(/^\S*$/, 'Spaces are not allowed')
    .when('country_name', {
      is: 'US',
      then: Yup.string()
        .matches(/^\+?\d*$/, "Only numeric characters allowed")
        .matches(/^\+1\d{10}$|\d{10}$/, 'Enter valid U.S. telephone ( e.g. +1XXXXXXXXXX or XXXXXXXXXX )')
    })
    .when('country_name', {
      is: 'Jamaica',
      then: Yup.string()
        .matches(/^\+?\d*$/, "Only numeric characters allowed")
        .matches(/^\+1876\d{7}$|876\d{7}$/, 'Enter valid Jamaican telephone ( e.g. +1876XXXXXXX or 876XXXXXXX )')
    }),
  average_annual_earnings: Yup.number()
    .nullable()
    .min(1, "Earning must be greater than 0")
    .max(maxAmountChar, 'Maximum of 16 numeric characters allowed')
    .required("Earning is required"),
  number_of_employees_full_time: Yup.number()
    .nullable()
    .integer('Number of employees must be an integer')
    .min(1, "Employee must be greater than 0")
    .max(maxEmployeeChar, 'Maximum of 9 numeric characters allowed')
    .typeError('Only numbers are allowed in this field')
    .required("This field is required"),
  number_of_employees_part_time: Yup.number()
    .nullable()
    .required("This field is required")
    .integer('Number of employees must be an integer')
    .min(1, "Employee must be greater than 0")
    .max(maxEmployeeChar, 'Maximum of 9 numeric characters allowed')
    .typeError('Only numbers are allowed in this field')
});

export const KycForm4Schema = Yup.object().shape({
  declaration: Yup.boolean()
    .oneOf([true], "You must agree to the terms and conditions.")
    .nullable()
    .required("You must agree to the terms and conditions."),
  declaration_name: Yup.string().nullable().required("Name is required")
    .max(70, "Maximum of 70 characters allowed")
    .matches(/^[A-Za-z0-9_\-"' ]*$/, 'Only A-Z, a-z, _, -, and quotes are allowed')
    .matches(/^[^\s].*[^\s]$/, "Name can't start or end with a space")
    .matches(/^[^\s]+(\s[^\s]+)*$/, 'Consecutive spaces are not allowed.'),
  declaration_date: Yup.string().nullable().required("Date is required"),
  digital_signature: Yup.string()
    .nullable()
    .required("Signature is required")
    .matches(/^[A-Z\s]+$/, "Signature must contain only capital letters.")
    .test('no-only-spaces', 'Signature contain atleast one capital letter.', (value) => {
      return /\S/.test(value);
    }),
});

export const KycForm2Schema = Yup.object().shape({
  bank_name: Yup.string()
  .nullable()
    .required("Bank name is required")
    .matches(/^[^\s].*[^\s]$/, "Bank name can't start or end with a space")
    .matches(/^[a-zA-Z\s]+$/, "Bank name can only contain letters and spaces"),
  bank_branch: Yup.string()
  .nullable()
    .required("Bank branch is required")
    .matches(/^[^\s].*[^\s]$/, "Branch can't start or end with a space")
    .matches(/^[a-zA-Z\s]+$/, "Bank branch can only contain letters and spaces"),
  bank_address: Yup.string()
  .nullable()
    .required("Bank address is required")
    .matches(/^[^\s].*[^\s]$/, "Name can't start or end with a space")
    .matches(/^[a-zA-Z0-9\s,-]+$/, "Bank address is not in a valid format"),
  business_account_name: Yup.string()
  .nullable()
    .required("Bank account name is required")
    .matches(/^[0-9a-zA-Z!@#$%^&*()_+{}\[\]:;<>,.?~\\| -]*[0-9a-zA-Z]+[0-9a-zA-Z!@#$%^&*()_+{}\[\]:;<>,.?~\\| -]*$/, "Bank account name can only contain letters and spaces"),
  business_account_number: Yup.string()
  .nullable()
    .required("Bank account number is required")
    .matches(/^\d+$/, "Bank account number can only contain numbers")
    .min(6, "Bank account number must be at least 6 digits")
    .max(15, "Bank account number cannot exceed 15 digits"),
  business_account_currency: Yup.string()
  .nullable()
    .required("Bank account currency is required")
    .oneOf(['USD', 'JMD', 'EUR'], "Invalid bank account currency"),
  business_account_type: Yup.string()
  .nullable()
    .required("Bank account type is required")
    .oneOf(["Savings", "Current", "Checking"], "Invalid bank account type"),
  tax_liabilities: Yup.boolean()
    .typeError("Tax liabilities must be selected")
    .nullable(),
  relevant_licenses: Yup.boolean()
  .typeError("Licenses current must be selected")
    .nullable(),
  list_current_liabilities: Yup.string()
  .nullable()
  .required('Current liabilities is required'),
  current_relevant_licenses: Yup.string()
  .nullable()
  .required('Current license is required')
})