import React from "react";

import {
  Grid,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";
import LeftLoginSection from "../../../components/src/LeftLoginSection.web";
import { styles } from "./EmailLoginStyles.web";
import PasswordStrengthWeb from "../../../components/src/PasswordStrength.web";
import { Formik } from "formik";
const pdfLogoIcon = require("../../../components/src/Assets/Login_Logo.svg");

class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { password, confirmPassword, snackbarSeverity , isSnackbarEnabled , tokenMessage} = this.state
    return (
      // Required for all blocks
      <Grid container style={{ height: '100%' }}>
        <Snackbar open={isSnackbarEnabled} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={snackbarSeverity}>
          {tokenMessage}
        </MuiAlert>
      </Snackbar>
        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.leftLoginSectionForgot}>
          {/* */}
          <LeftLoginSection />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.loginRightSectionForgot}>
          <Box className={classes.mobileLogoHolder}> <img src={pdfLogoIcon}/> </Box>
          <Box sx={{ maxWidth: "425px", width: "100%", minWidth: '280px' }}>
            <Typography variant="h4" className={classes.titleForgot}>
              Create Password
            </Typography>
            <Typography variant="body1" className={classes.subTitleForgot}>
              Reset your new password
            </Typography>
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={{ password: password, confirmPassword: confirmPassword }}
              validationSchema={this.ResetPasswordSchema}
              //
              onSubmit={() => this.doResetPassword()}
            >
              {({ errors, values, handleSubmit, setFieldValue }) => {
                return (<form onSubmit={handleSubmit}>
                  {/* */}
                  <Box>
                    <InputLabel className={classes.inputLabelForgot}>Password</InputLabel>
                    <TextField
                      data-test-id="txtInputPassword"
                      type={this.state.enablePasswordField ? "password" : "text"}
                      variant="outlined"
                      placeholder={this.state.placeHolderPassword}
                      value={values.password}
                      onChange={(e) => {
                        setFieldValue("password", e.target.value);
                        this.setState({ password: e.target.value });
                      }}

                      fullWidth
                      className={classes.inputFieldForgot}
                      required
                      error={Boolean(errors.password)}
                      helperText={errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              id='showButtonNewPassword'
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  {/* */}
                  <Box className={classes.errorsForgot}>
                    {/* */}
                    <PasswordStrengthWeb password={this.state.password} />
                  </Box>

                  {/* */}
                  <Box>
                    <InputLabel className={classes.inputLabelForgot}>Confirm Password</InputLabel>
                    <TextField
                      data-test-id="txtInputPassword"
                      type={this.state.enablePasswordField ? "password" : "text"}
                      variant="outlined"
                      placeholder={this.state.placeHolderPassword}
                      value={values.confirmPassword}
                      onChange={(e) => {
                        setFieldValue("confirmPassword", e.target.value);
                        this.setState({ confirmPassword: e.target.value });
                      }}
                      fullWidth
                      className={classes.inputFieldForgot}
                      required
                      error={Boolean(errors.confirmPassword)}
                      // helperText={errors.confirmPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              id='showButtonNewPassword'
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  {/* */}
                  <Box className={classes.errorMsgForgot} sx={{ marginTop: '30px' }} >{errors.confirmPassword} </Box>
                  {/* */}
                  <Box>
                    <Button
                      data-test-id={"btnEmailLogIn"}
                      variant="contained"
                      fullWidth
                      className={classes.loginBtnForgot}
                      type="submit"
                    >
                      Sign Up
                    </Button>
                  </Box>

                </form>
                )
              }}
            </Formik>
            {/* */}
            <Box
              data-test-id={"btnForgotPassword"}
              className={classes.endTextForgot}
            >
              Have an account? <span onClick={this.goToSignIn} >Sign In</span>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(NewPassword);
