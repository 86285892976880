import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Button } from "@material-ui/core";
import ActiveTabsController, {
    Props,
} from "./ActiveTabsController.web";
import { styles } from "./ActiveTabsStyles.web";
import ClientReport from "./ClientReport.web";
//@ts-ignore
import TwoDateSelector from "../../../components/src/CustomDateSelector/TwoDateSelector.web"
import ClientInvoice from "./ClientInvoice.web";

const screenProps = {
    id: "clientReports",
};

export class ActiveTabs extends ActiveTabsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const { isClientReportsSelected, isInvoiceSelected } = this.state;
        return (
            <Box sx={{ marginTop: "30px" }} >
                <Grid item xs={12}>
                    <Box sx={{ padding: "0 32px 32px", width: "calc(100vw - 285px)" }} className={classes.responsiveProvider1}>
                        <Box sx={{marginBottom:'28px' , fontWeight:700, fontSize:'18px'}}>Client Reports</Box>
                        <Grid container spacing={2} className={classes.navigationTabsBox}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Button
                                    data-test-id='invoiceNavigationButton'
                                    className={
                                        isInvoiceSelected
                                            ? classes.activeButton
                                            : classes.inActiveButton
                                    }
                                    onClick={() => this.handleInvoiceSelection()}
                                >
                                    Invoices
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Button
                                    data-test-id='clientReportNavigationButton1'
                                    className={
                                        isClientReportsSelected
                                            ? classes.activeButton
                                            : classes.inActiveButton
                                    }
                                    onClick={() => this.handleClientReportsSelection()}
                                >
                                    Clients
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {isInvoiceSelected &&  <ClientInvoice navigation={this.props.navigation} id="ClientInvoicePOReport" />}
                {isClientReportsSelected && <ClientReport navigation={this.props.navigation} {...screenProps} />}
            </Box>
        );
    }
}

export default withStyles(styles)(ActiveTabs);
