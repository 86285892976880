// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import * as Yup from "yup";
export const configJSON = require("./config");
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";

export interface Props {
  classes: any;
  navigation: any;
  id: string;
}

interface S {
  displayName: string;
  email: string;
  companyName: string;
  address: string;
  phoneNumber: string;
  primaryAccountHolderName: string;
  primaryAccountHolderEmail: string;
  secondaryAccountHolderName: string;
  secondaryAccountHolderEmail: string;
  industryOptions: string[];
  industry_ids: any;
  uniqueId: string;
  image: any;
  defaultImage: string;
  token: string | null;
  apiError: string;
}

interface SS {
  id: any;
}

export default class UserProfile extends BlockComponent<Props, S, SS> {
  UserProfileApi: string = "";
  getIndustryAPI: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      displayName: "",
      email: "",
      companyName: "",
      address: "",
      phoneNumber: "",
      primaryAccountHolderName: "",
      primaryAccountHolderEmail: "",
      secondaryAccountHolderName: "",
      secondaryAccountHolderEmail: "",
      industry_ids: [],
      uniqueId: "",
      defaultImage:
        "https://fastly.picsum.photos/id/44/4272/2848.jpg?hmac=a0rRK2VqTNYMvxqfQjFI65m4ZzMGnKRJzHvrJovjoQQ",
      image: {},
      industryOptions: [],
      token: "",
      apiError: ""
    };
  }

  async componentDidMount() {
    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else {
      const token = localStorage.getItem("auth_token");
      this.setState({ token: token });
      this.getDetails(token, "industries");
    }
  }

  validationSchema = Yup.object().shape({
    displayName: Yup.string().required("Display name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    companyName: Yup.string().required("Company name is required"),
    address: Yup.string().required("Address is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(/^\+?\d{1,2}?\(\d{3}\)\d{3}\-\d{4}$/, "Invalid phone number"),
    primaryAccountHolderName: Yup.string().required(
      "Primary account holder name is required"
    ),
    primaryAccountHolderEmail: Yup.string()
      .email("Invalid email address")
      .required("Primary account holder email is required"),
    secondaryAccountHolderName: Yup.string(),
    secondaryAccountHolderEmail: Yup.string().email("Invalid email address"),
    industry_ids: Yup.array().required("Industry is required"),
    uniqueId: Yup.string().required("Unique ID is required")

    // image: Yup.mixed().required('Image is required').test(
    //   "fileSize",
    //   "File size should not be more than 2MB",
    //   (value) => !value || value.size <= 2000000
    // ).test(
    //   "fileType",
    //   "Only jpeg, jpg, and png file formats are supported",
    //   (value) => !value || ['image/jpeg', 'image/png'].includes(value.type)
    // ),
  });

  setDisplayName = (text: string) => {
    this.setState({ displayName: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setCompanyName = (text: string) => {
    this.setState({ companyName: text });
  };

  setAddress = (text: string) => {
    this.setState({ address: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setPrimaryAccountHolderName = (text: string) => {
    this.setState({ primaryAccountHolderName: text });
  };

  setPrimaryAccountHolderEmail = (text: string) => {
    this.setState({ primaryAccountHolderEmail: text });
  };

  setSecondaryAccountHolderName = (text: string) => {
    this.setState({ secondaryAccountHolderName: text });
  };

  setsecondaryAccountHolderEmail = (text: string) => {
    this.setState({ secondaryAccountHolderEmail: text });
  };

  setUniqueId = (text: string) => {
    this.setState({ uniqueId: text });
  };

  setIndustryOptions = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ industry_ids: event.target.value });
  };

  receiveIndustryResponse = (response: any) => {
    const data = response.data.attributes;
    if (response.data) {
      this.setState({ industryOptions: response.data });
    }
    if (response.data && data) {
      if (
        data.account_block_industries &&
        data.account_block_industries.length
      ) {
        this.setState({
          industry_ids: data.account_block_industries.map((o: any) => o.id)
        });
      }
    }
  };

  handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    this.setState({
      image: file,
      defaultImage: URL.createObjectURL(event.target.files[0])
    });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.getResponse(apiRequestCallId, responseJson);
      }
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.UserProfileApi) {
      if (responseJson.data || responseJson.meta) {
        this.setState({ apiError: "" });
      } else {
        const errorMessages = responseJson.error || "";
        this.setState({ apiError: errorMessages });
        console.log("worked");
      }
    }
  };

  getResponse = (id: any, response: any) => {
    if (id === this.getIndustryAPI) {
      this.receiveIndustryResponse(response);
    }
  };

  handleSave = () => {
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token
    };

    const fd = new FormData();
    fd.append("user_name", this.state.displayName);
    fd.append("email", this.state.email);
    fd.append("company_name", this.state.companyName);
    fd.append("operating_address", this.state.address);
    fd.append("new_phone_number", this.state.phoneNumber);
    fd.append(
      "primary_account_holder_name",
      this.state.primaryAccountHolderName
    );
    fd.append(
      "primary_account_holder_email",
      this.state.primaryAccountHolderEmail
    );
    fd.append(
      "secondary_account_holder_name",
      this.state.secondaryAccountHolderName
    );
    fd.append(
      "secondary_account_holder_email",
      this.state.secondaryAccountHolderEmail
    );
    fd.append("unique_identifier", this.state.uniqueId);
    fd.append("industry_ids", this.state.industry_ids);
    fd.append("profile_photo", this.state.image);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UserProfileApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `update_profile_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), fd);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDetails = (token: any, endPoint: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (endPoint == "industries") {
      this.getIndustryAPI = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
