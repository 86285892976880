import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { isNegativeNumber } from '../Utils/utils';
import { returnHoverCurrencyFormat } from '../Utils/parseData';

interface AdminTopCardProps {
    classes: any;
   data:{
    title: string;
    amount: number;
    bottomTitle: string;
    percentage: string
    }
}

const styles: any = {
    root: {
        background: "#FFFFFF",
        border: "1px solid #EFF0F6",
        borderRadius: "10px",
        boxShadow: "none",
        "& .MuiCardContent-root": {
            padding: "14px 14px 18px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            "& .title": {
                fontSize: '12px',
                lineHeight: '20px',
                fontFamily: 'Plus Jakarta Sans',
                fontStyle: 'normal',
                margin: 0,
                fontWeight: 800,
                textTransform: 'capitalize',
                color: '#000000',
                marginBottom: "8px",
            },
            "& .amount": {
                fontSize: "24px",
                lineHeight: "30px",
                color: '#F3B229',
                margin: 0,
                fontFamily: 'Plus Jakarta Sans',
                fontStyle: 'normal',
                fontWeight: '800',
            },
            "& .btmContainer": {
                justifyContent: "space-between",
                display: "flex",
                alignItems: "flex-end",
                "& .bottomTitle": {
                    fontFamily: 'Plus Jakarta Sans',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '8px',
                    lineHeight: '10px',
                    textTransform: 'uppercase',
                    margin: 0,
                    color: '#202A45',
                    marginTop: "8px",
                },
                "& .percentage": {
                    marginTop: "-2px",
                    background: "rgba(2, 177, 90, 0.15)",
                    borderRadius: "10px",
                    color: "#02B15A",
                    fontWeight: 400,
                    fontSize: '12px',
                    fontFamily: 'Plus Jakarta Sans',
                    fontStyle: 'normal',
                    lineHeight: '15px',
                    padding: "4px 10px",
                    minWidth: "62px",
                    height: "23px",
                    marginLeft: "5px",
                    textAlign: 'center'
                },
                "& .negative":{
                    background: "rgba(235, 0, 27, 0.15)",
                    color: "#E41414"
                }
            }
        }
    }
};

class AdminTopCard extends React.Component<AdminTopCardProps> {
    render() {
        const { data, classes } = this.props;
        const isNegative = isNegativeNumber(data.percentage)
        return (
            <Card className={classes?.root}>
                <CardContent>
                    <p className='title'>{data?.title}</p>
                    <h5 className='amount'>
                        {returnHoverCurrencyFormat(data?.amount, "amount")}
                    </h5>
                    <div className='btmContainer'>
                        <p className='bottomTitle'>{data?.bottomTitle}</p>
                        {data?.percentage && <div className={`percentage ${isNegative ? 'negative' : ''}`}>{data?.percentage}</div>}
                    </div>
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(AdminTopCard);