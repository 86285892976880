import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import moment from 'moment';
import { handleVelocityDataFilteration, handleIndustryFilter, formatDate2 , getVelocityReportEndPoint , getVelocityReportCSVEndPoint } from "../../../components/src/Utils/utils"
import {shouldVelocityReportTableUpdate} from "../../../components/src/Utils/parseData"

export interface Props {
  classes: any;
  navigation: any;
}

type token = string | null

interface S {
  token: token;
  sectorOptionsResponse: any;
  sectorSelection: {
    id: string | number,
    name: string
  };
  mainPeriodStartDate: string;
  mainPeriodEndDate: string;
  comparisonPeriodStartDate: string;
  comparisonPeriodEndDate: string;
  sort_column: string;
  sort_direction: string;
  isMainPeriodFilterOpen: boolean;
  isComparisonPeriodFilterOpen: boolean;
  anchorEl: null | HTMLElement;
  isPopperOpen: boolean;
  isPopperOpen1: boolean;
  velocityData: any[]
  page: number,
  per_page: number,
  count: number,
  isLoading: boolean;
  VelocityExcelURL: string
}

interface SS {
  id: any;
}

export default class VelocityReportsController extends BlockComponent<Props, S, SS> {
  getClientSectorsAPI: string = "";
  getVelocityDataAPI: string = "";
  getVelocityFileURL: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      token: '',
      sectorOptionsResponse: [],
      sectorSelection: {
        id: '',
        name: ''
      },
      mainPeriodStartDate: moment().subtract(1, 'year').startOf('year').format('MMM DD, YYYY'),
      mainPeriodEndDate: moment().subtract(1, 'year').endOf('year').format('MMM DD, YYYY'),
      comparisonPeriodStartDate: moment().subtract(2, 'year').startOf('year').format('MMM DD, YYYY'),
      comparisonPeriodEndDate: moment().subtract(2, 'year').endOf('year').format('MMM DD, YYYY'),
      sort_column: '',
      sort_direction: '',
      isMainPeriodFilterOpen: false,
      isComparisonPeriodFilterOpen: false,
      anchorEl: null,
      isPopperOpen: false,
      isPopperOpen1: false,
      velocityData: [],
      page: 1,
      per_page: 10,
      count: 0,
      isLoading: false,
      VelocityExcelURL: ''
    };
  }

  async componentWillMount() {
    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else {
      const token = localStorage.getItem("auth_token");
      this.setState({ token: token, isLoading: true });
      const { mainPeriodStartDate, mainPeriodEndDate, comparisonPeriodStartDate, comparisonPeriodEndDate } = this.state
      this.getClientSectorsAPI = this.velocityApiCall(token, `industries`, "GET")
      const path = `start_date=${moment(mainPeriodStartDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}&end_date=${moment(mainPeriodEndDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}&comparison_start_date=${moment(comparisonPeriodStartDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}&comparison_end_date=${moment(comparisonPeriodEndDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}`
      this.getVelocityDataAPI = this.velocityApiCall(token, `get_velocity_report?${path}`, "GET")
      this.getVelocityFileURL = this.velocityApiCall(token, `velocity_report_excel?${path}`, "GET")
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const isUpdate = shouldVelocityReportTableUpdate(prevState, this.state)
    if (isUpdate) {
      const reportsEndoint = getVelocityReportEndPoint(this.state)
      const CSVEndpoint = getVelocityReportCSVEndPoint(this.state)
      this.getVelocityDataAPI = this.velocityApiCall(this.state.token, reportsEndoint , "GET")
      this.getVelocityFileURL = this.velocityApiCall(this.state.token, CSVEndpoint , "GET")
    }
  }

  clearVelocityFilters = () => {
    this.setState({
      sectorSelection: {
        id: '',
        name: ''
      },
      page: 1,
      comparisonPeriodStartDate: moment().subtract(2, 'year').startOf('year').format('MMM DD, YYYY'),
      per_page: 9,
      comparisonPeriodEndDate: moment().subtract(2, 'year').endOf('year').format('MMM DD, YYYY'),
      sort_column: '',
      mainPeriodEndDate: moment().subtract(1, 'year').endOf('year').format('MMM DD, YYYY'),
      sort_direction: '',
      mainPeriodStartDate: moment().subtract(1, 'year').startOf('year').format('MMM DD, YYYY'),
    }, () => {
      const { token, mainPeriodEndDate, mainPeriodStartDate, comparisonPeriodStartDate, comparisonPeriodEndDate,  } = this.state;
      const path4 = `start_date=${moment(mainPeriodStartDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}&end_date=${moment(mainPeriodEndDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}&comparison_start_date=${moment(comparisonPeriodStartDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}&comparison_end_date=${moment(comparisonPeriodEndDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}`
      this.getVelocityDataAPI = this.velocityApiCall(token, `get_velocity_report?${path4}`, "GET")
    })
  }

  handleMainPeriodFilter = (startDate: string, endDate: string) => {
    this.setState({
      mainPeriodStartDate: formatDate2(startDate),
      mainPeriodEndDate: formatDate2(endDate)
    })
    this.handleClose()
  }

  handleComparisonPeriodFilter = (startDate: string, endDate: string) => {
    this.setState({
      comparisonPeriodStartDate: formatDate2(startDate),
      comparisonPeriodEndDate: formatDate2(endDate)
    })
    this.handleClose()
  }

  handleMainPeriodFilterCall = (event: any) => {
    this.setState({
      anchorEl: event?.currentTarget,
      isMainPeriodFilterOpen: true,
      isComparisonPeriodFilterOpen: false,
      isPopperOpen: true,
      isPopperOpen1: false
    })
  }

  handleComparisonPeriodFilterCall = (event: any) => {
    this.setState({
      isPopperOpen1: true,
      isComparisonPeriodFilterOpen: true,
      isPopperOpen: false,
      isMainPeriodFilterOpen: false,
      anchorEl: event?.currentTarget
    })
  }

  handleClose = () => {
    this.setState({
      isMainPeriodFilterOpen: false,
      isComparisonPeriodFilterOpen: false
    })
  }

  handleSort = (columnName: string, direction: string) => {
    this.setState({
      sort_column: columnName,
      sort_direction: direction,
    });
  };

  handleSector = (event: any) => {
    const customOption = this.state.sectorOptionsResponse.filter(
      (option: any) => option.id == event.target.value
    );
    if (customOption?.length > 0) {
      this.setState({ sectorSelection: customOption?.[0] });
    }
  };

  handleSectorAPICall = (response: any) => {
    const options = handleIndustryFilter(response);
    this.setState({
      sectorOptionsResponse: options
    })
  }

  handleVelocityDataResponse = (response: any) => {
    const data = response;
    const filteredData = handleVelocityDataFilteration(data)
    this.setState({
      velocityData: filteredData,
      count: Math.ceil(data.total_count / this.state.per_page || 1),
      isLoading: false
    })
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  handleChangePage = (event: any, newPage: any) => {
    window.scrollTo(0, 0);
    this.setState({
      page: newPage,
    });
  };

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getVelocityFileURL) {
      responseJson?.url && this.setState({ VelocityExcelURL: responseJson.url });
    }
    if (apiRequestCallId === this.getClientSectorsAPI) {
      this.handleSectorAPICall(responseJson)
    }
    if (apiRequestCallId === this.getVelocityDataAPI) {
      this.handleVelocityDataResponse(responseJson)
    }
  }

  velocityApiCall = (token: token, endPoint: string, method: string, body?: any) => {
    const header = {
      token: token,
      "Content-Type": "application/json",
    };

    const requestAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
    );


    runEngine.sendMessage(requestAPIMessage.id, requestAPIMessage);
    return requestAPIMessage.messageId
  }
}


