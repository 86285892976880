import { MAIN_DIV_WIDTH } from "../../../components/src/Utils/constant";

export const styles: any = {
  inputField4: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        letterSpacing: "-0.24px",
        fontWeight: 700,
        color: "#2E2A40",
        lineHeight: "15px",
        padding: "18px 12px 17px",
        fontSize: "12px",
      },
      "& .MuiInputBase-inputMultiline": {
        padding: 0,
      },
    },
    margin: 0,
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
    marginTop: "12px",
  },
  inputLabel4: {
    color: "#202A45",
    fontSize: "12px",
    lineHeight: "15.12px",
    fontWeight: 500,
    marginTop: "4px",
    letterSpacing: "-0.24px",
  },
  changeRequestDiv: {
    padding: "0 50px 50px",
    gap: "28px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width:MAIN_DIV_WIDTH,
    flexGrow: 1,
    "& .subTitleContainer4": {
      marginTop: "15px",
      "& .title4": {
        fontStyle: "normal",
        fontWeight: 700,
        fontFamily: "'Plus Jakarta Sans'",
        color: "#2E2A40",
        fontSize: "18px",
        lineHeight: "23px",
        letterSpacing: "-0.48px",
      },
      "& .date4": {
        float: "left",
        letterSpacing: "-0.24px",
        color: "#757575",
        lineHeight: "13px",
        textAlign: "center",
        fontFamily: "'Plus Jakarta Sans'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        marginTop: "4px",
      },
    },
    "& .maintitle4": {
      marginTop: "3px",
      fontFamily: "'Plus Jakarta Sans'",
      lineHeight: "30px",
      letterSpacing: "-0.48px",
      color: "#2E2A40",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "24px",
    },
    "& .collapseContainer4": {
      display: "flex",
      gap: "20px",
      width: "100%",
      "& .collapseTitle4": {
        fontStyle: "normal",
        color: "#202A45",
        letterSpacing: "-0.24px",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "18px",
        fontFamily: "'Plus Jakarta Sans'",
      },
      flexDirection: "column",
      justifyContent: "space-between",
      "& .collapse4": {
        flexDirection: "row",
        alignItems: "center",
        columnGap: "50px",
        display: "flex",
        justifyContent: "flex-start",
        "& p": {
          "& img": {
            paddingRight: "8px",
          },
          display: "flex",
          width: "250px",
          alignItems: "center",
          lineHeight: "16px",
          opacity: 0.8,
          margin: 0,
          fontFeatureSettings: "'pnum' on, 'lnum' on",
          color: "#2E2A40",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "12px",
          '@media (max-width: 600px)': {
              width: "auto",
          },
          '@media (min-width: 601px) and (max-width: 960px)': {
            width: "auto",
          },
        },
        "& .dwnContainer4": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "24px",
          "& .reviewedBtn4": {
            "& .MuiButton-label": {
              color: "white !important",
            },
            background: "#20BB6E !important",
          },
          "& .btn4": {
            width: "94px",
            background: "white",
            borderRadius: "4px",
            border: "1px solid rgba(0, 0, 0, 0.08)",
            fontFamily: "'Plus Jakarta Sans'",
            height: "27px",
            "& .MuiButton-label": {
              fontStyle: "normal",
              textAlign: "center",
              color: "#979797",
              textTransform: "capitalize",
              padding: "10px 28px",
              fontFamily: "'Plus Jakarta Sans'",
              fontWeight: 700,
              fontSize: "10px",
              lineHeight: "13px",
            },
          },
          "& img": {
            height: "20px",
            width: "20px",
          },
        },
        '@media (max-width: 600px)': {
          columnGap:'20px',
          justifyContent: 'space-between',
        },
        '@media (min-width: 601px) and (max-width: 960px)': {
          columnGap:'20px'
        },
      },
    },
    
  },
  btnContainerLeft4: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& .leftbtn4": {
      background: "#E5B632",
      borderRadius: "4px",
      fontFamily: "'Plus Jakarta Sans'",
      height: "42px",
      "& .MuiButton-label": {
        textTransform: "capitalize",
        fontSize: "16px",
        lineHeight: "20px",
        fontFamily: "'Plus Jakarta Sans'",
        letterSpacing: "-0.32px",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: 600,
        color: "#FFFFFF",
        marginTop: "-2px",
      },
    },
    gap: "30px",
    flexDirection: "row",
    "& .approveBtn14": {
      marginLeft: "20px",
      background: "#20BB6E",
    },
  },
  '@media (max-width: 600px)': {
    changeRequestDiv:{
      width: "100%",
      marginTop:'15px'
    }
  },
};
