// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Utils/utils";
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface PastOption {
  name: string;
  id: number | string | null;
}

interface S {
  body: any,
  imgUrl: any,
  title: string | null,
  newsDate: string | null,
  isLoadNews: boolean,
  newsToken: string | null,
}

interface SS {
  id: any;
}

export default class NewsClientDetailsController extends BlockComponent<Props, S, SS> {
  getNewsDetailsAPI: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      body: "",
      imgUrl: "",
      title: "",
      isLoadNews: false,
      newsToken: null,
      newsDate: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const newsToken = localStorage.getItem("auth_token");
    const id = this.props.navigation.getParam("id");
    if (newsToken) {
      this.setState({ newsToken: newsToken, isLoadNews: true });
      this.getNewsDetailsAPI = apiCall(newsToken, `bx_block_dashboard/news/${id}`, "GET");
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiCallId && resJson) {
        if (apiCallId === this.getNewsDetailsAPI){
          this.getNewsResponse(resJson);
        }
      } else {
        this.setState({isLoadNews: false})
      }
    }
  }

  getNewsResponse = (res:any) => {
    if(res.data?.attributes) {
      const { body, title, images, } = res.data.attributes
      this.setState({
        body: body,
        isLoadNews: false,
        imgUrl: images[0]?.url,
        title: title,
        newsDate: moment().format('MMM DD, YYYY')
      })
    } else {
      this.props.navigation.navigate("CarbyneNews");
    }
  }
}
// Customizable Area End
