import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Button ,Tooltip } from "@material-ui/core";
import ClientDashboardController, {
  Props,
} from "./ClientDashboardController.web";
import { styles } from "./ClientDashboardStyles.web";
import {stripHtmlTags} from "../../../components/src/Utils/utils"
import ClientDashboardFactorChart from "../../../components/src/Charts/ClientDashboardFactotChart.web"
import { returnHoverCurrencyFormat } from "../../../components/src/Utils/parseData";

export class ClientDashboard extends ClientDashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { TotalFactorAmmountRender , currentNews  } = this.state;
    return (
      <Grid container
        style={{
          marginTop: "50px"
        }}
      >
        <Grid item>
          <Box
            sx={{
              padding: "0 0 32px",
            }}
            className={classes.DashboardContainerDiv}
          >
            <Box className={classes.AnalyticsBar}>
              <Grid container className={classes.cardHolder} spacing={2}>
                {this.state.cardData.map((item: any) => (
                  <Grid item xs={12} sm={12} md={4} key={item.id}>
                    <div className={classes.MainCardDiv}>
                      <span className={classes.cardTitle}>
                        <b>{item.Title}</b>
                      </span>
                      <span className={`${classes.cardDetails} ${item.is_paid ? `${classes.creditPaymentClass}` : ""}`}>
                      {returnHoverCurrencyFormat(item.Detail, "bold" ,item.is_paid)}
                      </span>
                      <span className={classes.cardDue}>{item.dues}</span>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <div className={classes.gapProvider} />
            <Grid container spacing={4} className={classes.ChartAndNewsOverview}>
              <Grid item xs={12} sm={12} md={6} className={classes.maxHeightProvider}>
                <Box className={classes.chartHolder}>
                  <div className={classes.factorHolder}>Factor Limit</div>
                  <div className={classes.mainChartDiv}>
                    {this.state.chartData.length > 0 &&
                    <>
                    <div className={classes.chart}>
                    <ClientDashboardFactorChart
                      chartData={this.state.chartData}
                      TotalFactorAmmountRender={TotalFactorAmmountRender}
                    />
                    </div>
                    <Box className={classes.chartData}>
                      <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        sx={{ padding: "10px" }}
                      >
                        <Box className={classes.chartLabelData} >
                            <Box
                              className="box"
                              style={{
                                backgroundColor: this.state.chartData[1].color,
                              }}
                            />
                            <span className="value">Factor Limit</span>
                            <div className="labelValue">
                              {returnHoverCurrencyFormat(TotalFactorAmmountRender, "value")}
                            </div>
                        </Box>
                        {this.state.chartData.map((data, index:number) => (
                          <Box
                            className={classes.chartLabelData}
                            key={`id-${index*2}-i`}
                          >
                            <Box style={{ backgroundColor: data.color }} className="box"/>
                            <span className="value">{data.name}</span>
                            <div className="labelValue">
                              {returnHoverCurrencyFormat(data.value, "value")}
                            </div>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    </>
                    }
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className={classes.maxHeightProvider}>
                <Box className={classes.newsHolder}>
                  <span className={classes.boxLable}> Latest News </span>
                  <div className={classes.newsContainer}>
                    {currentNews?.map(news => {
                      return (
                        <>
                          <Box
                            key={news?.id}
                            className={classes.singleNewsContainer}
                          >
                            <div className={classes.singleNewsTitleDiv}>
                              <div className={classes.titleHolderDiv}>
                                <div style={{ height: '40px', width: '40px' , minWidth: '40px' }}>
                                  <img src={news?.titleImage?.url} alt="" style={{ objectFit: 'cover', width: '100%', borderRadius: '2px' , height:'100%'}} />
                                </div>
                                <Tooltip title={news?.title} arrow classes={{ tooltip: classes.tooltip }} placement={"top"} disableFocusListener disableTouchListener>
                                  <div data-test-id="redirectionCall" onClick={()=>{this.handleSingleNewsRedirection(news?.id)}} className="singleNewsTitle">
                                    {news?.title}
                                  </div>
                                </Tooltip>
                              </div>
                              <span className="singleNewsDate">{news?.createdDate}</span>
                            </div>

                            <div className={classes.singleNewsDescriptionDiv}>
                              <span className="singleNewsDescription">
                                {stripHtmlTags(news?.body)}
                              </span>
                            </div>

                            <div className={classes.singleNewsAutherDiv}>
                              <span className="singleNewsAutherName">
                                <Button data-test-id="single-news-redirection" onClick={()=>{this.handleSingleNewsRedirection(news?.id)}}>Read More</Button>
                              </span>
                            </div>
                          </Box>
                          <div className={classes.borderBottomProvider} />
                        </>
                      );
                    })}
                  </div>
                  <div className={classes.buttonEffect} style={{ width: "100%" }}>
                    <Button className={classes.viewAllButton} data-test-id="news-redirection" onClick={()=>this.handleNewsRedirection()}>View All</Button>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ClientDashboard);
