import React, { Component } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles.web";

interface SearchProps {
  count: number;
  page: number;
  onPaginationChange: any;
  classes: any;
  msg?: any
}

class CustomPagination extends Component<SearchProps> {
  constructor(props: SearchProps) {
    super(props);
  }

  render() {
    const { classes, count, page, onPaginationChange, msg} = this.props;

    return (
      <div className={classes?.container}>
        <p>{msg ? msg : "Showing 1 to 9 list"} </p>
        <Pagination
          classes={{ root:classes?.pagination}}
          count={count ? count :1}
          page={page}
          onChange={onPaginationChange}
          variant="outlined" 
          shape="rounded"
          disabled={!count}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CustomPagination);
