export const styles: any = {
  widthProvider:{
    width:'calc(100vw - 285px)'
  },
  rootContainer: {
    marginTop: '5px',
  },
  headerCell: {
    backgroundColor: '#F6EACC',
  },
  clientNameCell: {
    width: '95%'
  },
  headerText: {
    fontSize: '18px',
    fontWeight: 800,
    marginBottom: '15px',
    fontFamily: 'Plus Jakarta Sans'
  },
  companyName: {
    fontSize: '12px',
    fontWeight: 800,
    marginBottom: '15px',
    fontFamily: 'Plus Jakarta Sans'
  },
  dateFieldHolder: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '20px'
  },
  agreementDateLable: {
    color: 'black',
    fontSize: '12px',
    fontWeight: 500
  },
  agreementDateField: {
    maxWidth: '540px',
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        letterSpacing: "-0.24px",
        fontWeight: 700,
        color: "#2E2A40",
        lineHeight: "15px",
        padding: "18px 12px 17px",
        fontSize: "12px",
      },
      "& .MuiInputBase-inputMultiline": {
        padding: 0,
      },
    },
    margin: 0,
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
    marginTop: "12px",
  },
  documents:{
    display:"flex",
    flexDirection: "column",
    gap: "40px"
  },
  dropAreaHolder: {
    display: "flex",
    flexDirection: "column",
    gap:"20px",
    "& .MuiDropzoneArea-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "& .MuiDropzoneArea-icon": {
      display: "none "
    },
    "& .MuiGrid-root.MuiDropzonePreviewList-root.MuiGrid-container.MuiGrid-spacing-xs-8": {
      display: 'none'
    },
    marginBottom: "20px",
  },
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px dashed grey',
    borderRadius: '12px',
    padding: '60px',
    height: '158px',
    width: '100%',
  },
  dropzoneBrowser: {
    backgroundColor: '#E5B632 !important',
    color: '#fff !important',
    fontFamily: 'Plus Jakarta Sans !important',
    fontSize: '12px !important',
    fontWeight: '600 !important',
    lineHeight: '15px ',
    letterSpacing: '-0.23999999463558197px ',
    textAlign: 'center !important',
    height: '15px',
    width: '38px',
    padding: '15px 50px !important',
    textTransform: 'capitalize !important'
  },
  documentTable: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  documentViewTable: {
    '& .MuiTableRow-root': {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    '& .MuiTableCell-root': {
      border: 'none'
    },

  },
  documentUploadLable: {
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: 'Plus Jakarta Sans',
  },
  documentUploadDetails: {
    fontSize: '12px',
    fontWeight: 300,
    fontFamily: 'Plus Jakarta Sans',
  },
  saveButtonHolder: {
    height: '20%',
    padding: "40px 0"
  },
  submissionButton: {
    color: "#fff",
    backgroundColor: "#e5b632",
    margin: "23px 0 0",
    padding: "10.5px 16px",
    boxShadow: "none",
    fontWeight: 600,
    textTransform: "capitalize",
    width: "320px",
    height: "60px",
    float: "right",
  },
  widthProviderCell1: {
    width: '45%'
  },
  widthProviderCell2: {
    width: '20%'
  },
  widthProviderCell3: {
    width: '20%'
  },
  iconImageContainer:{
    float: 'right',
    display: 'flex',
    gap: '10px',
    '& img': {
      cursor: 'pointer',
    },
    'img': {
      cursor: 'pointer',
    }
  },
  '@media (max-width: 600px)': {
    widthProvider:{
      width:'100%',
      marginTop:'25px'
    },
  },
}