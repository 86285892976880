import React from "react";
import {
  InputLabel,
  Button,
  // Customizable Area Start
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Box
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";

import tStyles from "./ValueChainEvaluationStyles.web";

import CustomTooltip from "../../../components/src/Tooltip/CustomTooltip.web";
import { evaluationColumns, removeTextAndSpecialChars } from "../../../components/src/Utils/dummyData";
import CommonModalWeb from "../../../components/src/Modal/CommonModal.web";
import { handleFormikErrors, handleFormikHelperText, onKeyDown } from "../../../components/src/Utils/utils";
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";
import NumberFormatCustom from "../../../components/src/CustomTextField/NumberFormatCustom"
// Customizable Area End

import ValueChainEvaluationController, {
  Props,
} from "./ValueChainEvaluationController.web";

class ValueChainEvaluation extends ValueChainEvaluationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTD(col: any, row: any) {
    const subState =
      row?.criteria && row.criteria.toLowerCase().replace(" ", "_");
    const rankValue = this.state?.object[subState]?.ranking ?? row.ranking
    if (col.isEdit && col.render === "ranking") {
      return (
        <TextField
          name={subState}
          type="number"
          value={rankValue}
          onChange={(e) => {
            this.handleRankingChange(e, row.weighting, row.id, row?.updateId);
          }}
          className="rankingInputField"
          variant="outlined"
          InputProps={{
            inputProps: {
              min: 1,
              max: 11,
              step: 0.01,
              style: { MozAppearance: "textfield" },
            }
          }}
          required
        />
      );
    } else if (col.render === "score") {
      return this.state?.object[subState]?.score ?? row.score;
    } else {
      return row[col.render];
    }
  }

  handleKeyDown = (e: any, commValue: any) => {
    const inputValue = e.target.value;
    const keyCode = e.keyCode || e.which;
    if (
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      keyCode === 190 || keyCode === 110
    ) {
      if (inputValue !== commValue && inputValue.includes(".")) {
        const decimalPart = inputValue.split(".")[1];
        if (decimalPart && decimalPart.length >= 2) {
          e.preventDefault();
        }
      }
    }
  };
  // Customizable Area Ends

  render() {
    const { classes } = this.props;
    const { client_name, data, saveOpen, commission, factor_limit, isValueLoading, manualCommission, totalScore } = this.state;
    return (
      <div className={classes.mainDiv}>
        {isValueLoading && <CustomLoader open={isValueLoading} />}
        <Grid className="title">
          <h4>{client_name}</h4>
          <p className={classes.commonTitle}>
            - Value chain evaluation criteria score
          </p>
        </Grid>
        <Formik
          enableReinitialize={true}
          validateOnBlur={true}
          initialValues={{ manualCommission: manualCommission, factor_limit: factor_limit }}
          validationSchema={this.validationValueSchema}
          onSubmit={(values) => this.saveData(values)}
        >
          {({ errors, values, handleSubmit, handleChange, touched }) => {
            const commValue = removeTextAndSpecialChars(values.manualCommission);
            return (
              <form onSubmit={handleSubmit} onKeyDown={onKeyDown} className="formDiv">
                <Grid container direction="row" className={classes.extraFlexProvider}>
                  <Grid item className="commision" style={{ marginRight: "30px", flexWrap: 'wrap' }} >
                    <InputLabel className={classes.commonTitle}>Commission*</InputLabel>
                    <Box style={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
                      <TextField
                        name="manualCommission"
                        value={commValue}
                        onChange={(e) => { handleChange(e) }}
                        onKeyDown={(e) => { this.handleKeyDown(e, commValue) }}
                        type="number"
                        variant="outlined"
                        error={handleFormikErrors(errors.manualCommission, touched.manualCommission)}
                        helperText={handleFormikHelperText(errors.manualCommission, touched.manualCommission)}
                        className={classes.inputField}
                        InputProps={{
                          endAdornment: "%",
                          inputProps: {
                            style: {
                              MozAppearance: "textfield",
                              textAlign: "center"
                            }
                          }
                        }}
                      />
                      <CustomTooltip tooltipText="You can manually update the commission figure here. This value will be used in all Client data calculations and will override any indicative commission values." />
                    </Box>
                  </Grid>
                  <Grid item className="commision" style={{ flexWrap: 'wrap' }}>
                    <InputLabel className={classes.commonTitle}>Factor Limit*</InputLabel>
                    <Box style={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
                      <TextField
                        name="factor_limit"
                        value={values.factor_limit}
                        onChange={(e) => { handleChange(e) }}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                          inputProps: {
                            style: {
                              MozAppearance: "textfield",
                            }
                          }
                        }}
                        error={handleFormikErrors(errors.factor_limit, touched.factor_limit)}
                        helperText={handleFormikHelperText(errors.factor_limit, touched.factor_limit)}
                        variant="outlined"
                        className={classes.inputField}
                      />
                      <CustomTooltip tooltipText="You can manually update the Factor Limit here." />
                    </Box>
                  </Grid>
                </Grid>
                <TableContainer component={Paper} className="tableContainer">
                  <Table>
                    <TableHead>
                      <TableRow>
                        {evaluationColumns.map((col, cellIndex: number) => {
                          return (
                            <TableCell
                              key={col.id}
                              style={{
                                width: col.width ? col.width : "auto",
                              }}
                            >
                              {cellIndex > 0 && <div className="verticalLine"></div>}
                              {col.label}{" "}
                              {col.tooltip ? (
                                <CustomTooltip
                                  tooltipText={col.tooltip}
                                  height="17px"
                                  width="17px"
                                />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((row: any) => (
                        <TableRow key={row.id}>
                          {evaluationColumns.map((col: any) => {
                            const align =
                              col.render === "indicator" || col.render === "criteria";
                            return (
                              <TableCell
                                key={`${row.id}-${col.id}`}
                                style={{
                                  textAlign: align ? "start" : "center",
                                  width: col.width ? col.width : "auto",
                                }}
                              >
                                {this.renderTD(col, row)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '25px', flexWrap: 'wrap' }}>
                  <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', gap: '18px' }}>
                    <InputLabel className={classes.commonTitle}>
                      Indicate Values
                    </InputLabel>
                    <CustomTooltip tooltipText="These values are indicative only. The true value for the commission can be manually inputted at the top of the page." />
                  </Box>
                  <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '18px', flexWrap: 'wrap' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                      <div className={classes.textBtn}>{commission !== "TBD" && commission !== null ? this.convertIntoFloat(commission) : commission}</div>
                      <div className="bottomLabel">Commission</div>
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                      <div className={classes.textBtn}>{totalScore}</div>
                      <div className="bottomLabel">Score Total</div>
                    </Box>
                  </Box>
                </Box>
                <Grid style={{ width: "100%" }}>
                  <Button
                    data-test-id={"btnSubmit1"}
                    variant="contained"
                    className={classes.saveBtn}
                    onClick={() => window.scrollTo(0, 0)}
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
                <CommonModalWeb
                  title={"Changes have been saved."}
                  description="Please upload new factoring agreement."
                  open={saveOpen}
                  btnText="Continue"
                  handleModelClose={this.handleCloseModal}
                  handleSubmitModal={this.handleSubmitModal}
                  widthToAdd="85%"
                />
              </form>
            )
          }
          }
        </Formik>
      </div>
    );
  }
}

export default withStyles(tStyles)(ValueChainEvaluation);
