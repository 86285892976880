// Customizable Area Start
import React from "react";
import {
  Hidden,
  Typography,
  CssBaseline,
  Drawer,
  Button,
  Modal,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";

import { styles } from "./SideNavigationMenuStyles.web";
import { navigationLogo } from "./assets";
import SideNavigationMenuController, {
  Props,
} from "./SideNavigationMenuController.web";
import TopNav from "./TopNav.web";
import {
  clientMenus,
  adminMenus,
  analystMenus,
  userRoles,
  urlCondition,
  clientUrlCondition
} from "../../../components/src/Utils/constant";
import {
  pathChecker,
  clientPathChecker
} from "../../../components/src/Utils/utils";

const container = window !== undefined ? () => window.document.body : undefined;
const configJSON = require("./config");


class SideNavigationMenu extends SideNavigationMenuController {
  constructor(props: Props) {
    super(props);
  }

  list = (role: any) => {
    const activeData = adminMenus.map(data => ({
      id : data.id,
      label : data.label,
      link : pathChecker(window.location.pathname)? urlCondition(data) : data.link
    }))
    
    const ClientData = clientMenus.map(data => ({
      id : data.id,
      label : data.label,
      link : clientPathChecker(window.location.pathname)? clientUrlCondition(data) : data.link
    }))

    let menus;
    if (role === userRoles.client) {
      menus = ClientData;
    } else if (role === userRoles.admin) {
      menus = activeData;
    } else if (role === userRoles.analyst) {
      menus = analystMenus;
    }
    
    const currentPath = window.location.pathname.toUpperCase();
    return (
      <div className={"rootDiv"}>
        <div className={"logoDiv"}>
          <img src={navigationLogo} className={"logoImg"} />
          <p className={"logoText"}>
            Carbyne Capital <br />
            Investments Limited
          </p>
        </div>
        {!configJSON.ekycPath.includes(currentPath) ? (
          <div className="menuContainer">
            <div className="menuGroup">
              {menus?.map((menu: any) => {
                return (
                  <NavLink
                    exact
                    to={menu.link}
                    className='menu'
                    activeClassName="activeMenu"
                    key={menu.id}
                  >
                    <p className="menuText">{menu.label}</p>
                  </NavLink>
                );
              })}
            </div>
            <Button
              className="logoutBtn"
              onClick={() => this.setState({ isOpen: true })}
            >
              Logout
            </Button>
          </div>
        ) : (
          <div className={currentPath === "/EKYC1" ? "bottomDiv" : ""}>
            {currentPath === "/EKYC1" ? (
              <>
                <p className={"bottomTitle"}>Let's begin!</p>
                <p className={"bottomBody"}>
                  Please provide your <br /> details in the following <br />
                  pages...
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const currentPath = window.location.pathname.toUpperCase();
    return (
      <>
        <div className={classes.root}>
          <CssBaseline />
          {!configJSON.ekycPath.includes(currentPath) ? (
            <TopNav
              role={this.props.role}
              id="topNavbar"
              navigation={this.props.navigation}
              handleDrawerToggle={this.handleDrawerToggle}
              isBack={this.props.isBack}
            />
          ) : (
            <></>
          )}
          <nav className={classes.drawer} aria-label="mailbox folders" style={{ zIndex: 10}}>
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor="left"
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.barPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {this.list(this.props.role)}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.barPaper,
                }}
                variant="permanent"
                open
              >
                {this.list(this.props.role)}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {this.props.children}
          </main>
        </div>
        <Modal
          classes={{ root: classes.MuiModalRoot }}
          open={this.state.isOpen}
          onClose={this.handleClose}
        >
          <div>
            <div className={classes.modalBox}>
              <Typography>Are you sure you want to log out?</Typography>
              <div className={classes.logoutButtonsHolder}>
                <Button
                  className={`${classes.doneButton}`}
                  onClick={this.handleModelClose}
                >
                  Yes
                </Button>
                <Button
                  className={`${classes.doneButton} ${classes.noBtn}`}
                  id="noBtn"
                  onClick={this.handleClose}
                >
                  No
                </Button>
              </div>
            </div>
            <div className={classes.modalOverlay} />
          </div>
        </Modal>
      </>
    );
  }
}

export default withStyles(styles)(SideNavigationMenu);
// Customizable Area End