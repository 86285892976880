import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { FileUploadValidationSchema } from "../../../components/src/Utils/../../../components/src/Utils/validations"
import { SubmitInvoiceFields, PurchaseOrderFields, userRoles } from "../../../components/src/Utils/constant"
import { getUserRole, refinedClientData } from "../../../components/src/Utils/utils";
import { toast } from "react-toastify";
import { handleAPIErrorMessage } from "../../../components/src/Utils/parseData";
import { getStorageData } from "../../../framework/src/Utilities";
import { FormikErrors, FormikTouched } from "formik";

export type arrayUploadedFile = {
  id: number;
  name: string;
  url: string;
  size: string;
  date: string;
  time: string;
};

export type FormDataObject = {
  [key: string]: any;
};

export type arrayOfFileToShow = {
  id: number;
  file: File;
};

export type token = string | null;

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  token: token;
  isOpen: boolean;
  uploadedFile: arrayUploadedFile[];
  FileDetails: arrayOfFileToShow[];
  snackbar: any;
  isModalOpen: boolean
  isPurchaseOrderActive: boolean,
  isSubmitInvoiceActive: boolean,
  formDataObject: FormDataObject,
  searchedClients: any[],
  selectedClient: { id: string | number, companyName: string };
  isClientSearchLoaded: boolean;
  clientAccountId : any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


export default class InvoiceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  submitInvoiceApiCall: string = '';
  productOrderApiCall: string = '';
  clientsSearchAPI: string = '';
  debounceInvoiceTimeout : any = null
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      uploadedFile: [],
      isLoading: false,
      FileDetails: [],
      isOpen: false,
      isSubmitInvoiceActive: true,
      isPurchaseOrderActive: false,
      token: '',
      isModalOpen: false,
      snackbar: {
        open: false,
        message: "",
        severity: "",
      },
      formDataObject: {},
      searchedClients: [],
      selectedClient: {id:0, companyName:''},
      isClientSearchLoaded: false,
      clientAccountId: null
      // Customizable Area Start
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else {
      let fileArray: any[] = []
      const token = localStorage.getItem("auth_token");
      this.setState({ token: token });
      const Url = window?.location?.pathname || ''
      if (Url.includes('submit-invoice')) {
        fileArray = SubmitInvoiceFields
        this.setState({
          isPurchaseOrderActive: false,
          isSubmitInvoiceActive: true
        });
      }
      if (Url.includes('purchase-order')) {
        fileArray = PurchaseOrderFields
        this.setState({
          isPurchaseOrderActive: true,
          isSubmitInvoiceActive: false
        });
      }
      const initialFormData = {};
      fileArray.forEach((item: any) => {
        // @ts-ignore
        initialFormData[item.name] = '';
      });
      
      this.setState({ formDataObject: initialFormData });
      const role = getUserRole()
      if(role === "client")  {
        const userData = await getStorageData("user")
        const userDetails = JSON.parse(userData)
        this.setState({
          clientAccountId: userDetails.id
        })
      }
    }
  }

  componentWillUnmount(): any {
    if (this.debounceInvoiceTimeout) {
      clearTimeout(this.debounceInvoiceTimeout);
    }
  }

  handleClientInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if(inputValue.length >= 1) { 
      this.setState({ selectedClient: { id: 0, companyName: inputValue } });
    } else {
      this.setState({ searchedClients: [] });
    }

    if (this.debounceInvoiceTimeout) {
      clearTimeout(this.debounceInvoiceTimeout);
    }

    this.debounceInvoiceTimeout = setTimeout(() => {
      this.clientsSearchAPI = this.apiCall(this.state.token,`client_advance_search?search=${inputValue}`,"get")
      this.setState({ isClientSearchLoaded: true });
    }, 300);
  
  };

  handleInvoiceClientSearch = (event: any, value: any) => {
    if (value?.id) {
      this.setState({ selectedClient: value });
    } else {
      this.setState({ selectedClient: { id: 0, companyName: "" } });
    }
  }; 

  submitInvoiceSchema = Yup.object().shape({
    buyer_name: Yup.string().required('Buyer Name is required'),
    invoice_number: Yup.string().required('Invoice Number is required'),
    invoice_date: Yup.string().required('Invoice Date is required'),
    invoice_amount: Yup.string().required('Invoice Amount is required').test('is-number', 'Only numbers are allowed.', (value) => {
       return /^\d+(\.\d+)?$/.test(value)}),
    payment_terms: Yup.number().min(30,"Payment terms cannot be less than 30 days").required('Payment Terms is required'),
  })

  purchaseOrderSchema = Yup.object().shape({
    buyer_name: Yup.string().required('Buyer Name is required'),
    purchase_order_no: Yup.string().required('Purchase Order No/Contract Number is required'),
    purchase_order_date: Yup.string().required('Purchase Order Date is required'),
    purchase_order_amount: Yup.string().required('PO Amount ($ Value) is required').test('is-number', 'Only numbers are allowed.', (value) => {
       return /^\d+(\.\d+)?$/.test(value)}),
    payment_terms: Yup.number().min(30,"Payment terms cannot be less than 30 days").required('Payment Terms is required'),
  });

  handleInvoiceFileUpload = (Files: File[]) => {
    Files.forEach((fileToUpload: any) => {
      FileUploadValidationSchema.validate({ file: fileToUpload }).then(() => {
        const existingFileIds = this.state.FileDetails.map(file => file.id);
        const availableIds = [1, 2, 3, 4, 5].filter(id => !existingFileIds.includes(id));
        const setFileDetails: arrayOfFileToShow[] = [
          ...this.state.FileDetails,
          ...Files.map((file, index) => ({
            id: availableIds[index],
            file: file,
          }))];
        if (setFileDetails.length > 5) {
          this.setState({ snackbar: { open: true, message: "Maximum File Limit is 5", severity: "error", }})
          return;
        }
        this.setState({ FileDetails: setFileDetails })
      }) .catch((error: any) => {
          this.setState({
            snackbar: { open: true, message: error.message, severity: "error"}})})
    })

  }
  /* istanbul ignore next */
   getHelperText = (item: { name: string}, values: { [x: string]: string; }, errors: FormikErrors<any>, touched: FormikTouched<any>) => {
    const paymentTermsHelperText = item.name === "payment_terms" && parseInt(values[item.name]) < 30 
    ? "Payment terms cannot be less than 30 days" 
    : null;
    return paymentTermsHelperText ?? this.handleHelperText(errors?.[item.name], touched?.[item.name]);
  };
  deleteFile = (id: number) => {

    const updatedFileDetails = this.state.FileDetails.filter(file => file.id !== id);
    this.setState({
      FileDetails: updatedFileDetails,
    });
  }

  handleProductOrderApiResponse = (res:any) => {
    if(res.data){
      this.setState({
        isLoading: false,
        isModalOpen: true,
        FileDetails: []
      })
    } else {
      toast.error(handleAPIErrorMessage(res.errors))
      this.setState({
        FileDetails: [],
        isLoading: false,
      })
    }
  }

  handleSubmitInvoiceApiResponse = (resp: any) => {
    if(resp.data){
      this.setState({
        isLoading: false,
        isModalOpen: true,
        FileDetails: []
      })
    } else {
      this.setState({
        isLoading: false,
        FileDetails: []
      })
      toast.error(handleAPIErrorMessage(resp.errors))
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: "",
        severity: "",
      },
    });
  };

  navigateToSubmitInvoice = () => {
    this.props.navigation.navigate(`${getUserRole() === "client" ? "SubmitInvoice": "AdminSubmitInvoice"}`)
  }

  navigateToProductOrder = () => {
    this.props.navigation.navigate(`${getUserRole() === "client" ? "PurchaseOrder": "AdminPurchaseOrder"}`)
  }

  handleModalClose = () => {
    this.setState({ isModalOpen: false })
    this.state.selectedClient.id && this.props.navigation.navigate("AdminNotifications")
  }

  handleErrors = (error: any, touch: any) => {
    return touch && Boolean(error);
  }

  handleHelperText = (text: any, touch: any) => {
    return touch && text
  }

  handleFormSubmit = (values: Record<string, string | Blob> , resetForm : any) => {
    const { FileDetails, isPurchaseOrderActive, isSubmitInvoiceActive, token, selectedClient } = this.state;
    const role = getUserRole();
    
    if (FileDetails.length < 1) {
      this.setState({
        snackbar: {
          open: true,
          message: "Please Upload Atleast one document",
          severity: "error",
        },
      });
    } else {
      const resultValue = {
        ...values,
        ...(role !== userRoles.client  ? {account_id :selectedClient.id}:{})
      }
      const formData = new FormData();
      Object.entries(resultValue).forEach(([key, value]) => {
        formData.append(key, value as string | Blob);
      });
  
      FileDetails.forEach((dataValue) => {
        formData.append('documents[]', dataValue.file);
      });

      if(role === userRoles.client){
        formData.append("account_id", this.state.clientAccountId)
      }
  
      const invoiceEndpoint =  `invoice_submit` 
      const purchaseEndpoint = `bx_block_invoicebilling/purchase_orders`
      const endpoint = isPurchaseOrderActive ?  purchaseEndpoint : invoiceEndpoint;
  
      this.setState({
        isLoading: true,
      });
  
      
      const apiCall = this.apiCall(token, endpoint, "POST", formData);
      if(role !== userRoles.client && !selectedClient.id){
        toast.warn(configJSON.selectClientWarning);
        this.setState({
          isLoading:false
        })
      } else if (isPurchaseOrderActive) {
        this.productOrderApiCall = apiCall;
        resetForm();
      } else if (isSubmitInvoiceActive) {
        this.submitInvoiceApiCall = apiCall;
        resetForm();
      }
  
      
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  receiveApi = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.productOrderApiCall:
        this.handleProductOrderApiResponse(responseJson);
        break;
      case this.submitInvoiceApiCall:
        this.handleSubmitInvoiceApiResponse(responseJson);
        break;
      case this.clientsSearchAPI:
        this.responseClientsSearch(responseJson);
        break;
      default:
        break;
    }
  };

  responseClientsSearch = (res: any): void => {
    this.setState({
      isClientSearchLoaded: false
    });
  
    if (!res.data || res.data.length < 1) {
      this.setState({
        searchedClients: []
      });
    } else {
      const refinedClients = refinedClientData(res.data);
      this.setState({
        searchedClients: refinedClients
      });
    }
    
  };

  apiCall = (token: token, endPoint: string, method: string, body?: any) => {
    const bodyHeader = {
      token: token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(bodyHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
  }
  // Customizable Area End
}
