export const styles: any = {
    newsCard: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    card: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        boxShadow: 'none',
        flexWrap:'wrap',
    },
    imageCard: {
        height: 'auto',
        borderRadius: '10px',
        maxWidth: '240px', 
    },
    bodyContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
    },
    bodyTitle: {
        color: 'black',
        fontSize: '1.5rem', // Adjusted to relative unit
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 700,
        fontFamily: 'Plus Jakarta Sans',
        cursor: 'pointer',
    },
    mainBody: {
        color: '#666666',
        fontSize: '1rem', // Adjusted to relative unit
        lineHeight: '27px',
        letterSpacing: '-0.36px',
        fontFamily: 'Plus Jakarta Sans',
    },
    createdAt: {
        fontSize: '0.75rem', // Adjusted to relative unit
        fontWeight: 600,
        fontFamily: 'Plus Jakarta Sans',
    },
    button: {
        height: '2rem', // Adjusted for responsiveness
        border: 'none',
        color: '#F3B229',
        fontSize: '1rem', // Adjusted to relative unit
        padding: 0,
        textTransform: 'capitalize',
    },
    tooltip: {
        backgroundColor: "white",
        color: "#000000",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        textAlign: "center",
        borderRadius: "8px",
        boxShadow: "1px 3px 6px 0px rgb(0 0 0 / 43%)",
        border: "0.5px solid rgb(250,250,250)",
        padding: "12px",
        maxWidth: 500,
        "& span": {
            color:'#fff',
            "&::before": {
                boxShadow: '12px 20px 20px 2px rgba(0, 0, 0, 0.3)',
                backgroundColor: 'white'
            }
        }
    },
    flexProvider:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    '@media (max-width: 600px)': {
        bodyTitle: {
            fontSize: '1.2rem', // Adjust font size for smaller screens
        },
        mainBody: {
            fontSize: '0.9rem', // Adjust font size for smaller screens
        },
        createdAt: {
            fontSize: '0.6rem', // Adjust font size for smaller screens
        },
        imageCard: {
           width:'50%'
        },
        button: {
            height: '1.5rem', // Adjust height for smaller screens
            fontSize: '0.8rem', // Adjust font size for smaller screens
        },
        card:{
            gap:'20px'
        }
    },
    '@media (min-width: 601px) and (max-width: 960px)': {
        imageCard: {
            width:'50%'
         },
         card:{
            gap:'20px'
         }
      },
}