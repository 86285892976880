import React, { Component } from "react";
import { Typography, Modal, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

interface ModalProps {
  classes: any;
  open: boolean;
  title: string;
  description?: string;
  btnText?: string;
  handleSubmitModal: any;
  handleModelClose: any;
  widthToAdd?: string;
  isKycModal?: boolean;
}

const styles: any = {
  MuiModalRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "50px",
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 'inherit',
    maxWidth: "905px",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    margin: "auto",
    borderRadius: "10px",
    "& .MuiTypography-root": {
      textAlign: "center",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "-0.32px",
      color: "#000000",
      padding: "0 60px",
    },
  },
  modalOverlay: {
    backgroundColor: "#D8D8D8",
    opacity: "0.33",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "-1",
  },
  doneButton: {
    backgroundColor: "#E5B632",
    color: "white",
    fontWeight: "600",
    padding: "8px 16px",
    borderRadius: "4px",
    cursor: "pointer",
    width: "210px",
    "&:hover": {
      backgroundColor: "#E5B632",
    },
  },
  kycTitle:{
    maxWidth: "535px",
    lineHeight: "34px !important",
  }
};

class CommonModal extends Component<ModalProps> {
  constructor(props: ModalProps) {
    super(props);
  }

  handleSubmit = () => {
    this.props.handleSubmitModal();
  };

  render() {
    const {
      open,
      classes,
      title,
      description,
      btnText,
      handleModelClose,
      widthToAdd,
      isKycModal
    } = this.props;

    return (
      <Modal
        classes={{ root: classes?.MuiModalRoot }}
        open={open}
        onClose={() => handleModelClose()}
      >
      <div style={{width:widthToAdd ? widthToAdd : 'max-content'}}>
          <div className={classes?.modalBox}>
            <Typography className={isKycModal? classes?.kycTitle : ''}>{title}</Typography>
            {description ? <Typography>{description || ""}</Typography> : <></>}
            <Button
              className={classes?.doneButton}
              onClick={() => this.handleSubmit()}
            >
              {btnText ? btnText : "Done"}
            </Button>
          </div>
          <div className={classes?.modalOverlay} />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(CommonModal);
