export const styles: any = {
  loginRightSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 50px",
  },
  title: {
    fontSize: "29px",
    fontWeight: "500",
  },
  subTitle: {
    fontSize: "15px",
    color: "#757575",
    margin: 0,
    marginTop: "20px",
  },
  inputLabel: {
    fontSize: "14px",
    color: "#000000",
    marginTop: "30px",
  },
  inputField: {
    margin: 0,
    marginTop: "15px",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "18px",
        fontSize: "14px",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
    "& .MuiFormHelperText-root": {
      color: "#ea2f2f",
      margin: 0,
      marginTop: "5px",
    },
  },
  forgotPass: {
    color: "#e5b632",
    fontSize: "14px",
    marginTop: "23px",
    marginLeft: "15px",
    float: "right",
    cursor: "pointer",
  },
  errorMsg: {
    color: "#ea2f2f",
    marginTop: "30px",
    marginBottom: "-20px",
    paddingBottom: "10px",
    fontSize: "0.75rem",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
  loginBtn: {
    color: "#fff",
    backgroundColor: "#e5b632",
    margin: "20px 0 25px 0",
    boxShadow: "none",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  endText: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: "14px",
    "& span": {
      color: "#e5b632",
      cursor: "pointer",
    },
  },
  mobileLogoHolder:{
    display:'none'
  },
  '@media (max-width: 599px)': {
    leftLoginSection: {
      display: 'none',
    },
    mobileLogoHolder:{
      display:'revert',
    },
    loginRightSection:{
      flexDirection: 'column',
      gap:'50px',
      "& img":{
        height:'100px'
      }
    },
    title:{
      textAlign:'center',
    }
  },
};
