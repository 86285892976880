import React, {Component} from "react";
import { Tooltip, IconButton, withStyles} from "@material-ui/core";
const InfoIcon = require("../Assets/info.png");

interface CustomTooltipProps {
  tooltipText: string;
  toolTipClasses?: string;
  height?: string;
  width?: string;
  classes: any;
  direction?: any;
}

const styles:any = ({
  arrow: {
    color: "white",
    "&::before": {
      boxShadow: "0 0px 5px rgba(0, 0, 0, 0.15)"
    }
  },
  tooltip: {
    backgroundColor: "white",
    color: "#000000",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    borderRadius: "8px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
    border: "0.5px solid rgb(250,250,250)",
    padding: "12px",
    "&::before": {
        content: "",
        position: "absolute",
        top: "-10px",
        left: "50%",
        transform: "translateX(-50%)",
        borderStyle: "solid",
        borderWidth: "8px 8px 0",
        borderColor: "#000000 transparent transparent",
      }
  },
  iconButton: {
    padding: 0,
  },
});

class CustomTooltip extends Component<CustomTooltipProps> {

  constructor(props: CustomTooltipProps) {
    super(props);
  }

  render() {
    const {tooltipText,toolTipClasses, height, width, classes , direction} = this.props;
  return (
    <Tooltip title={tooltipText} arrow classes={classes} placement={direction ? direction : "bottom"}>
      <IconButton
        className={`${classes?.iconButton} ${
          toolTipClasses ? toolTipClasses : ""
        }`}
        style={{
          width: width ? width : "24px",
          height: height ? height : "24px",
        }}
      >
        <img
          src={InfoIcon}
          alt="info icon"
          style={{
            height: height ? height : "24px",
            width: width ? width : "24px",
          }}
        />
      </IconButton>
    </Tooltip>
  );
  }
};

export default withStyles(styles)(CustomTooltip);