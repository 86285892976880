import React, { useState } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Grid } from "@material-ui/core";
// @ts-ignore
import { styles } from './adminDashboardGraphStyles'

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  percent,
  tooltipPayload,
  classes
}: any) => {

  const RADIAN = Math.PI / 180;
  const labelRadius = outerRadius + 5; 
  const x = cx + labelRadius * Math.cos(-midAngle * RADIAN);
  const y = cy + labelRadius * Math.sin(-midAngle * RADIAN);

  if (percent === 0) {
    return null;
  }


  return (
    <>
      <line
        x1={cx + outerRadius * Math.cos(-midAngle * RADIAN)}
        y1={cy + outerRadius * Math.sin(-midAngle * RADIAN)}
        x2={x}
        y2={y}
        stroke="black"
        strokeWidth="1"
      />
      <text
        x={x}
        y={y}
        dx={0}
        dy={0}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{ padding: '5px' }}
        className="textClassHolder"
      >
        {`${tooltipPayload[0].value.toFixed(1)}%`}
      </text>
    </>
  );
};

const CustomTooltip = ({ active, payload, classes }: any) => {
  if (active && payload && payload.length) {
    const dataItem = payload[0].payload;
    const { color, sector, ammount, percentage } = dataItem;
    return (
      <Box className={classes?.customTooltip}>
        <Box className={classes?.tooltipColor} style={{ background: color }}></Box>
        <Box className={classes?.tooltipContent}>
          <Box className={classes?.tooltipLabel}>{sector}</Box>
          <Box className={classes?.tooltipValue}>{ammount}</Box>
          <Box className={classes?.tooltipPercent}>{`${percentage}%`}</Box>
        </Box>
      </Box>
    );
  }

  return null;
};

type DropdownProps = {
  onChange: any;
  classes: any
};

const AdminDashboardDisbursedRevenueChart = ({ classes, revenueChartData, globalChartData, entireData }: any) => {

  const [activeTile, setActiveTile] = useState(null);
  const [showAllTiles, setShowAllTiles] = useState(false);
  const [revenueData, setRevenueData] = useState(revenueChartData);
  const [disbursmentData, setDisbursmentData] = useState(globalChartData);

  const handleTileHover = (dataItem: any) => {
    setActiveTile(dataItem);
  };

  const toggleTilesVisibility = () => {
    setShowAllTiles(!showAllTiles);
  };

  const handleTooltipTrigger = (data: any) => {
    setActiveTile(data)
  }

  const handleTooltipRemover = () => {
    setActiveTile(null)
  }

  const renderTiles = (data: any, chartType: string) => {

    return data.map((entry: any) => (
      <Box
        className={`${classes.chartTile} ${classes.chartTileHover}`}
        key={entry?.id}
        onMouseEnter={() => handleTileHover(entry)}
        onMouseLeave={() => setActiveTile(null)}
      >
        <Box className={classes.tileColor} style={{ background: entry?.color }} />
        <Box className={classes.tileContent}>
          <Box className={classes.tileLabel}>{entry.sector}</Box>
        </Box>
        {
          // @ts-ignore
          activeTile && activeTile?.sector === entry.sector ?
            <Box className={classes.tileTooltip}>
              <Box className={classes.tooltipLabel}>{chartType}</Box>
              <Box className={classes.sectorLableInsideTooltip}>{`${entry?.sector}`}</Box>
              <Box style={{ display: 'flex', gap: '10px', minWidth: "145px", overflow: 'hidden' }}>
                {chartType == 'Disbursed Amount' ? entry.total_disbrused : entry.total_commission}
                <Box className={classes.tooltipPercent}>
                  {chartType == 'Disbursed Amount' ? `${entry.total_disbursed_percentage}%` : `${entry.total_commission_percentage}%`}
                </Box>
              </Box>
            </Box>
            : ''
        }
      </Box>
    ));
  };

  return (
    <Box className={classes.mainChartContainer}>
      <Grid container spacing={2}>
        <Grid item md={12} lg={6} xs={12} sm={12}>
          <Box className={classes.flexProvider}>
            Disbursed amount chart
          </Box>
          <Box className={classes.chartContainer}>
            <ResponsiveContainer width="100%" height="100%" className={classes.responsiveChartContainer}>
              <PieChart>
                <Pie
                  dataKey="value"
                  data={disbursmentData}
                  cx="50%"
                  cy="50%"
                  innerRadius={40}
                  // outerRadius={140}
                  label={renderCustomizedLabel}
                  labelLine={false}
                >
                  {disbursmentData.map((entry: any, index: number) => (
                    <Cell onMouseEnter={() => handleTooltipTrigger(entry)} onMouseLeave={() => handleTooltipRemover()} key={`cell-${index}`} fill={entry?.color} />
                  ))}
                </Pie>
                {/* @ts-ignore */}
                <Tooltip wrapperStyle={{ zIndex: 9999 }} content={<CustomTooltip classes={classes} />} />
              </PieChart>
            </ResponsiveContainer>
            <Box className={classes.shadowCircle} />
          </Box>
        </Grid>
        <Grid item md={12} lg={6} xs={12} sm={12}>
          <Box className={classes.flexProvider}>
            Revenue chart
          </Box>
          <Box className={classes.chartContainer}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  dataKey="value"
                  data={revenueData}
                  cx="50%"
                  cy="50%"
                  innerRadius={40}
                  // outerRadius={140}
                  label={renderCustomizedLabel}
                  labelLine={false}
                >
                  {revenueData.map((entry: any, index: number) => (
                    <Cell onMouseEnter={() => handleTooltipTrigger(entry)} onMouseLeave={() => handleTooltipRemover()} key={`cell-${index}`} fill={entry?.color} />
                  ))}
                </Pie>
                {/* @ts-ignore */}
                <Tooltip wrapperStyle={{ zIndex: 9999 }} content={<CustomTooltip classes={classes} />} />
              </PieChart>
            </ResponsiveContainer>
            <Box className={classes.shadowCircle} />
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.tilesAndButtonHolder}>
        <Button onClick={toggleTilesVisibility}>
          {!showAllTiles ? "View More" : "Hide"}
        </Button>
        {showAllTiles ?
          <Box className={classes.tilesHolder}>
            <Box className={classes.tilesContainer}>
              {renderTiles(disbursmentData, "Disbursed Amount")}
            </Box>
            <hr />
            <Box className={classes.tilesContainer}>
              {renderTiles(revenueData, "Revenue Amount")}
            </Box>
          </Box>
          : ""}
      </Box>
    </Box>
  );
}

export default withStyles(styles)(AdminDashboardDisbursedRevenueChart)