// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ClientInvoiceTableController, {
  Props,
} from "./ClientInvoiceTableController.web";
import { styles } from "./ClientInvoiceTableStyles.web";
import CommonTable from "../../../components/src/Table/CommonTable.web";
import { clientInvoicesColumns } from "../../../components/src/Utils/dummyData";
import { parseTableData } from "../../../components/src/Utils/parseData";

class ClientInvoiceTable extends ClientInvoiceTableController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { clientInvoiceData, isLoading } = this.state;

    let data = parseTableData(clientInvoiceData);
    data = data.filter((item: any) => item.status === "disbursed")

    return (
      // Required for all blocks
      <div className={classes.invoiceReportDiv}>
        <h5 className="reportTitle">Client Reports</h5>
        <Grid className="invoice-tableContainer">
          <CommonTable
            columns={clientInvoicesColumns}
            data={data}
            headerClasses={classes.headerInvoiceClasses}
            loadingTableData={isLoading}
            navigate={this.props.navigation.navigate}
            handleRowClick={this.handleRowClick}
          />
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ClientInvoiceTable);
// Customizable Area End
