import React from "react";
import DashboardController, { Props } from "./DashboardController.web";

export class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
  }
  
  render() {
    return <></>
  }
}

export default Dashboard;
