import React, { Component } from "react";
import { Typography, Modal, Button , Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

interface ModalProps {
    classes: any;
    open: boolean;
    title: string;
    description?: string;
    btnText?: string;
    handleApproveModal?: any;
    handleModelClose: any;
    widthToAdd?: string
    extraButtonText?: string;
}

const styles: any = {
    MuiModalRoot: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "50px",
    },
    modalBox: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 'inherit',
        maxWidth: "905px",
        backgroundColor: "white",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        padding: "32px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        margin: "auto",
        borderRadius: "10px",
        "& .MuiTypography-root": {
            textAlign: "center",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
            letterSpacing: "-0.32px",
            color: "#000000",
            padding: "0 60px",
        },
    },
    modalOverlay: {
        backgroundColor: "#D8D8D8",
        opacity: "0.33",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "-1",
    },
    acceptButton: {
        backgroundColor: "#F27151",
        color: "white",
        fontWeight: "600",
        padding: "8px 16px",
        borderRadius: "4px",
        cursor: "pointer",
        width: "210px",
        "&:hover": {
            backgroundColor: "#F27151",
        },
    },
    declineButton: {
        backgroundColor: "#20BB6E",
        color: "white",
        fontWeight: "600",
        padding: "8px 16px",
        borderRadius: "4px",
        cursor: "pointer",
        width: "210px",
        "&:hover": {
            backgroundColor: "#20BB6E",
        },
    },
    buttonHolder:{
        display:'flex',
        gap:'20px',
        flexWrap: 'wrap',
        justifyContent: 'center',
    }
};

class DelinquentModal extends Component<ModalProps> {
    constructor(props: ModalProps) {
        super(props);
    }

    handleApprove = () => {
        this.props.handleApproveModal();
    };

    render() {
        const {
            open,
            classes,
            title,
            description,
            btnText,
            handleModelClose,
            widthToAdd,
            extraButtonText
        } = this.props;

        return (
            <Modal
                classes={{ root: classes?.MuiModalRoot }}
                open={open}
                onClose={() => handleModelClose()}
            >
                <div style={{ width: widthToAdd ? widthToAdd : 'max-content' }}>
                    <div className={classes?.modalBox}>
                        <Typography>{title}</Typography>
                        {description ? <Typography>{description || ""}</Typography> : <></>}
                        <Box className={classes?.buttonHolder}>
                            <Button
                                className={classes?.acceptButton}
                                onClick={() => handleModelClose()}
                                >
                                {btnText ? btnText : "No"}
                            </Button>
                            <Button
                                className={classes?.declineButton}
                                onClick={() => this.handleApprove()}
                                >
                                {extraButtonText ? extraButtonText : "Yes"}
                            </Button> 
                        </Box>
                    </div>
                    <div className={classes?.modalOverlay} />
                </div>
            </Modal>
        );
    }
}

export default withStyles(styles)(DelinquentModal);
