import { MAIN_DIV_WIDTH } from "../../../components/src/Utils/constant";

export const styles: any = {
  headingTitle:{
    fontFamily: "Plus Jakarta Sans",
    color: "#2E2A40",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "23px",
    marginBottom: "20px"
  },
  invoiceDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: MAIN_DIV_WIDTH,
    justifyContent: "center",
    padding: "0 50px 50px",
    gap: "28px",
    overflowY: "auto",
    flexGrow: 1,
    "& .maintitle": {
      marginTop: "3px",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "24px",
      lineHeight: "30px",
      letterSpacing: "-0.48px",
      color: "#2E2A40",
      fontFamily: "'Plus Jakarta Sans'",
    },
    "& .subTitleContainer1": {
      marginTop: "20px",
      "& .title1": {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "23px",
        letterSpacing: "-0.48px",
        fontFamily: "'Plus Jakarta Sans'",
        color: "#2E2A40",
      },
      "& .date1": {
        float: "left",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "13px",
        textAlign: "center",
        fontFamily: "'Plus Jakarta Sans'",
        letterSpacing: "-0.24px",
        color: "#757575",
        marginTop: "4px",
      },
    },
    "& .btnContainerLeft": {
      width: "100%",
      marginTop: "35px",
      display: "flex",
      "& .leftbtn": {
        width: "210px",
        background: "#F27151",
        borderRadius: "4px",
        fontFamily: "'Plus Jakarta Sans'",
        height: "42px",
        "& .MuiButton-label": {
          fontStyle: "normal",
          fontFamily: "'Plus Jakarta Sans'",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "20px",
          textAlign: "center",
          letterSpacing: "-0.32px",
          color: "#FFFFFF",
          textTransform: "capitalize",
          marginTop: "-2px",
        },
      },
      "& .approveBtn1": {
        background: "#20BB6E",
        marginLeft: "20px",
      },
      "& .disabled": {
        background: "#B6B6B6",
        marginRight: "20px",
      },
    },
  },
  bottomDiv:{
    flexDirection:"row",
    display: "flex",
    flexWrap:"wrap",
  },
  rightBtmDiv: {
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
    rowGap: "18px",
    flexDirection: "column",
  },
  rightBtmHeader:{
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    columnGap: "15px",
    flexDirection: "row",
    paddingTop: "28px",
    alignItems: "center",
    "& .rightBtmTitle":{
      color: '#2E2A40',
      fontSize: "14px",
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: '800',
      margin:0,
    },
    "& .rightBtmBtn":{
      display: "grid",
      alignContent: "center",
      "& .MuiButton-label":{
        whiteSpace: "nowrap",
        padding:"0px 20px !important",
        fontSize: "12px !important",
      }
    },
  },
  collapseContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "20px",
    margin:"27px 0 40px",
    "& .collapseTitle": {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "18px",
      color: "#202A45",
      letterSpacing: "-0.24px",
      fontFamily: "'Plus Jakarta Sans'",
    },
    "& .collapse": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      columnGap: "50px",
      "& p": {
        display: "flex",
        width: "250px",
        alignItems: "center",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "16px",
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        color: "#2E2A40",
        opacity: 0.8,
        margin: 0,
        "& img": {
          paddingRight: "8px",
        },
      },
      "& .dwnContainer": {
        display: "flex",
        columnGap: "24px",
        flexDirection: "row",
        alignItems: "center",
        "& img": {
          height: "20px",
          width: "20px",
        },
        "& .btn": {
          cursor: "default",
          width: "94px",
          background: "white",
          borderRadius: "4px",
          border: "1px solid rgba(0, 0, 0, 0.08)",
          fontFamily: "'Plus Jakarta Sans'",
          height: "27px",
          "& .MuiButton-label": {
            fontStyle: "normal",
            fontFamily: "'Plus Jakarta Sans'",
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: "13px",
            textAlign: "center",
            color: "#979797",
            textTransform: "capitalize",
            padding: "10px 28px",
          },
        },
        "& .reviewedBtn": {
          background: "#20BB6E !important",
          cursor: "default !important",
          pointerEvents:'none',
          "& .MuiButton-label": {
            color: "white !important",
          },
        },
      },
    },
  },
  inputLabel: {
    fontSize: "12px",
    lineHeight: "15.12px",
    letterSpacing: "-0.24px",
    fontWeight: 500,
    color: "#202A45",
    marginTop: "4px",
  },
  inputField: {
    margin: 0,
    marginTop: "12px",
    maxWidth: "533px",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        letterSpacing: "-0.24px",
        lineHeight: "15px",
        fontWeight: 700,
        padding: "18px 12px 17px",
        fontSize: "12px",
      },
    },
    "& .MuiOutlinedInput-adornedStart": {
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "15px",
      "& .MuiInputBase-input": {
       paddingLeft: "5px !important",
      }
    }, 
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#ea2f2f",
      marginTop: "5px",
    },
  },
  helperInvoiceText: {
    color: "#ea2f2f",
    fontWeight: 400,
    lineHeight: 1.66,
    margin: 0,
    marginTop: "5px",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
  },
  disbursementDateProvider:{
    flexDirection: "column",
    padding:'12px',
    '@media (max-width: 900px)': {
        width: "100%",
    },
  },
  formWidthProvider:{
    '@media (max-width: 1440px)': {
      width: "100%",
    },
  },
  '@media (max-width: 600px)': {
    invoiceDiv: {
      width: "100%",
      marginTop:'15px'
    },
  },
};
