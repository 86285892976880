export const styles: any = {
  textSearchField: {
    margin: 0,
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      height: "50px",
      "& .MuiInputBase-input": {
        padding: "17px 16px 15px",
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: 500,
        color: "#757575",
        '&::placeholder': {
          color: '#757575',
          opacity: 1,
        },
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px !important",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
  },
  searchIcon: {
    height: "19px",
    paddingRight: "22px",
    cursor: "pointer",
    marginLeft: "8px",
  },
  advanceSearchInput: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "7px 16px 7px",
      },
    },
  },
  advancePopper: {
    "& .MuiAutocomplete-popper": {
      marginTop: "5px",
      "& .MuiAutocomplete-paper": {
        margin: "6px 0",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#2E2A40",
      },
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
  },
  customListbox: {
    maxHeight: "120px",
    border: "1px solid #E7E7E7",
    borderRight: "none",
    marginRight: "15px",
    boxShadow: "none",
    "& li": {
      padding: "1px 20px",
      marginBottom: "6px",
      color: "#8F92A1",
      fontStyle: "normal",
      fontWeight: 400,
      fontFamily: "Plus Jakarta Sans",
      lineHeight: "15px",
      fontSize: "12px",
      "& :hover":{
        backgroundColor: "white",
      },
      "& :active":{
        backgroundColor: "white",
      }
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#BABABA",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "white",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    "&::-webkit-scrollbar": {
      width: "3px",
    },
  },
  circle: {
    color: "#8F92A1",
  },
};
