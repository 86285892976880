import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { handleNewsFilteration } from "../../../components/src/Utils/utils"


export interface Props {
    classes: any;
    navigation: any;
}

type token = string | null
type newsArray = {
    id : string | number,
    body : string,
    created_at : string,
    title : string,
    image_url : string,
}

interface S {
    token : token, 
    isLoading: boolean
    newsData:newsArray[]
}

interface SS {
    id: any;
}

export default class ClientNewsController extends BlockComponent<Props, S, SS> {
    getNewsApi : string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            token : '', 
            isLoading: false,
            newsData:[]
        };
    }

    async componentDidMount() {
        if (!localStorage.getItem("auth_token")) {
            this.props.navigation.navigate("EmailLogin");
        } else {
            const token = localStorage.getItem("auth_token");
            this.setState({ token:token , isLoading:true });
            this.getNewsApi = this.newsApiCall(token , 'bx_block_dashboard/news' , 'GET')
        }
    }

    handleNewsRedirection = (newsId:string) => {
        this.props.navigation.navigate("NewsClientDetails", {id: newsId})
    }

    handleNewsApicall = (response : any) => {
        const updatedNewsData = handleNewsFilteration(response)
        this.setState({
            newsData:updatedNewsData,
            isLoading:false
        })
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                this.receiveApi(apiRequestCallId, responseJson);
            }
        }
    }

    receiveApi = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getNewsApi) {
            this.handleNewsApicall(responseJson)
        }
    }

    newsApiCall = (token: token, endPoint: string, method: string) => {
        const header = {
            "Content-Type": "application/json",
            token: token,
        };

        const clientRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        clientRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
        );

        clientRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
        );

        clientRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), method
        );

        runEngine.sendMessage(clientRequestMessage.id, clientRequestMessage);

        return clientRequestMessage.messageId
    }
}


