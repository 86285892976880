import React from "react";
import {
  withStyles,
} from "@material-ui/core/styles";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import EKYC2Controller, { Props } from "./EKYC2Controller.web";
import { styles } from "./EKYCStyles.web";
import { Formik } from "formik";
import {onKeyDown} from "../../../components/src/Utils/utils"
import { KycForm2Schema } from "../../../components/src/Utils/validations";

export class EKYC2 extends EKYC2Controller {
  constructor(props: Props) {
    super(props);
    this.setSubmit = this.setSubmit.bind(this);
  }
  render() {
    const { classes } = this.props;
    const menuProps: any = {
      classes: { paper: classes.menuPaper },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
    }

    const initialFormValues = {
        bank_name: this.state.bankName,
        bank_branch: this.state.bankBranch,
        bank_address: this.state.bankAddress,
        business_account_name: this.state.bankAccountName,
        business_account_number: this.state.bankAccountNumber,
        business_background: this.state.businessBackground,
        business_account_currency: this.state.bankAccountCurrency,
        business_account_type: this.state.bankAccountType,
        tax_liabilities: this.state.taxLiabilities,
        relevant_licenses: this.state.licensesCurrent,
        list_current_liabilities: this.state.currentLiabilities,
        current_relevant_licenses: this.state.currentLicense
      }

    return (
        <Box className={classes.boxContainer}>
          <Grid className={classes.secondGrid}>
            <Divider className={classes.divider} />
              <Formik
                enableReinitialize={true}
                validateOnBlur={true}
                initialValues={initialFormValues}
                validationSchema={KycForm2Schema}
                onSubmit={(values : any) => this.handleFormSubmit(values)}
              >
                {({ errors, values, handleSubmit,setFieldValue, handleChange, touched}) => {
                  return (
                    <form onSubmit={handleSubmit} onKeyDown={onKeyDown} style={{ width: "100%" }}>
                    
                          
                          <Grid container spacing={3} direction={"row"}>
                            <Grid item xs={12} sm={12} md={6} className={classes.insideRow}>
                              <Typography className={classes.FontClass}>Bank Name*</Typography>{" "}
                              <TextField
                                id="outlined-basic" 
                                placeholder="E.g. First National Bank"
                                variant= "outlined"
                                className={classes.textField97}
                                value={values.bank_name}
                                name="bank_name"
                                error={this.handleErrors(
                                  errors.bank_name,
                                  touched.bank_name
                                )}
                                helperText={this.handleHelperText(
                                  errors.bank_name,
                                  touched.bank_name
                                )}
                                onChange={(e) => {
                                  handleChange(e);
                                  }
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className={classes.insideRow}>
                              <Typography className={classes.FontClass}>Bank Branch*</Typography>{" "}
                              <TextField
                                id="outlined-basic"
                                placeholder="e.g. Anytown Branch"
                                variant="outlined"
                                className={classes.textField97}
                                name="bank_branch"
                                value={values.bank_branch}
                                error={this.handleErrors(
                                  errors.bank_branch,
                                  touched.bank_branch
                                )}
                                helperText={this.handleHelperText(
                                  errors.bank_branch,
                                  touched.bank_branch
                                )}
                                onChange={(e) =>{
                                  handleChange(e);
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} direction={"row"}>
                            <Grid item xs={12} sm={12} md={6} className={classes.insideRow}>
                              <Typography className={classes.FontClass}>Bank Address*</Typography>{" "}
                              <TextField
                                id="outlined-basic"
                                placeholder="e.g. 786 Maple Street, Anytown, USA"
                                variant="outlined"
                                className={classes.textField97}
                                value={values.bank_address}
                                name="bank_address"
                                error={this.handleErrors(
                                  errors.bank_address,
                                  touched.bank_address
                                )}
                                helperText={this.handleHelperText(
                                  errors.bank_address,
                                  touched.bank_address
                                )}
                                onChange={(e) =>{
                                  handleChange(e);
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className={classes.insideRow}>
                              <Typography className={classes.FontClass}>
                                Business Account Name(Name On the Account)*
                              </Typography>{" "}
                              <TextField
                                id="outlined-basic"
                                placeholder="e.g. Tech Solution LLC"
                                variant="outlined"
                                className={classes.textField97}
                                name="business_account_name"
                                value={values.business_account_name}
                                error={
                                  this.handleErrors(errors.business_account_name, touched.business_account_name)
                                }
                                helperText={this.handleHelperText(
                                  errors.business_account_name,
                                  touched.business_account_name
                                )}
                                onChange={(e) =>{
                                  handleChange(e);
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} direction={"row"}>
                            <Grid item xs={12} sm={12} md={4} className={classes.insideRow}>
                              <Typography className={classes.FontClass}>Bank Account Number*</Typography>{" "}
                              <TextField
                                id="outlined-basic"
                                type="text"
                                placeholder="e.g. 7894561230"
                                variant="outlined"
                                className={classes.textField97}
                                name="business_account_number"
                                value={values.business_account_number}
                                error={this.handleErrors(
                                  errors.business_account_number,
                                  touched.business_account_number
                                )}
                                helperText={this.handleHelperText(
                                  errors.business_account_number,
                                  touched.business_account_number
                                )}
                                onChange={(e) =>{
                                  handleChange(e);
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} className={classes.insideRowUpdated}>
                              <Typography className={classes.FontClass}>Bank Account currency*</Typography>{" "}
                              <Select
                                labelId="Currency-lable"
                                id="Currency-lable"
                                variant="outlined"
                                name="business_account_currency"
                                defaultValue={this.state.bankAccountCurrency}
                                value={values.business_account_currency}
                                placeholder={"e.g. USD"}
                                className={classes.selectField}
                                MenuProps={menuProps}
                                fullWidth
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                >
                                {['USD', 'JMD'].map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                                {this.handleErrors(errors.business_account_currency , touched.business_account_currency) && (
                                  <FormHelperText className={classes.helperText}>
                                    {errors.business_account_currency}
                                  </FormHelperText>
                                )}
                              </Select>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} className={classes.insideRowUpdated}>
                              <Typography className={classes.FontClass}>Bank Account Type*</Typography>{" "}
                              <Select
                                labelId="account-type-label"
                                id="account-type-label"
                                variant="outlined"
                                name="business_account_type"
                                placeholder={"e.g. Checking"}
                                className={classes.selectField}
                                MenuProps={menuProps}
                                error={this.handleErrors(errors.business_account_type,touched.business_account_type)}
                                fullWidth
                                defaultValue={this.state.bankAccountType}
                                value={values.business_account_type}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              >
                                
                                {['Savings', 'Current'].map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                              </Select>
                              {this.handleErrors(errors.business_account_type, touched.business_account_type) && (
                                  <FormHelperText className={classes.helperText}>
                                    {errors.business_account_type}
                                  </FormHelperText>
                                )}
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} direction={"row"}>
                            <Grid item xs={12} sm={12} md={12} className={classes.insideRow}>
                              <Typography
                                align="left" className={`${classes.FontClass} ${classes.textField190}`}
                              >
                                Business Background(Description of Business,
                                Attach further details if required):
                              </Typography>{" "}
                              <TextField
                                id="outlined-basic"
                                placeholder="e.g. About your company"
                                variant="outlined"
                                name="business_background"
                                value={values.business_background}
                                className={classes.textField196}
                                onChange={(e) =>
                                  setFieldValue("business_background",e.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <div style={{marginTop:'15px'}}>
                            <Typography className={classes.FontClass} align="left">
                              Are you tax compliant (Y/N)?*
                            </Typography>{" "}
                            <RadioGroup name="tax_liabilities" row 
                            onChange={(e) => setFieldValue("tax_liabilities",e.target.value)} 
                            value={values.tax_liabilities.toString()}>
                              <FormControlLabel 
                                className={classes.FontClass} 
                                value="true"
                                control={
                                  <Radio 
                                    name="radioName3" 
                                    classes={{root: classes.radioController, 
                                    checked: classes.checkedRadio
                                    }} 
                                    className={classes.FontClass}
                                  />
                                } 
                                label="Yes"
                              />
                              <FormControlLabel 
                                className={classes.FontClass} 
                                value="false"
                                control={
                                  <Radio 
                                    name="radioName4" 
                                    classes={{root: classes.radioController, 
                                    checked: classes.checkedRadio
                                    }} 
                                    className={classes.FontClass}
                                  />
                                } 
                                label="No" 
                              />
                            </RadioGroup>
                          </div>
                          <Grid container spacing={3} direction={"row"}>
                            <Grid item xs={12} sm={12} md={6} className={classes.rowDivInside}>
                              <div className={classes.insideRow} style={{width:'100%'}}>
                                <Typography className={classes.FontClass}>
                                  List current liabilities*
                                </Typography>{" "}
                                <TextField
                                  id="outlined-basic"
                                  variant="outlined"
                                  name="list_current_liabilities"
                                  value={values.list_current_liabilities}
                                  className={classes.textFieldSelect}
                                  error={this.handleErrors(
                                    errors.list_current_liabilities,
                                    touched.list_current_liabilities
                                  )}
                                  helperText={this.handleHelperText(
                                    errors.list_current_liabilities,
                                    touched.list_current_liabilities
                                  )}
                                  multiline
                                  minRows={3}
                                  maxRows={3}
                                  onChange={(e) =>{
                                    handleChange(e);
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <div style={{marginTop:'15px'}}>
                            <Typography className={classes.FontClass} align="left">
                              Are the relevant licenses current, if applicable (Y/N) and list relevant licenses*:
                            </Typography>{" "}
                            <RadioGroup name="relevant_licenses" row 
                            onChange={(e) => setFieldValue("relevant_licenses",e.target.value )} 
                              value={values.relevant_licenses.toString()}
                              >
                              <FormControlLabel 
                                className={classes.FontClass} 
                                value="true"
                                control={
                                  <Radio 
                                    name="radioName3" 
                                    classes={{ 
                                      root: classes.radioController, 
                                      checked: classes.checkedRadio
                                    }} 
                                    className={classes.FontClass}
                                  />
                                } 
                                label="Yes" 
                              />
                              <FormControlLabel
                                className={classes.FontClass} 
                                value="false"
                                control={
                                  <Radio 
                                  name="radioName4" 
                                  classes={{ 
                                    root: classes.radioController, 
                                    checked: classes.checkedRadio 
                                    }} 
                                  className={classes.FontClass} 
                                  />
                                } 
                                label="No" />
                            </RadioGroup>
                          </div>
                          <Grid container spacing={3} direction={"row"}>
                            <Grid item xs={12} sm={12} md={6} className={classes.rowDivInside}>
                              <div style={{width:'100%'}} className={classes.insideRow}>
                                <Typography className={classes.FontClass} align="left">
                                  List licenses below* :
                                </Typography>{" "}
                                <TextField
                                  id="outlined-basic"
                                  variant="outlined"
                                  name="current_relevant_licenses"
                                  value={values.current_relevant_licenses}
                                  className={classes.textFieldSelect}
                                  error={this.handleErrors(
                                    errors.current_relevant_licenses,
                                    touched.current_relevant_licenses
                                  )}
                                  helperText={this.handleHelperText(
                                    errors.current_relevant_licenses,
                                    touched.current_relevant_licenses
                                  )}
                                  multiline
                                  minRows={3}
                                  maxRows={3}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <Box>
                            <Button
                              className={classes.nextButton}
                              variant="contained"
                              type="submit"
                            >
                              Save & Next
                            </Button>
                          </Box>
                    
                    </form>
                  );
                }}
              </Formik>
          </Grid>
        </Box>
    );
  }
}

export default withStyles(styles)(EKYC2);
