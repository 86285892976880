// Customizable Area Start
import * as Yup from "yup";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { dummyDeclareText } from "../../../components/src/Utils/dummyData";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  account_block_corporate_types_id: any;
  industry_ids: any[];
  company_name: string;
  company_registration_number: string;
  isAdmin: boolean;
  accountId:string;
  tax_registration_no: string | null;
  tax_identification_no: string | null;
  registered_business_address: string;
  operating_address: string;
  country_name: any;
  postal_code: string | null;
  zip_code: string | null;
  new_phone_number: string;
  email: string;
  primary_contact_name: string;
  primary_contact_email: string;
  primary_contact_telephone: string;
  secondary_contact_name: string | null;
  secondary_contact_email: string | null;
  secondary_contact_telephone: string | null;
  average_annual_earnings: number | null | string;
  number_of_employees_full_time: number | null;
  number_of_employees_part_time: number | null;
  declaration_name: string;
  declaration_date: string | Date;
  digital_signature: string;
  declaration: any;
  token: string | null;
  corporateOptions: [];
  industryOptions: [];
  isOpen: boolean;
  declarationText: string | null;
}

interface SS {
  id: any;
}

export default class KycForm1Controller extends BlockComponent<Props, S, SS> {
  kycForm1API: string = "";
  kycForm4API: string = "";
  getKycAPI: string = "";
  getCorporateAPI: string = "";
  getIndustryAPI: string = "";
  getDeclarationAPI: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      account_block_corporate_types_id: null,
      industry_ids: [],
      company_name: "",
      company_registration_number: "",
      tax_registration_no: "",
      tax_identification_no: "",
      registered_business_address: "",
      operating_address: "",
      country_name: "Jamaica",
      postal_code: "",
      zip_code: "",
      new_phone_number: "",
      email: "",
      primary_contact_name: "",
      primary_contact_email: "",
      isAdmin: false,
      accountId:'',
      primary_contact_telephone: "",
      secondary_contact_name: null,
      secondary_contact_email: null,
      secondary_contact_telephone: null,
      average_annual_earnings: null,
      number_of_employees_full_time: null,
      number_of_employees_part_time: null,
      declaration_name: "",
      declaration_date: "",
      digital_signature: "",
      declaration: false,
      token: "",
      corporateOptions: [],
      industryOptions: [],
      isOpen: false,
      declarationText: dummyDeclareText,
    };
  }

  async componentDidMount() {
    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else {
      const role = localStorage.getItem("account_role");
      const pathCheck = window.location.pathname
      const currentPath = window.location.pathname.toUpperCase();
      const token = localStorage.getItem("auth_token");
      this.setState({ token: token });
      if ( role === "admin" || role === "analyst" || pathCheck.includes('admin') ) {
        const roleToPass = "admin"
        const path = pathCheck.split('/')
        const id = path[path.length - 1];
        this.setState({
          isAdmin: true,
          accountId: id,
        })
        this.getDetails(token, "get_corporate_type");
        this.getDetails(token, "industries");
        this.getDetails(token, `get_ekyc_detail?account_id=${id}` , roleToPass);
      }else{
        this.getDetails(token, "get_ekyc_detail");
        if (currentPath === "/EKYC1") {
          this.getDetails(token, "get_corporate_type");
          this.getDetails(token, "industries");
        }
        if (currentPath === "/EKYC4") {
          this.getDetails(token, "get_declaration");
        }
      }
    }
  }

  handleInput = (e: any) => {
    const { value, name } = e.target;

    const newState: any = {
      [name]: value,
    };
    this.setState(newState);
  };

  handleErrors = (error:any, touch:any) =>{
    return touch && Boolean(error);
  }

  handleHelperText = (text:any, touch:any) => {
    return touch && text
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.getResponse(apiRequestCallId, responseJson);
      }
    }
  }

  getResponse = (id: any, response: any) => {
    if (id === this.kycForm1API || id === this.kycForm4API) {
      this.receiveFormSubmitResponse(id, response);
    }

    if (id === this.getKycAPI) {
      this.recieveKycResponse(response);
    }

    if (id === this.getCorporateAPI) {
      this.receiveCorporateResponse(response);
    }

    if (id === this.getIndustryAPI) {
      this.receiveIndustryResponse(response);
    }

    if (id === this.getDeclarationAPI) {
      this.receiveDeclarationResponse(response);
    }
  };

  receiveFormSubmitResponse = (id: any, response: any) => {
    if (response?.data && response.data?.attributes) {
      if (id === this.kycForm1API) {
        const adminRedirection = { id: this.state.accountId}
        this.state.isAdmin ? this.props.navigation.navigate("AdminKyc2Review" , adminRedirection) : this.props.navigation.navigate("EKYC2");
      } else if (id === this.kycForm4API) {
        this.setState({ isOpen: true });
      }
    } else {
      const errorMessages =
        response?.errors
          ?.map((error: any) => Object.values(error))
          .flat()
          .join(", ") || "";
      alert(errorMessages);
    }
  };

  recieveKycResponse = (response: any) => {
    const data = response.data?.attributes;
    if (response?.data && data) {
      this.setState({ company_name: data?.company_name });
      this.setState({ tax_registration_no: data?.tax_registration_no });
      this.setState({ tax_identification_no: data?.tax_identification_no });
      this.setState({
        registered_business_address: data?.registered_business_address,
      });
      this.setState({ operating_address: data?.operating_address });
      this.setState({ country_name: data?.country_name || "Jamaica" });
      this.setState({
        company_registration_number: data?.company_registration_number,
      });
      this.setState({ postal_code: data?.postal_code });
      this.setState({ zip_code: data?.zip_code });
      this.setState({ new_phone_number: data?.new_phone_number });
      this.setState({ email: data?.email });
      this.setState({ primary_contact_name: data?.primary_contact_name });
      this.setState({ primary_contact_email: data?.primary_contact_email });
      this.setState({
        primary_contact_telephone: data?.primary_contact_telephone,
      });
      this.setState({ secondary_contact_name: data?.secondary_contact_name });
      this.setState({ secondary_contact_email: data?.secondary_contact_email });
      this.setState({
        secondary_contact_telephone: data?.secondary_contact_telephone,
      });
      this.setState({ average_annual_earnings: data?.average_annual_earnings });
      this.setState({
        number_of_employees_full_time: data?.number_of_employees_full_time,
      });
      this.setState({
        number_of_employees_part_time: data?.number_of_employees_part_time,
      });
      this.setState({ declaration_name: data?.declaration_name });
      this.setState({ declaration_date: data?.declaration_date });
      this.setState({ digital_signature: data?.digital_signature });
      this.setState({ declaration: data?.declaration });

      if (data?.account_block_corporate_types) {
        this.setState({
          account_block_corporate_types_id:
            data?.account_block_corporate_types.id,
        });
      }
      if (
        data?.account_block_industries &&
        data?.account_block_industries.length
      ) {
        this.setState({
          industry_ids: data?.account_block_industries[0].id,
        });
      }
    }
  };

  receiveCorporateResponse = (response: any) => {
    if (response.data) {
      this.setState({ corporateOptions: response.data });
    }
  };

  receiveIndustryResponse = (response: any) => {
    if (response.data) {
      this.setState({ industryOptions: response.data });
    }
  };

  receiveDeclarationResponse = (response: any) => {
    if (response.data) {
      const declarationTexts = response.data
        .filter((item: any) => item.type === "declaration")
        .map((item: any) => item.attributes.declaration_text)
        .join(", ") || "Declaration not present";
      this.setState({ declarationText: declarationTexts });
    }
  };

  saveFirstKyc = (values: any) => {
    if (!!values && !!this.state.token) {
      const array2 = []
      array2.push(values.industry_ids)
      const bodyData = {
        ...values,
        industry_ids:array2
      }
      this.updateEkyc(bodyData, "kycForm1");
    }
  };

  submitLastKyc = (values: any) => {
    if (!!this.state.token && !!values ) {
      this.updateEkyc(values, "kycForm4");
    }
  };

  updateEkyc = (values: any, type: string) => {
    const { token , isAdmin , accountId } = this.state
    const endPoint = isAdmin ? `/kyc_update?account_id=${accountId}` : "kyc_update "
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const httpBody = {
      ...values,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (type === "kycForm1") {
      this.kycForm1API = requestMessage.messageId;
      this.kycForm4API = "";
    } else if (type === "kycForm4") {
      this.kycForm4API = requestMessage.messageId;
      this.kycForm1API = "";
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleModelClose = () => {
    this.props.navigation.navigate("EmailLogin");
    localStorage.removeItem("auth_token");
    this.setState({ isOpen: false });
  };

  getDetails = (token: any, endPoint: string , role? : string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (endPoint === "get_ekyc_detail" || role === "admin") {
      this.getKycAPI = requestMessage.messageId;
    } else if (endPoint === "get_corporate_type") {
      this.getCorporateAPI = requestMessage.messageId;
    } else if (endPoint === "industries") {
      this.getIndustryAPI = requestMessage.messageId;
    } else if (endPoint === "get_declaration") {
      this.getDeclarationAPI = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
