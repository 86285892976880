export const nStyles:any = {
  backNews: {
    fontSize: "18px",
    marginBottom: "28px",
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: '500',
    cursor: "pointer", 
    color: '#F3B229',
    lineHeight: "27px",
    wordWrap: 'break-word'
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "40px",
    "& img":{
      borderRadius: "10px",
      height: "auto",
      width: "auto",
      maxWidth: "520px",
      maxHeight: "320px",
    },
    '@media (max-width: 600px)': {
      "& img": {
          width: "50%", // Set width to 50% on screens less than 600px
          height: "50%", // Set height to 50% on screens less than 600px
          maxWidth: "none", // Remove max-width constraint
          maxHeight: "none", // Remove max-height constraint
      },
    },
    '@media (min-width: 601px) and (max-width: 900px)': {
      "& img": {
          width: "70%", // Adjust width to 70% on screens between 601px and 900px
          height: "70%", // Adjust height to 70% on screens between 601px and 900px
          maxWidth: "none", // Remove max-width constraint
          maxHeight: "none", // Remove max-height constraint
      },
    },
  },
  newsDate: {
    fontSize: "12px",
    color: '#2E2A40',
    wordWrap: 'break-word',
    fontWeight: '600',
    fontFamily: 'Plus Jakarta Sans',
    marginBottom: "25px",
  },
  htmlContent: {
    fontSize: "18px",
    display: "flex",
    gridRowGap: "16px",
    margin: "0",
    flexDirection: "column",
    "& p, a, h1, h2, h3, h4, h5, h6, span": {
      margin: "0px",
      lineHeight: "27px",
      fontFamily: 'Plus Jakarta Sans',
    },
    "& p": {
      fontWeight: "400",
    },
    "& a": {
      color: "#E2A805 !important",
      target: "_blank",
      rel: "noopener noreferrer",
    }
  },
  responsiveHeaderController: {
    width: "calc(100vw - 286px)"
  },
  '@media (max-width: 600px)': {
    responsiveHeaderController: {
      width: "100%",
      marginTop:'15px'
    },
  },
}