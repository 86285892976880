import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { KYC_Status } from "../../../components/src/Utils/constant";
import { getLocalUserData } from "../../../components/src/Utils/utils";
// Customizable Area Start

export const configJSON = require("./config");


export interface Props {
  navigation: any;
}

interface S {
  // Customizable Area Start
  isClient: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
  }

  async componentDidMount() {
    // Customizable Area Start
    const role = localStorage.getItem("account_role");
    const token = localStorage.getItem("auth_token");
    const url = window.location.href;
    this.handleMount(token, role, url);
    // Customizable Area End
  }

  // Customizable Area Start
  handleMount = (token:any, role:any, url:any) => {
    const { navigation } = this.props;

    if (!token && !role) {
      navigation.navigate(url.includes("/admin") ? "AdminEmailLogin" : "EmailLogin");
    } else {
      if (role !== 'client') {
        navigation.navigate("AdminDashboard");
      } else {
        const client = getLocalUserData();
        if (!!client && client.kyc_status !== KYC_Status.approve) {
          navigation.navigate("EmailLogin")
          localStorage.clear();
        } else {
          navigation.navigate("ClientDashboard");
        }
      }
    }
  }
  // Customizable Area Start
} 
