import React, { Component } from "react";
import { Modal } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CommonButtonWeb from "../Button/CommonButton.web";

interface ApproveModalProps {
  handleSubmitModal: any;
  title?: string;
  handleModelClose: any;
  classes: any;
  open: boolean;
  widthToAdd?:any
}

class ReviewApproveModal extends Component<ApproveModalProps> {
  constructor(props: ApproveModalProps) {
    super(props);
  }
  render() {
    const {
      handleModelClose,
      open,
      title,
      classes,
      handleSubmitModal,
      widthToAdd
    } = this.props;

    return (
      <Modal
        classes={{ root: classes?.MuiModalRoot }}
        open={open}
        onClose={() => handleModelClose()}
      >
        <div>
          <form className={classes?.modalBox4}  style={{width:widthToAdd ? widthToAdd : 'max-content'}}>
            <p className="title">{title || "Are you sure you want to approve this?"}</p>
            <div className="Btn4Container">
              <CommonButtonWeb
                btnText="No"
                handleClick={handleModelClose}
                backgroundColor="#F27151"
              />
              <CommonButtonWeb
                btnText={"Yes"}
                handleClick={handleSubmitModal}
                backgroundColor="#20BB6E"
              />
            </div>
          </form>
          <div className={classes?.modalOverlay4} />
        </div>
      </Modal>
    );
  }
}

const styles: any = {
  modalOverlay4: {
    backgroundColor: "#D8D8D8",
    left: "0",
    width: "100%",
    opacity: "0.33",
    position: "fixed",
    top: "0",
    height: "100%",
    zIndex: "-1",
  },
  MuiModalRoot: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: "50px",
  },
  modalBox4: {
    gap: "28px",
    margin: "auto",
    borderRadius: "12px",
    position: "absolute",
    top: "50%",
    left: "50%",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    padding: "55px 38px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    transform: "translate(-50%, -50%)",
    maxWidth: "900px",
    width: "100%",
    backgroundColor: "white",
    alignItems: "center",
    "& .title": {
      fontSize: "18px",
      fontWeight: '600',
      fontFamily: 'Plus Jakarta Sans',
      textAlign: "center",
      color: '#202A45',
      margin: 0,
      wordWrap: 'break-word'
    },
    "& .Btn4Container": {
      gap: "20px",
      justifyContent: "center",
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      flexWrap:'wrap',
    }
  },
 
};

export default withStyles(styles)(ReviewApproveModal);
