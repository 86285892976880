import { MAIN_DIV_WIDTH } from "../../../components/src/Utils/constant";

const tStyles: any = {
  mainDiv: {
    alignItems: "flex-start",
    justifyContent: "center",
    display: "flex",
    marginTop: "2px",
    flexDirection: "column",
    padding: "0 50px 50px",
    overflowY: "auto",
    flexGrow: 1,
    width:MAIN_DIV_WIDTH,
    gap: "25px",
    "& .title": {
      display: "flex",
      gap: "7px",
      flexDirection: "row",
      alignItems: "center",
      '@media (min-width: 601px) and (max-width: 860px)': {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      '@media (max-width: 560px)': {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      "& h4": {
        margin: "0",
        fontSize: "24px",
        fontStyle: "normal",
        lineHeight: "30px",
        textAlign: "center",
        fontWeight: 600,
        letterSpacing: "-0.48px",
        color: "#2E2A40",
      },
    },
    "& .formDiv": {
      width:"100%",
      alignItems: "flex-start",
      gap: "25px",
      flexDirection: "column",
      justifyContent: "center",
      display: "flex",
      "& .commision": {
        flexDirection: "row",
        display: "flex",
        gap: "18px",
        alignItems: "center",
        "& .bottomLabel": {
          width: "140px",
          margin: 0,
          letterSpacing: "-0.32px",
          marginTop: "-10px",
          fontStyle: "normal",
          lineHeight: "18px",
          textAlign: "center",
          color: "#2E2A40",
          fontWeight: "500",
          fontSize: "12px",
        },
      },
      "& .tableContainer": {
        boxShadow: "0px 3px 4px rgba(90, 88, 88, 0.09)",
        borderRadius: "0px",
        "& table": {
          "& thead": {
            background: "#E5B632",
            "& tr": {
              "& th": {
                fontSize: "14px",
                lineHeight: "18px",
                fontStyle: "normal",
                fontWeight: 600,
                color: "#2E2A40",
                textAlign: "center",
                borderRight: "0.6px solid #979797",
                borderBottom: "0.6px solid #979797",
                letterSpacing: "-0.24px",
                "&:first-child": {
                  paddingLeft: "30px",
                },
                "&:last-child": {
                  paddingRight: "30px",
                  borderRight: "none",
                },
              },
            },
          },
        },
        "& tbody": {
          "& tr": {
            "&:last-child": {
              "& td": {
                borderBottom: "none",
              },
            },
            "& td": {
              fontWeight: 500,
              fontStyle: "normal",
              color: "#2E2A40",
              maxWidth: "325px",
              borderRight: "0.6px solid #979797",
              lineHeight: "15px",
              letterSpacing: "-0.24px",
              borderBottom: "0.6px solid #979797",
              fontSize: "12px",
              "& .indiDesc": {
                fontWeight: 600,
                lineHeight: "13px",
                fontSize: "10px",
                color: "#2E2A40",
                letterSpacing: "-0.24px",
              },
              "& .rankingInputField": {
                width: "90%",
                "& .MuiInputBase-root": {
                  background: "#F4F4F4",
                  "& .MuiInputBase-input": {
                    lineHeight: "15px",
                    letterSpacing: "-0.24px",
                    textAlign: "center",
                    padding: "10px",
                    fontStyle: "normal",
                    color: "#2E2A40",
                    textDecoration: "underline",
                    fontWeight: "600",
                    fontSize: "12px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
                "& .Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "none",
                  },
                },
              },
              "&:first-child": {
                paddingLeft: "30px",
              },
              "&:last-child": {
                borderRight: "none",
                paddingRight: "30px",
              },
            },
          },
        },
      },
    },
  },
  commonTitle: {
    fontWeight: 500,
    margin: 0,
    lineHeight: "27px",
    letterSpacing: "-0.36px",
    fontStyle: "normal",
    fontSize: "18px",
    color: "#2E2A40",
    minWidth:'110px',
  },
  inputField: {
    width: "150px",
    "& .MuiInputBase-root": {
      background: "#FEF1CB",
      "& .MuiInputBase-input": {
        fontStyle: "normal",
        padding: "11px 20px 10px",
        fontWeight: "600",
        lineHeight: "21px",
        letterSpacing: "-0.32px",
        fontSize: "16px",
        color: "#2E2A40",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-adornedStart": {
      fontSize: "16px",
      fontWeight: 600,
      color: "#2E2A40",
      paddingLeft: "20px",
      lineHeight: "21px",
      "& .MuiInputBase-input": {
        paddingLeft: "5px !important",
      }
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e5b632",
      },
    },
    position: "relative",
    "& .MuiFormHelperText-root": {
      color: "#ea2f2f",
      margin: 0,
      marginTop: "5px",
      position: "absolute",
      bottom: "-22px",
      width: "max-content",
    },
  },
  textBtn: {
    width: "140px",
    fontStyle: "normal",
    fontWeight: "600",
    height: "41px",
    borderRadius: "4px",
    letterSpacing: "-0.32px",
    color: "#2E2A40",
    background: "#FEF1CB",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "21px",
    padding: "11px 10px 10px",
  },
  saveBtn: {
    color: "#fff",
    margin: "23px 0 0",
    padding: "10.5px 16px",
    boxShadow: "none",
    width: "320px",
    fontWeight: 600,
    textTransform: "capitalize",
    height: "50px",
    backgroundColor: "#e5b632",
    float: "right",
  },
  extraFlexProvider:{
    '@media (max-width: 1040px)': {
      flexDirection: "column",
      gap:'15px',
    },
  },
  '@media (max-width: 600px)': {
    mainDiv: {
      width: "100%",
      marginTop:'25px'
    },
    saveBtn:{
      width:'50%'
    }
  },
  '@media (min-width: 601px) and (max-width: 960px)': {
    saveBtn: {
      width: "50%", 
    },
  },
};
export default tStyles;
