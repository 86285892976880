import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Box, Tooltip , Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// @ts-ignore
import { styles } from "./NewsStyles"
import { formatDate2 } from "../Utils/utils"


const NewsComponent = ({ data, readMoreHandler, classes }: any) => {

  const stripHtmlTagsAndLimitWords = (html: string) => {
    const wordLimit = 40
    const temp = document.createElement('div');
    temp.innerHTML = html;
    const plainText = temp.innerText;

    const words = plainText.split(' ');
    const limitedWords = words.slice(0, wordLimit).join(' ');

    if (words.length > wordLimit) {
      return `${limitedWords}...`;
    }

    return limitedWords;
  };

  return (
    <Box className={classes.newsCard}>
      {data.map((newsItem: any) => (
        <Grid container spacing={1} key={newsItem?.id}>
          <Card style={{ marginBottom: '20px' }} className={classes.card}>
            <Grid item lg={3} md={3} xs={12} sm={12} className={classes.flexProvider}>
              <CardMedia
                component="img"
                height="140"
                image={newsItem?.image_url}
                alt="News"
                className={classes.imageCard}
              />
            </Grid>
            <Grid item lg={9} md={9} xs={12} sm={12}>
              <CardContent className={classes.bodyContent}>
                <Box sx={{ height: '48px', width: '100%' }}>
                  <Tooltip title={newsItem?.title} arrow classes={{ tooltip: classes.tooltip }} placement={"top"} disableFocusListener disableTouchListener>
                    <Typography onClick={() => readMoreHandler(newsItem?.id)} variant="h5" className={classes.bodyTitle}>
                      {newsItem?.title}
                    </Typography>
                  </Tooltip>
                  <Typography className={classes.createdAt}>
                    {formatDate2(newsItem?.created_at)}
                  </Typography>
                </Box>
                <Typography variant="h5" className={classes.mainBody}>
                  {stripHtmlTagsAndLimitWords(newsItem?.body)}
                </Typography>
                <Button onClick={() => readMoreHandler(newsItem?.id)} variant="outlined" className={classes.button}>
                  Read More
                </Button>
              </CardContent>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Box>
  );
};

export default withStyles(styles)(NewsComponent);