// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';


import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import UserProfile from '../../blocks/user-profile-basic/src/UserProfile.web';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import EmailLogin from '../../blocks/email-account-login/src/EmailLogin.web';
import EmailRegistration from '../../blocks/email-account-registration/src/EmailRegistration.web';
import SideNavigationMenu from '../../blocks/navigationmenu/src/SideNavigationMenu.web';
import KycForm1 from '../../blocks/EkycVerification2/src/KycForm1.web';
import KycForm4 from '../../blocks/EkycVerification2/src/KycForm4.web';
import EKYC2 from "../../blocks/EkycVerification2/src/EKYC2.web";
import EKYC3 from "../../blocks/EkycVerification2/src/EKYC3.web";
import { userRoles } from '../../components/src/Utils/constant';
import ActivityLog from '../../blocks/analytics/src/ActivityLog.web';
import EKYCNotification from "../../blocks/notifications/src/EKYCNotification.web"
import NotificationSettings from '../../blocks/notificationsettings/src/NotificationSettings.web';
import AdminChangeRequest from '../../blocks/AdminConsole/src/AdminChangeRequest.web';
import ValueChainEvaluation from "../../blocks/EkycVerification2/src/ValueChainEvaluation.web"
import SubmitInvoice from "../../blocks/FileAttachment/src/SubmitInvoice.web"
import PurchaseOrder from "../../blocks/FileAttachment/src/PurchaseOrder.web"
import InvoiceReview from "../../blocks/notifications/src/InvoiceReview.web";
import PurchaseOrderReview from "../../blocks/notifications/src/PurchaseOrderReview.web";
import ChangeRequestReview from "../../blocks/notifications/src/ChangeRequestReview.web";
import AdminDashboard from "../../blocks/dashboard/src/AdminDashboard.web"
import ClientDashboard from "../../blocks/dashboard/src/ClientDashboard.web"
import ProcessPayment from "../../blocks/PaymentAdmin/src/ProcessPayment.web"
import ActiveTabs from "../../blocks/BaselineReporting/src/ActiveTabs.web"
import ClientStatements from "../../blocks/BaselineReporting/src/ClientStatements.web"
import CompanyReportsTabs from "../../blocks/BaselineReporting/src/CompanyReportsTabs.web"
import FactorAgreemenetReview from "../../blocks/notifications/src/FactorAgreemenetReview.web"
import ClientInvoiceTable from "../../blocks/InvoiceBilling/src/ClientInvoiceTable.web"
import ClientNews from "../../blocks/postcreation/src/ClientNews.web"
import NewsClientDetails from "../../blocks/postcreation/src/NewsClientDetails.web"

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const routeMap = {
  
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  AdminForgotPassword: {
    component: ForgotPassword,
    path: '/admin/ForgotPassword'
  },
  NewPassword: {
    component: NewPassword,
    path: '/CreatePassword'
  },
  adminNewPassword: {
    component: NewPassword,
    path: '/admin/CreatePassword'
  },
  UserProfile: {
    component: UserProfile,
    path: '/UserProfile'
  },
  EmailLogin: {
    component: EmailLogin,
    path: '/login'
  },
  AdminEmailLogin: {
    component: EmailLogin,
    path: '/admin/login'
  },
  SideNavigationMenu: {
    component: SideNavigationMenu,
    path: '/navigation'
  },
  EmailRegistration: {
    component: EmailRegistration,
    path: '/sign-up'
  },
  
  ClientDashboard: {
    component: ClientDashboard,
    path: '/',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  KycDetails1: {
    component: KycForm1,
    path: '/EKYC1',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  KycDetails4: {
    component: KycForm4,
    path: '/EKYC4',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  EKYC2: {
    component : EKYC2,
    path: "/EKYC2",
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  EKYC3: {
    component : EKYC3,
    path: "/EKYC3",
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  FactoringAgreement : {
    component: AdminChangeRequest,
    path: '/factoring-agreement',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true,
  },
  ClientChangeRequestForm : {
    component: AdminChangeRequest,
    path: '/change-request-form',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true,
  },
  Invoice : {
    component: InfoPage,
    path: '/invoice',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true
  },
  Statement : {
    component: ClientStatements,
    path: '/statements',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true
  },
  CarbyneNews : {
    component: ClientNews,
    path: '/news',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true
  },
  Dashboard1:{
    component: Dashboard,
    path: '/',
    exact: true
  },
  Dashboard2:{
    component: Dashboard,
    path: '/admin',
    exact: true
  },
  AdminDashboard : {
    component: AdminDashboard,
    path: '/admin',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  AdminNotifications:{
    component: NotificationSettings,
    path: '/admin/notifications',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  AdminClientAccountReports: {
    component: InfoPage,
    path: '/admin/client-account-reports',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  AdminCompanyReports: {
    component: CompanyReportsTabs,
    path: '/admin/company-reports',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  AdminCompanyReportsPastDue: {
    component: CompanyReportsTabs,
    path: '/admin/company-reports/past-due',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  AdminCompanyReportsVelocity: {
    component: CompanyReportsTabs,
    path: '/admin/company-reports/velocity',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  AdminActivityLog: {
    component: ActivityLog,
    path: '/admin/activity-log',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  AdminChangeRequestForm: {
    component: AdminChangeRequest,
    path: '/admin/change-request-form',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  AdminEKYCreview: {
    component: EKYCNotification,
    path: '/admin/EKYC-review/:id',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true,
  },
  AdminChangeRequest: {
    component: AdminChangeRequest,
    path: '/admin/change-request',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  ClientChangeRequest: {
    component: AdminChangeRequest,
    path: '/change-request',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true
  },
  AdminEKYCevaluation: {
    component: ValueChainEvaluation,
    path: '/admin/ekyc-evaluation/:id',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  PurchaseOrder:{
    component: PurchaseOrder,
    path: '/purchase-order',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true
  },
  SubmitInvoice:{
    component: SubmitInvoice,
    path: '/submit-invoice',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true
  },
  AdminPurchaseOrder:{
    component: PurchaseOrder,
    path: '/admin/purchase-order',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  AdminSubmitInvoice:{
    component: SubmitInvoice,
    path: '/admin/submit-invoice',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  AdminInvoiceReview: {
    component: InvoiceReview,
    path: '/admin/invoice-review/:id',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  AdminInvoiceReviewBalance: {
    component: InvoiceReview,
    path: '/admin/client-reports/invoices/invoice-review/:id',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  AdminPOReviewBalance: {
    component: PurchaseOrderReview,
    path: '/admin/client-reports/PO/PO-review/:id',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  AdminPurchaseOrderReview: {
    component: PurchaseOrderReview,
    path: '/admin/purchase-order-review/:id',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true,
  },
  AdminChangeRequestReview: {
    component: ChangeRequestReview,
    path: '/admin/change-request-review/:id',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  AdminProcessPayment : {
    component: ProcessPayment,
    path: "/admin/client-reports/process-payment/:id",
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  ActiveTabs: {
    component: ActiveTabs,
    path: '/admin/client-reports',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  ActiveTabsClients: {
    component: ActiveTabs,
    path: '/admin/client-reports/clients',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  FactorAgreemenetReview: {
    component: FactorAgreemenetReview,
    path: '/admin/client-reports/factor-agreement-review/:id',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true,
  },
  AdminKyc1Review: {
    component: KycForm1,
    path: '/admin/client-reports/EKYC1/:id',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true,
  },
  AdminKyc2Review: {
    component : EKYC2,
    path: "/admin/client-reports/EKYC2/:id",
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  AdminKyc3Review: {
    component : EKYC3,
    path: "/admin/client-reports/EKYC3/:id",
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true,
    isBack: true
  },
  AdminClientInvoicesTable : {
    component: ClientInvoiceTable ,
    path: '/admin/client-reports/invoices/:id',
    roles: `${userRoles.admin},${userRoles.analyst}`,
    isProtected: true,
    exact: true
  },
  NewsClientDetails : { 
    component: NewsClientDetails ,
    path: '/news-details/:id',
    roles: `${userRoles.client}`,
    isProtected: true,
    exact: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100%'}}>
        {WebRoutesGenerator({ routeMap })}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            theme="light"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        <ModalContainer />
      </View>
    );
  }
}

export default App;