import { MAIN_DIV_WIDTH } from "../../../components/src/Utils/constant";

export const styles: any = {
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0 50px 50px",
    gap: "18px",
    overflowY: "auto",
    width: MAIN_DIV_WIDTH,
    "& .title": {
      marginTop: "3px",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "30px",
      letterSpacing: "-0.48px",
      color: "#2E2A40",
    },
    "& .subTitleContainer": {
      "& .title": {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "23px",
        letterSpacing: "-0.48px",
        color: "#2E2A40",
      },
      "& .date": {
        float: "left",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "13px",
        textAlign: "center",
        letterSpacing: "-0.24px",
        color: "#757575",
      },
    },
    "& .tableContainer": {
      boxShadow: "none",
      width: "100%",
      "& table": {
        "& tbody": {
          "& tr": {
            "& td": { 
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "12px 22px 12px 10px",
              gap:'50px',
              border: "none",
              "& .leftDiv": {
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                alignItems: "center",
                justifyContent:'space-between',
                width:'450px',
                paddingLeft:'10px',
                "& .docTitle": {
                  cursor: "pointer",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "15px",
                  display: "flex",
                  alignItems: "center",
                  letterSpacing: "-0.24px",
                  color: "#2E2A40",
                  width: "243px",
                },
              },
              "& .rightDiv": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "18px",
                "& .reviewBtn": {
                  width: "94px",
                  height: "28px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#FFFFFF",
                  border: "1.0757px solid rgba(0, 0, 0, 0.08)",
                  borderRadius: "5.37848px",
                  fontFamily: "Arial, sans-serif",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "13px",
                  leadingTrim: "both",
                  textEdge: "cap",
                  color: "#979797",
                },
              },
              "& .collapseContainer": {
                display: "flex",
                flexDirection: "column",
                marginBottom:"18px",
                justifyContent: "space-between",
                gap: "18px",
                "& .collapse": {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingLeft:'10px',
                  "& p": {
                    display: "flex",
                    alignItems:"center",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontFeatureSettings: "'pnum' on, 'lnum' on",
                    color: "#2E2A40",
                    opacity: 0.8,
                    "& img": {
                      paddingRight: "8px",
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    "& .btnContainer": {
      width: "100%",
      marginTop: "10px",
      // '@media (max-width: 600px)': {
        display: "flex",
        gap:'15px',
        justifyContent: "flex-end",
      // },
      "& .btn":{
        width: "210px",
        background: "#F27151",
        borderRadius: "4px",
        float: "right",
        "& .MuiButton-label":{
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "20px",
          textAlign: "center",
          letterSpacing: "-0.32px",
          color: "#FFFFFF",
          textTransform: "capitalize",
        }
      },
      "& .approveBtn":{
        background: "#20BB6E",
        marginLeft: "20px",
      },
      "& .disabled":{
        background:'#B6B6B6'
      }
    }
  },
  isReviewed:{
    width: "94px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#20BB6E",
    border: "1.0757px solid rgba(0, 0, 0, 0.08)",
    borderRadius: "5.37848px",
    fontFamily: "Arial, sans-serif",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "13px",
    leadingTrim: "both",
    textEdge: "cap",
    color: "#fff",
    opacity : '0.8',
    pointerEvents: 'none',
  },
  notReviewed:{
    width: "94px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
    border: "1.0757px solid rgba(0, 0, 0, 0.08)",
    borderRadius: "5.37848px",
    fontFamily: "Arial, sans-serif",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "13px",
    leadingTrim: "both",
    textEdge: "cap",
    color: "#979797",
  },
  '@media (max-width: 600px)': {
    mainDiv:{
      width: "100%",
      marginTop:'15px'
    }
  },
};
