export const styles: any = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "40px",
    flexWrap: "wrap",
    width: "100%",
    "& p":{
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '22px',
      fontFeatureSettings: "'pnum' on, 'lnum' on",
      color: '#333333',
    }
  },
  pagination: {
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
      "& li:first-child": {
        "& button": {
          background: '#FFEAB0',
        },
      },
      "& li:last-child": {
        "& button": {
          background: '#E5B632',
          color: 'white',
        },
      },
      "& li": {
        "& .MuiPaginationItem-outlined": {
          border: 'none',
        },
        "& .MuiPaginationItem-page.Mui-selected": {
          border: '1px solid #E5B632',
          background: 'transparent',
        },
      },
    },
  },
  "@media only screen and (min-width: 600px) and (max-width: 940px)":{
    container: {
      gap: "0",
    },
    pagination: {
      "& .MuiPagination-ul": {
        flexWrap: "wrap",
      },
    },
  },
  '@media (max-width: 600px)': {
    container: {
      gap: "0",
    },
    pagination: {
      "& .MuiPagination-ul": {
        flexWrap: "wrap",
      },
    },
  },
};