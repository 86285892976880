// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  Typography,
  InputLabel,
  TextField,
  Checkbox,
  Button,
  FormHelperText,
  Modal,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { styles } from "./KycFormStyles.web";
import KycForm1Controller, { Props } from "./KycForm1Controller.web";
import {onKeyDown} from "../../../components/src/Utils/utils"
import { KycForm4Schema } from "../../../components/src/Utils/validations";

class KycForm4 extends KycForm1Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {
      declaration_name,
      declaration_date,
      digital_signature,
      declaration,
      declarationText
    } = this.state;
    return (
      <div className={classes.mainDiv}>
        <Grid className={classes.rightMainDiv}>
          <Box>
            <Typography variant="h3" className={classes.pageTitle}>
              Declaration
            </Typography>
          </Box>
          <Formik
            enableReinitialize={true}
            validateOnBlur={true}
            initialValues={{
              declaration_name: declaration_name,
              declaration_date: declaration_date,
              digital_signature: digital_signature,
              declaration: declaration,
            }}
            validationSchema={KycForm4Schema}
            onSubmit={(values) => this.submitLastKyc(values)}
          >
            {({ errors, values, handleSubmit, handleChange, touched}) => {
              return (
                <form onSubmit={handleSubmit} onKeyDown={onKeyDown} style={{ width: "100%" }}>
                  <Grid container spacing={3} direction={"row"}>
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      alignItems="flex-start"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Checkbox
                          data-test-id="textInputAuthorize"
                          className={classes.checkbox}
                          icon={<RadioButtonUncheckedIcon />}
                          name="declaration"
                          checkedIcon={
                            <CheckCircleIcon className={classes.checkedIcon} />
                          }
                          checked={values.declaration}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          className={classes.checkboxLabel}
                        >
                          {declarationText}
                        </Typography>
                        {this.handleErrors(errors.declaration, touched.declaration) && (
                          <FormHelperText className={classes.helperText}>
                            {errors.declaration}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item sm={12} xs={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Name*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputName"
                        type="text"
                        variant="outlined"
                        name="declaration_name"
                        placeholder={"e.g. John Smith"}
                        value={values.declaration_name}
                        onChange={(e)=>handleChange(e)}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(errors.declaration_name, touched.declaration_name)}
                        helperText={this.handleHelperText(errors.declaration_name, touched.declaration_name)}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Date*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputDate"
                        placeholder="e.g. CCYY-MM-DD"
                        type="date"
                        variant="outlined"
                        fullWidth
                        // format={"yyyy-MM-dd"}
                        name="declaration_date"
                        value={values.declaration_date}
                        onChange={(e)=>handleChange(e)}
                        className={classes.inputField}
                        error={this.handleErrors(errors.declaration_date, touched.declaration_date)}
                        helperText={this.handleHelperText(errors.declaration_date, touched.declaration_date)}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12} md={4}>
                      <InputLabel className={classes.inputLabel}>
                        Digital Signature type your name in Capital*
                      </InputLabel>
                      <TextField
                        data-test-id="txtInputSignature"
                        variant="outlined"
                        type="text"
                        name="digital_signature"
                        placeholder={"Please print signature"}
                        value={values.digital_signature}
                        onChange={(e)=>handleChange(e)}
                        fullWidth
                        className={classes.inputField}
                        error={this.handleErrors(errors.digital_signature, touched.digital_signature )}
                        helperText={this.handleHelperText(errors.digital_signature, touched.digital_signature )}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ width: "100%" }}>
                    <Button
                      data-test-id={"btnSubmit1"}
                      variant="contained"
                      fullWidth
                      className={classes.saveBtn}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Grid>
                </form>
              );
            }}
          </Formik>
          <Modal
            classes={{ root: classes.MuiModalRoot }}
            open={this.state.isOpen}
            onClose={this.handleModelClose}
          >
            <div>
              <div className={classes.modalBox}>
                <span className={classes.modalText}>
                  {" "}
                  Thank you for submitting your personal information and
                  uploading your documents. We will review your details and
                  notify you via email.
                </span>
                <Button
                  className={classes.doneButton}
                  onClick={this.handleModelClose}
                >
                  Done
                </Button>
              </div>
              <div className={classes.modalOverlay} />
            </div>
          </Modal>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(KycForm4);
// Customizable Area End
