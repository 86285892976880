// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export type token = string | null;

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  token: any;
  clientInvoiceData: [];
  user_id: string | null;
  isLoading: boolean;
}

interface SS {
  id: any;
}

export default class NotificationSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getDataApi: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      clientInvoiceData: [],
      token: "",
      isLoading: false,
      user_id: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  async componentWillMount() {
    const token = localStorage.getItem("auth_token");
    const path: any[] = window.location.pathname.split("/");
    const id = path[path.length - 1];
    if (token && id) {
      this.setState({ token: token, user_id: id, isLoading: true });
      this.getDataApi = this.invoiceAPICall(token,`get_all_invoice_admin?account_id=${id}`,"GET");
    }
  }

  handleRowClick = (row: any) =>{
    if(row.order_type === "invoice_order"){
      this.props.navigation.navigate("AdminInvoiceReviewBalance", { id: row.id });
    } else if(row.order_type === "purchase_order"){
      this.props.navigation.navigate("AdminPOReviewBalance", { id: row.id });
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getDataApi) {
          this.getDataResponse(responseJson);
        }
      } else {
        this.setState({ isLoading: false }); 
      }
    }
  }

  getDataResponse = (res: any) => {
    if (res.data) {
      this.setState({
        clientInvoiceData: res.data,
        isLoading: false,
      });
    } else {
      this.setState({
        clientInvoiceData: [],
        isLoading: false,
      });
    }
  };

  invoiceAPICall = (
    token: token,
    endPoint: string,
    method: string,
  ) => {

    const table_header1 = {
      token: token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(table_header1)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };
}
// Customizable Area End
