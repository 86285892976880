export const styles: any = {
    rootContainer: {
        color: '#fff'
    },
    headerCell: {
        backgroundColor: '#F6EACC',
    },
    clientNameCell: {
        width: '95%'
    },
    tableHeaderClasses: {
        background: "#F6EACC",
    },
    dateViewerContainer: {
        "& .popperHolder": {
            top: 'auto'
        },
        fontSize: '10px',
        width: '50%'
    },
    sectorMenuPropsClass: {
        borderRadius: "5px",
        padding: "8px 20px",
        marginTop: "5px",
        maxHeight: "120px",
        border: "1px solid #E7E7E7",
        boxShadow: "0px 2px 3px rgba(41, 41, 41, 0.13)",
        "& .MuiMenu-list": {
            padding: "0 10px 0 0",
            "& .Mui-selected": {
                color: "#e5b632",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
            "& li": {
                backgroundColor: "transparent !important",
                fontSize: "12px",
                lineHeight: "15px",
                color: "#757575",
                fontWeight: 400,
                padding: "4px 0 !important",
                "& :hover": {
                    backgroundColor: "white",
                },
                "& :active": {
                    backgroundColor: "white",
                }
            },
        },
        "&::-webkit-scrollbar": {
            width: "3px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "8px",
            background: "#BABABA",
        },
        "&::-webkit-scrollbar-track": { background: "white", },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
        },
    },
    sectorSelectField: {
        height: "50px",
        "& .MuiSelect-root": {
            padding: "16px",
            fontWeight: 500,
            color: "#757575",
            fontSize: "14px",
        },
        margin: 0,
    },
    clientSearchCustomClass: {
        "& .MuiInputBase-root": {
            borderRadius: "4px !important"
        }
    },
    sectorBox: {
        "& .Mui-focused": { "& .MuiOutlinedInput-notchedOutline": { borderColor: "#e5b632" } },
    },

    dateViewerBorder: {
        height: '36px',
        display: 'flex',
        width: '100%',
        borderRadius: '4px',
        fontSize: '12px',
        border: '1px solid #E7E7E7',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',
        padding: '10px',
        color: '#979797'
    },
    clearClientFilter: {
        "& .MuiButton-label": {
            letterSpacing: "0px",
            fontSize: "13px !important",
            padding: "2px 10px 0px !important",
            whiteSpace: "nowrap",
        },
        display: "grid",
        alignContent: "center",
        marginBottom: "2px",
        width: "110px !important",
        marginRight: "7px",
        height: "30px !important",
    },
    tableClass: {
        color: 'white',
        "& .MuiTableContainer-root": {
            "& table": {
                "& thead": {
                    "& tr": {
                        "& th": {
                            "&:first-child": {
                                position: 'static',
                                "& p": {
                                    justifyContent: 'flex-start',
                                },
                                background: '#F6EACC',
                                "&::after": {
                                    content: "''",
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    height: '100%',
                                    width: '0.6px',
                                    background: 'white !important',
                                    zIndex: 1
                                },
                            },
                            "& div": {
                                // justifyContent: 'center'
                            },
                            "& p": {
                                justifyContent: 'center'
                            },

                            "&:last-child": {
                                background: '#F6EACC',
                                position: 'static',
                            },
                        },
                    },
                },
                "& tbody": {
                    "& tr": {
                        "& td": {
                            fontWeight: '500',
                            "&:last-child": {
                                position: 'static',
                                background: '#fff',
                            },
                            fontSize: '12px',
                            "&:first-child": {
                                position: 'static',
                                background: '#fff',
                                "&::after": {
                                    content: "''",
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    height: '100%',
                                    width: '0.6px',
                                    background: 'white',
                                    zIndex: 1
                                },
                            },
                        },

                    },
                },
            },
        }
    },
    borderProvider: {
        marginTop: '15px',
        width: '10px',
        borderRadius: '40px',
        border: '1px solid',
    },
    dateViewerIcon: {
        width: '22px',
        height: '20px',
    },
    statementTableGrid: {
        "& .statementProvider": {
            fontSize: '18px',
            fontWeight: 800
        },
        "& .clientDetailsProvider": {
            display: 'flex',
            gap: '20px',
            flexWrap: "wrap",
            padding: '20px 0px',
            "& .ClientBox1": {
                minWidth: '200px',
                display: 'flex',
                gap: '10px',
                "& .ClientBox1Header": {
                    minWidth: '100px',
                    letterSpacing: '-0.36px'
                },
                "& .ClientBox1value": {
                    minWidth: '150px',
                    letterSpacing: '-0.36px',
                    fontWeight: 800,
                    wordWrap: 'break-word'
                },
            },
            "& .ClientBox2": {
                minWidth: '250px',
                display: 'flex',
                gap: '10px',
                "& .ClientBox2Header": {
                    minWidth: '110px',
                    letterSpacing: '-0.36px'
                },
                "& .ClientBox2value": {
                    minWidth: '150px',
                    letterSpacing: '-0.36px',
                    fontWeight: 800,
                    wordWrap: 'break-word'
                },
            },
            "& .ClientBox3": {
                maxWidth: '300px',
                display: 'flex',
                gap: '10px',
                "& .ClientBox3Header": {
                    minWidth: '60px',
                    letterSpacing: '-0.36px'
                },
                "& .fontWeightProvider": {
                    width: '222px',
                    wordWrap: 'break-word',
                    letterSpacing: '-0.36px',
                    fontWeight: 800,
                    fontSize: '14px',
                }
            },
        },
        "& .clientPayment": {
            display: 'flex',
            maxWidth: '335px',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '40px',
            marginTop: '25px',
            fontSize: '14px',
            fontWeight: 800,
            "& .paymentBox": {
                width: '150px',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#FED86C',
                fontSize: '12px',
                borderRadius: '4px'
            }
        }
    },
    searchGrid: {
        display: 'flex',
        flexWrap: "wrap",
        justifyContent: 'space-between',
        alignItems: 'center',
        "& .iconsHolder": {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '15px',
            flexWrap: 'wrap'
        }
    },
    DownloadPositionProvider: {
        alignItems: "flex-end",
        justifyContent: "flex-end",
        gridColumnGap: "8px",
        display: "flex",
        padding: '8px'
    },
    DateSelectorPositionProvider: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
    },
    '@media (max-width: 599px)': {
        statementTableGrid: {
            marginTop: '50px',
        },
        updatedWidthProvider: {
            width: '100%',
        },
    },
    '@media (max-width: 600px)': {
        responsiveProvider: {
            width: '100%'
        }
    },
}