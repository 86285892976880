import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import ClientNewsController, {
    Props,
} from "./ClientNewsController.web";
import { styles } from "./ClientNewsStyles.web";
//@ts-ignore
import NewsComponent from "../../../components/src/CustomNewsList/NewsComponent.web"
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";

export class ClientNews extends ClientNewsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const {newsData , isLoading} = this.state;
        return (
            <Box sx={{ marginTop: "30px" }} >
                <Grid item>
                    <Box className={classes.responsiveHeaderController} sx={{ padding: "0 32px 32px", width: "100%" }} >
                        <Box className={classes?.newsHeader}>Recent Carbyne News</Box>
                        <Box>
                            {
                                isLoading ?
                                    <CustomLoader open={isLoading} /> :
                                    <NewsComponent
                                        data={newsData}
                                        readMoreHandler={(id: any) => this.handleNewsRedirection(id)}
                                    />
                            }
                        </Box>
                    </Box>
                </Grid>
            </Box>
        );
    }
}

export default withStyles(styles)(ClientNews);
