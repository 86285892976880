import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import {currencyFormat} from "../Utils/utils"

const RADIAN = Math.PI / 180;



const ClientDashboardFactotChart = ({ chartData , TotalFactorAmmountRender }: any) => {

  
    function parseChartData(chartData : any) {
      return chartData.map((entry : any) => ({
        ...entry,
        value: parseFloat(entry?.value?.replace(/[^\d.]/g, '')), // Remove non-numeric characters
      }));
    }

    const updatedChartData = parseChartData(chartData)
    // @ts-ignore
    const totalValue = updatedChartData?.reduce((sum, data) => sum + data.value, 0);
    const outerChartData = [{ name: "Total", value: totalValue }];
    const chartColor = chartData?.map((data:any) => data.color);


    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        index
      }: any) => {
          const entry = chartData[index];
          const value = entry.value;
          const color = entry.fillColors;
          const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          const y = cy + radius * Math.sin(-midAngle * RADIAN);
        
          return (
            <text
              x={x}
              y={y}
              fill={color}
              textAnchor={x > cx ? "start" : "end"}
              dominantBaseline="central"
              style={{ fontSize: "12px", fontWeight: 800 }}
            >
              {currencyFormat(value)}
            </text>
          );
      };

      const renderCustomizedLabel2 = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        index
      }: any) => {
        const entry = chartData[index];
        const color = entry.fillColors;
      
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + Math.cos(-midAngle * RADIAN) * radius + 250; // Adjust the x position
        const y = cy + Math.sin(-midAngle * RADIAN) * radius + 70; // Adjust the y position
      
        return (
          <text
            x={x}
            y={y}
            fill={color}
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
            style={{ fontSize: "12px", fontWeight: 800 }}
          >
            {currencyFormat(TotalFactorAmmountRender)}
          </text>
        );
      };

  return (
    <ResponsiveContainer width="100%" height={320}>
      <PieChart>
        {console.log(chartData)}
        <Pie
          data={updatedChartData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={0}
          outerRadius={110}
          label={renderCustomizedLabel}
          labelLine={false}
        >
            {/* @ts-ignore */}
          {chartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.color}
              radius={entry.radius}
            />
          ))}
        </Pie>
        <Pie
          data={outerChartData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={120}
          outerRadius={134}
          stroke="#E5B632"
          label={(renderCustomizedLabel2)}
          labelLine={false}
        >
          <Cell fill={chartColor[1]} />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ClientDashboardFactotChart;