// Customizable Area Start
import React from "react";
import {
  Grid,
  Box,
  withStyles,
  Select,
  Paper,
  MenuItem,
  Popper,
} from "@material-ui/core";
import PastDueController, {
  Props,
} from "./PastDueController.web";
import { styles } from "./ClientInvoiceStyles.web";
import { parseTableData } from "../../../components/src/Utils/parseData";
import { pastDueColumns } from "../../../components/src/Utils/dummyData";
import CommonTable from "../../../components/src/Table/CommonTable.web";
import AdvanceSearch from "../../../components/src/Search/AdvanceSearch.web";
import {
  downloadDocs,
  printListItemsMsg,
} from "../../../components/src/Utils/utils";
import { downloadExcel } from "./assets";
import TwoDateSelectorWeb from "../../../components/src/CustomDateSelector/TwoDateSelector.web";
import CustomPagination from "../../../components/src/Pagination/CustomPagination.web";
import CommonButtonWeb from "../../../components/src/Button/CommonButton.web";
const calenderIcon2 = require("../../../components/src/Assets/calender.png");

class PastDue extends PastDueController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {
      date_from,
      pastTableData,
      sector,
      isPastLoadTable,
      pastClientOptions,
      page,
      totalPastCount,
      csvPastURL,
      pastSectorOptions,
      isPastClientsLoad,
      client,
      isPastDateRangeOpen,
      date_to,
      anchorPastEl,
      per_page,
    } = this.state;

    const menuPastSectorProps: any = {
      transformOrigin: {
        horizontal: "left",
        vertical: "top",
      },
      classes: { paper: classes.menuSectorPaper },
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
    };

    const data = pastTableData?.data
    const grand_past_total_data: any = pastTableData.grand_total_data;

    return (
      // Required for all blocks
      <Box sx={{ padding: "0 32px 32px", width: "calc(100vw - 285px)" }} className={classes.responsiveProvider}>
        <Grid container>
          <Grid container spacing={2} style={{ marginBottom: '15px' }}>
            <Grid item md={12} lg={8} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={4} lg={4} xs={12} sm={12}>
                <AdvanceSearch
                  data-test-id="client-search-id"
                  placeHolder="Client"
                  handleInputChange={this.handlePastClientInput}
                  width="100%"
                  classNames={classes.clientSearch}
                  loading={isPastClientsLoad}
                  options={pastClientOptions}
                  handleSelectSearch={this.handlePastSelectClient}
                  searchValue={client}
                />
                </Grid>
                <Grid item md={4} lg={4} xs={12} sm={12}>
                <div className={classes.sectorContainer}>
                  <Select
                    className={classes.selectFieldSector}
                    variant="outlined"
                    MenuProps={menuPastSectorProps}
                    id="sectorGlobalId"
                    value={Number(sector.id)}
                    fullWidth
                    onChange={(e) => this.handlePastSector(e)}
                    data-test-id="select-sector"
                  >
                    <MenuItem value={0} style={{ display: "none" }} id="past-sector-id">
                      Sector
                    </MenuItem>
                    {!!pastSectorOptions.length &&
                      pastSectorOptions.map((po: any, index) => (
                        <MenuItem value={Number(po.id)} key={`${po.id + index}`}>
                          {po.name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                </Grid>
                <Grid item md={4} lg={4} xs={12} sm={12}>
                <div className={classes.dateRangeContainer}>
                  <div className="dateViewer" onClick={this.handlePastDateRangeOpen} data-test-id="date-range-dropdown">
                    {date_from} - {date_to}
                    <img className="calendorIcon" src={calenderIcon2} alt="calender" />
                  </div>
                  <Popper
                    anchorEl={anchorPastEl}
                    transition
                    style={{ zIndex: 100 }}
                    disablePortal
                    open={isPastDateRangeOpen}
                    placement="top"
                    className="popperHolder"
                  >
                    <Paper>
                      <TwoDateSelectorWeb
                        data-test-id="date-range-menus"
                        applyFunction={this.handlePastDateRangeFilter}
                        startDate={date_from}
                        handleClose={this.handlePastDateRangeClose}
                        endDate={date_to}
                      />
                    </Paper>
                  </Popper>
                </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid container md={12} lg={4} sm={12} xs={12} className={classes.DownloadPositionProvider1}>
              <CommonButtonWeb btnText="Clear Filters" classNames={classes.clearTableFilter} data-test-id='clear-filter' handleClick={this.clearPastDueFilters} />
              <img
                src={downloadExcel}
                style={{ cursor: "pointer" }}
                onClick={() => downloadDocs(csvPastURL)}
                data-test-id="download-csv"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <CommonTable
            columns={pastDueColumns}
            grand_total_data={grand_past_total_data}
            loadingTableData={isPastLoadTable}
            data-test-id="past-due-table"
            data={data}
            headerClasses={classes.headerInvoicePOClasses}
            isFirstVertical={true}
          />
        </Grid>
        <Box sx={{ marginTop: '35px' }}>
          <CustomPagination
            data-test-id="table-pagination"
            page={page}
            onPaginationChange={this.handlePastChangeReportPage}
            count={totalPastCount}
            msg={printListItemsMsg(totalPastCount, page, per_page)}
          />
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(PastDue);
// Customizable Area End
