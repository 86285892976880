// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import { imgPasswordInVisible } from "./assets";
import { apiCall } from "../../../components/src/Utils/utils"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export type token = string | null;

interface S {
  password: string;
  email: string;
  confirmPassword: string;
  enablePasswordField: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  apiError: any
  token: any
  isOpen:boolean
  isAdmin : boolean
  isTokenValid: boolean
  tokenMessage: string
  isSnackbarEnabled: boolean
  snackbarSeverity:any
}

interface SS {
  id: any;
}

export default class ForgotPasswordController extends BlockComponent<Props, S, SS> {
  apiForgotPassword: string = "";
  apiNewPasswrod: string = ''
  doTokenVerificationAPICall: string = ''

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      enablePasswordField: true,
      apiError: '',
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      token: '',
      isOpen:false,
      isAdmin:false,
      isTokenValid: false,
      tokenMessage:'',
      isSnackbarEnabled:false,
      snackbarSeverity:''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  async componentDidMount() {
    const Url = window.location.pathname || ''
    if (Url.includes('admin')) {
      this.setState({ isAdmin: true });
    }
    const UrlQueryParms = window?.location?.search || ''
    const queryParams = new URLSearchParams(UrlQueryParms) || ''
    const token = queryParams.get("token") || ''
    this.doTokenVerificationAPICall = apiCall(token,`reset_token_validation?token=${token}`,"POST")
    this.setState({ token: token });
  }

  handleClose = () =>{
    this.setState({ isSnackbarEnabled: false }); 
  }

  goToSignIn = () => {
    this.props.navigation.navigate(this.state.isAdmin ? "AdminEmailLogin" : "EmailLogin");
  };

  ResetPasswordSchema = Yup.object().shape({
    password: Yup
      .string()
      .min(8, 'Password must be 8 characters long')
      .matches(/\d/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .test(
        "special-characters",
        "Password requires a special character",
        (value) => {
          return /[^\w]/.test(value) || /_/.test(value);
        }
      ),

    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('password'), null], 'Your password and confirm password must match.'),
  });

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiForgotPassword) {
      if (responseJson.data || responseJson.meta) {
        this.setState({isOpen:true})
        !!this.state.apiError && this.setState({ apiError: "" })
      } else {
        const errorMessages = responseJson?.error || '';
        this.setState({ apiError: errorMessages })
      }
    }

    if (apiRequestCallId === this.apiNewPasswrod) {
      if (responseJson.data || responseJson.meta) {
        this.props.navigation.navigate("EmailLogin")
        !!this.state.apiError && this.setState({ apiError: "" })
      } else {
        const errorMessages = responseJson?.error || '';
        this.setState({ apiError: errorMessages })
      }
    }

    if (apiRequestCallId === this.doTokenVerificationAPICall) {
      if(responseJson.message === "Token is valid"){
        this.setState({
          isTokenValid: true,
          tokenMessage:'Please proceed',
          isSnackbarEnabled:true,
          snackbarSeverity:'success'
        })
      }else if (responseJson.message === "Link expired. Please reach out to Carbyne"){
        this.setState({
          isTokenValid: false,
          tokenMessage:'Link expired. Please reach out to Carbyne',
          isSnackbarEnabled:true,
          snackbarSeverity:'error'
        })
      }else {
        this.setState({
          isTokenValid: false,
          tokenMessage:'Invalid Link',
          isSnackbarEnabled:true,
          snackbarSeverity:'error'
        })
      }
    }
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson)
      }

    }
  }

  passwordForgot = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      email: this.state.email,
    };

    const Url = window.location.protocol + window.location.host;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForgotPassword = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isAdmin? `admin_forgot_password_link?email=${this.state.email}&link=${Url}` : `forgot_password_link?email=${this.state.email}&link=${Url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleModelClose = () => {
    this.setState({ isOpen: false });
  }


  doResetPassword = () => {

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };


    const httpBody = {
      token: this.state.token,
      new_password: this.state.password,
      new_password_confirmation: this.state.confirmPassword
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiNewPasswrod = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isAdmin? `admin_reset_password?token=${httpBody.token}&new_password=${httpBody.new_password}&new_password_confirmation=${httpBody.new_password_confirmation}` : `reset_password?token=${httpBody.token}&new_password=${httpBody.new_password}&new_password_confirmation=${httpBody.new_password_confirmation}`
      
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

}
