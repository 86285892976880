// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export type DocumentsArray = { [x: string]: any }[];

export type token = string | null;

export interface Fields {
  reason_for_request: string;
  description: string;
}

interface S {
  comment5: string;
  requestId: number;
  token: token;
  document4: string;
  isLoading4: boolean;
  changeRequestDocuments: DocumentsArray;
  companyName4: string;
  createdAtDate4: string;
  fields: Fields;
  isApproveOpen: boolean;
  isRejectOpen: boolean;
  accountId:number
}

interface SS {
  id: any;
}

export default class ChangeRequestReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  getChangeRequestDetailsAPI: string = "";
  changeRequestVerificationAPI: string = "";
  updateChangeRqtDocuments: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      document4: "",
      changeRequestDocuments: [],
      companyName4: "",
      token: "",
      isApproveOpen: false,
      isRejectOpen: false,
      createdAtDate4: "",
      fields: {
        reason_for_request: "",
        description: "",
      },
      comment5: "",
      requestId: 0,
      isLoading4: false,
      accountId:0
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    if (localStorage.getItem("auth_token")) {
      const path4: any[] = window.location.pathname.split("/");
      const id4 = path4[path4.length - 1];
      const token4 = localStorage.getItem("auth_token");
      this.setState({
        token: token4,
        requestId: Number(id4),
        isLoading4: true,
      });
      this.getChangeRequestDetailsAPI = this.apiCallChangeRequest(
        token4,
        `bx_block_documentation/factoring_agreements/${id4}`,
        "GET"
      );
    } else {
      this.props.navigation.navigate("EmailLogin");
    }
  }

  handleChangeRequestDocumentsResponse = (response: any) => {
    const companyName4 = response.data.attributes.company_name;
    const createdDate4 = response.data.attributes.created_at;
    const accountId = response.data.attributes.account_id;
    const { reason_for_request, description, documents } =
      response.data.attributes;

    const object = {
      reason_for_request: reason_for_request,
      description: description,
    };
    this.setState({
      changeRequestDocuments: documents,
      fields: object,
      isLoading4: false,
      companyName4: companyName4,
      createdAtDate4: createdDate4,
      accountId:accountId,
    });
  };

  handleChangeRequestChangeRequest = (id: any) => {
    const { token, requestId } = this.state;
    this.updateChangeRqtDocuments = this.apiCallChangeRequest(
      token,
      `update_attachment_status?attachment_id=${id}&status=reviewed&change_request_id=${requestId}`,
      "PUT"
    );
    this.setState({ isLoading4: true });
  };

  handleChangeDocumentStatusUpdate = (res: any) => {
    if (res.status === "reviewed") {
      const { token, requestId } = this.state;
      this.getChangeRequestDetailsAPI = this.apiCallChangeRequest(
        token,
        `bx_block_documentation/factoring_agreements/${requestId}`,
        "GET"
      );
    } else {
      this.setState({
        isLoading4: false,
      });
    }
  };

  closedChangeRequest = () => {
    const { token, requestId , accountId , isApproveOpen , isRejectOpen , comment5 } = this.state;
    const data = {
      factoring_agreement_id: requestId,
      review_status: "closed",
    };

    if(isApproveOpen){
      const status = 'approve'
      const endPoint = `/approve_reject_change_request_form?factoring_agreement_id=${requestId}&factoring_agreement_status=${status}&account_id=${accountId}`
      this.changeRequestVerificationAPI = this.apiCallChangeRequest(
        token,
        endPoint,
        "PUT"
      );
    }else if(isRejectOpen){
      const status = 'reject'
      const endPoint = `/approve_reject_change_request_form?factoring_agreement_id=${requestId}&factoring_agreement_status=${status}&account_id=${accountId}&reason_for_rejecting=${comment5}`
      this.changeRequestVerificationAPI = this.apiCallChangeRequest(
        token,
        endPoint,
        "PUT"
      );
    }
    this.setState({ isLoading4: true });
  };

  hadleResChangeRequestVerificationAPI = (response: any) => {
    this.setState({
      isLoading4: false,
    });
    this.props.navigation.navigate("AdminNotifications");
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId4 = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson4 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId4 && responseJson4) {
        this.receiveApi4(apiRequestCallId4, responseJson4);
      }
    }
  }

  inReviewChangeRequest = () => {
    const { token, requestId , accountId } = this.state;
    const status : string = "in_review"
    this.setState({
      isLoading4: true,
    });
    this.changeRequestVerificationAPI = this.apiCallChangeRequest(
      token,
      `approve_reject_change_request_form?factoring_agreement_id=${requestId}&factoring_agreement_status=${status}&account_id=${accountId}`,
      "PUT"
    );
  };

  handleApproveModalOpen = () => {
    this.setState({
      isApproveOpen:true
    })
  }
  
  handleRejectModalOpen = () => {
    this.setState({
      isRejectOpen:true
    })
  }

  handleModalClose = () => {
    this.setState({
      isApproveOpen:false,
      isRejectOpen:false
    })
  }

  handleModalSubmit = () => {
    this.closedChangeRequest()
  }

  handleRejectInputChangeClient = (e: any) => {
    const { value, name } = e.target;
    const newState: any = {
      [name + 5 ]: value,
    };
    this.setState(newState);
  };

  apiCallChangeRequest = (
    token: token,
    endPoint: string,
    method: string,
    body?: any
  ) => {
    const bodyHeader5 = {
      token: token,
      "Content-Type": "application/json",
    };

    const header5 = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header5)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  receiveApi4 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.changeRequestVerificationAPI) {
      this.hadleResChangeRequestVerificationAPI(responseJson);
    }
    if (apiRequestCallId === this.getChangeRequestDetailsAPI) {
      this.handleChangeRequestDocumentsResponse(responseJson);
    }
    if (apiRequestCallId === this.updateChangeRqtDocuments) {
      this.handleChangeDocumentStatusUpdate(responseJson);
    }
  };
}
// Customizable Area End
