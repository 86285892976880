import React from "react";
import {
  Box,
  // Customizable Area Start
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import CustomTooltipWeb from "../../../components/src/Tooltip/CustomTooltip.web";
import { styles } from "./InvoiceStyles.web";
import Dropzone from "react-dropzone";
import {
  PurchaseOrderFields,
  userRoles,
} from "../../../components/src/Utils/constant";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CommonModalWeb from "../../../components/src/Modal/CommonModal.web";
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";
import { getUserRole, onKeyDown } from "../../../components/src/Utils/utils";
import AdminAdvanceSearchWeb from "../../../components/src/Search/AdminAdvanceSearch.web";
import NumberFormatCustom from "../../../components/src/CustomTextField/NumberFormatCustom"
const icons = require("./assets");
// Customizable Area End

import InvoiceController, { Props } from "./InvoiceController.web";

export class PurchaseOrder extends InvoiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const { classes } = this.props;
    const { open, message, severity } = this.state.snackbar;
    const {
      FileDetails,
      isPurchaseOrderActive,
      isModalOpen,
      isLoading,
      isSubmitInvoiceActive,
      formDataObject,
      searchedClients,
      selectedClient,
      isClientSearchLoaded,
    } = this.state;

    return (
      // Customizable Area Start
      <Box className={classes.boxContainerInvoice}>
        <Grid item xs={12} md={12} sm={12}>
          {isLoading && <CustomLoader open={isLoading} />}
          <Box
            sx={{ padding: "32px 50px", width: "100%" }}
            className={classes.rootInvoice}
          >
            <div className={classes.navigationButtonInvoice}>
              <Button
                data-test-id="submitNavigation"
                onClick={() => this.navigateToSubmitInvoice()}
                className={
                  isSubmitInvoiceActive
                    ? classes.navButtonActiveInvoice
                    : classes.navButtonNotActiveInvoice
                }
              >
                Submit Invoice
              </Button>
              <Button
                data-test-id="productNavigation"
                className={
                  isPurchaseOrderActive
                    ? classes.navButtonActiveInvoice
                    : classes.navButtonNotActiveInvoice
                }
                onClick={() => this.navigateToProductOrder()}
              >
                Submit purchase order
              </Button>
            </div>
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              validationSchema={this.purchaseOrderSchema}
              initialValues={formDataObject}
              onSubmit={(values: any, { resetForm }) =>
                this.handleFormSubmit(values, resetForm)
              }
            >
              {({ handleChange, handleSubmit, touched, errors, values }) => {
                return (
                    <Grid item xs={12}>
                      <form
                        onKeyDown={onKeyDown}
                        onSubmit={handleSubmit}
                        className={classes.userFormInvoice}
                      >
                        <div className={classes.FormHolderInvoice}>
                          <div className={classes.inputMapHolderInvoice}>
                            {getUserRole() !== userRoles.client && (
                              <Grid
                                className={classes.FieldLableHolderInvoice}
                                sm={12}
                                xs={12}
                                item
                                md={12}
                              >
                                <span id="purchase-search-client" className={classes.fieldLableInvoice}>Client*:</span>
                                <AdminAdvanceSearchWeb
                                  data-test-id="poClientSelect"
                                  options={searchedClients}
                                  handleSelectSearch={
                                    this.handleInvoiceClientSearch
                                  }
                                  loading={isClientSearchLoaded}
                                  searchValue={selectedClient}
                                  width="49%"
                                  handleInputChange={
                                    this.handleClientInputChange
                                  }
                                />
                              </Grid>
                            )}
                            {PurchaseOrderFields.map((item, index) => (
                              <Grid
                                key={item.id}
                                className={classes.FieldLableHolderInvoice}
                                item
                                sm={12}
                                xs={12}
                                md={index < 2 ? 6 : 4}
                              >
                                <span className={classes.fieldLableInvoice}>
                                  {item.lable}
                                  {item.isTooltipRequired ? (
                                    <Box sx={{ marginLeft: "5px" }}>
                                      <CustomTooltipWeb
                                        tooltipText={item.toolTipText}
                                        height="15px"
                                        width="15px"
                                        direction="top"
                                      />
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                </span>
                                
                                <TextField
                                  name={item.name}
                                  data-test-id={`purchase-order-id-${item.id}`}
                                  variant="outlined"
                                  InputProps={{
                                    inputComponent: item.prefix ? NumberFormatCustom as any : undefined,
                                    inputProps: {
                                      max:new Date().toISOString().split("T")[0], 
                                    },
                                  }}
                                  type={item.type}
                                  placeholder={item.placeHolder}
                                  helperText = {this.getHelperText(item, values, errors, touched)}
                                  error={this.handleErrors(
                                    errors?.[item.name],
                                    touched?.[item.name]
                                  )}
                                  value={values[item.name]}
                                  onChange={(event: any) => handleChange(event)}
                                  className={classes.inputTextFieldInvoice}
                                />
                              </Grid>
                            ))}
                          </div>
                        </div>
                        <div className={classes.dropzoneHolderInvoice}>
                          <span style={{ fontSize: "12px", fontWeight: 600 }}>
                            Upload Document*:{" "}
                          </span>
                          <span style={{ fontSize: "12px" }}>
                            File should be PDF, Excel, JPG, PNG.doc or .docx,
                            and the maximum file size should not exceed 20MB.
                          </span>
                          <Dropzone
                            multiple={false}
                            onDrop={(files) =>
                              this.handleInvoiceFileUpload(files)
                            }
                            data-test-id="FileDropFunction"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps()}
                                className={classes.dropzoneInvoice}
                              >
                                <img
                                  src={icons.image_Vuesax}
                                  alt=""
                                  className={classes.modalIconInvoice}
                                />
                                <input {...getInputProps()} />
                                <p className={classes.dropzoneTextInvoice}>
                                  Drop files here or Browse to upload
                                </p>
                                <Button
                                  variant="contained"
                                  className={classes.selectInvoice}
                                >
                                  Browse
                                </Button>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        <div className={classes.manageFilesInvoice}>
                          <span style={{ fontSize: "14px", fontWeight: 700 }}>
                            Uploaded files
                          </span>
                          <TableContainer>
                            <Table>
                              <TableBody
                                className={classes.fileViewTableInvoice}
                              >
                                {FileDetails.map((item) => (
                                  <TableRow key={item.id}>
                                    <TableCell>{item.file.name}</TableCell>
                                    <TableCell
                                      onClick={() => this.deleteFile(item.id)}
                                    >
                                      <img src={icons.Delete_icon} alt="" />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                        <div style={{ width: "100%" }}>
                          <Button
                            data-test-id={"btnSubmit1"}
                            fullWidth
                            className={classes.submitBtnInvoice}
                            type="submit"
                            variant="contained"
                          >
                            Submit PO
                          </Button>
                        </div>
                      </form>
                    </Grid>
                );
              }}
            </Formik>
          </Box>
        </Grid>
        <CommonModalWeb
          title="Thank you for submitting your purchase order! We have received your request and will process it as soon as possible. You can expect a confirmation email with further details shortly."
          open={isModalOpen}
          widthToAdd="80%"
          handleSubmitModal={this.handleModalClose}
          handleModelClose={this.handleModalClose}
          btnText="Done"
        />
        <CustomSnackbar
          open={open}
          onCloseSnack={this.closeSnackbar}
          message={message}
          severity={severity}
        />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(PurchaseOrder);
// Customizable Area End
