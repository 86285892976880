import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles.web";

const SearchIcon = require("../Assets/search.png");

interface SearchProps {
  options: any;
  width?: string;
  loading: boolean;
  searchValue: { id: string | number, companyName: string };
  handleSelectSearch: any;
  handleInputChange: any;
  handleSearchClick?: any;
  isSearchIcon?: boolean;
  classes: any;
  placeholder?: string;
}

class AdminAdvanceSearch extends Component<SearchProps> {
  constructor(props: SearchProps) {
    super(props);
  }

  render() {
    const {
      options,
      loading,
      searchValue,
      isSearchIcon,
      classes,
      width,
      handleInputChange,
      handleSelectSearch,
      handleSearchClick,
      placeholder
    } = this.props;

    return (
      <Autocomplete
        id="asynchronous-demo"
        options={options?.length > 0 ? options : []}
        getOptionSelected={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option?.companyName}
        onChange={(e, value) => { handleSelectSearch(e, value) }}
        style={{ width: width ? width : "300px" }}
        classes={{
          popper: classes?.advancePopper,
          listbox:  classes?.customListbox,
        }}
        value={searchValue || ''}
        fullWidth
        loading={loading}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder || "Search..."}
            value={searchValue?.companyName || ""}
            onChange={(event) => handleInputChange(event)}
            className={`${classes?.textSearchField} ${classes?.advanceSearchInput}`}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress className={classes?.circle} color="inherit" size={20} />}
                  {isSearchIcon && (
                    <img src={SearchIcon} className={classes?.searchIcon} onClick={handleSearchClick} />
                  )}
                  {!isSearchIcon && params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );

  }
}

export default withStyles(styles)(AdminAdvanceSearch);