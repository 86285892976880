import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Button, Typography, InputLabel, TextField, Table, TableBody, TableCell, TableRow, TableContainer } from "@material-ui/core";
import FactorAgreemenetReviewController, { Props } from "./FactorAgreemenetReviewController.web";
import { styles } from "./FactorAgreemenetReviewStyles.web";
import Dropzone from 'react-dropzone';
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";
import { downloadFiles } from '../../../components/src/Utils/utils'
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CommonModalWeb from "../../../components/src/Modal/CommonModal.web";
const icons = require("../../AdminConsole/src/assets")

export class FactorAgreemenetReview extends FactorAgreemenetReviewController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const { isLoading, documents, isDataAvialable, existingDocuments, isError, fieldError, isModalOpen } = this.state;
        const { open, message, severity } = this.state.snackbar

        return (
            <Box className={classes.widthProvider}>
                {isLoading && <CustomLoader open={isLoading} />}
                <Grid item >
                    <Box sx={{ padding: "0 50px 32px", width: '100%' }} className={classes.rootContainer} >
                        <Box>
                            <Typography className={classes.headerText}>Factoring Agreement</Typography>
                            <Typography className={classes.companyName}>
                                {this.state.companyName}
                            </Typography>
                        </Box>
                        <Box className={classes.dateFieldHolder}>
                            <InputLabel className={classes.agreementDateLable}>
                                Agreement Date*:
                            </InputLabel>
                            <TextField
                                type="date"
                                variant="outlined"
                                name="Agreement_Date"
                                data-test-id="Date-test-Field"
                                //@ts-ignore
                                value={this.state.agreementDate}
                                className={classes.agreementDateField}
                                onChange={(event: any) => this.handleDateChange(event.target.value)}
                                error={isError}
                                helperText={fieldError}
                            />
                        </Box>
                        <Box className={classes.documents}>
                            <Box className={classes.dropAreaHolder }>
                                <Typography className={classes.documentUploadLable}>Upload Documents*:</Typography>
                                <Typography className={classes.documentUploadDetails} >
                                    File  should be PDF, Excel, JPG, PNG.doc or .docx,
                                    and the maximum file size should not exceed 20MB.
                                </Typography>
                                <Dropzone
                                    multiple={false}
                                    onDrop={(files) => this.handleDocUpload(files)}
                                    data-test-id="FileDropFunction"
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <Box {...getRootProps()} className={classes.dropzone}>
                                            <img src={icons.image_Vuesax} alt="" className={classes.modalIcon} />
                                            <input {...getInputProps()} />
                                            <p>Drop files here or click to browse</p>
                                            <Button variant="contained" className={classes.dropzoneBrowser}>
                                                Browse
                                            </Button>
                                        </Box>
                                    )}
                                </Dropzone>
                            </Box>
                            <div className={classes.documentTable}>
                                <span style={{ fontSize: '12px', fontWeight: 700 }}>Existing files:</span>
                                <TableContainer>
                                    <Table>
                                        <TableBody className={classes.documentViewTable}>
                                            {isDataAvialable && (
                                                existingDocuments.map((item: any) => (
                                                    <TableRow key={item.id}>
                                                        <TableCell className={classes.widthProviderCell1}>{item.file.name}</TableCell>
                                                        {/* @ts-ignore */}
                                                        <TableCell className={classes.widthProviderCell2}>
                                                            {item.file.date}
                                                        </TableCell>
                                                        <TableCell className={classes.widthProviderCell3}>
                                                            <span className={classes.iconImageContainer}>
                                                                <img
                                                                    src={icons.Download}
                                                                    alt=""
                                                                    className={classes.fileUploadIconDownload}
                                                                    data-test-id="downloadIcon1"
                                                                    onClick={() => downloadFiles(item.file.url)}
                                                                />
                                                                <img onClick={() => this.handleDeleteDocFromAPI(item.file.id)} data-test-id="deleteIcon1" src={icons.Delete_icon} alt="" />
                                                            </span>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                            {documents.map((item: any) => {
                                                const timestamp =  item.file.lastModified;
                                                const date = new Date(timestamp);
                                                const formattedDate = date.toLocaleDateString('en-GB', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                }).split('/').join('-');

                                                return (
                                                    <TableRow key={item.id}>
                                                        <TableCell className={classes.widthProviderCell1}>{item.file.name}</TableCell>
                                                        <TableCell className={classes.widthProviderCell2}>{formattedDate}</TableCell>
                                                        <TableCell className={classes.widthProviderCell3}>
                                                            <span className={classes.iconImageContainer}>
                                                                <img
                                                                    src={icons.Download}
                                                                    alt=""
                                                                    className={classes.fileUploadIconDownload}
                                                                    data-test-id="downloadIcon2"
                                                                    onClick={() => downloadFiles(item.url)}
                                                                />
                                                                
                                                                <img
                                                                    onClick={() => this.deleteDoc(item.id)}
                                                                    src={icons.Delete_icon}
                                                                    data-test-id="deletIcon2"
                                                                    alt=""
                                                                />
                                                            </span>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Box>
                        <Box className={classes.saveButtonHolder}>
                            <Button
                                className={classes.submissionButton}
                                variant="contained"
                                onClick={this.handleFormSubmission}
                            >
                                Save Factor Agreement
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <CommonModalWeb
                    open={isModalOpen}
                    title="Changes have been saved."
                    btnText="Done"
                    handleModelClose={this.handleModalClose}
                    handleSubmitModal={this.handleRedirection}
                    widthToAdd='85%'
                />
                {/* @ts-ignore */}
                <CustomSnackbar open={open} onCloseSnack={this.closeSnackbar} message={message} severity={severity} />
            </Box>
        );
    }
}

export default withStyles(styles)(FactorAgreemenetReview);
