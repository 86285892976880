import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { refineNewsData , updatedChartData , updatedChartCardData , totalFactorCalculator} from "../../../components/src/Utils/utils"

export interface Props {
  classes: any;
  navigation: any;
}

export type responseChartData = {
    color: string
    name: string
    value: string | number
    radius: string | number
    fillColors: string
};

type token = string | null

interface S {
  tooltipTitle: string;
  chartData: responseChartData[];
  TotalFactorAmmount: number;
  currentNews : any[]
  token : token ;
  dashboardData:any ;
  cardData: any;
  TotalFactorAmmountRender:string
}

interface SS {
  id: any;
}

export default class ClientDashboardController extends BlockComponent<Props, S, SS> {
  getClientNews: string = "";
  getClientDashboardDataAPI: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      tooltipTitle: "show the tooltip on the dashboard",
      chartData: [],
      TotalFactorAmmount: 0,
      currentNews:[],
      token: '',
      dashboardData:{},
      cardData:[],
      TotalFactorAmmountRender:''
    };
  }

  async componentDidMount() {
    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else {
      const token = localStorage.getItem("auth_token");
      this.getClientNews = this.apiCall(token , "bx_block_dashboard/news" , "GET")
      this.getClientDashboardDataAPI = this.apiCall(token , "get_client_dashboard_data" , "GET")
    }
  }
  
  HandleGetNewsAPIResponse = (response : any) => {
    const refinedNewsData = refineNewsData(response)
    this.setState({
      currentNews: refinedNewsData
    })
  }
  
  HandleGetDashboardAPIResponse = (response : any) => {
    const cardMapperData = updatedChartCardData(response)
    const chartData = updatedChartData(response)
    const totalFactorValue = totalFactorCalculator(response)
    this.setState({
      dashboardData:response,
      chartData:chartData,
      cardData:cardMapperData,
      TotalFactorAmmount: totalFactorValue,
      TotalFactorAmmountRender: response.factor_limit,
    })
  }

  handleNewsRedirection = () => {
    this.props.navigation.navigate("CarbyneNews")
  }

  handleSingleNewsRedirection = (newsId:string) => {
    this.props.navigation.navigate("NewsClientDetails", {id: newsId})
}


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getClientNews) {
      this.HandleGetNewsAPIResponse(responseJson.data)
    }
    if (apiRequestCallId === this.getClientDashboardDataAPI) {
      if(responseJson.error) return
      this.HandleGetDashboardAPIResponse(responseJson)
    }
  }

  apiCall = (token: token , endPoint: string , method : string , body? : any ) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId
  }
  
}
